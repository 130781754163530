<template>
  <div class="wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '' }">数据看板</el-breadcrumb-item>
      <el-breadcrumb-item>
        <router-link :to="{ path: 'IncomeStatistics?menuId=111' }">盈亏统计</router-link>
      </el-breadcrumb-item>
      <!-- <el-breadcrumb-item>
        <router-link :to="{ path: 'PortfolioDetails' }">投资组合详情</router-link>
      </el-breadcrumb-item> -->
      <el-breadcrumb-item :to="{ path: '' }">投资组合详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!-- title：数据总览 -->
    <div class="statistics">
      <!-- <h1>数据总览</h1> -->
      <span>每小时更新数据</span>
    </div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item>
        <el-select v-model="formInline.valueEXchange" placeholder="请选择">
          <el-option
            v-for="item in optionsExchange"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <!-- 日期选择器 -->
        <div class="block">
          <el-date-picker
            v-model="formInline.assetsDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy - MM - dd"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
          <el-button
            icon="el-icon-search"
            type="primary"
            style="margin-left: 15px"
            @click="clickInquire"
          >查询</el-button>
        </div>
      </el-form-item>
    </el-form>
    <div id="main" :style="{ width: '100%', height: '500px' }" v-loading="TableLoading"></div>
    <el-table :data="tableData.list" border style="width: 100%" v-loading="TableLoading">
      <el-table-column label="序号" width="60">
        <template v-slot="scope">
          <p>{{ scope.$index + 1 }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="统计日期"></el-table-column>
      <el-table-column prop="initialAsset" label="初始资产(USDT)"></el-table-column>
      <el-table-column prop="balance" label="当前净值(USDT)"></el-table-column>
      <el-table-column prop="totalBalance" label="累计收益(USDT)"></el-table-column>
      <el-table-column prop="totalYield" label="累计收益率(%)" width="120"></el-table-column>
      <!-- <el-table-column prop="nowMaxBalanceBack" label="最大资产回撤(USDT)">
      </el-table-column>
      <el-table-column
        prop="nowMaxBalanceBackScale"
        label="最大资产回撤比例(%)"
      ></el-table-column>-->
      <el-table-column prop="runningDay" label="累计运行天数(天)"></el-table-column>
      <el-table-column prop="teamName" label="团队"></el-table-column>
      <el-table-column prop="combinationName" label="交易所名称"></el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageList.page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageList.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      optionsExchange: [], //交易所组合下拉 表头
      formInline: {
        valueEXchange: "", // 表头交易所组合下拉 model值
        assetsDate: ["", ""] // 自选日期
      },
      tableData: [],
      pageList: {
        page: 1, //当前是第几页
        pageSize: 10 //一页多少条数据
      },
      TableLoading: false
    };
  },
  watch: {},
  computed: {},
  methods: {
    // 交易所组合下拉菜单的数据 -- 用于查询列表（交易所组合）
    getSearchSelect() {
      this.axios.get("/business/admin/config/combinations-all").then(res => {
        // console.log("交易所组合下拉", res.data.data);
        this.optionsExchange = res.data.data;
        if (this.$route.query.configId) {
          this.formInline.valueEXchange = parseInt(this.$route.query.configId);
        } else {
          this.formInline.valueEXchange = res.data.data[0].id;
        }
        this.clickInquire();
      });
    },
    // 获取盈亏统计-投资组合资产明细
    getComboDetailPage() {
      this.axios
        .get("/business/admin/snapshot/combo-detail-page", {
          params: {
            configId: this.formInline.valueEXchange,
            startTime: this.formInline.assetsDate
              ? this.formInline.assetsDate[0]
              : "",
            endTime: this.formInline.assetsDate
              ? this.formInline.assetsDate[1]
              : "",
            page: this.pageList.page,
            pageSize: this.pageList.pageSize
          }
        })
        .then(res => {
          // console.log("盈亏统计-投资组合资产明细:", res.data.data);
          this.tableData = res.data.data;
        });
    },
    // 获取盈亏统计-投资组合详情
    getComboDetail() {
      this.axios
        .get("/business/admin/snapshot/combo-detail", {
          params: {
            configId: this.formInline.valueEXchange,
            startTime: this.formInline.assetsDate
              ? this.formInline.assetsDate[0]
              : "",
            endTime: this.formInline.assetsDate
              ? this.formInline.assetsDate[1]
              : ""
          }
        })
        .then(res => {
          console.log("盈亏统计-投资组合详情:", res.data);
          // this.tableData = res.data;
          if (res.data.status === "success") {
            let arr1 = []; // 总资产
            let arr2 = []; // 时间
            let arr3 = []; // 收益率
            for (let i = 0; i < res.data.data.length; i++) {
              arr1.push(res.data.data[i].balance);
              arr2.push(res.data.data[i].createTime);
              arr3.push(res.data.data[i].totalYield);
            }
            this.ComboDetailRandomData(arr1, arr2, arr3);
            this.TableLoading = false;
          }
        });
    },
    // 查询按钮，发两个接口
    clickInquire() {
      this.TableLoading = true;
      this.getComboDetail();
      this.getComboDetailPage();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageList.pageSize = val;
      this.clickInquire();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageList.page = val;
      this.clickInquire();
    },
    // 加载折线图
    ComboDetailRandomData(totalBalance, createTime, Yield) {
      const dom = document.getElementById("main");
      const chart = this.$echarts.init(dom);
      const money = totalBalance;
      const option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: createTime
        },
        yAxis: {
          type: "value"
        },
        tooltip: {
          trigger: "axis",
          formatter: function(params) {
            // console.log("parm", params);
            return (
              "日期：" +
              (params[0].name ? params[0].name : "") +
              "<br/>" +
              "累计收益率： " +
              (params[0].value ? params[0].value : "0") +
              "%" +
              "<br/>" +
              "总资产： " +
              money[params[0].dataIndex] +
              "USDT"
            );
          }
        },
        series: [
          {
            data: Yield,
            type: "line",
            areaStyle: {}
          }
        ]
      };
      chart.setOption(option);
    }
  },
  created() {
    this.ComboDetailRandomData(); // 初始化折线图
  },
  mounted() {
    // this.ComboDetailRandomData(); // 初始化折线图
    this.getSearchSelect(); // 交易所组合下拉菜单的数据 -- 用于查询列表（交易所组合）
    // this.getComboDetail(); // 盈亏统计-投资组合资产明细
  }
};
</script>
<style lang="scss" scope>
.wrapper {
  // background-color: aquamarine;
  margin: 0 2%;
  width: 96%;
  .el-divider--horizontal {
    margin: 12px 0;
  }
  .statistics {
    h1 {
      font-size: 18px;
      display: inline-block;
    }
    span {
      // margin-left: 15px;
      font-size: 14px;
      color: #bdaaaa;
      line-height: 18px;
      margin: 10px 0;
      display: inline-block;
    }
  }
  .card-item {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 10px auto;
    .el-card {
      display: inline-block;
      width: 30%;
      height: 100%;
      .el-card__body {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }
    }
    .card-top {
      font-size: 16px;
      .top-left {
        float: left;
        color: #ffffff;
        p {
          font-size: 12px;
        }
      }
      .top-right {
        float: right;
      }
    }
    .card-middle {
      text-align: center;
      font-size: 24px;
      color: #ffffff;
      margin: 3px auto;
    }
    .card-bottom {
      float: right;
      color: #ffffff;
    }
  }
  // .block {
  //   margin: 15px 0;
  // }
}
</style>
