<!-- 系统设置 - 跟单账户自动划账配置信息 -->
<template>
  <div class="user-group">
    <cloud-title title="系统配置相关选项值" />
    <!-- input -->
    <div class="role-wrapper">

      <div class="content-wrapper">
        <el-form ref="netMulForm" :model="formInput">
          <div class="tits">1.跟单脚本的启动与停止密码设置</div>
          <el-form-item style="width: 100%" label="启动密码：" prop="value">
            <el-input v-model="formInput.value" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <button style="padding:12px 30px" class="confirmBtn" @click="updateNetMul">确定保存</button>
          </el-form-item>
        </el-form>

        <el-form ref="netMulForm" :model="formInput1">
          <div class="tits">2.修改动态比例调节时间分钟数</div>
          <el-form-item style="width: 100%" label="动态比例调节时间：" prop="value">
            <el-input v-model="formInput1.value" placeholder="请输入时间分钟数"></el-input>
          </el-form-item>

          <el-form-item>
            <button style="padding:12px 30px" class="confirmBtn" @click="updateMenute">确定保存</button>
          </el-form-item>
        </el-form>

        <el-form ref="netMulForm" :model="robotForm">
          <div class="tits">3.服务商机器人设置</div>
          <el-form-item style="width: 100%" label="密码：" prop="value">
            <el-input v-model="robotForm.value" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <button style="padding:12px 30px" class="confirmBtn" @click="updateRobot">确定保存</button>
          </el-form-item>
        </el-form>

        <el-form ref="netMulForm" :model="curryForm">
          <div class="tits">4.一键结算并初始化本金</div>
          <el-form-item style="width: 100%" label="密码：" prop="value">
            <el-input v-model="curryForm.value" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <button style="padding:12px 30px" class="confirmBtn" @click="updateCurry">确定保存</button>
          </el-form-item>
        </el-form>

        <el-form ref="netMulForm" :model="serviceForm">
          <div class="tits">5.服务商一键平仓</div>
          <el-form-item style="width: 100%" label="密码：" prop="value">
            <el-input v-model="serviceForm.value" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <button style="padding:12px 30px" class="confirmBtn" @click="updateService">确定保存</button>
          </el-form-item>
        </el-form>

        <el-form ref="netMulForm" :model="dataStartForm">
          <div class="tits">6.数据统计启动</div>
          <el-form-item style="width: 100%" label="密码：" prop="value">
            <el-input v-model="dataStartForm.value" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <button style="padding:12px 30px" class="confirmBtn" @click="updateStart">确定保存</button>
          </el-form-item>
        </el-form>
      </div>

    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
import SystemManage from "../../common/utils/api/system";

export default {
  data() {
    return {
      formInput: {
        value: ''
      },
      formInput1: {
        value: ''
      },
      robotForm: {
        value: ''
      },
      curryForm: {
        value: ''
      },
      serviceForm: {
        value: ''
      },
      dataStartForm: {
        value: ''
      }
    };
  },
  methods: {
    // 获取密码
    getPassWord() {
      this.listLoading = true;
      SystemManage.getPassWord({
        code: 'scriptPwd'
      }).then((res) => {
        this.listLoading = false;
        if (res.status === "success") {
          this.formInput.value = res.data.value
          this.listLoading = false;
        } else {
          return false;
        }
      });
    },
    // 获取分钟数
    geSetMenutes() {
      this.listLoading = true;
      SystemManage.getPassWord({
        code: 'queueNumber'
      }).then((res) => {
        this.listLoading = false;
        if (res.status === "success") {
          this.formInput1.value = res.data.value
          this.listLoading = false;
        } else {
          return false;
        }
      });
    },
    // 获取服务商机器人密码
    geSetRobot() {
      this.listLoading = true;
      SystemManage.getPassWord({
        code: 'groupRobotPwd'
      }).then((res) => {
        this.listLoading = false;
        if (res.status === "success") {
          this.robotForm.value = res.data.value
          this.listLoading = false;
        } else {
          return false;
        }
      });
    },
    // 获取一键结算面板密码
    geSetCurry() {
      this.listLoading = true;
      SystemManage.getPassWord({
        code: 'groupSettlementPwd'
      }).then((res) => {
        this.listLoading = false;
        if (res.status === "success") {
          this.curryForm.value = res.data.value
          this.listLoading = false;
        } else {
          return false;
        }
      });
    },
    // 获取服务商一键平仓
    getService() {
      this.listLoading = true;
      SystemManage.getPassWord({
        code: 'groupOneTouchPwd'
      }).then((res) => {
        this.listLoading = false;
        if (res.status === "success") {
          this.serviceForm.value = res.data.value
          this.listLoading = false;
        } else {
          return false;
        }
      });
    },
    // 获取数据统计密码
    getDataStart() {
      this.listLoading = true;
      SystemManage.getPassWord({
        code: 'groupStatisticalPwd'
      }).then((res) => {
        this.listLoading = false;
        if (res.status === "success") {
          this.dataStartForm.value = res.data.value
          this.listLoading = false;
        } else {
          return false;
        }
      });
    },

    // 保存密码
    updateNetMul() {
      SystemManage.setPassWord({
        code: 'scriptPwd',
        value: this.formInput.value
      }).then(res => {
        if (res.status === "success") {
          this.$notify({
            title: "操作提示",
            offset: 100,
            type: res.status,
            message: "修改成功！"
          });
          this.getPassWord();
        } else {
          this.$notify({
            title: "操作提示",
            offset: 100,
            type: "error",
            message: res.message
          });
        }
      });
    },
    // 保存分钟数
    updateMenute() {
      SystemManage.setPassWord({
        code: 'queueNumber',
        value: this.formInput1.value
      }).then(res => {
        if (res.status === "success") {
          this.$notify({
            title: "操作提示",
            offset: 100,
            type: res.status,
            message: "修改成功！"
          });
          this.geSetMenutes();
        } else {
          this.$notify({
            title: "操作提示",
            offset: 100,
            type: "error",
            message: res.message
          });
        }
      });
    },
    // 保存服务商机器人密码
    updateRobot() {
      SystemManage.setPassWord({
        code: 'groupRobotPwd',
        value: this.robotForm.value
      }).then(res => {
        if (res.status === "success") {
          this.$notify({
            title: "操作提示",
            offset: 100,
            type: res.status,
            message: "修改成功！"
          });
          this.geSetRobot();
        } else {
          this.$notify({
            title: "操作提示",
            offset: 100,
            type: "error",
            message: res.message
          });
        }
      });
    },
    // 保存一键结算面板密码
    updateCurry() {
      SystemManage.setPassWord({
        code: 'groupSettlementPwd',
        value: this.curryForm.value
      }).then(res => {
        if (res.status === "success") {
          this.$notify({
            title: "操作提示",
            offset: 100,
            type: res.status,
            message: "修改成功！"
          });
          this.geSetCurry();
        } else {
          this.$notify({
            title: "操作提示",
            offset: 100,
            type: "error",
            message: res.message
          });
        }
      });
    },
    // 保存服务商一键平仓
    updateService() {
      SystemManage.setPassWord({
        code: 'groupOneTouchPwd',
        value: this.serviceForm.value
      }).then(res => {
        if (res.status === "success") {
          this.$notify({
            title: "操作提示",
            offset: 100,
            type: res.status,
            message: "修改成功！"
          });
          this.getService();
        } else {
          this.$notify({
            title: "操作提示",
            offset: 100,
            type: "error",
            message: res.message
          });
        }
      });
    },
    // 保存数据统计密码
    updateStart() {
      SystemManage.setPassWord({
        code: 'groupStatisticalPwd',
        value: this.dataStartForm.value
      }).then(res => {
        if (res.status === "success") {
          this.$notify({
            title: "操作提示",
            offset: 100,
            type: res.status,
            message: "修改成功！"
          });
          this.getDataStart();
        } else {
          this.$notify({
            title: "操作提示",
            offset: 100,
            type: "error",
            message: res.message
          });
        }
      });
    }
  },

  created() {
    this.getPassWord();
    this.geSetMenutes();
    this.geSetRobot();
    this.geSetCurry();
    this.getService();
    this.getDataStart();
  },
};
</script>

<style lang="scss">
.role-wrapper{
  padding: 20px;
  position: relative;
  .tits {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px
  }
  .el-form-item {
    display: flex;
    margin-bottom: 40px!important;
    .el-form-item__label {
      font-size: 16px;
      font-weight: bold;
    }
    .el-form-item__content {
      display: flex;
      .fonts{
        font-size:15px;
        margin:0 20px
      }
    }
  }
}
</style>