<!-- 系统管理 - 菜单管理 -->
<template>
  <div class="menu">
    <cloud-title title="菜单管理" />
    <!-- input -->
    <div class="menu-wrapper">
      <div class="form-wrapper">
        <el-form :inline="true" class="form-inline" onsubmit="return false;">
          <el-input
            v-model="searchInfo.menuName"
            clearable
            placeholder="请输入菜单名称"
          />
          <el-select
            v-model="searchInfo.levelNum"
            clearable
            placeholder="选择菜单等级"
          >
            <el-option label="一级菜单" value="1" />
            <el-option label="二级菜单" value="2" />
          </el-select>
          <el-select
            v-model="searchInfo.parentId"
            clearable
            placeholder="选择上级菜单"
          >
            <el-option
              v-for="(item, index) in allOneMenu.list"
              :key="index"
              :label="item.menuName"
              :value="item.id"
            />
          </el-select>
          <!-- button -->
          <el-button
            @click="getMenuList"
            class="blue-btn"
            icon="icon-cloud-search"
            >查询</el-button
          >
          <el-button
            @click="addMenu"
            class="black-btn"
            icon="el-icon-zoom-in"
            >新增</el-button
          >
        </el-form>
      </div>

      <!-- table -->
      <el-table
        :data="dataList"
        stripe
        v-loading="listLoading"
        border
        style="width: 100%; margin-top: 20px;"
        height="380px"
      >
        <el-table-column align="center" prop="menuName" label="菜单名称" />
        <el-table-column
          align="center"
          prop="remark"
          :formatter="isNull"
          label="菜单描述"
        />
        <el-table-column align="center" prop="menuUrl" label="菜单链接" />
        <el-table-column
          align="center"
          prop="levelNum"
          :formatter="setLevelText"
          label="菜单等级"
        />
        <el-table-column
          align="center"
          prop="parentId"
          :formatter="setParenttext"
          label="上级菜单"
        />
        <el-table-column align="center" prop="orderby" label="排序" />
        <el-table-column align="center" prop="createTime" label="录入时间" />
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <a
              @click="handleUpdate(scope.$index, scope.row)"
              href="javascript:;"
              class="blueColor"
              >修改</a
            >
            <a
              @click="handleDelete(scope.row.id)"
              href="javascript:;"
              class="blueColor"
              >删除</a
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- pagination -->
      <cloud-pagination
        v-show="pageTotal"
        :total="pageTotal"
        :listQuery="listQuery"
        @currentChange="currentChange"
        @sizeChange="sizeChange"
      />

      <!-- add - update  dialog -->
      <cloud-dialog
        width="38%"
        :title="title"
        :visible.sync="showMenuDialog"
        :modal-append-to-body="false"
        @close="closeDialog"
      >
        <el-form :model="menuInfo" :rules="menuFormRule" ref="menuForm">
          <el-form-item
            label="菜单名称："
            :label-width="formLabelWidth"
            prop="menuName"
          >
            <el-input
              v-model="menuInfo.menuName"
              placeholder="请输入菜单名称"
              auto-complete="off"
            />
          </el-form-item>
          <el-form-item
            label="菜单链接："
            :label-width="formLabelWidth"
            prop="menuUrl"
          >
            <el-input
              v-model="menuInfo.menuUrl"
              placeholder="请输入菜单链接"
              auto-complete="off"
            />
          </el-form-item>
          <el-form-item
            label="菜单排序："
            :label-width="formLabelWidth"
            prop="orderby"
          >
            <el-input
              v-model="menuInfo.orderby"
              type="number"
              min="1"
              onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
              @mousewheel.native.prevent
              @wheel.native.prevent
              placeholder="请输入菜单排序"
              auto-complete="off"
            />
          </el-form-item>
          <el-form-item
            label="上级菜单："
            :label-width="formLabelWidth"
            prop="parentId"
          >
            <el-select
              style="width: 100%;"
              placeholder="请选择上级菜单"
              v-model="menuInfo.parentId"
            >
              <el-option label="我是一级菜单" :value="0" />
              <el-option
                v-for="(item, index) in allOneMenu.list"
                :key="index"
                :label="item.menuName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="菜单等级："
            :label-width="formLabelWidth"
            prop="levelNum"
          >
            <el-select
              style="width: 100%;"
              v-model="menuInfo.levelNum"
              placeholder="请选择菜单等级"
            >
              <el-option label="一级菜单" :value="1" />
              <el-option label="二级菜单" :value="2" />
            </el-select>
          </el-form-item>
          <el-form-item
            label="菜单描述："
            :label-width="formLabelWidth"
            prop="remark"
          >
            <el-input
              v-model="menuInfo.remark"
              placeholder="请输入菜单描述，可选"
              auto-complete="off"
            />
          </el-form-item>
          <el-form-item
            label="菜单图标："
            :label-width="formLabelWidth"
            prop="menuIcon"
          >
            <el-input
              v-model="menuInfo.menuIcon"
              placeholder="请输入菜单图标"
              auto-complete="off"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <button class="cancelBtn" @click="showMenuDialog = false">
            取 消
          </button>
          <button class="confirmBtn" v-if="isAdd" @click="saveAddMenu">
            确 定
          </button>
          <button class="confirmBtn" v-if="isUpdate" @click="saveUpdateMenu">
            确定修改
          </button>
        </div>
      </cloud-dialog>

      <cloud-dialog title="温馨提示" :visible="showConfirm">
        <div class="tc" style="padding: 30px 0;">
          <p>确定删除所选择的内容吗?</p>
        </div>
        <div slot="footer" class="dialog-footer">
          <button class="cancelBtn" @click="showConfirm = false">取 消</button>
          <button class="confirmBtn" @click="sureDelMenu">确定</button>
        </div>
      </cloud-dialog>
    </div>
  </div>
</template>

<script>
// import Vue from "vue";
import SystemManage from "../../common/utils/api/system";
import PublicMixin from "../mixins";
// import { Component } from "vue-property-decorator";

// @Component({
//   name: 'MenuManage'
// })

export default {
  mixins: [PublicMixin],
  data() {
    return {
      searchInfo: {
        menuName: "", // 菜单名称
        levelNum: "", // 菜单等级
        parentId: "", // 上级菜单
      },
      formLabelWidth: "140px",
      showMenuDialog: false,
      allOneMenu: {
        list:[]
      }, // 所有一级菜单
      menuInfo: {
        levelNum: "", // 菜单等级
        menuName: "", // 菜单名称
        menuIcon: "", // 菜单图标
        menuUrl: "", // 菜单链接
        orderby: "", // 排序
        parentId: "", // 上级菜单ID，一级菜单值为0
        remark: "", // 描述
      },

      menuFormRule: {
        menuName: [
          { required: true, message: "请输入菜单名称", trigger: "blur" },
        ],
        levelNum: [
          { required: true, message: "请输入菜单等级", trigger: "blur" },
        ],
        menuUrl: [
          { required: true, message: "请输入菜单链接", trigger: "blur" },
        ],
        orderby: [
          { required: true, message: "请输入菜单顺序", trigger: "blur" },
        ],
        parentId: [
          { required: true, message: "请输入上级菜单ID", trigger: "blur" },
        ],
      },

      title: "",
      delLoading: false,
      isAdd: false,
      isUpdate: false,
      // $refs: {
      //   menuForm: HTMLFormElement,
      // },
      showConfirm: false,
      systemMenuId: "",
      btns: {
        insert: 0,
        update: 0,
        delete: 0,
        selectMenus: 0,
      },
    };
  },
  methods: {
    exitBtn() {
      this.showMenuDialog = false;
      this.isAdd = false,
      this.isUpdate = false
    },
    getLevelMenu() {
      SystemManage.getMenuByLevel(1).then((res) => {
        this.allOneMenu = res.data;
      });
    },
    getMenuList(flag = true) {
      if (flag) {
        this.listQuery.page = 1;
        this.listQuery.pageSize = 10;
      }
      const params = Object.assign({}, this.searchInfo, this.listQuery);
      this.listLoading = true;
      SystemManage.getMenuList(params).then((res) => {
        this.listLoading = false;
        if (res.status === "success") {
          this.pageTotal = res.data.total; // 设置总条数
          this.dataList = res.data.list;
        } else {
          this.getListError();
        }
      });
    },

    addMenu() {
      this.title = "添加菜单";
      this.showMenuDialog = true;
      this.isAdd = true;
      this.isUpdate = false;
      this.$refs.menuForm.resetFields();
    },

    isNull(row) {
      return !row.remark ? "暂无" : row.remark;
    },

    // 保存新增菜单
    saveAddMenu() {
      this.$refs.menuForm.validate((valid) => {
        if (valid) {
          SystemManage.addMenu(this.menuInfo, "Object").then((res) => {
            this.operTips(res, () => {
              this.showMenuDialog = false;
              this.getMenuList();
              this.getLevelMenu();
            });
          });
        } else {
          return false;
        }
      });
    },

    // 修改菜单
    saveUpdateMenu() {
      this.$refs.menuForm.validate((valid) => {
        if (valid) {
          SystemManage.updateMenuById(this.menuInfo, "Object").then((res) => {
            this.operTips(res, () => {
              this.showMenuDialog = false;
              this.getMenuList();
              this.getLevelMenu();
            });
          });
        } else {
          return false;
        }
      });
    },

    handleUpdate(index) {
      this.title = "修改菜单";
      this.showMenuDialog = true;
      this.isUpdate = true;
      this.isAdd = false;

      const item = this.dataList[index];
      window.console.log(item);
      this.menuInfo = {
        levelNum: item.levelNum,
        menuName: item.menuName,
        menuUrl: item.menuUrl,
        orderby: item.orderby,
        parentId: item.parentId,
        remark: item.remark,
        // menuIcon: '',
        menuIcon: item.menuIcon,
        id: item.id,
      };
    },

    handleDelete(menuId) {
      this.systemMenuId = menuId;
      this.showConfirm = true;
    },

    sureDelMenu() {
      SystemManage.delMenuById({ id: this.systemMenuId }).then((res) => {
        this.showConfirm = false;
        this.operTips(res, () => {
          this.getMenuList();
        });
      });
    },

    currentChange(page) {
      this.listQuery.page = page;
      this.getMenuList(false);
    },

    sizeChange(pageSize) {
      this.listQuery.pageSize = pageSize;
      this.getMenuList(false);
    },

    closeDialog() {
      this.title = "";
      this.isAdd = false;
      this.isUpdate = false;
      this.$refs.menuForm.resetFields();
    },

    setLevelText(row) {
      return row.levelNum === 1 ? "一级菜单" : "二级菜单";
    },

    setParenttext(row) {
      let name = "";
      this.allOneMenu.list.filter((item) => {
        if (item.id === row.parentId) {
          name = item.menuName;
        } else if (row.parentId === 0) {
          name = "没有上级菜单";
        }
      });
      return name;
    },
  },

  created() {
    this.getMenuList();
    this.getLevelMenu();
  },
};
</script>
