<template>
  <div>
    <!-- 跟单异常 -->
    <div v-if="type == 1">
      <h2>跟单异常</h2>
      <el-divider></el-divider>
      <el-table border ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%;margin-top:20px;"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column prop="bossName" label="跟单账户" width="120">
        </el-table-column>
        <el-table-column prop="followName" label="服务商账户">
        </el-table-column>
        <el-table-column prop="errorType" label="异常类型">
        </el-table-column>
        <el-table-column prop="errorCode" label="API返回错误码">
        </el-table-column>
        <el-table-column prop="errorMessage" label="错误信息" width="120">
        </el-table-column>
        <el-table-column prop="location" label="错误位置" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="createTime" label="触发时间">
        </el-table-column>
        <el-table-column prop="logLevel" label="日志级别">
        </el-table-column>
        <el-table-column prop="exchange" label="涉及交易所">
        </el-table-column>
        <el-table-column prop="isHandle" label="处理状态">
          <template slot-scope="scope">
            <span v-if="scope.row.isHandle === 0">未处理</span>
            <span v-else>已处理</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注">
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <span @click="handleAbnormal(scope.row)" v-if="scope.row.isHandle === 0" style="color: red;">[处理异常]</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 跟单账单操作记录 -->
    <div v-else>
      <h2 class="titles">
        跟单账户操作记录
        <span @click="goBack">返回</span>
      </h2>
      <el-divider></el-divider>
      <el-date-picker v-model="searchDate" value-format="yyyy-MM-dd" type="daterange" range-separator="至"
        start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <el-button type="primary" icon="el-icon-search" style="margin-left: 1rem" @click="searchData">
        搜索
      </el-button>
      <el-table border ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%;margin-top:20px;">
        <el-table-column prop="bossName" label="跟单账户" width="120">
        </el-table-column>
        <el-table-column prop="followName" label="服务商账户">
        </el-table-column>
        <el-table-column prop="operationType" label="操作的功能" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="describe" label="操作的内容" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="isResult" label="操作的结果" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.isResult === 0">失败</span>
            <span v-else>成功</span>
          </template>
        </el-table-column>
        <el-table-column prop="createName" label="操作人" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="createTime" label="时间" show-overflow-tooltip>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="跟单异常处理" :visible.sync="dialogFormVisible" @close="closeWind">
      <el-form :model="form">
        <el-form-item label="异常错误信息">
          <el-input v-model="form.errorMessage" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">暂不处理</el-button>
        <el-button type="primary" @click="updateException">处理异常</el-button>
      </div>
    </el-dialog>
    <!-- 分页 -->
    <el-pagination style="text-align: center;" background layout="prev, pager, next, sizes" :total="pageNum"
      :page-sizes="[10, 20]" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page">
    </el-pagination>
  </div>
</template>

<script>
import SystemManage from "../../common/utils/api/system";
export default {
  data() {
    return {
      pageSize: 10,
      page: 1,
      pageNum: null,
      searchDate: [],
      groupId: null,
      type: 0,
      orderId: '',
      form: {
        id: null,
        errorMessage: '',
        remark: ''
      },
      dialogFormVisible: false,
      list: [],
      multipleSelection: []
    }
  },
  mounted() {
    this.type = this.$router.history.current.query.type
    this.groupId = this.$router.history.current.query.groupId
    if (this.type == 1) {
      // 异常订单
      this.getAllOrder()
    } else {
      // 操作日志
      this.getOperationLog()
    }
  },
  methods: {
    // 关闭窗口时
    closeWind() {
      this.form.remark = ''
    },
    // 返回上一级页面
    goBack() {
      this.$router.go(-1);
    },
    // 搜索
    searchData() {
      this.page = 1
      this.getOperationLog()
    },
    newList() {
      this.list.forEach((v, i) => {
        switch (true) {
          case v.operationType === 1:
            this.list[i].operationType = '停止跟单'
            break;
          case v.operationType === 2:
            this.list[i].operationType = '开启跟单'
            break;
          case v.operationType === 3:
            this.list[i].operationType = '正向跟单'
            break;
          case v.operationType === 4:
            this.list[i].operationType = '反向跟单'
            break;
          case v.operationType === 5:
            this.list[i].operationType = '跟单比例'
            break;
          default:
            break;
        }
      })
    },
    // 处理异常
    handleAbnormal(val) {
      this.dialogFormVisible = true
      this.form.errorMessage = val.errorMessage
      this.form.id = val.id
    },
    updateException() {
      SystemManage.updateException({
        remark: this.form.remark,
        id: this.form.id
      }).then(res => {
        if (res.status === 'success') {
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.dialogFormVisible = false
          this.getAllOrder()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })
    },
    // 获取操作日志
    getOperationLog() {
      let startDate
      let overDate
      if (this.searchDate) {
        startDate = this.searchDate[0]
        overDate = this.searchDate[1]
      }
      console.log('page', this.page)
      SystemManage.getOperationLog({
        page: this.page,
        pageSize: this.pageSize,
        groupId: this.groupId,
        startDate,
        overDate
      }).then(res => {
        if (res.status === 'success') {
          this.list = res.data.list
          this.newList()
          this.pageNum = res.data.total
        }
      })
    },
    // 获取所有异常订单
    getAllOrder() {
      SystemManage.getAllOrder({
        groupId: this.groupId,
        page: this.page,
        pageSize: this.pageSize
      }).then(res => {
        if (res.status === 'success') {
          this.list = res.data.list
          this.pageNum = res.data.total
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })
    },
    // 全选按钮
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 分页
    handleCurrentChange(val) {
      this.page = val
      if (this.type == 1) {
        this.getAllOrder()
      } else {
        this.getOperationLog()
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
      if (this.type == 1) {
        this.getAllOrder()
      } else {
        this.getOperationLog()
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.titles{
  position: relative;
  span{
    font-size: 18px;
    font-weight: normal;
    color: blue;
    cursor: pointer;
  }
}
</style>