 <template>
  <div>
    <div class="riskControl">
      <div class="riskControl-left">
        <div
          class="riskControl-body"
          v-for="(item, index) in riskList"
          :key="index"
        >
          <div class="one-header">
            <span>{{ item.teamName }}</span>
          </div>

          <div
            class="riskcontrol-one"
            v-for="(itemRules, indexRules) in item.rules"
            :key="indexRules"
          >
            <div class="one-body">
              <!-- <el-tooltip :content="'Switch value: ' + itemRules.isOn" placement="top"> -->
              <el-switch
                v-model="itemRules.isOn"
                active-color="#13ce66"
                inactive-color="#ff4949"
                class="switchRuleType"
                @change="changeState(itemRules.id)"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
              <!-- </el-tooltip> -->
              <span class="switchText">{{
                itemRules.ruleType == 1
                  ? "净值回撤"
                  : itemRules.ruleType == 2
                  ? "交易品种限制参数"
                  : "交易频率限制参数"
              }}</span>

              <!-- 出发开关 -->
              <el-popover
                placement="top-start"
                title="标题"
                width="300"
                trigger="hover"
                content="等级说明"
              >
                <el-button
                  slot="reference"
                  circle
                  class="circleButton1"
                  :type="
                    itemRules.state == 1
                      ? 'info'
                      : itemRules.state == 2
                      ? 'success'
                      : 'danger'
                  "
                  ><span>{{ itemRules.grade }}</span></el-button
                >
              </el-popover>

              <el-popover
                placement="bottom"
                width="400"
                :ref="`popover-${itemRules.id}`"
              >
                <!-- <el-table :data="gridData">
                        <el-table-column width="150" property="date" label="日期"></el-table-column>
                        <el-table-column width="100" property="name" label="姓名"></el-table-column>
                        <el-table-column width="300" property="address" label="地址"></el-table-column>
                    </el-table> -->
                <div class="closePolice-container">
                  <div>
                    <span>关闭人:</span>
                    <span>{{ userName }}</span>
                  </div>

                  <div>备注:</div>
                  <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="textareaPolice"
                  >
                  </el-input>
                </div>

                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="AlarmCloseCancel"
                    >取消</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="AlarmCloseButton"
                    >确定</el-button
                  >
                </div>
                <el-button
                  slot="reference"
                  type="text"
                  class="closePolice"
                  @click="closePolice(itemRules.id)"
                  >关闭报警</el-button
                >
              </el-popover>

              <span class="date1">12/1.3</span>

              <!-- <el-popover
                    class="detailButton"
                    placement="right"
                    width="400"
                    trigger="click">
                     <div v-show="showTable">
                         123455
                     </div> -->
              <el-button
                slot="reference"
                type="text"
                class="detail1"
                @click="
                  clickDetail(itemRules.id, itemRules.grade, itemRules.ruleType)
                "
                >详情</el-button
              >
              <span class="heart">12</span>
              <!-- </el-popover> -->
            </div>
          </div>
          <el-button @click="exchangeRecord(item.configId)"
            >当前交易数据</el-button
          >
          <el-button @click="riskHistoryClick(item.configId)"
            >历史风控记录</el-button
          >
        </div>
      </div>
      <!-- <div class="riskControl-right">
      </div> -->
    </div>
    <!-- <div id="main" :style="{ width: '100%', height: '500px' }"></div> -->
    <!-- 详情页弹出List -->
    <el-dialog
      title="风控报警"
      :visible.sync="detailsList"
      width="90%"
      :modal-append-to-body="false"
    >
      <div class="details-list">
        <div class="details-riskPolice">
          <!-- <div class="details-riskPolice-title">
            <span>风控报警</span>
          </div> -->
          <div class="details-riskPolice-main">
            <el-collapse v-model="activeNamesRisk" @change="handleChangeRisk">
              <el-collapse-item
                :title="title"
                :name="title"
                class="riskPolice-item-title"
              >
                <div>
                  <span>初始资产:</span>
                  {{ initialAssets }}
                </div>

                <div>
                  <span> 历史最大净值: </span>
                  {{ totalMax }}

                  <span class="BitCoke"> BitCoke: </span>
                  {{ BitCoke }}
                </div>

                <div>
                  <span> 当前回撤率: </span>
                  {{ nowBack }}

                  <span class="threshold"> 触发阈值: </span>
                  {{ threshold }}
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>

        <div class="details-exchangeData">
          <div class="details-exchangeData-title">
            <span>当前交易数据</span>
          </div>
          <div class="details-exchangeData-main">
            <el-collapse
              v-model="activeNamesExchangeDataList"
              @change="handleChangeExchangeDataList"
              class="collapseMain"
            >
              <!-- <el-collapse-item
              v-for="(item, index) in mapData"
              :key="index"
              title="好的"
              :name="index"
            >
              <el-table> </el-table>
            </el-collapse-item> -->
              <el-collapse-item
                :name="item.name"
                :title="item.name"
                v-for="(item, index) in mapData"
                :key="index"
                class="collapse"
              >
                <!-- <span class="collapse-title">{{ item.name }}</span> -->
                <el-button class="refresh">刷新</el-button>
                <el-table :data="item.list" border style="100%">
                  <el-table-column
                    prop="symbol"
                    label="合约"
                    width="180"
                  ></el-table-column>
                  <el-table-column
                    prop="side"
                    label="方向"
                    width="180"
                  ></el-table-column>
                  <el-table-column
                    prop=""
                    v-if="item.price"
                    label="委托价格"
                    width="180"
                  ></el-table-column>
                  <el-table-column
                    v-if="item.price"
                    prop="price"
                    label="开仓均价"
                  ></el-table-column>
                  <el-table-column prop="qty" label="数量USD"></el-table-column>
                  <!-- <el-table-column v-if="item.price" prop="qty" label="成交均价"></el-table-column> -->
                  <!-- <el-table-column v-if="item.price" prop="qty" label="费率"></el-table-column> -->
                  <!-- <el-table-column v-if="item.price" prop="qty" label="费用"></el-table-column> -->
                  <el-table-column
                    v-if="item.type"
                    prop="type"
                    label="类型"
                  ></el-table-column>
                  <!-- <el-table-column v-if="item.price" prop="qty" label="成交id"></el-table-column> -->
                  <el-table-column
                    v-if="item.ordStatus"
                    prop="ordStatus"
                    label="状态"
                  ></el-table-column>
                  <el-table-column
                    v-if="item.created"
                    prop="created"
                    label="时间"
                  ></el-table-column>
                  <el-table-column
                    v-if="item.liquidationPrice"
                    prop="liquidationPrice"
                    label="强平价格"
                  ></el-table-column>
                  <el-table-column
                    v-if="item.pnl"
                    prop="pnl"
                    label="浮动盈亏"
                  ></el-table-column>
                  <el-table-column
                    v-if="item.urPnL"
                    prop="urPnL"
                    label="已实现盈亏"
                  ></el-table-column>
                </el-table>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
      <!-- <span>这是一段信息</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </span> -->
    </el-dialog>
    <!-- 详情页弹窗 -->
    <el-dialog
      title="风控报警"
      :visible.sync="detailsTable"
      :modal-append-to-body="false"
      width="90%"
    >
      <div class="details-list">
        <div class="details-riskPolice">
          <!-- <div class="details-riskPolice-title">
            <span>风控报警</span>
          </div> -->
          <div class="details-riskPolice-main">
            <el-collapse
              v-model="activeNamesRiskTable"
              @change="handleChangeRiskTable"
            >
              <el-collapse-item
                :title="detailsTableTitle"
                :name="detailsTableTitle"
                class="riskPolice-item-title-table"
              >
                <span class="dec">违规订单成交明细:</span>
                <el-table :data="tableDataListTableErrFromServer" border>
                  <el-table-column prop="errorTime" label="时间" width="180">
                  </el-table-column>
                  <el-table-column prop="exchange" label="交易所" width="180">
                  </el-table-column>
                  <el-table-column prop="businessLine" label="业务线">
                  </el-table-column>
                  <el-table-column prop="symbol" label="交易品种">
                  </el-table-column>
                  <el-table-column prop="side" label="方向"> </el-table-column>
                  <el-table-column prop="qty" label="仓位"> </el-table-column>
                  <el-table-column prop="pnl" label="盈亏"> </el-table-column>
                </el-table>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>

        <div class="details-exchangeData">
          <div class="details-exchangeData-title">
            <span>当前交易数据</span>
          </div>
          <div class="details-exchangeData-main">
            <el-collapse
              v-model="activeNamesExchangeDataTable"
              @change="handleChangeExchangeDataTable"
            >
              <el-collapse-item
                :title="item.name"
                :name="item.name"
                class="collapse-item-Table"
                v-for="(item, index) in tableDataListmapDataFromServer"
                :key="index"
              >
                <el-button class="tableExchangeRefresh">刷新</el-button>
                <!-- <span>初始资产:</span>
                            <span></span>

                            <span>当前净值:</span>
                            <span></span> -->
                <el-table :data="item.list" border style="100%">
                  <el-table-column
                    prop="symbol"
                    label="合约"
                    width="180"
                  ></el-table-column>
                  <el-table-column
                    prop="side"
                    label="方向"
                    width="180"
                  ></el-table-column>
                  <el-table-column
                    prop=""
                    v-if="item.price"
                    label="委托价格"
                    width="180"
                  ></el-table-column>
                  <el-table-column
                    v-if="item.price"
                    prop="price"
                    label="开仓均价"
                  ></el-table-column>
                  <el-table-column prop="qty" label="数量USD"></el-table-column>
                  <el-table-column
                    v-if="item.type"
                    prop="type"
                    label="类型"
                  ></el-table-column>
                  <el-table-column
                    v-if="item.ordStatus"
                    prop="ordStatus"
                    label="状态"
                  ></el-table-column>
                  <el-table-column
                    v-if="item.created"
                    prop="created"
                    label="时间"
                  ></el-table-column>
                  <el-table-column
                    v-if="item.liquidationPrice"
                    prop="liquidationPrice"
                    label="强平价格"
                  ></el-table-column>
                  <el-table-column
                    v-if="item.pnl"
                    prop="pnl"
                    label="浮动盈亏"
                  ></el-table-column>
                  <el-table-column
                    v-if="item.urPnL"
                    prop="urPnL"
                    label="已实现盈亏"
                  ></el-table-column>
                </el-table>
                <!-- <el-table
                :data="item.list"
                border
                style="100%"
              >
                <el-table-column prop="symbol" label="合约" width="180">
                </el-table-column>
                <el-table-column prop="side" label="方向" width="180">
                </el-table-column>
                <el-table-column prop="price" label="开仓均价">
                </el-table-column>
                <el-table-column prop="qty" label="数量USD"> </el-table-column>
                <el-table-column prop="liquidationPrice" label="强平价格">
                </el-table-column>
                <el-table-column prop="pnl" label="浮动盈亏"> </el-table-column>
                <el-table-column prop="urPnL" label="已实现浮动盈亏">
                </el-table-column>
              </el-table>

              <el-table
                :data="item.list"
                border
                style="width: 100%"
              >
                <el-table-column prop="symbol" label="合约" width="180">
                </el-table-column>
                <el-table-column prop="side" label="方向" width="180">
                </el-table-column>
                <el-table-column prop="price" label="委托价格">
                </el-table-column>
                <el-table-column prop="qty" label="数量USD"> </el-table-column>
                <el-table-column prop="type" label="类型"> </el-table-column>
                <el-table-column prop="ordStatus" label="状态">
                </el-table-column>
                <el-table-column prop="created" label="时间"> </el-table-column>
              </el-table>

              <el-table
                :data="item.list"
                border
                style="width: 100%"
              >
                <el-table-column prop="symbol" label="合约" width="100">
                </el-table-column>
                <el-table-column prop="side" label="方向" width="100">
                </el-table-column>
                <el-table-column prop="qty" label="数量USD"> </el-table-column>
                <el-table-column prop="avgPx" label="成交均价">
                </el-table-column>
                <el-table-column prop="rate" label="费率"> </el-table-column>
                <el-table-column prop="fee" label="费用"> </el-table-column>
                <el-table-column prop="type" label="类型"> </el-table-column>
                <el-table-column prop="ordStatus" label="状态">
                </el-table-column>
                <el-table-column prop="created" label="时间"> </el-table-column>
                <el-table-column prop="id" label="成交id" width="100">
                </el-table-column>
              </el-table> -->
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
      <!-- <span>这是一段信息</span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span> -->
    </el-dialog>
    <!-- 当前交易数据弹窗 -->
    <el-dialog
      title="当前交易数据"
      :visible.sync="dataExchange"
      :modal-append-to-body="false"
      width="90%"
    >
      <div class="data-exchange">
        <!-- <div class="data-exchange-title">
          <span class="exchangeDataSpan"> 当前交易数据 </span>
        </div> -->

        <el-collapse
          v-model="activeNamesExchangeDataNow"
          @change="handleChangeExchangeDataNow"
        >
          <el-collapse-item
            :title="item.name"
            :name="item.name"
            v-for="(item, index) in nowExchangeDataList"
            :key="index"
            class="nowExchangeDataItem"
          >
            <!-- 当前持仓列表表格 -->
            <el-table :data="item.list" border style="100%">
              <el-table-column
                prop="symbol"
                label="合约"
                width="180"
              ></el-table-column>
              <el-table-column
                prop="side"
                label="方向"
                width="180"
              ></el-table-column>
              <el-table-column
                prop=""
                v-if="item.price"
                label="委托价格"
                width="180"
              ></el-table-column>
              <el-table-column
                v-if="item.price"
                prop="price"
                label="开仓均价"
              ></el-table-column>
              <el-table-column prop="qty" label="数量USD"></el-table-column>
              <!-- <el-table-column v-if="item.price" prop="qty" label="成交均价"></el-table-column> -->
              <!-- <el-table-column v-if="item.price" prop="qty" label="费率"></el-table-column> -->
              <!-- <el-table-column v-if="item.price" prop="qty" label="费用"></el-table-column> -->
              <el-table-column
                v-if="item.type"
                prop="type"
                label="类型"
              ></el-table-column>
              <!-- <el-table-column v-if="item.price" prop="qty" label="成交id"></el-table-column> -->
              <el-table-column
                v-if="item.ordStatus"
                prop="ordStatus"
                label="状态"
              ></el-table-column>
              <el-table-column
                v-if="item.created"
                prop="created"
                label="时间"
              ></el-table-column>
              <el-table-column
                v-if="item.liquidationPrice"
                prop="liquidationPrice"
                label="强平价格"
              ></el-table-column>
              <el-table-column
                v-if="item.pnl"
                prop="pnl"
                label="浮动盈亏"
              ></el-table-column>
              <el-table-column
                v-if="item.urPnL"
                prop="urPnL"
                label="已实现盈亏"
              ></el-table-column>
            </el-table>
          </el-collapse-item>
        </el-collapse>
      </div>
      <!-- <span>这是一段信息</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </span> -->
    </el-dialog>
    <!-- 历史风控记录弹窗 -->
    <el-dialog
      title="历史风控记录"
      :visible.sync="riskHistory"
      width="90%"
      :modal-append-to-body="false"
    >
      <div class="riskHistory">
        <!-- <div class="riskHistory-title">
            <span>历史风控记录</span>
          </div> -->
        <div>
          <!-- <el-date-picker
          v-model="valueDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker> -->
          <el-date-picker
            v-model="valueDate.startTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="开始日期"
            style="width: 150px; margin-left: 10px"
          />
          <el-date-picker
            v-model="valueDate.endTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="结束日期"
            style="width: 150px; margin-left: 10px"
          />
          <el-button
            type="primary"
            icon="el-icon-search"
            class="history-search"
            @click="riskHistoryClick()"
            >查询</el-button
          >
        </div>

        <el-checkbox
          :indeterminate="Log.isIndeterminate"
          v-model="Log.logTypeAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <div style="margin: 15px 0"></div>
        <el-checkbox-group
          v-model="Log.checkedLogType"
          @change="handleCheckedCitiesChange"
        >
          <el-checkbox
            v-for="(item, index) in Log.logTypeItem"
            :label="item"
            :index="index"
            :key="item"
            >{{ item }}</el-checkbox
          >
        </el-checkbox-group>

        <!-- <div>
        <el-checkbox-group v-model="checkListRuleTypeFromH">
          <el-checkbox label="复选框 A"></el-checkbox>
          <el-checkbox label="复选框 B"></el-checkbox>
        </el-checkbox-group>
      </div> -->

        <div class="historyTable">
          <el-table :data="tableDataFromHistory" border>
            <el-table-column prop="recordTime" label="记录时间" width="180">
            </el-table-column>
            <el-table-column
              prop="exchangeCombination"
              label="交易所组合"
              width="180"
            >
            </el-table-column>
            <el-table-column prop="logType" label="日志类别">
              <template slot-scope="scope">
                <span>{{
                  scope.row.logType == 1
                    ? "净值回撤"
                    : scope.row.logType == 2
                    ? "交易品种限制"
                    : scope.row.logType == 3
                    ? "交易频率限制"
                    : "风控开关"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="content" label="警报内容">
              <template slot-scope="scope">
                <div v-html="scope.row.content"></div>
                <el-button type="text" @click="historyDetail(scope.row.id)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
            <el-table-column prop="processRecord" label="处理过程记录">
              <template slot-scope="scope">
                <div v-html="scope.row.processRecord"></div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- 历史风控页面详情弹出 -->
        <!-- <div class="history-container"> -->
        <el-dialog
          title="违规订单成交明细："
          :visible.sync="dialogFormVisibleHistoryDe"
          class="historyDetail"
          :modal-append-to-body="false"
        >
          <el-table :data="tableDataDetailHistory" border style="width: 100%">
            <el-table-column prop="errorTime" label="时间" width="100">
            </el-table-column>
            <el-table-column prop="exchange" label="交易所" width="180">
            </el-table-column>
            <el-table-column prop="businessLine" label="业务线">
            </el-table-column>
            <el-table-column prop="symbol" label="交易品种"> </el-table-column>
            <el-table-column prop="side" label="方向"> </el-table-column>
            <el-table-column prop="qty" label="仓位"> </el-table-column>
            <el-table-column prop="pnl" label="盈亏" width="100">
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @size-change="handleSizeChangeHistory"
            @current-change="handleCurrentChangeHistory"
            :current-page="pageListHistory.page"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="pageListHistory.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalHistoryDetails"
          >
          </el-pagination>
          <!-- <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisibleHistoryDe = false"
              >取 消</el-button
            >
            <el-button
              type="primary"
              @click="dialogFormVisibleHistoryDe = false"
              >确 定</el-button
            >
          </div> -->
        </el-dialog>
        <!-- </div> -->

        <el-pagination
          @size-change="handleSizeChangeMainHistory"
          @current-change="handleCurrentChangeMainHistory"
          :current-page="pageListMainHistory.page"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageListMainHistory.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalHistory"
          class="mainPage"
        >
        </el-pagination>
      </div>
      <!-- <span>这是一段信息</span> -->
      <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </span> -->
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      logType: [],
      Log: {
        logTypeAll: false,
        checkedLogType: [],
        logTypeItem: ["净值回撤", "交易币种限制", "交易频率限制", "风控开关"],
        isIndeterminate: true,
        // logTypes: {}, // 日志类型
      },
      testId: "",
      totalHistoryDetails: 1,
      itemId: null, //列表id
      totalHistory: 1,
      visible: false,
      textareaPolice: "", //关闭报警 备注
      currentPage4: 3,
      pageListMainHistory: {
        page: 1,
        pageSize: 10,
      }, //历史风控主页分页
      tableDataDetailHistory: [], //历史风控详情页
      dialogFormVisibleHistoryDe: false, //历史风控详情弹窗
      // totalHistory:2,
      riskHistory: false, //历史风控记录  弹窗
      pageListHistory: {
        page: 1,
        pageSize: 10,
      },

      activeNamesExchangeDataNow: "", //当前交易数据 model值
      nowExchangeDataList: {}, //当前交易数据  对象数据
      dataExchange: false, // 当前交易数据 弹窗
      activeNamesExchangeDataTable: "",
      tableDataListmapDataFromServer: {}, // 带表格页面的list mapdata表格
      tableDataListTableErrFromServer: [], //带表格页面的List error 表格
      detailsTableTitle: "", //带表格页面的title
      detailsTableErrorData: [], //点击详情带表格的error
      detailsTable: false, //控制详情页面  table
      detailsList: false, //控制详情页面list
      exchangeListTableObject: {
        symbol: "",
        side: "",
        price: "",
        qty: "",
        liquidationPrice: "",
        pnl: "",
        urPnL: "",
      },
      exchangeListTable: [{}, {}, {}],
      mapData: {},
      activeNamesExchangeDataList: "",
      BitCoke: "", //
      totalMax: "", //历史最大净值
      initialAssets: "", //初始资产
      nowBack: "", //当前回撤率
      threshold: "", //触发阈值
      tableDetailsFromServerRisk: [],
      listDetailsFromServerRisk: {},
      title: "",
      // errorDataFromServer:{},
      ruleTypeFromList: "", //风控报警    名称
      gradeFromList: "", //风控报警 等级多少
      dateFromList: "", //风控报警  日期
      tableDataFromHistory: [],
      checkListRuleTypeFromH: [], //历史风控记录 多选按钮 model
      valueDate: {
        startTime: "",
        endTime: "",
      },
      activeNamesExchangeData: [], //当前交易数据model值

      // handleChangeExchangeData:"",
      tableDataDetailsTables: [], //详情页中风控报警为表格的当前交易的数据
      activeNamesRiskTable: [], //
      // detailsTable:true,
      tableDataDetailsExchange: [], //详情页中的当前交易数据表格
      // activeNamesExchangeData:[],
      activeNamesRisk: [], //风控报警   model值

      showTable: true,
      // buttonvalueThreeThree:"",
      // buttonvalueThreeTwo:"",
      // buttonvalueThreeOne:"",
      // buttonvalueTwoThree:"",
      // buttonvalueTwoTwo:"",
      // buttonvalueTwoOne:"",
      buttonvalue1: "",
      // buttonvalue2:"",
      // buttonvalue3:"",
      ruleTypeModel: "",
      riskList: [], //主页的列表
      websock: null,
      configIdFromServer: "",
      userName: "",
    };
  },
  methods: {
    // random_Data() {
    //   //把代码封装到一个方法里
    //   function randomData() {
    //     now = new Date(+now + oneDay);
    //     value = value + Math.random() * 21 - 10;
    //     return {
    //       name: now.toString(),
    //       value: [
    //         [now.getFullYear(), now.getMonth() + 1, now.getDate()].join("/"),
    //         Math.round(value),
    //       ],
    //     };
    //   }
    //   var data = [];
    //   var now = +new Date(1997, 9, 3);
    //   var oneDay = 24 * 3600 * 1000;
    //   var value = Math.random() * 1000;
    //   for (var i = 0; i < 1000; i++) {
    //     data.push(randomData());
    //   }
    //   let myChart = this.$echarts.init(document.getElementById("main")); //自己加的代码
    //   let option = {
    //     //加个let
    //     title: {
    //       text: "动态数据 + 时间坐标轴",
    //     },
    //     tooltip: {
    //       trigger: "axis",
    //       formatter: function (params) {
    //         params = params[0];
    //         var date = new Date(params.name);
    //         return (
    //           date.getDate() +
    //           "/" +
    //           (date.getMonth() + 1) +
    //           "/" +
    //           date.getFullYear() +
    //           " : " +
    //           params.value[1]
    //         );
    //       },
    //       axisPointer: {
    //         animation: false,
    //       },
    //     },
    //     xAxis: {
    //       type: "time",
    //       splitLine: {
    //         show: false,
    //       },
    //     },
    //     yAxis: {
    //       type: "value",
    //       boundaryGap: [0, "100%"],
    //       splitLine: {
    //         show: false,
    //       },
    //     },
    //     series: [
    //       {
    //         name: "模拟数据",
    //         type: "line",
    //         showSymbol: false,
    //         hoverAnimation: false,
    //         data: data,
    //       },
    //     ],
    //   };

    //   setInterval(function () {
    //     for (var i = 0; i < 5; i++) {
    //       data.shift();
    //       data.push(randomData());
    //     }
    //     //这个换成下方的一句代码，不然会报错（series.type should be specified.）
    //     // myChart.setOption({
    //     //   series: [
    //     //     {
    //     //       data: data
    //     //     }
    //     //   ]
    //     // });
    //     myChart.setOption(option);
    //   }, 1000);
    // },

    getUser: function () {
      this.axios.get("/business/admin/user/user/info").then((res) => {
        console.log(res);
        this.userName = res.data.data.businessAdminUser.realName;
      });
    },
    handleSizeChangeMainHistory(val) {
      this.pageListMainHistory.pageSize = val;
      this.riskHistoryClick();
    },
    handleCurrentChangeMainHistory(val) {
      this.pageListMainHistory.page = val;
      this.riskHistoryClick();
    },
    handleSizeChangeHistory(val) {
      //分页
      console.log(val);
      this.pageListHistory.pageSize = val;
      this.riskHistoryClick();
    },

    handleCurrentChangeHistory(val) {
      //分页
      this.pageListHistory.page = val;
      this.riskHistoryClick();
      console.log(val);
    },
    formatCheckBox() {
      let arr = [];
      for (let i = 0; i < this.Log.checkedLogType.length; i++) {
        for (let j = 0; j < this.Log.logTypeItem.length; j++) {
          if (this.Log.checkedLogType[i] == this.Log.logTypeItem[j]) {
            arr.push(j + 1);
          }
        }
      }
      // console.log("arr:", arr);
      return arr.join();
    },
    riskHistoryClick(itemRulesFromForm) {
      this.logType = this.formatCheckBox();
      console.log("arr:", this.logType);
      // debugger;
      this.itemId = itemRulesFromForm ? itemRulesFromForm : this.itemId;
      // this.itemId = itemRulesFromForm;
      console.log(itemRulesFromForm);
      console.log("valueDate:", this.valueDate);
      console.log("checkedLogType:", this.Log.checkedLogType);
      if (itemRulesFromForm) {
        this.valueDate.startTime = "";
        this.valueDate.endTime = "";
      }
      this.axios
        .get("/business/admin/index/riskHistoryLog", {
          params: {
            page: this.pageListHistory.page,
            pageSize: this.pageListHistory.pageSize,
            configId: this.itemId,
            startTime: this.valueDate.startTime,
            endTime: this.valueDate.endTime,
            logType: this.logType,
          },
          // logTypes:logTypeFromForm
        })
        .then((res) => {
          console.log(res);
          this.detailsTable = false;
          this.detailsList = false;
          this.dataExchange = false;
          this.riskHistory = true;
          //   this.pageListHistory.page=res.data.data.pageNum;
          //   this.pageListHistory.pageSize=res.data.data.pageSize;
          //   this.totalHistory=res.data.data.total;
          this.tableDataFromHistory = res.data.data.list;
          this.totalHistory = res.data.data.total;
          this.pageListHistory.page = res.data.data.pageNum;
        });
    }, //点击历史风控按钮
    exchangeRecord(configIdFromList) {
      this.axios
        .get("/business/admin/index/currentTransactionData", {
          params: {
            configId: configIdFromList,
          },
        })
        .then((res) => {
          this.riskHistory = false;
          (this.detailsTable = false),
            (this.detailsList = false),
            (this.dataExchange = true);
          this.nowExchangeDataList = res.data.data;
          console.log(res);
        });
    }, //点击当前交易数据按钮
    handleChangeExchangeDataList() {},
    //
    // handleChangeExchangeData(){},
    //
    handleChangeRiskTable() {},
    //选中change
    handleChangeExchangeData(val) {
      console.log(val);
    },
    //选中change
    handleChangeRisk(val) {
      console.log(val);
    },
    //获取首页列表

    // 历史风控记录 - 警报内容 - 详情按钮
    historyDetail(idFromView) {
      console.log("历史风控记录 - 警报内容 - 详情按钮 发出......");
      this.dialogFormVisibleHistoryDe = true;
      this.axios
        .get("/business/admin/index/historyDetail", {
          params: {
            id: idFromView,
            page: 1,
            pageSize: 10,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == "fail") {
            this.$router.push("/");
          } else {
            this.tableDataDetailHistory = res.data.data.list;
          }
        });
    },
    //点击历史页面详情按钮
    getRiskList: function () {
      this.axios.get("/business/admin/index/list").then((res) => {
        console.log(res);
        this.riskList = res.data.data;
      });
    },
    changeState(idFromView) {
      this.axios
        .get("/business/admin/index/switch", {
          params: {
            ruleId: idFromView,
          },
        })
        .then((res) => {
          console.log(res);
          this.getRiskList();
          // this.getRiskList();
        });
    },
    initWebSocket() {
      // debugger;
      if (window.sessionStorage.getItem("token")) {
        var Token = window.sessionStorage.getItem("token");
      }
      let url = `ws://47.241.161.217:8050/websocket/heartbeat/${Token}`;
      this.websock = new WebSocket(url);
      this.websock.onopen = this.websocketOnopen;
      this.websock.onmessage = this.websocketOnmessage;
      this.websock.onerror = this.websocketOnerror;
      this.websock.onclose = this.websocketOnclose;
      console.log(Token);
    },
    websocketOnopen() {
      console.log("连接成功");
      // this.websock.send("111");
    },
    websocketOnerror() {
      console.log("心跳连接错误");
    },
    websocketOnmessage(e) {
      console.log(e);
      //  debugger
      this.configIdFromServer = e.data;
    },
    websocketOnclose(e) {
      console.log(e);
    },
    //关闭报警
    closePolice(id) {
      this.testId = id;

      // this.visible=true;
      // this.visible=true;
      // this.axios.get('/business/admin/index/alarm',{
      //     params:{
      //         ruleId:idFromView,

      //     }
      // }).then((res)=>{
      //   console.log(res);
      //   this.getRiskList();
      // })
    },
    handleChangeExchangeDataTable() {},
    handleChangeExchangeDataNow() {},
    //  详情按钮
    async clickDetail(idFromView, gradeFromView, ruleTypeFromView) {
      console.log("点击了详情按钮");
      console.log("详情id", idFromView);
      // if(idFromView==1){
      //     this.detailsList=true;
      // }
      await this.axios
        .get("/business/admin/index/detail", {
          params: {
            ruleId: idFromView,
          },
        })
        .then((res) => {
          console.log(res);
          this.gradeFromList = gradeFromView;
          this.ruleTypeFromList = ruleTypeFromView;
          //  this.dateFromList=res.data.data.errorData[0].errorTime
          //  this.errorDataFromServer=res.data.data.errorData;
          if (ruleTypeFromView == 1) {
            this.riskHistory = false;
            this.detailsTable = false;
            this.detailsList = true;
            this.dataExchange = false;
            this.listDetailsFromServerRisk = res.data.data.errorData;
            this.initialAssets = res.data.data.errorData.initialAssets;
            this.nowBack = res.data.data.errorData.nowBack;
            this.threshold = res.data.data.errorData.threshold;
            this.totalMax = res.data.data.errorData.historicalMaxMap.totalMax;
            this.BitCoke = res.data.data.errorData.historicalMaxMap.bitcoke;
            this.mapData = res.data.data.mapData;
            //  this.exchangeListTableObject;
            this.title = res.data.data.title;
          } else {
            this.riskHistory = false;
            this.detailsList = false;
            this.detailsTable = true;
            this.dataExchange = false;
            this.tableDetailsFromServerRisk = res.data.data.errorData;
            this.detailsTableErrorData = res.data.data.errorData;
            // debugger;
            this.detailsTableTitle = res.data.data.title;
            this.tableDataListTableErrFromServer = res.data.data.errorData;
            // debugger;
            this.tableDataListmapDataFromServer = res.data.data.mapData;
          }
        });
    },
    AlarmCloseButton() {
      this.axios
        .put("/business/admin/index/alarm", {
          closer: this.userName,
          remark: this.textareaPolice,
          ruleId: this.testId,
        })
        .then((res) => {
          console.log(res);
          this.$refs[`popover-${this.testId}`][0].doClose();
          this.textareaPolice = ""; // 清空关闭报警中的备注
          this.getRiskList();
        });
    },
    AlarmCloseCancel() {
      this.textareaPolice = "";
      this.$refs[`popover-${this.testId}`][0].doClose();
    },
    // checkRuleTypeModel(){
    //     if(this.itemRules.isOn==0){
    //       this.ruleTypeModel=false
    //     }else{
    //         this.ruleTypeModel=true
    //     }
    // }
    handleCheckAllChange(val) {
      this.Log.checkedLogType = val ? this.Log.logTypeItem : [];
      this.Log.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      // console.log("checkedCount:", checkedCount);
      this.Log.logTypeAll = checkedCount === this.Log.logTypeItem.length;
      // console.log("checkedCount2:", checkedCount);
      // console.log("this.Log.logTypeAll:", this.Log.logTypeAll);
      // console.log("Log.logTypeItem.length:", this.Log.logTypeItem.length);
      this.Log.isIndeterminate =
        checkedCount > 0 && checkedCount < this.Log.logTypeItem.length;
    },
  },
  mounted() {
    // 页面渲染时，挂载虚拟dom树时
    this.getRiskList();
    this.getUser();
    // this.checkRuleTypeModel();
    // this.random_Data();
  },
  created() {
    //new vue实例时触发
    this.initWebSocket();
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name == "riskLv" ||
      to.name == "Exchange" ||
      to.name == "Team" ||
      to.name == "exConfiguration" ||
      to.name == "account" ||
      to.name == "Userconf" ||
      to.name == "exchangeObject"
    ) {
      this.getRiskList();
      this.riskHistory = false;
      this.detailsList = false;
      this.detailsTable = false;
      this.dataExchange = false;
    }
    next();
  },
};
</script>

<style>
.detail1,
.detail2,
.detail3 {
  transform: translate(12.5rem, 0rem);
}

.one-body {
  transform: translateX(-0.75rem);
  width: 25rem;
}
.one-header {
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: #25255e;
  font-size: 1rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}

.riskcontrol-one {
  margin-left: 1.25rem;
}

.riskControl-two {
  margin-left: 1.25rem;
}

.riskControl-three {
  margin-left: 1.25rem;
}

.riskControl-three > .one-body {
  transform: translateX(-0.8125rem);
}

.riskControl-two > .one-body {
  transform: translateX(-0.8125rem);
}
.circleButton1,
.circleButton2,
.circleButton3 {
  width: 2.1875rem;
  height: 2.1875rem;
  /* background: #E95959; */
  border-radius: 50%;
}

.circleButton1 {
  /* margin-left: 68px; */
  position: absolute;
  left: 53%;
  text-align: center;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}

.circleButton2 {
  margin-left: 2.6875rem;
  text-align: center;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}

.circleButton3 {
  margin-left: 2.6875rem;
  text-align: center;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}

/* .circleButton >span{
       width: 8px;
        height: 10px; 
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;       
   } */

.detailButton {
  margin-left: 5px;
}

.riskControl {
  /* width: 470px; */
  /* height: 62.125rem;
  background: #ffffff; */
  float: left;
  margin-left: 10px;
}
.riskControl-left {
  width: 28.9rem;
  /* width: 100%; */
  height: inherit;
  /* height: 48.5vw; */
  overflow-y: auto;
}

/* .riskControl-right {
  width: calc(100% - 28.9rem);
  float: right;
} */
.riskControl-three,
.riskcontrol-one,
.riskControl-two {
  margin-top: 0.625rem;
}

.one-body > .el-switch {
  margin-left: 1.0625rem;
}

.switchText {
  position: absolute;
  left: 20%;
  top: 19%;
  /* padding-left: 7px; */
  width: 7rem;
  height: 0.875rem;
  font-size: 0.875rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}

.closePolice {
  margin-left: 0.4375rem;
}

.date1,
.date2,
.date3 {
  width: 3rem;
  height: 0.625rem;
  font-size: 0.75rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  margin-left: 0.25rem;
}

.detailButtonExchange {
  transform: translate(10rem, 0rem);
}

.detailButtonriskH {
  transform: translate(1.25rem, 0rem);
}
/* .riskHistory1{
     width: 118px;
    height: 33px;
    background: #EBEBEB;
    border-radius: 5px;
    font-size: 14px;
    line-height: 4px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #666666;
    transform: translate(-90px,20px);
 } */
/* .exchangeRecord1{
        width: 118px;
        height: 33px;
        background: #EBEBEB;
        border-radius: 5px; */
/* width: 83px; */
/* height: 14px; */
/* font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #666666;
        text-align:center;
        line-height: 4px;
        transform: translate(65px,20px);
  } */

/* .two-header{
      width: 420px;
      height: 32px;
      background: #25255E;
      transform: translate(30px,30px);
      font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
  } */

/* .two-body-one{
      margin-top: 2.5rem;
  } */

/* .switchText-two-one{
      margin-left: 8px;
      width: 56px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
  } */

/* .twoOneSwitch{
      margin-left:38px;
  } */

/* .circleButton-two-one{
       width: 35px;
        height: 35px;
        background: #E95959;
        border-radius: 50%;
       margin-left: 68px;
       text-align: center; 
       font-family: PingFang SC; 
       font-weight: 500;
       color: #FFFFFF;
       border-radius: 50%;
  } */

/* .closePolice-two-one{
      margin-left: 7px;
  } */

/* .date-two-one{
      width: 48px;
    height: 10px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
    margin-left: 4px;
  } */

/* .detail-two-one{
      transform: translate(220px,-39px);
  } */

.circleButton-two-two {
}

/* .two-body-two{
      transform: translate(35px,-26px);
  } */

/* .switchText-two-two{
       margin-left: 8px;
      width: 56px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
  } */

/* .circleButton-two-two{
       width: 35px;
        height: 35px;
        background: #E95959;
        border-radius: 50%;
       margin-left: 42px;
       text-align: center; 
       font-family: PingFang SC; 
       font-weight: 500;
       color: #FFFFFF;
       border-radius: 50%;
  } */

/* .closePolice-two-two{
      margin-left: 7px;
  } */

/* .date-two-two{
      width: 48px;
    height: 10px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
    margin-left: 4px;
  } */

/* .detail-two-two{
       transform: translate(23px,0px);
  } */

/* .two-body-three{
      transform: translate(-39px,-13px);
  } */

/* .switchText-two-three{
      margin-left: 9px;
      width: 56px;
      height: 14px;
      font-size: 14px;
      font-family: PingFang SC;
       font-weight: 500;
       color: #333333;
  } */

/* .circleButton-two-three{
       width: 35px;
        height: 35px;
        background: #E95959;
        border-radius: 50%;
       margin-left: 38px;
       text-align: center; 
       font-family: PingFang SC; 
       font-weight: 500;
       color: #FFFFFF;
       border-radius: 50%;
  } */

/* .exchangeRecord2{
        width: 118px;
        height: 33px;
        background: #EBEBEB;
        border-radius: 5px; */
/* width: 83px;
        height: 14px; */
/* font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #666666;
        transform: translate(67px,4px);
    } */

/* .detailButtonExchange2{
        transform: translate(190px,0px);
    } */

/* .riskHistory2{
        width: 118px;
     height: 33px;
     background: #EBEBEB;
     border-radius: 5px;
     font-size: 14px;
     line-height: 4px;
     font-family: PingFang SC;
     font-weight: 500;
     color: #666666;
     transform: translate(90px,0px);
    } */
/* 
    .detailButtonriskH2{
        transform: translate(34px,0px);
    } */

/* .header-three{
         width: 420px;
      height: 32px;
      background: #25255E;
      transform: translate(30px,10px);
      font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
    } */

/* .three-body-one{
        transform: translate(-38px,20px);
    } */

.switchText-three-one {
  width: 3.5rem;
  height: 0.875rem;
  font-size: 0.875rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-left: 0.5rem;
}

.circleButton-three-one {
  width: 2.1875rem;
  height: 2.1875rem;
  /* background: #E95959; */
  border-radius: 50%;
  margin-left: 4.5rem;
  text-align: center;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  border-radius: 50%;
}

.three-body-two {
  transform: translate(-2.3125rem, 2.5rem);
}

.switchText-three-two {
  width: 3.5rem;
  height: 0.875rem;
  font-size: 0.875rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-left: 8px;
}

.circleButton-three-two {
  width: 2.1875rem;
  height: 2.1875rem;
  /* background: #E95959; */
  border-radius: 50%;
  margin-left: 46px;
  text-align: center;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  border-radius: 50%;
}

.three-body-three {
  transform: translate(-2.375rem, 3.625rem);
}

.switchText-three-three {
  width: 3.5rem;
  height: 0.875rem;
  font-size: 0.875rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-left: 0.5rem;
}

.circleButton-three-three {
  width: 2.1875rem;
  height: 2.1875rem;
  /* background: #E95959; */
  border-radius: 50%;
  margin-left: 2.875rem;
  text-align: center;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  border-radius: 50%;
}

.detailButtonExchangeThree {
  transform: translate(12.5rem, 0rem);
}

.detailButtonriskHThree {
  transform: translate(3.125rem, 0rem);
}

.exchangeRecord3 {
  width: 7.375rem;
  height: 2.0625rem;
  background: #ebebeb;
  border-radius: 0.3125rem;
  /* width: 83px;
        height: 14px; */
  font-size: 0.875rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  transform: translate(4.1875rem, 4.625rem);
}

/* .riskHistory3{
        width: 118px;
     height: 33px;
     background: #EBEBEB;
     border-radius: 5px;
     font-size: 14px;
     line-height: 4px;
     font-family: PingFang SC;
     font-weight: 500;
     color: #666666;
     transform: translate(90px,70px);
    } */

.switchRuleType {
  position: absolute;
  left: -4%;
  top: 26%;
}

.closePolice {
  position: absolute;
  left: 65%;
}

.date1 {
  position: absolute;
  left: 82%;
  top: 28%;
}

.details-riskPolice-title {
  background: #bdc3c7;
  width: inherit;
  height: 2rem;
  line-height: 2rem;
  /* transform: translate(420px,-182px); */
  text-align: center;
  position: relative;
}

.details-riskPolice-title > span {
  position: absolute;
  left: 45%;
}

.details-list {
  overflow: auto;
  /* width: 51.5rem; */
  /* height: 42.5rem; */
  /* margin-left: 29.125rem;
  margin-top: -100vh;
  float: left; */
}

.el-collapse-item__header {
  color: #0abde3;
}

.details-exchangeData-title {
  background: #bdc3c7;
  width: inherit;
  height: 32px;
  line-height: 32px;
  /* transform: translate(420px,-182px); */
  text-align: center;
  position: relative;
}

.details-exchangeData-title > span {
  position: absolute;
  left: 45%;
}

.details-table {
  overflow: scroll;
  /* scrollbar-base-color:red; */
  width: 62.5rem;
  height: 42.5rem;
  /* position:fixed;
            top:9%;
            left:47%; */
  margin-left: 28.125rem;
  margin-top: -41.375rem;
}

.data-exchange-title {
  background: #bdc3c7;
  width: inherit;
  height: 32px;
  line-height: 32px;
  /* transform: translate(420px,-182px); */
  text-align: center;
  position: relative;
}

.BitCoke {
  margin-left: 30px;
}

.threshold {
  margin-left: 30px;
}

.refresh {
  transform: translate(-355px, 0px);
  margin-bottom: 20px;
}

.exchangeTableList {
  width: 400px;
  /* margin-bottom: 100px; */
}

.collapse {
  width: inherit;
}

.details-exchangeData-main {
  width: inherit;
}

/* .collapseMain{
        width: 700px;
    } */
.collapse-title {
  flex: 1 0 90%;
  order: 1;
  /* width: 1300px; */
}

.el-collapse-item__header {
  flex: 1 0 auto;
  order: -1;
}

.collapse-title {
}

.riskPolice-item-title-table {
  width: 850px;
}

.dec {
  margin-left: -500px;
}

.collapse-item-Table {
  width: 900px;
}

.tableExchangeRefresh {
  margin-left: -740px;
}

.nowExchangeDataItem {
  width: inherit;
}

.data-exchange {
  overflow: scroll;
  width: 100%;
  height: 43.75rem;
  /* margin-left: 29.125rem;
  margin-top: -100vh; */
}

.exchangeDataSpan {
  position: absolute;
  left: 40%;
}

.riskHistory-title {
  background: #bdc3c7;
  width: 75rem;
  height: 2rem;
  line-height: 2rem;
  /* transform: translate(420px,-182px); */
  text-align: center;
  position: relative;
  /* position:fixed */
}

.riskHistory-title > span {
  position: absolute;
  left: 45%;
}

.riskHistory {
  overflow: scroll;
  /* width: 51.5rem; */
  height: 80vh;
  /* margin-left: 29.125rem;
  margin-top: -100vh; */
}

.historyTable {
  width: 62.5rem;
  height: 50rem;
  margin-right: 18.75rem;
  /* white-space:pre-line; */
  /* overflow: scroll; */
  /* height: 50px; */
}

.historyDetail {
  /* width: 75rem; */
}

.history-container {
  position: fixed;
  left: 30%;
}

.mainPage {
  /* position: fixed;
  left: 53.125rem;
  top: 43.75rem; */
  margin: 20px auto;
}

.history-search {
  margin-left: 0.625rem;
}

.riskControl-body {
  text-align: center;
}

.police {
  white-space: pre-line;
}

.heart {
  /* transform: translate(40px,0px); */
  position: absolute;
  left: 26.25rem;
  top: 0.625rem;
}
</style>