<template>
  <div class="tableDate">
    <h2>动态跟单比例配置</h2>
    <div class="button">
      <el-button type="success" @click.prevent="addRow()">增加（行）</el-button>
      <el-button type="danger" @click.prevent="delData()">删除（行）</el-button>
      <div class="minus">
        服务商 {{minutes}} 分钟内的平均净值
      </div>
    </div>
    <div class="table">
      <el-table
        :data="tableData"
        ref="table"
        tooltip-effect="dark"
        border
        stripe
        @selection-change='selectRow'>
        <el-table-column type="selection" width="100" align="center"></el-table-column>
        <el-table-column label="服务商账户净值" align="center" width="300">
          <template slot-scope="scope">
            <el-input class="require_des" v-model="scope.row.boosWorth" @input="scope.row.boosWorth = scope.row.boosWorth.replace(/[^\d.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"></el-input>
          </template> 
        </el-table-column>
        <el-table-column label="服务商的平均净值区间" align="center">
          <template slot-scope="scope">
            <p class="tits">净值 *</p>
            <el-input 
              class="require_des" 
              v-model="scope.row.beginThreshold" 
              @input="scope.row.beginThreshold = scope.row.beginThreshold.replace(/[^\d.]/g,'').replace(/^0[0-9]|^[2-9]|^1[0-9]|^1\.|[^\d.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"
            >
            </el-input>
            <p style="margin:0 0 0 20px">~</p>
            <p class="tits">净值 *</p>
            <el-input 
              class="require_des" 
              v-model="scope.row.endThreshold"
              @input="scope.row.endThreshold = scope.row.endThreshold.replace(/[^\d.]/g,'').replace(/^\D*([1-9]\d*\.?\d{0,2})?.*$/,'$1')"
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="跟单比例" align="center" width="300">
          <template slot-scope="scope">
            <p class="tits">1:</p>
            <el-input class="require_des" v-model="scope.row.scale" @input="scope.row.scale = scope.row.scale.replace(/[^\d.]/g,'').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="depc">根据业务的实际场景设置上面的值</div>
    <div class="save">
      <el-button type="primary" @click.prevent="saveData()">保存</el-button>
    </div>
  </div>
</template>

<script>
import SystemManage from "../../common/utils/api/system";

export default {
  data () {
    return {
      minutes: '',
      tableData: [],
      selectlistRow: [],
      rowNum:1
    }
  },
  created() {
    this.getDataList ()
    this.getPassWord()
  },
  methods: {
    // 获取跟单比例列表
    getDataList () {
      SystemManage.getScaleList({}).then((res) => {
        if (res.status === "success") {
          this.tableData = res.data
        } else {
          return false;
        }
      });
    },
    // 获取分钟
    getPassWord() {
      SystemManage.getPassWord({
        code: 'queueNumber'
      }).then((res) => {
        if (res.status === "success") {
          this.minutes = res.data.value
        } else {
          return false;
        }
      });
    },
    // 获取表格选中时的数据
    selectRow (val) {
      this.selectlistRow = val
    },
    // 增加行
    addRow () {
      let list = {
        id: null,
        rowNum:this.rowNum,
        boosWorth: null,
        beginThreshold: null,
        endThreshold: null,   
        scale: ''
      };
      this.tableData.unshift(list)
      console.log(this.tableData)
      this.rowNum += 1;
    },
    // 删除选中行
    delData () {
      for (let i = 0; i < this.selectlistRow.length; i++) {
        let val = this.selectlistRow
        // 获取选中行的索引的方法
        // 遍历表格中tableData数据和选中的val数据，比较它们的rowNum,相等则输出选中行的索引
        // rowNum的作用主要是为了让每一行有一个唯一的数据，方便比较，可以根据个人的开发需求从后台传入特定的数据
        val.forEach((val) => {
          this.tableData.forEach((v, i) => {
            if(v.id === null) {
              if (val.rowNum === v.rowNum) {
                // i 为选中的索引
                this.tableData.splice(i, 1)
              }
            }else{
              if (val.id === v.id) {
                // i 为选中的索引
                this.tableData.splice(i, 1)
              }
            }
          })
        })
      }
      // 删除完数据之后清除勾选框
      this.$refs.table.clearSelection()
    },
    //保存
    saveData() {
      console.log(this.tableData)
      SystemManage.addSwitchScale(
        this.tableData
      ).then(res => {
        if (res.status === "success") {
          this.$notify({
            title: "操作提示",
            offset: 100,
            type: res.status,
            message: "保存成功！"
          });
          this.getDataList();
        } else {
          this.$notify({
            title: "操作提示",
            offset: 100,
            type: "error",
            message: res.message
          });
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .button {
    display: flex;
    margin: 20px 0;
  }
  .el-table {
    ::v-deep{
      .cell{
        display: flex;
        line-height: 40px;
        justify-content: center;
      }
    }
    .tits{
      width: 110px;
      padding:0 20px
    }
  }
  .depc {
    font-size: 14px;
    font-weight: bold;
    color: red;
    margin-top: 20px;
  }
  .minus {
    line-height: 40px;
    color: red;
    margin-left: 30px;
  }
  .save{
    width: 100%;
    margin: 20px auto;
    text-align: center;
  }
</style>