/*
 * @Author: chengengfeng
 * @Date: 2019-07-24 16:14:37
 * @Last Modified by: chengengfeng
 * @Last Modified time: 2021-04-30 10:24:31
 */
import userManage from "../../common/utils/api/user";
import { generaMenu } from "../../common/utils";
import { Commit, Dispatch } from "@vue/cli-plugin-vuex";
// import { Commit, Dispatch } from "vuex";
// import { loginParams, UserState } from 'interfaces';
import * as types from "../mutation-types";
// import notFound from '../../router/notFoundMap';
import { constantRouterMap } from "../../router/index";
import { setToken, getToken, removeToken } from "../../common/utils/auth";

const state = {
    username: "Administrator", // 用户名
    token: getToken() || "", // token
    isOK: false, // 标识
    asyncRouter: [], // 异步请求返回的路由
    currentUserRouterMap: [], // 当前用户的所有可以访问的路由
    // currentUserRouterMap: constantRouterMap, // 当前用户的所有可以访问的路由
    newAction: [], // 当前访问的动作文件的路由
    allActionArray: [], // 所有已经注册 的动作文件,用于重复判断
};

// 实时注册 动作文件的路由
let id = ""; // 父级菜单ID
let path = ""; // 当前子菜单
let parentPath = ""; // 父级菜单

const dynamicRouters = [];

function _getResponseRouter(data) {
    window.console.log("data.....", data);
    // debugger;
    generaMenu(dynamicRouters, data);
}

// 是否是数字
function fn(str) {
    return typeof str === "number" && isFinite(str) && Math.round(str) === str;
}

function getView(name) {
    window.console.log("async name: ", name);
    // return () => import(`views/${name}`);
    return () => Promise.resolve(require(`@/views/${name}`).default);
}

const mutations = {
    [types.SET_TOKEN](states, token) {
        window.console.log("action token", token);
        states.token = token;
    },
    [types.SET_ISOK](states, flag) {
        states.isOK = flag;
    },
    [types.SET_ROUTERS](states, routers = []) {
        // 设置用户路由
        // states.asyncRouter = routers;
        states.currentUserRouterMap = routers;
    },
    [types.SET_USER_NAME](states, name) {
        // 设置用户名
        states.username = name;
    },
    [types.SET_ASYNCROUTER](states, menus) {
        // 后端返回的用户菜单
        states.asyncRouter = menus;
    },
    [types.SET_ACTION_ARRAY](states, infoArray) {
        // action
        window.console.log("////////////////////////////////");
        window.console.log("infoArray：", infoArray);
        window.console.log("////////////////////////////////");
        const actionPath = infoArray.thePath;
        const newActionRouters = infoArray.routesTemplate;
        states.allActionArray.push(actionPath);
        states.newAction = newActionRouters;
    },
};

const actions = {
    userLogin(
        context = {
            commit: Commit,
            Dispatch,
        },
        loginInfo
    ) {
        const userNameOrPhone = loginInfo.userNameOrPhone.trim();
        // const userPassword = loginInfo.userMd5Password;
        const userPassword = loginInfo.userPassword.trim();
        const verifyCode = loginInfo.verifyCode.trim();

        return new Promise((resolve, reject) => {
            userManage
                .userLogin({
                    userNameOrPhone,
                    userPassword,
                    verifyCode,
                })
                .then((res) => {
                    if (res.status === "success") {
                        context.commit(types.SET_TOKEN, res.msg);
                        context.commit(types.SET_ISOK, false);
                        setToken(res.msg);
                    } else {
                        // reject(res.data);
                        context.dispatch("fedLogOut");
                    }
                    resolve(res);
                })
                .catch((error) => {
                    alert(error);
                    reject(error);
                });
        });
    },
    // 获取完了用户信息之后生成用户菜单
    generateRoutes(
        context = {
            commit: Commit,
            Dispatch,
        },
        data // 菜单数据
    ) {
        return new Promise((resolve) => {
            // debugger;
            window.console.log("menus：", data.menus);
            _getResponseRouter(data.menus || []);

            const theRouters = [
                ...dynamicRouters,
                ...constantRouterMap,
                // ...notFound
            ];
            // debugger;
            // window.console.log('_routers', dynamicRouters);
            context.commit(types.SET_ASYNCROUTER, dynamicRouters); // 需返回格式化后的路由表
            context.commit(types.SET_ROUTERS, theRouters); // 设置全局可访问的路由
            context.commit(types.SET_ISOK, true);
            resolve();
        });
    },
    // 根据token获取用户信息
    getUserInfo(
        context = {
            commit: Commit,
            state,
        }
    ) {
        return new Promise((resolve, reject) => {
            userManage
                .getUserInfos()
                .then((res) => {
                    // debugger;
                    console.log("获取用户信息：", res);
                    if (res.status !== "success") {
                        reject(res.message);
                    }
                    context.commit(types.SET_ISOK, false);
                    context.commit(types.SET_USER_NAME, res.data.businessAdminUser.realName); // 设置用户名
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    actionRoutes(
        context = {
            commit: Commit,
            state,
        },
        obj
    ) {
        try {
            return new Promise((resolve, reject) => {
                // window.console.log(context.state);
                // debugger;
                id = obj.id;
                parentPath = obj.parentPath;
                window.console.log("parentPath: ", parentPath);

                path = !fn(parseInt(id, 10)) ? `/${parentPath}` : `/${id}/${parentPath}`;
                // router template
                // debugger;
                const routesTemplate = [{
                    path,
                    hidden: true,
                    component: "layout/Home",
                    children: [{
                        path: obj.path,
                        component: obj.path,
                    }, ],
                }, ];

                window.console.log("routesTemplate：", routesTemplate);

                const routersTempNewItem = [];
                routesTemplate.map((item) => {
                    try {
                        item.component = getView(item.component);
                    } catch (err) {
                        window.console.log("组件不存在:" + err);
                    }
                    const routersTempNewChild = [];
                    item.children.map((child) => {
                        try {
                            const compStr = parentPath + "/" + child.component;

                            window.console.log("compStr: ", compStr);

                            child.component = getView(compStr);
                            routersTempNewChild.push(child);

                            window.console.log("routersTempNewChild: ", routersTempNewChild);
                        } catch (error) {
                            alert(error);
                            window.console.log("组件不存在:" + child.component); // for debug
                            reject(error);
                        }
                    });
                    item.children = routersTempNewChild;
                    if (item.children.length > 0) {
                        routersTempNewItem.push(item);
                    }
                });
                if (routersTempNewItem) {
                    const thePath = obj.path;
                    context.commit("SET_ACTION_ARRAY", { routesTemplate, thePath });
                }
                resolve();
            });
        } catch (err_1) {
            window.console.log("async router error: ", err_1);
        }
    },
    // 退出登录
    userLogout(
        context = {
            commit: Commit,
            state,
        }
    ) {
        return new Promise((resolve, reject) => {
            userManage
                .userLogout(state.token)
                .then(() => {
                    context.commit(types.SET_TOKEN, "");
                    context.commit(types.SET_ISOK, false);
                    context.commit(types.SET_ROUTERS, []);
                    removeToken();
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 出现错误，强制退回登录页面
    fedLogOut(context = { commit: Commit }) {
        return new Promise((resolve) => {
            context.commit(types.SET_TOKEN, "");
            context.commit(types.SET_ROUTERS, []);
            context.commit(types.SET_ISOK, false);
            removeToken();
            resolve();
        });
    },
};

const getters = {
    token: (states) => states.token, // token
    username: (states) => states.username, // 用户名
    isOK: (states) => states.isOK, // 是否渲染完成
    currentRouter: (states) => states.currentUserRouterMap, // 当前用户路由
    asyncRouter: (states) => states.asyncRouter, // 返回的路由
    newAction: (states) => states.newAction, // 当前访问的动作文件的路由
    allActionArray: (states) => states.allActionArray, // 所有已经注册 的动作文件,用于重复判断
    // currentActiveRouter: (state) => state.currentActiveRouter, // 当前高亮路由
    // currentMenuId: (state) => state.currentMenuId || 0 // 当前模块menuId
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
};