<template>
  <div class="exchangeObject">
    <el-form>
      <el-form-item>
        <el-button
          type="warning"
          icon="el-icon-zoom-in"
          @click="dialogFormVisibleAdd = true"
          >新增</el-button
        >
      </el-form-item>
    </el-form>

    <el-table :data="tableDataObject.list" border>
      <el-table-column prop="varietiesName" label="交易品种"> </el-table-column>

      <el-table-column prop="varietiesRemark" label="交易品种说明">
      </el-table-column>

      <el-table-column prop="orderNum" label="排序号"> </el-table-column>

      <el-table-column prop="state" label="状态">
        <template slot-scope="scope">
          <div>{{ scope.row.state == 1 ? "启用" : "停用" }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="opration" label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="
              Editor(
                scope.row.id,
                scope.row.orderNum,
                scope.row.state,
                scope.row.varietiesName,
                scope.row.varietiesRemark
              )
            "
            >【编辑】</el-button
          >
          <el-button
            type="text"
            v-if="scope.row.state == 0"
            @click="changeState(1, scope.row.id)"
            >【启用】</el-button
          >
          <el-button
            type="text"
            v-if="scope.row.state == 1"
            @click="changeState(0, scope.row.id)"
            >【停用】</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageList.page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageList.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableDataObject.total"
    ></el-pagination>

    <!-- 分页 -->
    <!-- <div class="exchangeobject-pagi">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagList.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagList.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div> -->

    <!-- 新增表头弹窗 -->
    <el-dialog
      title="交易品种配置维护"
      :visible.sync="dialogFormVisibleAdd"
      :modal-append-to-body="false"
    >
      <el-form
        ref="formHeader"
        :model="formAdd"
        label-width="106px"
        :rules="rulesAdd"
      >
        <el-form-item label="交易品种" prop="varietiesName">
          <el-input v-model="formAdd.varietiesName"></el-input>
        </el-form-item>

        <el-form-item label="交易品种说明" prop="varietiesRemark">
          <el-input
            v-model="formAdd.varietiesRemark"
            type="textarea"
          ></el-input>
        </el-form-item>

        <el-form-item label="排序号" prop="orderNum">
          <el-input v-model="formAdd.orderNum" type="number" min="0"></el-input>
        </el-form-item>

        <el-form-item prop="state" label="状态">
          <el-radio-group v-model="formAdd.state">
            <el-radio :label="0">停用</el-radio>
            <el-radio :label="1">启用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleAdd = false">取 消</el-button>
        <el-button type="primary" @click="addConfirm('formHeader')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 编辑弹窗 -->
    <el-dialog
      title="交易品种配置维护"
      :visible.sync="dialogFormVisibleBody"
      :modal-append-to-body="false"
    >
      <el-form ref="formEditor" :model="formEditor" label-width="96px">
        <el-form-item label="交易品种">
          <el-input v-model="formEditor.varietiesName"></el-input>
        </el-form-item>

        <el-form-item label="交易品种说明">
          <el-input
            v-model="formEditor.varietiesRemark"
            type="textarea"
          ></el-input>
        </el-form-item>

        <el-form-item label="排序号" type="number">
          <el-input
            v-model="formEditor.orderNum"
            type="number"
            min="0"
          ></el-input>
        </el-form-item>

        <el-radio-group v-model="formEditor.state">
          <el-radio :label="0">停用</el-radio>
          <el-radio :label="1">启用</el-radio>
        </el-radio-group>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleBody = false">取 消</el-button>
        <el-button type="primary" @click="addConfirmBody">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rulesAdd: {
        varietiesName: [
          { required: true, message: "请输入交易品种名称", trigger: "blur" },
        ],
        varietiesRemark: [
          { required: true, message: "请输入交易品种说明", trigger: "blur" },
        ],
        orderNum: [
          { required: true, message: "请输入排序号", trigger: "change" },
        ],
        state: [{ required: true, message: "请输入状态", trigger: "change" }],
      },
      //   控制编辑弹窗
      dialogFormVisibleBody: false,
      formAdd: {
        varietiesName: "",
        varietiesRemark: "",
        orderNum: "",
        state: "",
      },
      pageList: {
        page: 1, //当前是第几页
        pageSize: 10, //一页多少条数据
      },
      formEditor: {
        id: 0,
        orderNum: "",
        state: 0,
        varietiesName: "",
        varietiesRemark: "",
      },
      //控制表头查询弹窗
      dialogFormVisibleAdd: false,

      currentPage4: 3, //当前是第几页

      //交易品种页面列表
      tableDataObject: [],
    };
  },
  methods: {
    //   获取交易品种列表数据
    getExchangeList() {
      this.axios
        .get("/business/admin/varieties/varietie-info", {
          params: this.pageList,
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == "fail") {
            this.$router.push("/");
          } else {
            this.tableDataObject = res.data.data;
            // this.total = res.data.data.total;
          }
        });
    },

    addConfirmBody() {
      this.axios
        .put("/business/admin/varieties/save", this.formEditor)
        .then((res) => {
          console.log(res);

          if (res.data.status == "success") {
            this.$notify({
              title: "成功",
              message: "修改成功",
              type: "success",
              position: "top-left",
              duration: 5000,
            });
          } else {
            this.$notify({
              title: "失败",
              message: "修改失败",
              type: "error",
              position: "top-left",
              duration: 5000,
            });
          }
          this.dialogFormVisibleBody = false;
          this.getExchangeList();
        });
    },
    addConfirm(val) {
      this.$refs[val].validate((valid) => {
        if (valid) {
          // alert(" 验证成功");
          this.axios
            .put("/business/admin/varieties/save", this.formAdd)
            .then((res) => {
              console.log("新增成功：", res);
              this.$notify({
                title: "成功",
                message: res.data.msg,
                type: "success",
                positon: "top-left",
                duration: 5000,
              });
              this.getExchangeList(); // 获取列表数据
              this.dialogFormVisibleAdd = false;
              if (res.data.status == "fail") {
                this.$message({
                  showClose: true,
                  message: `新增错误: \xa0 \xa0 ${res.data.msg}`,
                  type: "error",
                });
              }
            });
        } else {
          return false;
        }
      });

      //    this.axios.put('/business/admin/varieties/save',this.formEditor).then((res)=>{
      //        console.log(res);
      //        this.getExchangeList();
      //        this.dialogFormVisibleAdd=false
      //     })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageList.pageSize = val;
      this.getExchangeList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageList.page = val;
      this.getExchangeList();
    },
    Editor(
      idForm,
      orderNumForm,
      stateForm,
      varietiesNameForm,
      varietiesRemarkForm
    ) {
      // 编辑

      this.dialogFormVisibleBody = true;
      console.log(
        idForm,
        orderNumForm,
        stateForm,
        varietiesNameForm,
        varietiesRemarkForm
      );
      this.formEditor.id = idForm;
      this.formEditor.orderNum = orderNumForm;
      this.formEditor.state = stateForm;
      this.formEditor.varietiesName = varietiesNameForm;
      this.formEditor.varietiesRemark = varietiesRemarkForm;
    },
    //添加弹窗
    addForm() {
      this.dialogFormVisibleAdd = true;
    },
    changeState(stateForm, idForm) {
      console.log(stateForm);
      console.log(idForm);
      this.axios
        .get("/business/admin/varieties/updateState", {
          params: {
            state: stateForm,
            id: idForm,
          },
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.$notify({
              title: "成功",
              message: "修改状态成功",
              type: "success",
              positon: "top-left",
              duration: 5000,
            });
          } else {
            this.$notify({
              title: "失败",
              message: "修改状态失败",
              type: "error",
              duration: 5000,
            });
          }
          this.getExchangeList();
        });
    },
  },
  mounted() {
    this.getExchangeList();
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name == "riskLv" ||
      to.name == "Exchange" ||
      to.name == "Team" ||
      to.name == "Index" ||
      to.name == "account" ||
      to.name == "Userconf" ||
      to.name == "exConfiguration"
    ) {
      this.getExchangeList();
    }
    next();
  },
};
</script>

<style scoped>
.exchangeobject-pagi {
  /* position: fixed;
       bottom:2%;
       left:40%; */
  /* margin-right: 38.75rem; */
  margin-top: 1rem;
  text-align: center;
}

.table {
  /* margin-left: 1062px; */
  width: 78.125rem;
  height: 34.375rem;
  overflow-y: scroll;
  /* overflow-x: hidden; */
}

.table {
  width: 75rem;
  height: 31.25rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

</style>
