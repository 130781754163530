<script>
// import Vue, { CreateElement } from 'vue';
// import { Prop, Component } from 'vue-property-decorator';

// @Component({
//   name: 'CloudTitle'
// })
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  render() {
    return (
      <div class="title-wrapper">
        {this.$slots.default ? (
          <div class="title-customize">{this.$slots.default}</div>
        ) : (
          <div class="title-name">{this.title}</div>
        )}
      </div>
    );
  },
};
</script>

<style lang="scss" scoped>
.title-wrapper {
  // height: 62px;
  // margin: 0 40px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 12px;
  .title-name {
    height: 32px;
    display: block;
    // padding: 30px 0 0 0;
    font-size: 18px;
    font-weight: bold;
    color: #303d4a;
  }
}
</style>
