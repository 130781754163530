<!-- 系统设置 - 用户组管理 -->
<template>
  <div class="user-group">
    <cloud-title title="用户组管理" />
    <!-- input -->
    <div class="role-wrapper">
      <div class="form-wrapper">
        <el-form :inline="true" class="form-inline" onsubmit="return false;">
          <el-select clearable v-model="roleId" placeholder="用户组名称">
            <el-option
              v-for="(item, index) in userGroupList"
              :key="index"
              :label="item.roleName"
              :value="item.id"
            />
          </el-select>
          <!-- button -->
          <el-button
            @click="getRoleList"
            class="blue-btn"
            icon="icon-cloud-search"
            >查询</el-button
          >
          <el-button
            @click="addRole"
            class="black-btn"
            icon="el-icon-zoom-in"
            >新增</el-button
          >
        </el-form>
      </div>

      <!-- table -->
      <el-table
        :data="dataList"
        stripe
        v-loading="listLoading"
        border
        style="width: 100%; margin-top: 20px"
        height="380px"
      >
        <el-table-column align="center" prop="roleName" label="用户组名称" />
        <el-table-column
          align="center"
          prop="remark"
          :formatter="isNull"
          label="用户组描述"
        />
        <el-table-column align="center" prop="createTime" label="创建时间" />
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <a
              @click="handleAuthority(scope.row.id, menuId)"
              href="javascript:;"
              class="blueColor"
              >分配权限</a
            >
            <a
              @click="handleUpdate(scope.$index)"
              href="javascript:;"
              class="blueColor"
              >修改</a
            >
            <a
              v-if="btns.delete"
              @click="handleDelete(scope.row.id)"
              href="javascript:;"
              class="blueColor"
              >删除</a
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- pagination -->
      <cloud-pagination
        v-show="pageTotal"
        :total="pageTotal"
        :listQuery="listQuery"
        @currentChange="currentChange"
        @sizeChange="sizeChange"
      />

      <!-- add-update role dialog -->
      <cloud-dialog
        :title="title"
        :visible.sync="showForm"
        @close="closeDialog"
      >
        <el-form :model="roleInfo" :rules="addRoleFormRule" ref="roleForm">
          <el-form-item
            label="用户组名称："
            :label-width="formLabelWidth"
            prop="roleName"
          >
            <el-input
              v-model="roleInfo.roleName"
              placeholder="请输入用户组名称"
              auto-complete="off"
            />
          </el-form-item>
          <el-form-item
            label="用户组描述："
            :label-width="formLabelWidth"
            prop="remark"
          >
            <el-input
              v-model="roleInfo.remark"
              placeholder="请输入用户组描述"
              auto-complete="off"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <button class="cancelBtn" @click="showForm = false">取 消</button>
          <button
            class="confirmBtn"
            v-if="isAdd"
            type="primary"
            @click="saveAddRole('roleForm')"
          >
            确定
          </button>
          <button
            class="confirmBtn"
            v-if="isUpdate"
            type="primary"
            @click="saveUpdateRole('roleForm')"
          >
            确定修改
          </button>
        </div>
      </cloud-dialog>

      <cloud-dialog title="温馨提示" :visible.sync="showConfirm">
        <div class="tc" style="padding: 30px 0">
          <p>确定删除所选择的内容吗?</p>
        </div>
        <div slot="footer" class="dialog-footer">
          <button class="cancelBtn" @click="showConfirm = false">取 消</button>
          <button class="confirmBtn" @click="sureDelUserGroup">确定</button>
        </div>
      </cloud-dialog>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
import SystemManage from "../../common/utils/api/system";
import PublicMixin from "../mixins";
// import { Component } from 'vue-property-decorator';

// @Component({
//   name: 'UserGroup'
// })
export default {
  mixins: [PublicMixin],
  data() {
    return {
      roleId: "",
      // 用户组列表
      userGroupList: [],
      title: "",
      showForm: false,
      roleInfo: {
        roleName: "",
        remark: "",
      },
      addRoleFormRule: {
        roleName: [
          {
            required: true,
            message: "请输入用户组名称",
            trigger: "blur",
          },
        ],
      },
      isAdd: false,
      isUpdate: false,
      // $refs: {
      //   roleForm: HTMLFormElement
      // },
      formLabelWidth: "140px",
      showConfirm: false,
      systemRoleId: "",
      btns: {
        insert: 0,
        delete: 0,
        update: 0,
        select: 0,
        updateAuth: 0,
      },
    };
  },
  methods: {
    // 获取用户组列表
    getRoleList(flag = true) {
      if (flag) {
        this.listQuery.page = 1;
        this.listQuery.pageSize = 10;
      }
      const params = Object.assign({}, { roleId: this.roleId }, this.listQuery);
      this.listLoading = true;
      SystemManage.getRoleList(params).then((res) => {
        this.listLoading = false;
        if (res.status === "success") {
          this.dataList = res.data.list;
          this.pageTotal = res.data.total;
        } else {
          this.getListError();
        }
      });
    },

    // 获取所有用户组
    getAllRoles() {
      SystemManage.getAllRoles().then((res) => {
        if (res.status === "success") {
          window.console.log(res);
          this.userGroupList = res.data;
        }
      });
    },

    isNull(row) {
      return !row.remark ? "暂无" : row.remark;
    },

    closeDialog() {
      this.isUpdate = false;
      this.isAdd = false;
      this.title = "";
    },

    currentChange(page) {
      this.listQuery.page = page;
      this.getRoleList(false);
    },

    sizeChange(pageSize) {
      this.listQuery.pageSize = pageSize;
      this.getRoleList(false);
    },

    addRole() {
      this.showForm = true;
      this.isAdd = true;
      this.isUpdate = false;
      this.title = "添加用户组";
      this.roleInfo = {
        roleName: "",
        remark: "",
      };
    },

    // 保存添加用户用户组
    saveAddRole() {
      this.$refs.roleForm.validate((valid) => {
        if (valid) {
          SystemManage.addRole(this.roleInfo, "String").then((res) => {
            this.operTips(res, () => {
              this.showForm = false;
              this.getAllRoles();
              this.getRoleList();
            });
          });
        } else {
          return false;
        }
      });
    },

    saveUpdateRole() {
      this.$refs.roleForm.validate((valid) => {
        if (valid) {
          SystemManage.updateRoleById(this.roleInfo, "String").then((res) => {
            this.operTips(res, () => {
              this.showForm = false;
              this.getAllRoles();
              this.getRoleList();
            });
          });
        } else {
          return false;
        }
      });
    },

    handleUpdate(index) {
      const item = this.dataList[index];
      window.console.log(item);
      this.roleInfo = {
        id: item.id,
        roleName: item.roleName,
        remark: item.remark,
      };
      this.isAdd = false;
      this.isUpdate = true;
      this.title = "修改用户组信息";
      this.showForm = true;
    },

    handleDelete(roleId) {
      this.systemRoleId = roleId;
      this.showConfirm = true;
    },

    sureDelUserGroup() {
      SystemManage.delRoleById({
        id: this.systemRoleId,
      }).then((res) => {
        this.showConfirm = false;
        this.operTips(res, () => {
          this.getRoleList();
        });
      });
    },

    handleAuthority(rid, mid) {
      window.console.log(rid, mid);
      const query = {
        parentPath: "systemManage",
        rid,
        mid,
      };
      this.$router.push({
        path: "userGroup_authority",
        query,
      });
    },
  },

  created() {
    this.getRoleList();
    this.getAllRoles();
  },
};
</script>
