<script>
// import Vue, { CreateElement } from "vue";
// import { Prop, Component } from "vue-property-decorator";

const delta = 230;

// @Component({
//   name: "CloudScrollBar",
// })
export default {
  data() {
    return {
      top: 0,
    };
  },
  // $refs: any = {
  //   scrollContainer: HTMLElement,
  //   scrollWrapper: HTMLElement,
  // };
  render() {
    return (
      <div
        class="scroll-container"
        ref="scrollContainer"
        on-mousewheel={this.handleScroll}
        on-wheel={this.handleScroll}
      >
        <div
          class="scroll-wrapper"
          ref="scrollWrapper"
          style={"top:" + this.top + "px"}
        >
          {this.$slots.default}
        </div>
      </div>
    );
  },
  methods: {
    handleScroll(event) {
      // WheelEvent
      // fix: 显式取消默认行为，菜单滚动右侧内容随之滚动的bug
      event.preventDefault();
      // fix: tslint: Property 'wheelDelta' does not exist on type 'WheelEvent'.
      // https://www.soinside.com/question/xYd3h2nETkwT5iqsnAbuaZ
      // https://github.com/Microsoft/TypeScript/issues/9071
      // (event as MouseWheelEvent).wheelDelta
      // const eventDelta = event.wheelDelta || -event.deltaY * 3;
      const eventDelta = -event.deltaY * 3;
      const $container = this.$refs.scrollContainer;
      const $containerHeight = $container.offsetHeight;
      const $wrapper = this.$refs.scrollWrapper;
      const $wrapperHeight = $wrapper.offsetHeight;
      if (eventDelta > 0) {
        this.top = Math.min(0, this.top + eventDelta);
      } else {
        if ($containerHeight - delta < $wrapperHeight) {
          if (this.top < -($wrapperHeight - $containerHeight + delta)) {
            this.top;
          } else {
            this.top = Math.max(
              this.top + eventDelta,
              $containerHeight - $wrapperHeight - delta
            );
          }
        } else {
          this.top = 0;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/style/mixin";
@import "../../common/style/variables";
.scroll-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $menuBg;
  .scroll-wrapper {
    position: absolute;
    width: 100% !important;
  }
}
</style>
