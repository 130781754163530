<template>
  <div class="wrapper" v-loading="TableLoading">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '' }">数据看板</el-breadcrumb-item>
      <el-breadcrumb-item>
        <router-link :to="{ path: 'IncomeStatistics?menuId=111' }">盈亏统计</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <router-link :to="{ path: '' }">资金管理</router-link>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!-- title：数据总览 -->
    <div class="statistics">
      <!-- <h1>数据总览</h1> -->
      <!-- <span>每天01:00更新数据</span> -->
    </div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item>
        <el-select
          v-model="formInline.valueEXchange"
          placeholder="请选择投资组合"
        >
          <el-option
            v-for="item in optionsExchange"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          clearable
          v-model="formInline.changeType"
          placeholder="请选择变更类型"
        >
          <el-option
            v-for="item in changeType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button
          icon="el-icon-search"
          type="primary"
          style="margin-left: 15px"
          @click="getManageInfos"
          >查询</el-button
        >
      </el-form-item>
      <el-button
        type="danger"
        style="float: right"
        size="small"
        @click="getSynchronize(true)"
        >同步本金</el-button
      >
      <el-button
        type="warning"
        style="float: right;margin-right: 10px"
        size="small"
        @click="getSynchronize(false)"
        >手动同步本金</el-button
      >
    </el-form>
    <el-table
      :data="tableData.list"
      border
      style="width: 100%"
      v-loading="TableLoading"
    >
      <el-table-column label="序号" min-width="50" align="center">
        <template v-slot="scope">
          <p>{{ scope.$index + 1 }}</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="currentAssets"
        label="变更前资产"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="changeAssets"
        label="变更资产"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="walletAssets"
        label="变更后资产"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column label="变更类型" min-width="80" align="center">
        <template v-slot="scope">
          <p>
            {{
              scope.row.changeType === 1
                ? "入金"
                : scope.row.changeType === 2
                ? "出金"
                : scope.row.changeType === 3
                ? "盈利分佣"
                : "亏损补齐"
            }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        prop="createBy"
        label="操作人"
        min-width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="createDate"
        label="同步（变更）时间"
        min-width="180"
      ></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageList.page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageList.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.total"
    ></el-pagination>
    <!-- API弹窗 -->
    <el-dialog
      title="同步本金"
      :visible.sync="SynchronizedPrincipalShow"
      width="30%"
      :modal-append-to-body="false"
      center
    >
      <el-form
        ref="SynchronizeForm"
        :model="SynchronizeForm"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="钱包净值">
          <el-input
            v-model="SynchronizeForm.walletAssets"
            @input="ChangeAmount"
          ></el-input>
          <p style="color: #909399; line-height: 18px">*交易所钱包净值</p>
        </el-form-item>
        <el-form-item label="当前资产">
          <el-input
            v-model="SynchronizeForm.currentAssets"
            @input="ChangeAmount"
          ></el-input>
          <p style="color: #909399; line-height: 18px">*上一次变更后资产</p>
        </el-form-item>
        <el-form-item label="变更金额">
          <el-input
            v-model="SynchronizeForm.changeAssets"
            :disabled="true"
          ></el-input>
          <p style="color: #909399; line-height: 18px">*钱包净值减去当前资产</p>
        </el-form-item>
        <el-form-item label="变更类型" prop="changeType">
          <el-select
            clearable
            v-model="SynchronizeForm.changeType"
            placeholder="请选择变更类型"
          >
            <el-option
              v-for="item in changeType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            v-model="SynchronizeForm.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="SynchronizedPrincipalShow = false">取 消</el-button>
        <el-button type="primary" @click="SynchronizeSave('SynchronizeForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      optionsExchange: [], //交易所组合下拉 表头
      formInline: {
        valueEXchange: "", // 表头交易所组合下拉 model值
        changeType: "", // 变更类型（1、入金，2、出金，3、盈利分佣，4、亏损补齐）
      },
      SynchronizeForm: {
        walletAssets: "", // 钱包净值
        currentAssets: "", // 当前资产
        changeAssets: "", // 变更资产
        changeType: "", // 变更类型
        remark: "", // 备注
        createBy: "",
        createDate: "",
        id: "",
        configId: ""
      },
      tableData: [],
      pageList: {
        page: 1, //当前是第几页
        pageSize: 10, //一页多少条数据
      },
      // 校验规则
      rules: {
        changeType: [
          { required: true, message: "请选择变更类型", trigger: "change" },
        ],
      },
      changeType: [
        {
          value: 1,
          label: "入金",
        },
        {
          value: 2,
          label: "出金",
        },
        {
          value: 3,
          label: "盈利分佣",
        },
        {
          value: 4,
          label: "亏损补齐",
        },
      ],
      TableLoading: false, // 加载进度
      SynchronizedPrincipalShow: false, // 同步本金-弹窗
    };
  },
  watch: {},
  computed: {},
  methods: {
    // 交易所组合下拉菜单的数据 -- 用于查询列表（交易所组合）
    getSearchSelect() {
      this.axios.get("/business/admin/config/combinations-all").then((res) => {
        // console.log("交易所组合下拉", res.data.data);
        this.optionsExchange = res.data.data;
        if (this.$route.query.configId) {
          this.formInline.valueEXchange = parseInt(this.$route.query.configId);
        } else {
          this.formInline.valueEXchange = res.data.data[0].id;
        }
        this.getManageInfos();
      });
    },
    // 数据看板-资金管理-查询按钮
    getManageInfos() {
      // debugger;
      this.TableLoading = true;
      this.axios
        .get("/business/admin/balance-manage/infos", {
          params: {
            configId: this.formInline.valueEXchange,
            changeType: this.formInline.changeType,
            page: this.pageList.page,
            pageSize: this.pageList.pageSize,
          },
        })
        .then((res) => {
          console.log("数据看板-资金管理-查询按钮:", res.data.data);
          this.tableData = res.data.data;
          this.TableLoading = false;
        });
    },
    // 同步本金-打开按钮
    getSynchronize(type) {
      this.TableLoading = true;
      if(type) {
        this.axios
        .get("/business/admin/balance-manage/synchronization", {
          params: {
            configId: this.formInline.valueEXchange,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            // console.log("同步本金:", res.data.data);
            this.SynchronizeForm = res.data.data;
            this.SynchronizedPrincipalShow = true;
            this.TableLoading = false;
          } else if (res.data.status === "fail") {
            this.$notify.error({
              title: "错误",
              message: res.data.msg,
            });
            this.TableLoading = false;
          }
        });
      } if (!type) {
        debugger;
        this.SynchronizeForm.configId = this.formInline.valueEXchange;
        this.SynchronizedPrincipalShow = true;
        this.TableLoading = false;
      }
      
    },
    // 同步本金弹窗-确定校验
    SynchronizeSave(val) {
      this.$refs[val].validate((valid) => {
        if (valid) {
          this.axios
            .put("/business/admin/balance-manage/save", this.SynchronizeForm)
            .then((res) => {
              if (res.data.status == "success") {
                this.$notify({
                  title: "成功",
                  message: "同步成功",
                  type: "success",
                  // position: "top-left",
                  duration: 5000,
                });
                this.getManageInfos();
                this.SynchronizedPrincipalShow = false;
              } else {
                this.$notify({
                  title: "失败",
                  message: "同步失败",
                  type: "error",
                  // position: "top-left",
                  duration: 5000,
                });
              }
            });
        } else {
          return false;
        }
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageList.pageSize = val;
      this.getManageInfos();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageList.page = val;
      this.getManageInfos();
    },
    ChangeAmount() {
      this.SynchronizeForm.changeAssets = this.SynchronizeForm.walletAssets - this.SynchronizeForm.currentAssets;
    }
  },
  created() {},
  mounted() {
    this.getSearchSelect(); // 交易所组合下拉菜单的数据 -- 用于查询列表（交易所组合）
  },
};
</script>
<style lang="scss" scope>
.wrapper {
  // background-color: aquamarine;
  margin: 0 2%;
  width: 96%;
  .el-divider--horizontal {
    margin: 12px 0;
  }
  .statistics {
    h1 {
      font-size: 18px;
      display: inline-block;
    }
    span {
      // margin-left: 15px;
      font-size: 14px;
      color: #bdaaaa;
      line-height: 18px;
      margin: 10px 0;
      // display: inline-block;
    }
  }
  .card-item {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 10px auto;
    .el-card {
      display: inline-block;
      width: 30%;
      height: 100%;
      .el-card__body {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }
    }
    .card-top {
      font-size: 16px;
      .top-left {
        float: left;
        color: #ffffff;
        p {
          font-size: 12px;
        }
      }
      .top-right {
        float: right;
      }
    }
    .card-middle {
      text-align: center;
      font-size: 24px;
      color: #ffffff;
      margin: 3px auto;
    }
    .card-bottom {
      float: right;
      color: #ffffff;
    }
  }
  // .block {
  //   margin: 15px 0;
  // }
}
</style>
