<template>
  <div>
    <!-- 搜索 -->
    <div>
      跟单组合名称：<el-input v-model="formData.groupName" placeholder="请选择跟单组合名称" style="width: 180px;"></el-input>
      跟单账号：<el-select v-model="formData.followId" placeholder="请选择跟单账号" clearable>
        <el-option v-for="item in options" :key="item.id" :label="item.accountName" :value="item.id">
        </el-option>
      </el-select>
      是否机器人：<el-select v-model="formData.switchRobot" placeholder="请选择" clearable>
        <el-option label="机器人" value="1" />
        <el-option label="服务商" value="0" />
      </el-select>
      <el-button type="primary" icon="el-icon-search" style="margin-left: 1rem" @click="searchData">
        搜索
      </el-button>
      <el-button style="margin-left: 1rem" @click="checkAccount">检查账户是否重复</el-button>
    </div>
    <!-- 列表信息 -->
    <div class="box-cont">
      <el-card class="box-card" v-for="(item, index) in dataList" :key="index" style="margin-bottom: 15px">
        <div slot="header" class="clearfix">
          <span>{{ item.groupName }}</span>
          <el-button type="primary" size="small" @click="transtionRecord(item)">
            交易记录
          </el-button>
          <el-button size="small" @click="viewOperationLog(item.id)" style="margin-right:10px">
            跟单账户操作记录
          </el-button>
          <el-button size="small" v-preventClick @click="followAccount(item.id)" style="margin-right:5px">
            小单测试
          </el-button>
          <el-button size="small" type="warning" v-preventClick @click="stopScript(item.id)" style="margin-right:5px">
            停止脚本
          </el-button>
          <el-button size="small" type="primary" v-preventClick @click="startScript(item.id)" style="margin-right:5px">
            启动脚本
          </el-button>
          <el-button size="small" v-preventClick @click="setFollow(item.id)" style="margin-right:5px">
            跟单设置
          </el-button>
          <el-button size="small" v-preventClick @click="setAcceson(item.id)" style="margin-right:5px">
            划账设置
          </el-button>
          <el-button size="small" v-preventClick @click="setRobot(item.id)" style="margin-right:5px">
            服务商机器人设置
          </el-button>
          <el-button size="small" v-preventClick @click="setCurry(item.id)" style="margin-right:5px">
            一键结算并初始化本金
          </el-button>
          <el-button size="small" v-preventClick @click="serviceOneclick(item.id)" style="margin-right:5px">
            服务商一键平仓
          </el-button>
          <el-button size="small" type="primary" v-if="item.switchStatistics == 0" v-preventClick @click="dataBegin(item.id)" style="margin-right:5px">
            数据统计未开始
          </el-button>
          <el-button size="small" type="danger" v-if="item.switchStatistics == 1" v-preventClick @click="dataStart(item.id)" style="margin-right:5px">
            数据统计启动中
          </el-button>
        </div>
        <div class="list_details">
          <el-descriptions border :column="2" class="first">
            <el-descriptions-item label="服务商:" label-class-name="my-label" content-class-name="my-content">
              <div class="my-left">{{ item.bossName }}（{{item.bossSubAccount}}）</div>
              <div class="my-right">账户净值：{{ item.bossTotal }}</div>
            </el-descriptions-item>
            <el-descriptions-item label="跟单:" label-class-name="my-label1" content-class-name="my-content1">
              <div class="my-left">{{ item.followName }}（{{item.followSubAccount}}）</div>
              <div class="my-right">账户净值：{{ item.followTotal }}</div>
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions border :column="2">
            <el-descriptions-item label="正在跟单中">
              <el-button v-if="item.isRun === 1" type="danger" size="small" @click="stopKeep(item.id)">停止跟单</el-button>
              <el-button v-if="item.isRun === 0" type="primary" size="small" @click="startKeep(item.id)">开始跟单
              </el-button>
              <el-radio-group style="margin-left: 30px;" v-model="item.isDirection" @change="changeRadio(index)">
                <el-radio :label=1 style="margin-right: 30px">正向跟单</el-radio>
                <el-radio :label=0>反向跟单</el-radio>
              </el-radio-group>
            </el-descriptions-item>
            <el-descriptions-item label="跟单比例">
              <div class="menubili">
                <div>
                  1： 
                  <el-input v-model="item.leverScale" placeholder="请输入跟单比例" style="width: 100px;"></el-input>
                  <!-- <el-select ref="optionRef" style="width: 100px" size="small" v-model="item.leverScale" filterable placeholder="请选择"
                    @change="changeLever">
                    <el-option v-for="v in proportions" :key="v.value" :label="v.label" :value="v.value">
                    </el-option>
                  </el-select> -->
                  <el-button size="small" type="primary" style="margin-left: 1rem" @click="upDataLever(item)">
                    修改
                  </el-button>
                  <div class="desc">（ 值：0.01~10 ）</div>
                </div>
                <div>
                  动态跟单比例
                  <el-switch v-model="item.switchScale" active-color="#13ce66" :active-value="1" :inactive-value="0"
                      inactive-color="#ff4949" @change="changeState(item.id)"></el-switch>
                </div>
              </div>
            </el-descriptions-item>
            <!-- <el-descriptions-item label="跟单异常警告">
              <el-button type="danger" size="small" @click="viewExceptions(item.id)">查看</el-button>
            </el-descriptions-item>
            <el-descriptions-item>
              <el-button size="small" @click="viewOperationLog(item.id)">跟单账户操作记录</el-button>
            </el-descriptions-item> -->
          </el-descriptions>
        </div>
      </el-card>
    </div>
    <!-- 弹窗 -->
    <el-dialog :title='stopTitle' :visible.sync="keepFlag" width="30%" center @close="closeStop">
      <span :style="stopFlag ? 'color: #000' : 'color: red'">{{ mainText }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="keepFlag = false">取 消</el-button>
        <el-button size="small" type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" :title='justBackTitle'
      :visible.sync="justBackFlag" width="30%" center @close="closeStop">
      <span>{{ justBackText }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" v-if="justBackSteps === 0" @click="closeJustBack">取 消</el-button>
        <el-button size="small" type="primary" @click="determineJustBack">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" :title='leverTitle'
      :visible.sync="leverFlag" width="30%" center @close="closeStop">
      <span>{{ leverText }}</span><span v-if="justBackSteps == 1">1:{{ label }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" v-if="justBackSteps === 0" @click="leverFlag = false">取 消</el-button>
        <el-button size="small" type="primary" @click="determineLever">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="开始跟单" :visible.sync="startFlag">
      <el-form :model="runForm" :rules="rules" ref="runForm" label-position="left">
        <el-form-item label="当前跟单方式" prop="isDirection" label-width="120px">
          <el-radio-group v-model="runForm.isDirection">
            <el-radio :label=1 style="margin-right: 30px">正向跟单</el-radio>
            <el-radio :label=0>反向跟单</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="杠杆比例" prop="leverScale" label-width="120px">
          <el-select ref="optionRef" style="width: 100px" size="small" v-model="runForm.leverScale" placeholder="请选择">
            <el-option v-for="v in proportions" :key="v.value" :label="v.label" :value="v.value">
            </el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="startFlag = false">取 消</el-button>
        <el-button type="primary" @click="runStart">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 分页 -->
    <el-pagination background layout="prev, pager, next, sizes" :total="pageNum" :page-sizes="[10]"
      @size-change="handleSizeChange" @current-change="handleCurrentChange">
    </el-pagination>

    <!-- 检查账号是否重复 -->
    <el-dialog
      title="账号是否重复"
       v-loading="loading"
      :visible.sync="checkDialogVisible"
      width="30%"
      center>
      <span class="titcont">{{ checkCount }}</span>
    </el-dialog>

    <!-- 验证密码弹框 -->
    <el-dialog
      title="请输入密码"
       v-loading="loading"
      :visible.sync="checkPassWord"
      :close-on-click-modal="false"
      width="30%"
      center>
      <div style="display: inline-block;">密码：</div>
      <el-input style="width:90%" placeholder="请输入密码" v-model="scriptPwd" show-password></el-input>
      <span slot="footer" class="dialog-footer" style="width:50%;margin:0 auto;">
        <el-button @click="checkPassWord = false">取 消</el-button>
        <el-button type="primary" v-if="isShow == true" @click="sureStartPwd">确 定</el-button>
        <el-button type="primary" v-if="isShow == false" @click="sureStopPwd">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 验证数据统计密码弹框 -->
    <el-dialog
      title="请输入数据统计密码"
       v-loading="loading"
      :visible.sync="dataCenterPwd"
      :close-on-click-modal="false"
      width="30%"
      center>
      <div style="display: inline-block;">密码：</div>
      <el-input style="width:90%" placeholder="请输入密码" v-model="statisticsPwd" show-password></el-input>
      <span slot="footer" class="dialog-footer" style="width:50%;margin:0 auto;">
        <el-button @click="dataCenterPwd = false">取 消</el-button>
        <el-button type="primary" v-if="isStatist == true" @click="startStatist">确 定</el-button>
        <el-button type="primary" v-if="isStatist == false" @click="stopStatist">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 验证一键平仓密码弹框 -->
    <el-dialog
      title="请输入服务商一键平仓密码"
       v-loading="loading"
      :visible.sync="servicePassWord"
      :close-on-click-modal="false"
      width="30%"
      center>
      <div style="display: inline-block;">密码：</div>
      <el-input style="width:90%" placeholder="请输入密码" v-model="oneTouchPwd" show-password></el-input>
      <span slot="footer" class="dialog-footer" style="width:50%;margin:0 auto;">
        <el-button @click="servicePassWord = false">取 消</el-button>
        <el-button type="primary" @click="sureOneTouchPwd">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 服务商机器人验证密码弹框 -->
    <el-dialog
      title="请输入服务商机器人密码"
       v-loading="loading"
      :visible.sync="robotPassWord"
      :close-on-click-modal="false"
      width="30%"
      center>
      <div style="display: inline-block;">密码：</div>
      <el-input style="width:90%" placeholder="请输入密码" v-model="servicePwd" show-password></el-input>
      <span slot="footer" class="dialog-footer" style="width:50%;margin:0 auto;">
        <el-button @click="robotPassWord = false">取 消</el-button>
        <el-button type="primary" @click="sureRobotPwd">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 一键结算密码弹框 -->
    <el-dialog
      title="请输入一键结算并初始化本金密码"
       v-loading="loading"
      :visible.sync="curryPassWord"
      :close-on-click-modal="false"
      width="30%"
      center>
      <div style="display: inline-block;">密码：</div>
      <el-input style="width:90%" placeholder="请输入密码" v-model="curryPwd" show-password></el-input>
      <span slot="footer" class="dialog-footer" style="width:50%;margin:0 auto;">
        <el-button @click="curryPassWord = false">取 消</el-button>
        <el-button type="primary" @click="sureCurryPwd">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 一键结算并初始化本金弹框 -->
    <el-dialog
      title="一键结算并初始化本金"
       v-loading="loading"
      :visible.sync="curryDialog"
      :close-on-click-modal="false"
      width="35%"
      center>
      <el-form :model="curryForm" ref="curryForm" label-position="right">
        <el-form-item label="是否爆仓" label-width="135px">
          <el-radio-group v-model="curryForm.isMargin">
            <el-radio :label=1 style="margin-right: 30px">是</el-radio>
            <el-radio :label=0>否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="服务商初始化本金" label-width="135px">
          <el-input v-model="curryForm.boosInitialFund" @input="curryForm.boosInitialFund = curryForm.boosInitialFund.replace(/[^\d.]/g,'')"></el-input>
        </el-form-item>
        <el-form-item label="跟单初始化本金" label-width="135px">
          <el-input v-model="curryForm.followInitialFund" @input="curryForm.followInitialFund = curryForm.followInitialFund.replace(/[^\d.]/g,'')"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" style="width:50%;margin:0 auto;">
        <el-button type="primary" @click="currySubmit">一键结算</el-button>
      </span>
    </el-dialog>

    <!-- 跟单设置弹框 -->
    <el-dialog
      title="跟单设置"
       v-loading="loading"
      :visible.sync="followMenuDialog"
      :close-on-click-modal="false"
      width="30%"
      center>
      <div style="display: inline-block;margin-right:20px">1.停止跟单设置：</div>
      <el-switch v-model="followData.switchCease" active-color="#13ce66" :active-value="1" :inactive-value="0"
                      inactive-color="#ff4949"></el-switch>
      <div>
        当喊单账户净值达到
        <el-input style="width: 20%;margin: 30px 0 15px;" v-model="followData.ceaseFund"></el-input>
        时，停止跟单并平仓。
      </div>
      <div style="display: inline-block;margin-right:20px">2.开启跟单设置：</div>
      <el-switch v-model="followData.switchOpen" active-color="#13ce66" :active-value="1" :inactive-value="0"
                      inactive-color="#ff4949"></el-switch>
      <div>
        当喊单账户净值低于
        <el-input style="width: 20%;margin: 30px 0 15px;" v-model="followData.openFund"></el-input>
        时，开启跟单。
      </div>
      <span slot="footer" class="dialog-footer" style="width:50%;margin:0 auto;">
        <el-button type="primary" @click="saveFollowMenu">保存</el-button>
      </span>
    </el-dialog>

    <!-- 划账设置弹框 -->
    <el-dialog
      title="划账设置"
       v-loading="loading"
      :visible.sync="accountMenuDialog"
      :close-on-click-modal="false"
      width="40%"
      center>
      <el-switch v-model="accountData.switchTransfer" active-color="#13ce66" :active-value="1" :inactive-value="0"
                      inactive-color="#ff4949" style="position: absolute; top: 22px;left: 65%;"></el-switch>
      <div class="bots">
        1.划入设置：
        <span style="margin-left: 10%;">累计划账次数: {{ accountData.transferNow }}次</span>
        <span style="margin-left: 10%;">累计划账金额: {{ accountData.transferAmountSum }}</span>
      </div>
      <div class="bots">
        <span class="barnum">自动划账次数：</span><el-input v-model="accountData.transferCount" style="width: 180px;"></el-input>
      </div>
      <div class="bots">
        <span class="barnum">划账资金：</span><el-input v-model="accountData.transferQuota" style="width: 180px;"></el-input>
      </div>
      <div class="bots">
        <span class="barnum">跟单最大净值：</span><el-input v-model="accountData.transferMaximum" style="width: 180px;"></el-input>
      </div>
      <div class="bots">
        2.划出设置：
        <span style="margin-left: 10%;">累计划账次数: {{ accountData.delimitNow }}次</span>
        <span style="margin-left: 10%;">累计划出金额: {{ accountData.delimitAmountSum }}</span>
      </div>
      <div class="bots">
        <span class="barnum">服务商净值低于：</span><el-input v-model="accountData.transferBossWorth" style="width: 180px;"></el-input>
      </div>
      <div class="bots">
        <span class="barnum">跟单净值高于：</span><el-input v-model="accountData.transferFollowWorth" style="width: 180px;"></el-input>
      </div>
      <span slot="footer" class="dialog-footer" style="width:50%;margin:0 auto;">
        <el-button type="primary" @click="saveAccountMenu">保存</el-button>
      </span>
    </el-dialog>

    <!-- 服务商机器人下单设置弹框 -->
    <el-dialog
      title="服务商机器人下单设置"
       v-loading="loading"
      :visible.sync="robotMenuDialog"
      :close-on-click-modal="false"
      width="40%"
      center>
      <el-switch v-model="switchRobot" active-color="#13ce66" :active-value="1" :inactive-value="0" inactive-color="#ff4949"
        @change="changeRobot()" style="position: absolute; top: 22px;left: 65%;"></el-switch>
      <el-form :model="robotForm" :rules="rules1"  ref="robotForm" label-position="right">
        <el-form-item label="买入币种" prop="currency" label-width="120px">
          <el-select v-model="robotForm.currency" placeholder="请选择">
            <el-option v-for="v in currencyList" :key="v.value" :label="v.label" :value="v.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数量比例" prop="scale" label-width="120px">
          <el-input style="width: 34%;" v-model="robotForm.scale" @input="handleInput"></el-input>
          <span style="margin-left: 15px;color: red;">(0-1之间 ；1位小数如0.5 不能填0跟大于1的数)</span>
        </el-form-item>
        <el-form-item label="方向" prop="direction" label-width="120px">
          <el-select v-model="robotForm.direction" placeholder="请选择">
            <el-option v-for="v in dirList" :key="v.value" :label="v.label" :value="v.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="杠杆倍数" prop="lever" label-width="120px">
          <el-select v-model="robotForm.lever" placeholder="请选择">
            <el-option v-for="v in leverList" :key="v.value" :label="v.label" :value="v.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" style="width:50%;margin:0 auto;">
        <el-button type="primary" @click="saveRobotMenu">下单</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SystemManage from "../../common/utils/api/system";
export default {
  data() {
    return {
      exchangeInfosList: [],
      runForm: {
        id: '',
        isDirection: 0,
        leverScale: 2
      },
      robotForm: {
        id: '',
        currency: '',
        direction: '',
        lever: null,
        scale: null
      },
      curryForm: {
        id: '',
        isMargin: 1,
        boosInitialFund: null,
        followInitialFund: null
      },
      switchRobot: '',
      loading: false,
      startFlag: false,
      checkDialogVisible: false,
      checkPassWord: false,
      servicePassWord: false,
      robotPassWord: false,
      curryPassWord: false,
      dataCenterPwd: false,
      curryDialog: false,
      robotMenuDialog: false,
      followMenuDialog: false,
      accountMenuDialog: false,
      isShow: true,
      isStatist: true,
      pwdId: null,
      robotId: null,
      touchId: null,
      scriptPwd: '',
      servicePwd: '',
      oneTouchPwd: '',
      statisticsPwd: '',
      curryPwd: '',
      bigInput: false,
      isDirection: null,
      proportion: null,
      listNum: null,
      upDataId: null,
      stopId: null,
      label: '',
      leverFlag: false,
      checkCount: '',
      leverTitle: '',
      leverText: '',
      justBackFlag: false,
      justBackSteps: 0,
      justBackText: '提示：跟单方式调整后,仓位全平，反向跟单',
      justBackTitle: '',
      mainText: '提示：仓位全平，停止跟单',
      stopFlag: 0,
      stopTitle: '停止跟单',
      keepFlag: false,
      lever: '',
      radio: '1',
      pageSize: 10,
      page: 1,
      pageNum: null,
      dataList: [],
      followData: {},
      accountData: {},
      followId: null,
      proportions: [
        { value: 1, label: '1:1' },
        { value: 1.5, label: '1:1.5' },
        { value: 2, label: '1:2' },
        { value: 2.5, label: '1:2.5' },
        { value: 3, label: '1:3' },
        { value: 4, label: '1:4' },
        { value: 5, label: '1:5' }
      ],
      currencyList: [
        { value: 'BTC', label: 'BTC-比特币' },
        { value: 'ETH', label: 'ETH-以太坊' },
      ],
      dirList: [
        { value: 'long', label: 'long' },
        { value: 'short', label: 'short' }
      ],
      leverList: [
        { value: 3, label: '3倍' },
        { value: 5, label: '5倍' },
        { value: 10, label: '10倍' },
        { value: 20, label: '20倍' },
        { value: 30, label: '30倍' },
        { value: 50, label: '50倍' },
        { value: 75, label: '75倍' },
        { value: 100, label: '100倍' },
      ],
      options: [],
      formData: {
        groupName: '',
        followId: '',
        switchRobot: ''
      },
      rules: {
        isDirection: [
          { required: true, message: '请选择跟单方式', trigger: 'change' }
        ],
        leverScale: [
          { required: true, message: '请选择杠杆比例', trigger: 'change' }
        ]
      },
      rules1: {
        currency: [
          { required: true, message: '请选择币种', trigger: 'change' }
        ],
        scale: [
          { required: true, message: '数量比例不能为空', trigger: 'change' }
        ],
        direction: [
          { required: true, message: '请选择方向', trigger: 'change' }
        ],
        lever: [
          { required: true, message: '请选择杠杆倍数', trigger: 'change' }
        ]
      }
    }
  },
  created() {
    this.getDataList()
    this.exchangeInfos()
  },
  methods: {
    // 重新计算资产比例
    updateProportion(id) {
      SystemManage.updateProportion({ id }).then(res => {
        if (res.status === 'success') {
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.startFlag = false
          this.getDataList()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })
    },
    // 交易记录
    transtionRecord(data) {
      this.$router.push({
        path: 'transaction_record',
        query: {
          parentPath: "riskControl",
          bossId: data.bossId,
          followId: data.followId
        }
      })
    },
    // 获取交易所列表（带label下拉数据）
    exchangeInfos() {
      SystemManage.getAccountSelectList().then(res => {
        if (res.status === 'success') {
          this.options = res.data
        }
      })
    },
    // 查看操作日志
    viewOperationLog(id) {
      this.$router.push({
        path: 'quantification_detail',
        query: {
          parentPath: "riskControl",
          type: 0,
          groupId: id
        }
      })
    },
    // 查看异常
    viewExceptions(id) {
      const query = {
        parentPath: "riskControl",
        type: 1,
        groupId: id
      };
      this.$router.push({
        path: 'quantification_detail',
        query,
      })
    },
    // 修改杠杆比例
    upDataLever(val) {
      this.upDataId = val.id
      this.label = val.leverScale
      this.proportion = val.leverScale
      this.leverFlag = true
      this.leverTitle = '修改跟单比例（当前跟单中）'
      this.leverText = '当前组合正处于跟单状态，确认修改，会重新计算净值后，按新的比例在进行跟单'
      // console.log(this.$refs.optionRef.selected.label)
      // this.label = this.$refs.optionRef.selected.label
    },
    determineLever() {
      if (this.justBackSteps === 1) {
        this.upDataFollow()
        this.leverFlag = false
        return
      }
      this.justBackSteps++
      this.switchText(this.justBackSteps)
    },
    changeLever(val) {
      this.label = val
      this.proportion = val
    },
    // 正反跟单
    changeRadio(value) {
      this.listNum = value
      this.isDirection = this.dataList[value].isDirection
      this.stopId = this.dataList[value].id
      if (this.dataList[value].isDirection == 0) {
        this.justBackTitle = '“正向跟单” 调整成 “反向跟单”'
      } else {
        this.justBackTitle = '“反向跟单” 调整成 “正向跟单”'
      }
      this.justBackFlag = true
    },
    determineJustBack() {
      if (this.justBackSteps === 1) {
        this.upDataDirection()
        this.justBackFlag = false
        return
      }
      this.justBackSteps++
      this.switchText(this.justBackSteps)
    },
    closeJustBack() {
      if (this.dataList[this.listNum].isDirection == 0) {
        this.dataList[this.listNum].isDirection = 1
      } else {
        this.dataList[this.listNum].isDirection = 0
      }
      this.justBackSteps = 0
      this.justBackFlag = false
    },
    // 开始跟单
    startKeep(id) {
      this.runForm.id = id
      this.startFlag = true
    },
    // 停止跟单
    stopKeep(id) {
      this.keepFlag = true
      this.stopId = id
    },
    determine() {
      if (this.stopFlag) {
        this.keepFlag = false
        this.runStop()
        return
      }
      this.stopFlag++
      this.switchText(this.stopFlag)
    },
    // 关闭弹窗
    closeStop() {
      this.stopTitle = '停止跟单'
      this.mainText = '提示：仓位全平，停止跟单'
      this.stopFlag = 0
      this.justBackTitle = ''
      this.justBackSteps = 0
      this.justBackText = '提示：跟单方式调整后,仓位全平，反向跟单'
    },
    switchText(val) {
      switch (true) {
        case val === 1:
          this.stopTitle = '确认停止跟单'
          this.mainText = '确认停止当前账户组合跟单功能？'
          this.justBackText = this.justBackTitle
          this.justBackTitle = '跟单方式调整中'
          this.leverTitle = '跟单比例调整中'
          this.leverText = '调整后，比例变为'
          break;
        // case val === 2:
        //   this.justBackTitle = '跟单方式调整成功'
        //   this.justBackText = '当前已完成跟单方式的调整'
        //   this.leverTitle = '跟单杠杠比例调整成功'
        //   this.leverText = '当前已完成跟单杠杠比例调整'
        //   break;
        default:
          break;
      }
    },
    // 监控跟单
    // leverInput(val) {
    //   if (val > 10 || val < 0.01) {
    //     this.bigInput = true
    //   } else {
    //     this.bigInput = false
    //   }
    //   this.label = val
    //   this.proportion = val
    // },
    // 开始跟单
    runStart() {
      this.$refs.runForm.validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '开启跟单中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          SystemManage.runStart({ ...this.runForm }).then(res => {
            loading.close();
            if (res.status === 'success') {
              this.$message({
                message: res.msg,
                type: 'success'
              });
              this.startFlag = false
              this.getDataList()
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
            }
          })
        }
      });
    },
    // 正反跟单修改
    upDataDirection() {
      const loading = this.$loading({
        lock: true,
        text: '调整中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      SystemManage.upDataDirection({
        id: this.stopId,
        isDirection: this.isDirection
      }).then(res => {
        loading.close();
        if (res.status === 'success') {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.getDataList()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
          this.closeJustBack()
        }
      })
    },
    // 小单测试
    followAccount(id) {
      SystemManage.accountOrder({ id }).then(res => {
        if (res.status === 'success') {
          this.$message({
            message: res.msg,
            type: 'success'
          });
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })
    },
    // 启动脚本
    startScript(id) {
      this.checkPassWord = true
      this.pwdId = id
      this.scriptPwd = ''
      this.isShow = true
    },
    // 确定启动
    sureStartPwd() {
      this.loading = true
      SystemManage.startScript({ 
        id: this.pwdId,
        scriptPwd: this.scriptPwd
      }).then(res => {
        if (res.status === 'success') {
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.loading = false
          this.checkPassWord = false
        } else {
          this.loading = false
          this.$message({
            message: res.msg,
            type: 'error'
          });
          this.scriptPwd = ''
        }
      })
    },
    // 停止脚本
    stopScript(id) {
      this.checkPassWord = true
      this.pwdId = id
      this.scriptPwd = ''
      this.isShow = false
    },
    // 确定停止
    sureStopPwd() {
      this.loading = true
      SystemManage.stopScript({ 
        id: this.pwdId,
        scriptPwd: this.scriptPwd
      }).then(res => {
        if (res.status === 'success') {
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.loading = false
          this.checkPassWord = false
        } else {
          this.loading = false
          this.$message({
            message: res.msg,
            type: 'error'
          });
          this.scriptPwd = ''
        }
      })
    },
    // 数据统计未开始
    dataBegin(id) {
      this.dataCenterPwd = true
      this.pwdId = id
      this.statisticsPwd = ''
      this.isStatist = true
    },
    startStatist() {
      this.loading = true
      SystemManage.dataStatist({ 
        id: this.pwdId,
        groupStatisticalPwd: this.statisticsPwd
      }).then(res => {
        if (res.status === 'success') {
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.loading = false
          this.dataCenterPwd = false
          this.getDataList()
        } else {
          this.loading = false
          this.$message({
            message: res.msg,
            type: 'error'
          });
          this.statisticsPwd = ''
        }
      })
    },
    // 数据统计启动中
    dataStart(id) {
      this.dataCenterPwd = true
      this.pwdId = id
      this.statisticsPwd = ''
      this.isStatist = false
    },
    stopStatist() {
      this.loading = true
      SystemManage.dataStatist({ 
        id: this.pwdId,
        groupStatisticalPwd: this.statisticsPwd
      }).then(res => {
        if (res.status === 'success') {
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.loading = false
          this.dataCenterPwd = false
          this.getDataList()
        } else {
          this.loading = false
          this.$message({
            message: res.msg,
            type: 'error'
          });
          this.statisticsPwd = ''
        }
      })
    },
    // 校验服务商密码
    setRobot(id) {
      this.robotPassWord = true
      this.robotId = id
      this.robotForm.id = id
      this.servicePwd = ''
      this.getGroup(id)
    },
    // 确定服务商密码
    sureRobotPwd() {
      this.loading = true
      SystemManage.checkRobotPwd({ 
        id: this.robotId,
        groupRobotPwd: this.servicePwd
      }).then(res => {
        if (res.status === 'success') {
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.loading = false
          this.robotPassWord = false
          this.robotMenuDialog = true
        } else {
          this.loading = false
          this.$message({
            message: res.msg,
            type: 'error'
          });
          this.servicePwd = ''
        }
      })
    },
    // 一键结算弹框
    setCurry(id) {
      this.curryPassWord = true
      this.curryForm.id = id
      this.curryPwd = ''
    },
    // 确定一键结算密码
    sureCurryPwd() {
      this.loading = true
      SystemManage.checkCurryPwd({ 
        id: this.curryForm.id,
        groupSettlementPwd: this.curryPwd
      }).then(res => {
        if (res.status === 'success') {
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.loading = false
          this.curryPassWord = false
          this.curryDialog = true
          this.curryForm.boosInitialFund = null
          this.curryForm.followInitialFund = null
        } else {
          this.loading = false
          this.$message({
            message: res.msg,
            type: 'error'
          });
          this.servicePwd = ''
        }
      })
    },
    // 确定一键结算
    currySubmit() {
      this.$refs.curryForm.validate((valid) => {
        if (valid) {
          this.$confirm('是否确定?', '结算确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            SystemManage.currySubmit(this.curryForm).then(res => {
              if (res.status === "success") {
                this.$notify({
                  title: "成功",
                  message: res.msg,
                  type: "success",
                  duration: 5000
                });
                this.robotMenuDialog = false
              } else {
                this.$notify({
                  title: "失败",
                  message: res.msg,
                  type: "error",
                  duration: 5000
                });
              }
            })
          }).catch(() => {});
        }
      })
    },
    // 服务商一键平仓弹框
    serviceOneclick(id) {
      this.servicePassWord = true
      this.touchId = id
      this.oneTouchPwd = ''
    },
    // 确定一键平仓密码
    sureOneTouchPwd() {
      this.loading = true
      SystemManage.touchSubmit({ 
        id: this.touchId,
        groupOneTouchPwd: this.oneTouchPwd
      }).then(res => {
        if (res.status === 'success') {
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.loading = false
          this.servicePassWord = false
        } else {
          this.loading = false
          this.$message({
            message: res.msg,
            type: 'error'
          });
          this.oneTouchPwd = ''
        }
      })
    },
    //数量比例正则
    handleInput(value) {
      let val=value.replace(/^\D*([0-9]\d*\.?\d{0,1})?.*$/,'$1');
      this.robotForm.scale = val
    },
    // 开启关闭机器人开关
    changeRobot() {
      SystemManage.switchRobot({
        id: this.robotId
      }).then(res => {
        if (res.status === "success") {
            this.$notify({
              title: "成功",
              message: res.msg,
              type: "success",
              duration: 5000
            });
          } else {
            this.$notify({
              title: "失败",
              message: res.msg,
              type: "error",
              duration: 5000
            });
          }
      })
    },
    // 下单
    saveRobotMenu() {
      this.$refs.robotForm.validate((valid) => {
        if (valid) {
          this.$confirm('确定下单?', '下单确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            SystemManage.robotOrder(this.robotForm).then(res => {
              if (res.status === "success") {
                  this.$notify({
                    title: "成功",
                    message: res.msg,
                    type: "success",
                    duration: 5000
                  });
                  this.robotMenuDialog = false
                } else {
                  this.$notify({
                    title: "失败",
                    message: res.msg,
                    type: "error",
                    duration: 5000
                  });
                }
            })
          }).catch(() => {});
        }
      })
    },
    // 跟单设置
    setFollow(id) {
      this.followMenuDialog = true
      this.followId = id
      this.getGroup(id)
    },
    // 获取跟单信息
    getGroup(id) {
      SystemManage.getGroup({ id }).then(res => {
        if (res.status === 'success') {
          this.followData = res.data
          this.switchRobot = res.data.switchRobot
        }
      })
    },
    // 保存
    saveFollowMenu() {
      this.loading = true
      if(this.followData.ceaseFund > this.followData.openFund) {
        SystemManage.saveFollowMenu({ 
          id: this.followId,
          ceaseFund: this.followData.ceaseFund,
          switchCease: this.followData.switchCease,
          openFund: this.followData.openFund,
          switchOpen: this.followData.switchOpen
        }).then(res => {
          if (res.status === 'success') {
            this.$message({
              message: res.msg,
              type: 'success'
            });
            this.loading = false
            this.followMenuDialog = false
          } else {
            this.loading = false
            this.$message({
              message: res.msg,
              type: 'error'
            });
          }
        })
      } else {
        this.$message({
          message: '停止跟单净值应大于开启跟单净值',
          type: 'error'
        });
        this.loading = false
      }
    },
    // 划账设置
    setAcceson(id) {
      this.accountMenuDialog = true
      this.followId = id
      this.getAccountGroup(id)
    },
    // 获取划账信息
    getAccountGroup(id) {
      SystemManage.getAccountGroup({ id }).then(res => {
        if (res.status === 'success') {
          this.accountData = res.data
        }
      })
    },
    // 保存
    saveAccountMenu() {
      this.loading = true
      SystemManage.saveAccountMenu({ 
        id: this.followId,
        switchTransfer: this.accountData.switchTransfer,
        transferCount: this.accountData.transferCount,
        transferQuota: this.accountData.transferQuota,
        transferMaximum: this.accountData.transferMaximum,
        transferBossWorth: this.accountData.transferBossWorth,
        transferFollowWorth: this.accountData.transferFollowWorth
      }).then(res => {
        if (res.status === 'success') {
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.loading = false
          this.accountMenuDialog = false
        } else {
          this.loading = false
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })
    },
    // 停止跟单
    runStop() {
      const loading = this.$loading({
        lock: true,
        text: '修改中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      SystemManage.runStop({
        id: this.stopId
      }).then(res => {
        loading.close();
        if (res.status === 'success') {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.getDataList()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })
    },
    // 修改杠杆比例
    upDataFollow() {
      SystemManage.upDataFollow({
        id: this.upDataId,
        leverScale: this.proportion
      }).then(res => {
        if (res.status === 'success') {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.getDataList()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })
    },
    // 动态跟单比例
    changeState(id) {
      console.log(id)
      SystemManage.switchScale({
        id: id
      }).then(res => {
        if (res.status === "success") {
            this.$notify({
              title: "成功",
              message: res.msg,
              type: "success",
              duration: 5000
            });
            this.getDataList();
          } else {
            this.$notify({
              title: "失败",
              message: res.msg,
              type: "error",
              duration: 5000
            });
          }
      })
    },
    // 获取数据
    getDataList() {
      SystemManage.getDataList({
        page: this.page,
        pageSize: this.pageSize,
        ...this.formData
      }).then(res => {
        this.loading = false
        if (res.status === 'success') {
          this.dataList = res.data.list
          this.pageNum = res.data.total
        }
      })
    },
    // 搜索
    searchData() {
      this.getDataList()
    },
    // 检查账号是否重复 
    checkAccount() {
      this.loading = true
      this.checkCount = ''
      this.checkDialogVisible = true
      SystemManage.getCheckAccount({
        confId:1
      }).then(res => {
        this.checkCount = res.msg
        this.loading = false
      })
    },
    // 分页
    handleCurrentChange(val) {
      this.page = val
      this.getDataList()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getDataList()
    }
  }
}
</script>

<style lang="scss" scoped>
.box-cont{
  margin-top: 30px;
}
.box-card {
  .clearfix {

    button {
      float: right;
    }
  }

  margin: auto;
  ::v-deep .el-card__header {
    padding: 6px 15px 0;
    border: none;
  }
  ::v-deep .el-card__body, .el-main {
    padding: 6px 15px;
    .el-descriptions .is-bordered .el-descriptions-item__cell {
      padding: 5px 10px 5px;
    }
  }

  .list_details {
    .first {
      ::v-deep .el-descriptions__body {

        .my-label {
          background: #E1F3D8;
          width: 9%;
        }

        .my-content {
          background: #E1F3D8;
          width: 41%;
          padding-right: 20px;
          .my-left {
            float: left;
          }
          .my-right {
            float: right;
          }
        }

        .my-label1 {
          background: #FDE2E2;
          width: 9%;
        }

        .my-content1 {
          background: #FDE2E2;
          width: 41%;
          padding-right: 20px;
          .my-left {
            float: left;
          }
          .my-right {
            float: right;
          }
        }

        // background: #ccc;

        // .el-descriptions-item__container {
        //   justify-content: center;

        //   .el-descriptions-item__content {
        //     flex: none;

        //   }
        // }
      }
    }
  }

}
.bots{
  margin-bottom: 20px;
  font-size: 14px;
  .barnum{
    width:120px;
    display:inline-block;
  }
}
.menubili{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titcont{
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 16px;
}

.dialog-footer {
  display: flex;
  justify-content: space-around;
}
.desc{
  display: inline-block;
  color: red;
  font-size: 14px;
  margin-left: 10px;
}

::v-deep .el-card.is-always-shadow {
  box-shadow: 0 2px 25px 0 rgb(0 0 0 / 50%);
}
</style>