/*
 * @Author: chengengfeng
 * @Date: 2019-07-23 16:15:25
 * @Last Modified by: chengengfeng
 * @Last Modified time: 2021-04-30 10:23:58
 * @Description: common utils
*/
// system variable
// const passwordSalt = 'qhjyscloud@admin';

/**
 * 将URL参数转成object
 */
export function param2Obj(url, isExport = false) {
  if (!isExport) {
    const search = url.split('?')[1];
    if (!search) {
      return {};
    }
    return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
  } else { // 导出时用，参数非obj时，直接取参数转为json
    return JSON.parse('{"' + decodeURIComponent(url).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
  }
}

/**
 * 目标变量是否为空
 * @param val 目标值
 */
export function isEmpty(val) {
  if (typeof val === 'boolean') {
    return false;
  }
  if (Object.prototype.toString.call(val) === '[object Array]') {
    if (val.length === 0) {
      return true;
    }
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') {
      return true;
    }
  } else {
    if (val === 'null' || val == null || val === 'undefined' || val === undefined || val === '') {
      return true;
    }
    return false;
  }
  return false;
}

/**
 * 设置query参数
 * @param querys object 的键值对
 * example: var queryConfig = {'page': 'index', 'method': 2, 'subpage': -1, 'spec_method': -1};
 */
export function setQueryConfig(querys) {
  let str = '';
  for (const o in querys) {
    if (querys[o] !== -1) {
      str += o + '=' + querys[o] + '&';
    }
  }
  str = str.substring(0, str.length - 1);
  return '?' + str;
}

/**
 * 刷新整个网页
 */
export function reLoad() {
  window.location.reload();
}

/**
 * 判断姓名是否是中文
 * @param name 待验证的字符
 */
export function validateName(name) {
  const regName = /^[\u4e00-\u9fa5]{2,4}$/;
  if (!regName.test(name)) {
    return false;
  }
  return true;
}

// /**
//  * 验证邮箱
//  * @param email 需要验证的邮箱
//  */
// export function validateEmail(email) {
//   // tslint:disable-next-line:max-line-length
//   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   return re.test(email);
// }

/**
 * 获取url参数
 * @param param 某个参数名
 */
export function getQueryString(param) {
  const reg = new RegExp('(^|&)' + param + '=([^&]*)(&|$)', 'i');
  // window.console.log(reg);
  // const r = window.location.search.substr(1).match(reg);
  let r = '';
  try {
    r = window.location.hash.split('?')[1].match(reg);
  } catch (error) {
    r = null;
  }
  if (r != null) {
    // return unescape(r[2]);
    return decodeURIComponent(r[2]);
  }
  return null;
}

/**
 * @param {String} url
 * @description 从URL中解析参数
 */
export function getParams(url) {
  const keyValueArr = url.split('?')[1].split('&');
  const paramObj = {};
  keyValueArr.forEach((item) => {
    const keyValue = item.split('=');
    paramObj[keyValue[0]] = keyValue[1];
  });
  return paramObj;
}

/**
 * 递归调用，滚动至指定位置
 * @param element 需要滚动的元素
 * @param to 最终滚动到的位置
 * @param duration 滚动过程延迟时间
 */
export function scrollTo(element, to, duration) {
  if (duration <= 0) {
    return;
  }
  const difference = to - element.scrollTop;
  const perTick = difference / duration * 10;
  setTimeout(() => {
    window.console.log(new Date());
    element.scrollTop = element.scrollTop + perTick;
    if (element.scrollTop === to) {
      return;
    }
    scrollTo(element, to, duration - 10);
  }, 10);
}

/**
 * 返回异步组件
 * @param path 组件所在路径
 */
export const asyncComponent = (path) => () => {
  debugger;
  const component = Promise.resolve(`@/views/${path}.vue`);
  component.catch((e) => {
    window.console.error(e);
  });
  return component;
  // Promise.resolve(require(`@/views/${path}`).default);
};

// /**
//  * MD5加密
//  * @param pass 待加密字符串
//  */
// export function getMD5Code(pass) {
//   if (isEmpty(pass)) { return; }
//   return md5(pass + passwordSalt).toString();
// }

// 生成菜单
export function generaMenu(routers = [], data = []) {
  // debugger;
  data.forEach((item) => {
    // debugger;
    const menu = Object.assign({}, item);
    // menu.component = () => import(`views/${item.component}.vue`);
    // menu.component = () => Promise.resolve(require(`@/views/${item.component}`).default);
    // menu.component = () => Promise.resolve(require(`../../views/${item.component}.vue`).default);
    menu.component = (resolve) => require([`../../views/${item.component}.vue`], resolve);
    console.log("动态注册组件：", menu);
    if (item.children) {
      menu.children = [];
      generaMenu(menu.children, item.children);
    }
    return routers.push(menu);
  });
}

/**
 * 返回千分位分割的数字字符串
 * @param str 金额
 * @param needPadZero 是否需要继续补零
 * https://github.com/lishengzxc/bblog/issues/15
 */
export function toThousandsString(str, needPadZero) {
  if (!str) { return '0'; }
  const splitNumber = str.toString().split('.');
  let integer = splitNumber[0];
  const decimal = splitNumber[1] || '';

  let result = '';
  while (integer.length > 3) {
    result = ',' + integer.slice(-3) + result;
    integer = integer.slice(0, integer.length - 3);
  }
  if (integer) {
    result = integer + result;
  }

  // 补零
  if (decimal === '' && needPadZero) {
    return result.toString() + '.00';
  } else if (decimal !== '' && decimal.length < 2 && needPadZero) {
    return result = result.toString() + '.' + decimal + '0';
  }

  return decimal === '' ? result : result + '.' + decimal;
}

/**
 * 从路由列表动态获取指定名称的路由，用于跳转指定页面
 * @param routeList 全部路由列表
 * @param targetName 一级菜单名称
 * @param childrenName 二级菜单名称
 * @example
 * import { Getter } from 'vuex-class';
 * @Getter('user/asyncRouter') allRoutes;
 * private jumpRouter() {
 * const data = getTargetRoute(this.allRoutes, '会员管理', '充值管理');
 * this.$router.push({
 * path: data.path,
 * query: data.query
 * });
 * };
 */
export function getTargetRoute(
  routeList,
  targetName,
  childrenName
) {
  debugger;
  const rect = routeList.filter((item) => {
    return item.name === targetName;
  })[0];
  const result = rect.children && rect.children.filter((item) => {
    return item.name === childrenName;
  })[0];
  window.console.log('rect', rect);
  window.console.log('result', result);
  return {
    // path: rect.redirect ? `/${rect.redirect.path}` : `${rect.path}/${result.path}`,
    // query: rect.redirect ? rect.redirect.query : { menuId: result.id }
    path: `${rect.path}/${result.path}`,
    query: { menuId: result.id }
  };
}

/**
 * 将毫秒数装换为 xx小时xx分钟xx秒
 * @param value 毫秒数
 */
export function formatSeconds(value) {
  let secondTime = parseInt(String(value), 10); // 秒
  let minuteTime = 0; // 分
  let hourTime = 0; // 小时
  if (secondTime > 60) { // 如果秒数大于60，将秒数转换成整数
    // 获取分钟，除以60取整数，得到整数分钟
    minuteTime = parseInt(String(secondTime / 60), 10);
    // 获取秒数，秒数取佘，得到整数秒数
    secondTime = parseInt(String(secondTime % 60), 10);
    // 如果分钟大于60，将分钟转换成小时
    if (minuteTime > 60) {
      // 获取小时，获取分钟除以60，得到整数小时
      hourTime = parseInt(String(minuteTime / 60), 10);
      // 获取小时后取佘的分，获取分钟除以60取佘的分
      minuteTime = parseInt(String(minuteTime % 60), 10);
    }
  }

  let result = `${secondTime}秒`;

  (minuteTime > 0 && minuteTime < 10) ? result = `0${minuteTime}分${result}` : result = `${minuteTime}分${result}`;

  hourTime > 0 ? result = `${hourTime}小时${result}` : result = `0小时${result}`;

  return result;
}

/**
 * 将数组按照指定的位数分割
 * @param {*} arr 待分割数组
 * @param {*} count 分割成几份
 */
export function chunkArray(arr, count) {
  count = count * 1 || 1;
  const ret = [];
  arr.forEach((item, i) => {
    if (i % count === 0) {
      ret.push([]);
    }
    ret[ret.length - 1].push(item);
  });
  return ret;
}
