<template>
  <div>
    <!-- 跟单账单操作记录 -->
    <div class="record">
      <el-button class="back" @click="goback">返回</el-button>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="服务商交易记录" name="first">
          <div class="record_tit">
            <h2>当前仓位</h2>
            <div class="times">
              <el-date-picker
                v-model="nowTime.utime"
                type="datetime"
                @change="selectData(nowTime.utime)"
                placeholder="选择日期时间">
              </el-date-picker>
              <el-button type="primary" icon="el-icon-search" @click="searchDate">查询</el-button>
            </div>
          </div>
          <el-table border ref="multipleTable" :data="retainList" tooltip-effect="dark" style="width: 100%;margin:20px 0" v-loading="loading">
            <el-table-column prop="instId" label="产品Id" width="160">
            </el-table-column>
            <el-table-column prop="pos" label="持仓数量(张)" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="avgPx" label="开仓均价" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="imr" label="初始保证金" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="mmr" label="维持保证金" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="margin" label="保证金余额" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="posCcy" label="仓位资产币种">
            </el-table-column>
            <el-table-column prop="mgnRatio" label="保证金率" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="mgnMode" label="保证金模式" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="lever" label="杠杆倍数">
            </el-table-column>
            <el-table-column prop="availPos" label="可平仓数量(张)" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="ctime" label="持仓创建时间" width="170">
            </el-table-column>
            <el-table-column prop="utime" label="持仓更新时间" width="170">
            </el-table-column>
            <el-table-column prop="ccy" label="保证金币种" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="posSide" label="持仓方向" show-overflow-tooltip>
            </el-table-column>
          </el-table>
          <div class="record_tit">
            <h2>历史仓位</h2>
            <div class="times">
              <el-date-picker
                v-model="oldTime.utime"
                type="datetime"
                @change="selectData1(oldTime.utime)"
                placeholder="选择日期时间">
              </el-date-picker>
              <el-button type="primary" icon="el-icon-search" @click="searchDate1">查询</el-button>
            </div>
          </div>
          <el-table border ref="multipleTable" :data="historyList" tooltip-effect="dark" style="width: 100%;margin:20px 0 40px" v-loading="loading">
            <el-table-column prop="instId" label="产品Id" width="160">
            </el-table-column>
            <el-table-column prop="closeTotalPos" label="累计平仓量(张)" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="openAvgPx" label="开仓均价" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="closeAvgPx" label="平仓均价" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="mgnMode" label="保证金模式">
            </el-table-column>
            <el-table-column prop="type" label="平仓类型">
            </el-table-column>
            <el-table-column prop="ctime" label="持仓创建时间" width="170">
            </el-table-column>
            <el-table-column prop="utime" label="持仓更新时间" width="170">
            </el-table-column>
            <el-table-column prop="openMaxPos" label="最大持仓量(张)" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="pnl" label="平仓收益额(USDT)" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="lever" label="杠杆倍数">
            </el-table-column>
            <el-table-column prop="direction" label="持仓方向" show-overflow-tooltip>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="跟单交易记录" name="second">
          <div class="record_tit">
            <h2>当前仓位</h2>
            <div class="times">
              <el-date-picker
                v-model="nowTime.utime"
                type="datetime"
                @change="selectData(nowTime.utime)"
                placeholder="选择日期时间">
              </el-date-picker>
              <el-button type="primary" icon="el-icon-search" @click="searchDate">查询</el-button>
            </div>
          </div>
          <el-table border ref="multipleTable" :data="retainList" tooltip-effect="dark" style="width: 100%;margin:20px 0" v-loading="loading">
            <el-table-column prop="instId" label="产品Id" width="160">
            </el-table-column>
            <el-table-column prop="pos" label="持仓数量(张)" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="avgPx" label="开仓均价" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="imr" label="初始保证金" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="mmr" label="维持保证金" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="margin" label="保证金余额" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="posCcy" label="仓位资产币种">
            </el-table-column>
            <el-table-column prop="mgnRatio" label="保证金率" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="mgnMode" label="保证金模式" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="lever" label="杠杆倍数">
            </el-table-column>
            <el-table-column prop="availPos" label="可平仓数量(张)" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="ctime" label="持仓创建时间" width="170">
            </el-table-column>
            <el-table-column prop="utime" label="持仓更新时间" width="170">
            </el-table-column>
            <el-table-column prop="ccy" label="保证金币种" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="posSide" label="持仓方向" show-overflow-tooltip>
            </el-table-column>
          </el-table>
          <div class="record_tit">
            <h2>历史仓位</h2>
            <div class="times">
              <el-date-picker
                v-model="oldTime.utime"
                type="datetime"
                @change="selectData1(oldTime.utime)"
                placeholder="选择日期时间">
              </el-date-picker>
              <el-button type="primary" icon="el-icon-search" @click="searchDate1">查询</el-button>
            </div>
          </div>
          <el-table border ref="multipleTable" :data="historyList" tooltip-effect="dark" style="width: 100%;margin:20px 0 40px" v-loading="loading">
            <el-table-column prop="instId" label="产品Id" width="160">
            </el-table-column>
            <el-table-column prop="closeTotalPos" label="累计平仓量(张)" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="openAvgPx" label="开仓均价" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="closeAvgPx" label="平仓均价" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="mgnMode" label="保证金模式">
            </el-table-column>
            <el-table-column prop="type" label="平仓类型">
            </el-table-column>
            <el-table-column prop="ctime" label="持仓创建时间" width="170">
            </el-table-column>
            <el-table-column prop="utime" label="持仓更新时间" width="170">
            </el-table-column>
            <el-table-column prop="openMaxPos" label="最大持仓量(张)" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="pnl" label="平仓收益额(USDT)" :formatter="rouding">
            </el-table-column>
            <el-table-column prop="lever" label="杠杆倍数">
            </el-table-column>
            <el-table-column prop="direction" label="持仓方向" show-overflow-tooltip>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="跟单账号下单日志" name="three">
          <el-table border ref="multipleTable" :data="tradeLogList" tooltip-effect="dark" style="width: 100%;margin:20px 0">
            <el-table-column prop="instId" label="产品Id" width="120">
            </el-table-column>
            <el-table-column prop="tdMode" label="交易模式">
            </el-table-column>
            <el-table-column prop="ccy" label="保证金币种" width="100">
            </el-table-column>
            <el-table-column prop="side" label="订单方向">
            </el-table-column>
            <el-table-column prop="posSide" label="持仓方向">
            </el-table-column>
            <el-table-column prop="ordType" label="订单类型">
            </el-table-column>
            <el-table-column prop="sz" label="委托数量">
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="170" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="updateTime" label="修改时间" width="170" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="remark" label="备注" width="140" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="resCode" label="结果编码">
            </el-table-column>
            <el-table-column prop="resMsg" label="结果信息">
            </el-table-column>
            <el-table-column prop="jsonStr" label="下单json" width="170" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="resJson" label="交易内容" width="170" show-overflow-tooltip>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import SystemManage from "../../common/utils/api/system";
export default {
  data() {
    return {
      activeName: 'first',
      loading: false,
      accountId: null,
      nowTime: {
        utime: ''
      },
      oldTime: {
        utime: ''
      },
      bossId: null,
      followId: null,
      retainList: [],
      historyList: [],
      tradeLogList: []
    }
  },
  mounted() {
    this.bossId = this.$router.history.current.query.bossId
    this.followId = this.$router.history.current.query.followId
    this.accountId = this.bossId
    this.getRetain()
    this.getHistory()
  },
  methods: {
    // tab切换
    handleClick() {
      this.retainList = []
      this.historyList = []
      if(this.activeName == 'first') {
        this.accountId = this.bossId
        this.nowTime.utime = ''
        this.oldTime.utime = ''
        this.getRetain()
        this.getHistory()
      }else if(this.activeName == 'second') {
        this.accountId = this.followId
        this.nowTime.utime = ''
        this.oldTime.utime = ''
        this.getRetain()
        this.getHistory()
      }else if(this.activeName == 'three') {
        this.accountId = this.followId
        this.getTradeLog()
      }else {
        return
      }
    },
    rouding(row,column) {
      if(row[column.property] != undefined){
        let num = Number(row[column.property])
        if(parseInt(num) != num){
          return parseFloat(num.toFixed(3))
        }else{
          return num
        }
      }else {
        return ''
      }
    },
    // 平仓类型
    newList() {
      this.historyList.forEach((v, i) => {
        switch (true) {
          case v.type == '1':
            this.historyList[i].type = '部分平仓'
            break;
          case v.type == '2':
            this.historyList[i].type = '完全平仓'
            break;
          case v.type == '3':
            this.historyList[i].type = '强平'
            break;
          case v.type == '4':
            this.historyList[i].type = '强减'
            break;
          case v.type == '5':
            this.historyList[i].type = 'ADL自动减仓'
            break;
          default:
            break;
        }
      })
    },
    // 返回上一页
    goback() {
      //this.$router.back(-1);
      this.$router.push({
        path: 'quantification'
      })
    },
    // 保证金模式
    modeList() {
      if(this.retainList.length != 0){
        this.retainList.forEach((v, i) => {
          switch (true) {
            case v.mgnMode == 'cross':
              this.retainList[i].mgnMode = '全仓'
              break;
            case v.mgnMode == 'isolated':
              this.retainList[i].mgnMode = '逐仓'
              break;
            default:
              break;
          }
        })
      }
      if(this.historyList.length != 0) {
        this.historyList.forEach((v, i) => {
          switch (true) {
            case v.mgnMode == 'cross':
              this.historyList[i].mgnMode = '全仓'
              break;
            case v.mgnMode == 'isolated':
              this.historyList[i].mgnMode = '逐仓'
              break;
            default:
              break;
          }
        })
      }
    },
    timestampToTime(timestamp) {
      let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y,M,D,h,m,s
      Y = date.getFullYear() + '-';
      M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
      D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' ';
      h = (date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
      m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ':';
      s = (date.getSeconds() < 10 ? '0'+(date.getSeconds()) : date.getSeconds());
      return Y+M+D+h+m+s;
    },
    // 当前仓位转换时间戳
    timeCode() {
      this.retainList.forEach((v) => {
        v.ctime = this.timestampToTime(v.ctime)
        v.utime = this.timestampToTime(v.utime)
      })
    },
    // 历史仓位转换时间戳
    timeCode1() {
      this.historyList.forEach((v) => {
        v.ctime = this.timestampToTime(v.ctime)
        v.utime = this.timestampToTime(v.utime)
      })
    },
    // 选中时间
    selectData(time) {
      let date = new Date(time)
      let Y,M,D,h,m,s
      Y = date.getFullYear() + '-';
      M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
      D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' ';
      h = (date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
      m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ':';
      s = (date.getSeconds() < 10 ? '0'+(date.getSeconds()) : date.getSeconds());  
      const data = Y+M+D+h+m+s
      this.nowTime.utime = data
    },
    selectData1(time) {
      let date = new Date(time)
      let Y,M,D,h,m,s
      Y = date.getFullYear() + '-';
      M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
      D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' ';
      h = (date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
      m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ':';
      s = (date.getSeconds() < 10 ? '0'+(date.getSeconds()) : date.getSeconds());  
      const data = Y+M+D+h+m+s
      this.oldTime.utime = data
    },
    // 根据更新时间查当前仓位
    searchDate() {
      this.getRetain()
    },
    // 根据更新时间查历史仓位
    searchDate1() {
      this.getHistory()
    },
    // 获取当前仓位
    getRetain() {
      this.loading = true
      SystemManage.getRetain({
        accountId: this.accountId,
        utime: this.nowTime.utime
      }).then(res => {
        if (res.status === 'success') {
          this.retainList = res.data
          console.log(this.retainList)
          this.loading = false
          this.modeList()
          this.timeCode()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
          this.loading = false
        }
      })
    },
    // 获取历史仓位
    getHistory() {
      this.loading = true
      SystemManage.getHistory({
        accountId: this.accountId,
        utime: this.oldTime.utime
      }).then(res => {
        if (res.status === 'success') {
          this.historyList = res.data
          this.loading = false
          this.newList()
          this.modeList()
          this.timeCode1()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
          this.loading = false
        }
      })
    },
    // 获取交易日志
    getTradeLog() {
      this.loading = true
      SystemManage.getTradeLog({
        accountId: this.accountId
      }).then(res => {
        if (res.status === 'success') {
          this.tradeLogList = res.data
          this.loading = false
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
          this.loading = false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.record{
  position: relative;
  .record_tit{
    display: flex;
    .times{
      margin-left: 40px;
      .el-button{
        margin-left: 20px;
      }
    }
  }
  .back {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    padding: 10px 20px;
  }
  h2{
    font-size: 20px;
    line-height: 40px;
  }
  .el-table{
    ::v-deep{
      .cell {
        text-align: center;
      }
    }
  } 
}
</style>