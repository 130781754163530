<template>
  <div class="exConfiguration">
    <!-- 搜索框 -->
    <div class="exConfiguration-header">
      <el-form :inline="true" :model="formInline">
        <el-form-item>
          <el-input
            v-model="formInline.input"
            placeholder="请输入内容"
            clearable
            @clear="clearText"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <!-- 下拉选择框 -->
          <el-select
            v-model="formInline.value"
            placeholder="请选择"
            clearable
            @clear="clearStatus"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <!-- 搜索按钮 -->
          <el-button type="primary" icon="el-icon-search" @click="searchExCList"
            >搜索</el-button
          >
          <!-- 新增按钮 -->
          <el-button
            type="warning"
            icon="el-icon-zoom-in"
            @click="addExconfiguration()"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 新增弹框 -->
    <el-dialog
      title="交易所配置维护"
      :visible.sync="dialogFormVisible"
      :modal-append-to-body="false"
      center
    >
      <el-form
        ref="formAdd"
        :model="exchangeToast"
        label-width="92px"
        :rules="formAddRules"
      >
        <el-form-item label="交易所名称" prop="exchangeName">
          <el-input v-model="exchangeToast.exchangeName"></el-input>
        </el-form-item>

        <el-form-item label="交易所说明" prop="remark">
          <el-input type="textarea" v-model="exchangeToast.remark"></el-input>
        </el-form-item>

        <el-form-item label="排序号" prop="orderNum">
          <el-input
            v-model="exchangeToast.orderNum"
            type="number"
            min="0"
          ></el-input>
        </el-form-item>

        <el-form-item prop="state" label="状态">
          <el-radio-group v-model="exchangeToast.state">
            <el-radio :label="0">停用</el-radio>
            <el-radio :label="1">启用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="exConfAddConfirm('formAdd')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 表格组件  -->
    <el-table :data="tableData" border>
      <el-table-column prop="exchangeName" label="交易所名称">
      </el-table-column>
      <el-table-column prop="remark" label="交易所说明"> </el-table-column>
      <el-table-column prop="orderNum" label="排序号" width="160">
      </el-table-column>

      <el-table-column prop="state" label="状态">
        <template slot-scope="scope">
          <div>{{ scope.row.state == 1 ? "启用" : "停用" }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="opration" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="exchangeConfiEdi(scope.row)"
            >【编辑】</el-button
          >
          <el-button
            type="text"
            @click="changeState(scope.row.id, 1, scope.row)"
            v-if="scope.row.state == 0"
            >【启用】</el-button
          >
          <el-button
            type="text"
            @click="changeState(scope.row.id, 0, scope.row)"
            v-if="scope.row.state == 1"
            >【停用】</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 编辑弹窗 -->

    <el-dialog
      title="交易所配置维护"
      :visible.sync="dialogFormVisibleEdit"
      :modal-append-to-body="false"
    >
      <el-form
        ref="formExconfiguration"
        :model="formExconfiguration"
        label-width="92px"
        :rules="formAddRules"
      >
        <el-form-item label="交易所名称" prop="exchangeName">
          <el-input v-model="formExconfiguration.exchangeName"></el-input>
        </el-form-item>

        <el-form-item label="交易所说明" prop="remark">
          <el-input v-model="formExconfiguration.remark"></el-input>
        </el-form-item>

        <el-form-item label="排序号" prop="orderNum">
          <el-input
            v-model="formExconfiguration.orderNum"
            type="number"
            min="0"
          ></el-input>
        </el-form-item>
        <el-form-item prop="state" label="状态">
          <el-radio-group v-model="formExconfiguration.state">
            <el-radio :label="0">停用</el-radio>
            <el-radio :label="1">启用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleEdit = false">取 消</el-button>
        <el-button type="primary" @click="exbodyConfirm('formExconfiguration')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 查看修改历史 -->

    <el-dialog
      title="交易所配置维护"
      :visible.sync="dialogFormVisibleHistory"
      :modal-append-to-body="false"
    >
      <div class="block">
        <span class="demonstration">默认</span>
        <el-date-picker
          v-model="value1"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>

      <div>
        <el-select v-model="valueSelectHistory" clearable placeholder="请选择">
          <el-option
            v-for="item in optionsHistory"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="复选框 A"></el-checkbox>
          <el-checkbox label="复选框 B"></el-checkbox>
          <el-checkbox label="复选框 C"></el-checkbox>
          <el-checkbox label="禁用" disabled></el-checkbox>
          <el-checkbox label="选中且禁用" disabled></el-checkbox>
        </el-checkbox-group>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleHistory = false">取 消</el-button>
        <el-button type="primary">确 定</el-button>
      </div>
    </el-dialog>

    <!-- </div> -->

    <!-- 页面组件 -->
    <div class="exConfiguration-page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagesParams.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagesParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogFormVisibleHistory: false,
      checkList: [],
      optionsHistory: [],
      valueSelectHistory: "",
      value1: "", //日期返回的v-model值
      total: 5,
      pagesParams: {
        page: 1, //第几页
        pageSize: 10, //每页显示条数
        state: this.value,
      },
      //    currentPage4:3,

      //控制编辑弹窗
      dialogFormVisibleEdit: false,
      // rowIndex:0,

      // 表格数据
      tableData: [],

      // 新增弹窗数据
      exchangeToast: {
        // name:"",
        // dec:"",
        // sort:"",
        // status:""
        // id:1,
        exchangeName: "",
        orderNum: "",
        remark: "",
        state: "",
      },

      //交易所配置Body下的列表
      formInline: {
        input: "",
        value: "",
      },

      options: [
        {
          value: 0,
          label: "停用",
        },
        {
          value: 1,
          label: "启用",
        },
      ],

      //控制新增弹窗
      dialogFormVisible: false,

      // 编辑弹窗列表
      formExconfiguration: {
        id: 1,
        exchangeName: "",
        orderNum: 1,
        remark: "",
        state: 1,
      },
      formAddRules: {
        exchangeName: [
          { required: true, message: "请输入交易所名称", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "请输入交易所说明", trigger: "blur" },
        ],
        orderNum: [
          { required: true, message: "请输入排序号", trigger: "blur" },
        ],
        state: [{ required: true, message: "请选择状态", trigger: "blur" }],
      },
    };
  },
  methods: {
    clearText() {
      this.searchExCList();
    },
    clearStatus() {
      this.searchExCList();
    },
    handleSizeChange(val) {
      this.pagesParams.pageSize = val;
      this.getexConfigList();
    },
    handleCurrentChange(val) {
      this.pagesParams.page = val;
      this.getexConfigList();
    },
    // 新增
    exConfAddConfirm(val) {
      this.$refs[val].validate((valid) => {
        if (valid) {
          this.axios
            .put("/business/admin/exchange/save", this.exchangeToast)
            .then((res) => {
              console.log(res);
              this.$notify({
                title: "成功",
                message: res.data.msg,
                type: "success",
                // positon: "top-left",
                duration: 5000,
              });
              this.dialogFormVisible = false;
              if (res.data.status == "fail") {
                this.$message({
                  showClose: true,
                  message: `新增错误: \xa0 \xa0 ${res.data.msg}`,
                  type: "error",
                });
              }
              this.getexConfigList();
              this.$refs.formAdd.resetFields();
            });
        } else {
          return false;
        }
      });
    },
    //修改状态
    changeState(idForm, stateForm) {
      this.axios
        .get("/business/admin/exchange/updateState", {
          params: {
            id: idForm,
            state: stateForm,
          },
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.$notify({
              title: "成功",
              message: "修改状态成功",
              type: "success",
              positon: "top-left",
              duration: 5000,
            });
          } else {
            this.$notify({
              title: "失败",
              message: "修改状态失败",
              type: "error",
              duration: 5000,
            });
          }
          console.log(res);
          this.getexConfigList();
        });
    },

    //   点击搜索框添加
    addExconfiguration() {
      this.dialogFormVisible = true;
    },

    //搜索框搜索
    searchExCList() {
      this.axios
        .get("/business/admin/exchange/exchange-info", {
          params: {
            exchangeName: this.formInline.input,
            state: this.formInline.value,
            page: 1,
            pageSize: 10,
          },
        })
        .then((res) => {
          this.tableData = res.data.data.list;
          this.total = res.data.data.total;
          // this.pagesParams.page=res.data.data.pageNum;
          // this.pagesParams.pageSize=res.data.data.pageSize
        });
    },

    //点击编辑按钮
    exchangeConfiEdi(row) {
      // this.rowIndex=index;
      //     this.dialogFormVisibleEdit=true;
      //     // this.exchangeToast.name=row.name;
      //     // this.exchangeToast.dec=row.exchandec;
      //     // this.exchangeToast.sort=row.sort;
      //     // this.exchangeToast.status=row.status;
      //    console.log(row);
      //    console.log(this.rowIndex);
      console.log(row);
      this.formExconfiguration.id = row.id;
      this.formExconfiguration.state = row.state;
      this.formExconfiguration.remark = row.remark;
      this.formExconfiguration.exchangeName = row.exchangeName;
      this.formExconfiguration.orderNum = row.orderNum;
      this.dialogFormVisibleEdit = true;
    },

    //编辑弹框确定按钮
    exbodyConfirm(val) {
      this.$refs[val].validate((valid) => {
        if (valid) {
          this.axios
            .put("/business/admin/exchange/save", this.formExconfiguration)
            .then((res) => {
              if (res.data.status == "success") {
                this.dialogFormVisibleEdit = false;
                this.$notify({
                  title: "成功",
                  message: res.data.msg,
                  type: "success",
                  // position: "top-left",
                  duration: 5000,
                });
              } else {
                this.$notify({
                  title: "失败",
                  message: res.data.msg,
                  type: "error",
                  // position: "top-left",
                  duration: 5000,
                });
              }
              console.log(res);
              this.getexConfigList();
            });
        } else {
          return false;
        }
      });
      // let {name,dec,sort,status}=this.exchangeToast
      // this.tableData[this.rowIndex].name=name;
      // this.tableData[this.rowIndex].exchandec=dec;
      // this.tableData[this.rowIndex].sort=sort;
      // this.tableData[this.rowIndex].status=status;
    },
    // 获取页面表格数据
    getexConfigList: function() {
      this.axios
        .get("/business/admin/exchange/exchange-info", {
          params: this.pagesParams,
        })
        .then((res) => {
          if (res.data.status == "fail") {
            this.$router.push("/");
          } else {
            this.tableData = res.data.data.list;
            this.total = res.data.data.total;
            this.pagesParams.page = res.data.data.pageNum;
            this.pagesParams.pageSize = res.data.data.pageSize;
          }
        });
    },

    // resful1:function(res){
    //     // this.tableData=res.data.data.list
    //     // this.total=res.data.data.total
    //     // this.pagesParams.page=res.data.data.pageNum;
    //     // this.pagesParams.pageSize=res.data.data.pageSize
    // }
  },
  mounted() {
    this.getexConfigList();
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name == "riskLv" ||
      to.name == "Exchange" ||
      to.name == "Team" ||
      to.name == "Index" ||
      to.name == "account" ||
      to.name == "Userconf" ||
      to.name == "exchangeObject"
    ) {
      this.getexConfigList();
      this.input = "";
      this.value = "";
    }
    next();
  },
};
</script>

<style scoped>

/* .el-input--suffix{
      
   }

  .el-input{
      width: 300px;
      /* transform: translateX(420px); */
/* }
  .el-select{
      width: 140px;
      display: inline-block;
      margin-left:410px
  }

  .el-button--primary{
      margin-left:10px
  } */

.exConfiguration-page {
  /* margin-right: 51.25rem; */
  margin-top: 1.25rem;
  text-align: center;
}

.exConfiguration-tableClassItem {
  /* margin-left: 1062px; */
  width: 75rem;
  height: 31.25rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.exConfiguration-header {
  width: 62.5rem;
  display: flex;
  margin-right: 50.625rem;
  margin-top: 1.25rem;
}
</style>
