/*
 * @Author: chengengfeng
 * @Date: 2019-07-23 17:35:19
 * @Last Modified by: chengengfeng
 * @Last Modified time: 2019-08-01 19:59:58
 * @Description: 用户管理
 */
import BaseRequest from "../fetch";
import { getToken } from "../auth";

class UserManage extends BaseRequest {
  constructor() {
    super();
    this.prefixURL = "/business/admin/system";
  }
  // 登陆
  userLogin(params) {
    return super.fetch("post", "/business/admin/user/login", params, "Object");
  }

  // 获取用户信息
  getUserInfos() {
    // fix: no token after axios instantiation
    super._setHeaderFiled("token", getToken() || "");
    return super.fetchDelOrGet('/business/admin/user/user/info');
  }

  // 修改密码
  modifyPassword(...options) {
    return super.fetch(
      "put",
      '/business/admin/user/user/update-password',
      ...options
    );
  }

  // 退出登录
  userLogout(token) {
    return super.fetchDelOrGet('/business/admin/user/login_out', { token });
  }

  // 获取登录验证码
  getVerifyCode() {
    return super.fetchDelOrGet(
      `${this.prefixURL}/user/verification-code`,
      {},
      "get",
      true,
      {
        responseType: "arraybuffer",
        time: new Date().getTime(),
      }
    );
  }
}

export default new UserManage();
