<template>
  <div class="wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '' }">跟单系统配置</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href>跟单比例配置</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!-- title:跟单杠杆配置 -->
    <div class="statistics">
      <!-- <h1>跟单杠杆配置</h1> -->
      <!-- <span>每小时更新数据</span> -->
    </div>
    <el-form :inline="true" class="demo-form-inline">
      <!-- <el-form-item label="账号查询">
        <el-select v-model="configId" placeholder="全部" clearable>
          <el-option
            v-for="(item, index) in Combinations"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <!-- <el-button type="primary" icon="el-icon-search" @click="getDocInfo()"
          >搜索</el-button
        > -->
        <el-button disabled type="warning" icon="el-icon-zoom-in" @click="addEXchange">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" border style="width: 100%" v-loading="Loading">
      <el-table-column
        prop="teamName"
        label="序号"
        align="center"
      >
      </el-table-column>
      <el-table-column label="跟单等级" align="center">
        <template v-slot="scope">
          <p>{{ scope.row.useScale }}%</p>
        </template>
      </el-table-column>
      <el-table-column label="资金（本金）比例" align="center">
        <template v-slot="scope">
          <p>{{ scope.row.status ? "启动" : "停止" }}</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="仓位比例"
        label="序号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="teamName"
        label="杠杆比例"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="teamName"
        label="跟单比例"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="teamName"
        label="跟单比例说明"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="teamName"
        label="状态"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" width="150" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            style="color: #409eff; text-decoration: none"
            v-if="scope.row.status == false"
            @click="getDocInfo(scope.row.id)"
            >【修改】</el-button
          >
          <!-- <br /> -->
          <el-button
            type="text"
            style="color: #409eff; text-decoration: none"
            v-if="scope.row.status == false"
            @click="DocStart(scope.row.id)"
            >【启动】</el-button
          >
          <el-button
            type="text"
            style="color: #409eff; text-decoration: none"
            v-if="scope.row.status == true"
            @click="DocStop(scope.row.id)"
            >【停止】</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 修改提示弹窗 -->
    <el-dialog
      title="跟单杠杆配置"
      :visible.sync="ReviseDialogVisible"
      width="30%"
      center
    >
      <el-form :model="ReviseDialogData">
        <el-form-item label="团队名称：" label-width="100px">
          <el-input
            v-model="ReviseDialogData.teamName"
            placeholder="请输入团队名称"
            disabled
            style="width:300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="杠杆使用率：" label-width="100px">
          <el-input
            v-model="ReviseDialogData.useScale"
            placeholder="请输入杠杆使用率"
            style="width:300px"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ReviseDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="DocSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      configId: "",
      Combinations: [], // 下拉列表
      tableData: [],
      Loading: false, // 加载进度
      ReviseDialogVisible: false, // 修改弹窗
      ReviseDialogData: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    // 获取下拉数据
    async getCombinations() {
      await this.axios.get("/business/admin/doc/combinations").then((res) => {
        // debugger
        if (res.data.status === "success") {
          this.Combinations = res.data.data;
        }
      });
    },
    // 获取列表数据-渲染表格
    async getDocInfo(id = "") {
      // debugger
      await this.axios
        .get("/business/admin/doc/doc-info", {
          params: {
            configId: this.configId,
            id,
          },
        })
        .then((res) => {
          this.Loading = true;
          // debugger
          if (id == "") {
            if (res.data.status === "success") {
              this.tableData = res.data.data; // 表格数据
              this.Loading = false;
            }
          } else if (id !== "") {
            if (res.data.status === "success") {
              this.ReviseDialogData = res.data.data; // 点击修改的数据
              this.ReviseDialogVisible = true; // 打开弹窗
              this.Loading = false;
            }
          }
        });
    },

    // 启动
    async DocStart(id) {
      await this.axios
        .post("/business/admin/doc/start", this.$qs.stringify({ id }))
        .then((res) => {
          // debugger
          if (res.data.status === "success") {
            // this.tableData = res.data.data;
            this.$notify({
              title: "成功",
              message: "启动成功",
              type: "success",
              duration: 5000,
            });
            this.getDocInfo();
          } else {
            this.$notify({
              title: "失败",
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
            this.getDocInfo();
          }
        });
    },

    // 停止
    async DocStop(id) {
      await this.axios
        .post("/business/admin/doc/stop", this.$qs.stringify({ id }))
        .then((res) => {
          // debugger
          if (res.data.status === "success") {
            // this.tableData = res.data.data;
            this.$notify({
              title: "成功",
              message: "停止成功",
              type: "success",
              duration: 5000,
            });
            this.getDocInfo();
          } else {
            this.$notify({
              title: "失败",
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
            this.getDocInfo();
          }
        });
    },

    // 保存修改数据
    async DocSave() {
      await this.axios
        .put("/business/admin/doc/save", {
          id: this.ReviseDialogData.id,
          useScale: this.ReviseDialogData.useScale,
        })
        .then((res) => {
          // debugger
          if (res.data.status === "success") {
            this.$notify({
              title: "成功",
              message: "修改成功",
              type: "success",
              duration: 5000,
            });
            this.ReviseDialogVisible = false; // 打开弹窗
            this.getDocInfo();
          } else {
            this.$notify({
              title: "失败",
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
            this.getDocInfo();
          }
        });
    },
  },
  created() {
    // this.getCombinations();
    // this.getDocInfo();
  },
  mounted() {
    // this.getCombinations();
  },
};
</script>
<style lang="scss" scope>
.wrapper {
  // background-color: aquamarine;
  margin: 0 1%;
  width: 98%;
  padding-bottom: 30px;
  h1 {
    margin-bottom: 10px;
  }
  .el-divider--horizontal {
    margin: 12px 0;
  }
  .statistics {
    h1 {
      font-size: 18px;
      display: inline-block;
    }
    span {
      // margin-left: 15px;
      font-size: 14px;
      color: #bdaaaa;
      line-height: 18px;
      margin: 10px 0;
      // display: inline-block;
    }
  }
  .card-item {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 10px auto;
    .el-card {
      display: inline-block;
      width: 26%;
      height: 100%;
      .el-card__body {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }
    }
    .card-top {
      font-size: 16px;
      .top-left {
        float: left;
        color: #ffffff;
        p {
          font-size: 12px;
        }
      }
      .top-right {
        float: right;
      }
    }
    .card-middle {
      text-align: center;
      font-size: 24px;
      color: #ffffff;
      margin: 3px auto;
    }
    .card-bottom {
      div {
        float: left;
        color: #ffffff;
        font-size: 12px;
      }
    }
  }
  // .block {
  //   margin: 15px 0;
  // }
}
</style>
