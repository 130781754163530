<template>
  <div class="wrapper">
    <div class="statistics">
      <h1>账户资产明细</h1>
      <span>每天01:00更新数据</span>
    </div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="筛选">
        <el-select
          v-model="formInline.businessType"
          placeholder="全部"
          clearable
        >
          <el-option label="服务商" value="1"></el-option>
          <el-option label="跟单" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="在跑停跑数据">
        <el-select v-model="formInline.vaild" placeholder="全部" clearable>
          <el-option label="停跑" value="0"></el-option>
          <el-option label="在跑" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="日期">
        <el-date-picker
          v-model="assetsDate"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="yieldDetail()">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="yieldDetailData" border style="width: 100%" v-loading="TableLoading">
      <el-table-column label="序号" min-width="40" align="center">
        <template v-slot="scope">
          <p>{{ scope.$index + 1 }}</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="combinationName"
        label="投资组合"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="teamName"
        label="团队"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建日期"
        min-width="90"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="initialAssets"
        label="本金(USDT)"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="balance"
        label="余额(USDT)"
        min-width="110"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="totalBalance"
        label="累计收益(USDT)"
        min-width="110"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="totalYield"
        label="累计收益率(%)"
        min-width="90"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="totalYearYield"
        label="预期年化收益率(%)"
        min-width="90"
        align="center"
      ></el-table-column>
      <!-- <el-table-column prop="maxBalanceBack" label="最大资产回撤（USDT）" min-width="125"></el-table-column> -->
      <!-- <el-table-column prop="maxBalanceBackScale" label="回撤比例(%)" min-width="80"></el-table-column> -->
      <el-table-column
        prop="runningDay"
        label="累计运行天数"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" min-width="90" align="center">
        <template slot-scope="scope">
          <router-link
            style="color: #409eff; text-decoration: none"
            :to="{
              path: 'PortfolioDetails',
              query: {
                configId: scope.row.configId,
              },
            }"
            >详情</router-link
          >
          <br />
          <router-link
            style="color: #409eff; text-decoration: none"
            :to="{
              path: 'MoneyManagement',
              query: {
                configId: scope.row.configId,
              },
            }"
            >资金管理</router-link
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      assetsDate: ["", ""], // 自选日期
      yieldDetailData: [],
      formInline: {
        businessType: "",
        vaild: "",
      },
      TableLoading: false, // 加载进度
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    assetsDate(newVal) {
      if (newVal == null) {
        this.assetsDate = ["", ""];
      }
    },
  },
  //方法集合
  methods: {
    // 获取账户资产明细
    yieldDetail() {
      this.TableLoading = true;
      this.axios
        .get("/business/admin/snapshot/yield-detail", {
          params: {
            startTime: this.assetsDate[0],
            endTime: this.assetsDate[1],
            businessType: this.formInline.businessType,
            vaild: this.formInline.vaild,
          },
        })
        .then((res) => {
          console.log("yieldDetail:", res.data.data);
          this.yieldDetailData = res.data.data;
          this.TableLoading = false;
        });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.yieldDetail();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  //生命周期 - 更新之后
  updated() {},
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scope>
.wrapper {
  margin: 0 2%;
  width: 96%;
  h1 {
    margin-bottom: 10px;
  }
  .el-divider--horizontal {
    margin: 12px 0;
  }
  .statistics {
    h1 {
      font-size: 18px;
      display: inline-block;
    }
    span {
      // margin-left: 15px;
      font-size: 14px;
      color: #bdaaaa;
      line-height: 18px;
      margin: 10px 0;
      display: inline-block;
    }
  }
}
</style>
