<template>
  <div class="user">
    <div class="user-userHeader">
      <el-input
        placeholder="用户名"
        v-model="userName"
        clearable
        class="user-userheader-userInput"
        @clear="cleanUser"
      >
      </el-input>

      <el-input
        placeholder="姓名"
        v-model="name"
        clearable
        class="user-userheader-nameInput"
        @clear="cleanName"
      >
      </el-input>

      <el-select
        v-model="valuestatus"
        clearable
        placeholder="状态"
        class="user-userHeader-selectClass"
        @clear="cleanStatus"
      >
        <el-option
          v-for="item in optionsstatus"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <div class="user-userheader-searchAndAdd">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="searchUser"
          class="user-userHeader-search"
          >搜索</el-button
        >
        <el-button
          type="warning"
          icon="el-icon-zoom-in"
          @click="addBtn"
          class="user-userHeader-add"
          >新增</el-button
        >
      </div>
    </div>

    <!-- 表 -->
    <div class="user-tableUser">
      <el-table :data="tableDataUserConf" border class="user-tableClass">
        <el-table-column prop="userName" label="用户名"> </el-table-column>
        <el-table-column prop="realName" label="姓名"> </el-table-column>
        <el-table-column prop="phone" label="手机号"> </el-table-column>

        <el-table-column prop="position" label="职位"> </el-table-column>

        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <span>{{ scope.row.status == 1 ? "启用" : "停用" }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="创建时间"> </el-table-column>

        <el-table-column prop="opration" label="操作">
          <template slot-scope="scope">
            <el-button @click="editorUser(scope.row.id)" type="text"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 编辑表格弹窗 -->

    <el-dialog
      title="用户配置"
      :visible.sync="dialogFormVisibleBody"
      :modal-append-to-body="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleFormUpdate"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="ruleForm.userName"></el-input>
        </el-form-item>

        <el-form-item label="姓名" prop="realName">
          <el-input v-model="ruleForm.realName"></el-input>
        </el-form-item>

        <el-form-item label="密码" prop="password">
          <el-input
            v-model="ruleForm.password"
            placeholder="不修改密码，请留空"
          ></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="phone">
          <el-input v-model="ruleForm.phone"></el-input>
        </el-form-item>

        <el-form-item label="职位" prop="position">
          <el-input v-model="ruleForm.position"></el-input>
        </el-form-item>

        <el-form-item label="用户组：" prop="userGroupId">
          <el-select
            style="width: 100%"
            v-model="ruleForm.userGroupId"
            placeholder="请选择用户组"
          >
            <el-option
              v-for="(item, index) in userGroupList"
              :key="index"
              :label="item.roleName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-select v-model="ruleForm.status" clearable placeholder="请选择">
            <el-option
              v-for="item in optionsEditor"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleBody = false">取 消</el-button>
        <el-button type="primary" @click="userBodyConfirm('ruleFormUpdate')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 新增弹窗 -->

    <el-dialog
      title="用户配置"
      :visible.sync="dialogFormVisibleAdd"
      :modal-append-to-body="false"
    >
      <el-form
        :model="ruleFormAdd"
        :rules="rules"
        ref="ruleFormAdd"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="ruleFormAdd.userName"></el-input>
        </el-form-item>

        <el-form-item label="姓名" prop="realName">
          <el-input v-model="ruleFormAdd.realName"></el-input>
        </el-form-item>

        <el-form-item label="密码" prop="password">
          <el-input
            v-model="ruleFormAdd.password"
            placeholder="不修改密码，请留空"
          ></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="phone">
          <el-input v-model="ruleFormAdd.phone"></el-input>
        </el-form-item>

        <el-form-item label="职位" prop="position">
          <el-input v-model="ruleFormAdd.position"></el-input>
        </el-form-item>
        <el-form-item label="用户组：" prop="userGroupId">
          <el-select
            style="width: 100%"
            v-model="ruleFormAdd.userGroupId"
            placeholder="请选择用户组"
          >
            <el-option
              v-for="(item, index) in userGroupList"
              :key="index"
              :label="item.roleName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="ruleFormAdd.status" placeholder="请选择">
            <el-option
              v-for="item in optionsEditor"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleAdd = false">取 消</el-button>
        <el-button type="primary" @click="userAddConfirm('ruleFormAdd')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 底部分页 -->

    <el-pagination
      class="user-page"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageList.page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageList.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userGroupList: [],
      dialogFormVisibleAdd: false,
      //编辑表格数据
      ruleForm: {
        userName: "",
        realName: "",
        phone: "",
        position: "",
        userGroupId: "",
        status: 1,
        phoneAddress: "",
        id: 0,
        phoneAuthStatus: 0,
        createTime: "",
      },

      // 表头弹窗绑定值
      ruleFormAdd: {
        userName: "",
        realName: "",
        phone: "",
        position: "",
        userGroupId: "",
        status: 1,
        phoneAddress: "",
        phoneAuthStatus: 0,
      },
      rules: {
        userName: [
          { required: true, message: "请输入用户名名称", trigger: "blur" },
        ],
        realName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            min: 3,
            max: 11,
            message: "长度 在 3 到 11 个字符",
            trigger: "blur",
          },
        ],
        position: [
          { required: true, message: "请输入职位名", trigger: "blur" },
          { min: 2, max: 7, message: "长度 在 2 到 7 个字符", trigger: "blur" },
        ],
        userGroupId: [
          { required: true, message: "请选择用户组", trigger: "change" },
        ],
        status: [
          { required: true, message: "请输入状态名", trigger: "change" },
        ],
        // password: [
        //   { min: 3, max: 8, message: "长度 在 3 到 8 个字符", trigger: "blur" },
        // ],
      },
      //编辑页面下拉菜单
      optionsEditor: [
        {
          value: 1,
          label: "启用",
        },
        {
          value: 0,
          label: "停用",
        },
      ],

      dialogFormVisibleBody: false,
      total: 3,
      pageList: {
        page: 1,
        pageSize: 10,
      },
      // 当前是第几页
      currentPage4: 2,
      //表格数据
      tableDataUserConf: [],

      //  下拉状态数据
      optionsstatus: [
        {
          value: 1,
          label: "启用",
        },
        {
          value: 0,
          label: "停用",
        },
      ],
      valuestatus: "", //状态select
      userName: "", //用户名表单输入  model
      name: "", //名字输入model
    };
  },
  methods: {
    cleanName() {
      this.searchUser();
    },
    cleanUser() {
      this.searchUser();
    },
    cleanStatus() {
      this.searchUser();
    },
    searchUser() {
      this.axios
        .get("/business/admin/user/admin-user-info", {
          params: {
            userName: this.userName,
            nickName: this.name,
            status: this.valuestatus,
            page: this.pageList.page,
            pageSize: this.pageList.pageSize,
          },
        })
        .then((res) => {
          console.log(res);
          //    this.getUserConfiList();
          this.tableDataUserConf = res.data.data.list;
        });
    },
    // 打开新增弹窗-按钮
    addBtn() {
      this.dialogFormVisibleAdd = true;
      this.$refs.ruleFormAdd.resetFields();
    },
    // 用户新增确定-按钮
    userAddConfirm(val) {
      this.$refs[val].validate((valid) => {
        if (valid) {
          this.axios
            .post(
              "/business/admin/user/user",
              this.$qs.stringify(this.ruleFormAdd)
            )
            .then((res) => {
              if (res.data.status == "success") {
                this.$notify({
                  title: "成功",
                  message: "新增成功",
                  type: "success",
                  position: "top-right",
                  duration: 5000,
                });
                this.dialogFormVisibleBody = false;
                this.dialogFormVisibleAdd = false;
                // console.log(res);
                this.getUserConfiList();
                this.$refs.ruleForm.resetFields();
              } else {
                console.log(res);
                this.$notify({
                  title: "失败",
                  message: res.data.msg,
                  type: "error",
                  position: "top-right",
                  duration: 5000,
                });
              }
            });
        } else {
          return false;
        }
      });
    },
    //获取页面列表数据
    getUserConfiList() {
      this.axios
        .get("/business/admin/user/admin-user-info", {
          params: this.pageList,
        })
        .then((res) => {
          console.log("获取页面列表数据:", res);
          if (res.data.status == "fail") {
            this.$router.push("/");
          } else {
            this.tableDataUserConf = res.data.data.list;
            this.total = res.data.data.total;
          }
          //  this.pageList.pageSize=10;
          //  this.pageList
        });
    },
    //当一页几条数据改变时触发
    handleSizeChange(val) {
      console.log(val);
      this.pageList.pageSize = val;
      this.getUserConfiList();
    },
    // 当前第几页
    handleCurrentChange(val) {
      console.log(val);
      this.pageList.page = val;
      this.getUserConfiList();
    },
    //点击编辑按钮
    editorUser(row) {
      this.axios
        .get("/business/admin/user/admin-user-info", {
          params: {
            id: row,
          },
        })
        .then((res) => {
          this.ruleForm = res.data.data;
          this.dialogFormVisibleBody = true;
        });
    },
    // 点击编辑弹窗确定按钮
    userBodyConfirm(val) {
      this.$refs[val].validate((valid) => {
        if (valid) {
          this.ruleForm.createTime = "";
          this.axios
            .put(
              "/business/admin/user/update-admin-user",
              this.$qs.stringify(this.ruleForm)
            )
            .then((res) => {
              if (res.data.status == "success") {
                this.$notify({
                  title: "成功",
                  message: "修改成功",
                  type: "success",
                  position: "top-right",
                  duration: 5000,
                });
                this.dialogFormVisibleBody = false;
                this.getUserConfiList();
              } else {
                this.$notify({
                  title: "失败",
                  message: res.data.msg,
                  type: "error",
                  position: "top-right",
                  duration: 5000,
                });
              }
            });
        } else {
          return false;
        }
      });
    },
    // 获取下拉用户组数据
    getAllRoles() {
      this.axios
        .get("/business/admin/system/authority-role-roles")
        .then((res) => {
          if (res.status == 200) {
            // window.console.log(res);
            window.console.log("获取下拉用户组数据:", res);
            this.userGroupList = res.data.data;
          }
        });
    },
  },
  mounted() {
    this.getUserConfiList();
    this.getAllRoles();
    // console.log(this.tableDataUserConf);
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name == "Index" ||
      to.name == "Exchange" ||
      to.name == "Team" ||
      to.name == "exConfiguration" ||
      to.name == "account" ||
      to.name == "riskLv" ||
      to.name == "exchangeObject"
    ) {
      this.getUserConfiList();
      this.userName = "";
      this.name = "";
      this.valuestatus = "";
    }
    next();
  },
};
</script>

<style scoped>
.user-userHeader {
  /* position:fixed;
      left:20%;
      top:10%; */
  display: flex;
  width: 62.5rem;
  /* margin-right: 50.625rem; */
  margin-left: 1.25rem;
  margin-top: 1.25rem;
}
/* .userCHeader{
          margin-left: -500px;
      } */
/* .table{
          margin:0px
      } */
/* .user-userHeader> .el-input:nth-child(1){
      transform: translateX(8.125rem);
     } */
/* .user-userHeader> .el-input{
      width: 12.5rem;
      margin-left:8.75rem
    } */

/* .userCHeader >.el-select{
        margin-left: 10px;  
    } */

/* .user-userHeader> .el-button--primary{
        margin-left: 1.25rem;
    } */

/* .el-table{ */
/* margin-top: 20px; */
/* margin-top: 20px; */
/* /* width: 750px; */
/* transform: translate(1040px,30px);  */
/* transform: translateY(30px); */
/* } */

.user-page {
  /* margin-right: 51.25rem; */
  margin-top: 1rem;
  text-align: center;
}

.table {
  /* position:fixed; */
  /* left:20%; */
  /* top:20%; */
  /* left:20%; */
  /* overflow-y: scroll; */
}

.user-tableClass {
  /* position: fixed; */
  width: 100%;
  height: 31.25rem;
  overflow-y: scroll;
  /* overflow-y: scroll; */
  /* overflow-x: scroll; */
  /* margin-top: 20px; */
}
.user-tableUser {
  /* position:fixed; */
  /* left:20%; */
  /* top:20%; */
  /* left:20%; */
  margin-top: 1.25rem;
  margin-left: 1.25rem;
}

.user-userHeader-selectClass {
  /* margin-left: 0.625rem; */
  margin-left: 20px;
  width: 28.125rem;
}

.user-userheader-userInput {
  width: 17.5rem;
  /* width: 58.75rem;
       margin-left: 40px; */
  /* margin-left: 2.5rem; */
  /* transform: translate(2.5rem,0rem); */
}

.user-userheader-nameInput {
  width: 17.5rem;
  margin-left: 1.25rem;
  /* width: 40rem;
       margin-left: 40px; */
  /* margin-left: 2.5rem; */
  /* transform: translate(3.125rem,0rem); */
}

.user-userHeader-selectClass {
  /* width: 300px; */
  /* transform: translate(25rem,-2.5rem); */
  /* margin-left: 1.25rem; */
  /* transform: translate(3.125rem,0rem); */
  width: 9.375rem;
  margin-left: 0.625rem;
}

.user-userheader-searchAndAdd {
  display: flex;
  width: 25rem;
}

.user-userHeader-search {
  /* margin-left: 20px; */
  width: 5.625rem;
  margin-left: 1.25rem;
}
</style>
