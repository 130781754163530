<template>
  <div class="login">
    <!-- 左侧图片栏 -->
    <div class="login_form">
      <div class="left">
        <div class="logo"></div>
        <h2 class="name">金钥匙财富</h2>
      </div>
      <div class="right">
        <div class="login-right-title">用户登录</div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleFormRef"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="用户名" prop="userNameOrPhone" class="userInput">
            <el-input
              v-model="ruleForm.userNameOrPhone"
              icon="el-icon-user"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item label="密码" prop="userPassword" class="passwordInput">
            <el-input
              v-model="ruleForm.userPassword"
              type="password"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item label="验证码" prop="verifyCode" class="codeInput">
            <el-input
              v-model="ruleForm.verifyCode"
              clearable
              @change="loginIn"
              label="请输入验证码"
              class="code-class"
            ></el-input>
            <img :src="imgSrc" alt class="code-login" @click="getCode()" />
          </el-form-item>
          <div class="login_button">
            <el-button type="warning" round>登录</el-button>
            <el-button type="info" @click="resetForm" round>重置</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgSrc: "",
      ruleForm: {
        userNameOrPhone: "",
        userPassword: "",
        verifyCode: "",
      },

      rules: {
        userNameOrPhone: [
          { required: true, message: "请输入用户名称", trigger: "blur" },
          {
            min: 3,
            max: 11,
            message: "长度在 3 到 11 个字符",
            trigger: "blur",
          },
        ],
        // userPassword: [
        //   { required: true, message: "请输入用户密码", trigger: "blur" },
        //   {
        //     min: 3,
        //     max: 8,
        //     message: "密码长度在 3 到 8 个字符",
        //     trigger: "blur",
        //   },
        // ],
        verifyCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          //  {min: 4, max: 4, message: '密码长度在 4个字符', trigger: 'blur'}
        ],
      },
    };
  },
  methods: {
    loginIn() {
      this.$refs.ruleFormRef.validate((valid) => {
        if (valid) {
          // this.axios.post("/business/admin/user/login", this.ruleForm).then(res => {
          //     if (res.data.status != "success") {
          //       this.getCode();
          //       this.$notify.error({
          //         title: "登录失败",
          //         message: res.data.msg,
          //         type: "error",
          //         duration: 5000
          //       });
          //     } else {
          //       this.$message({
          //         message: "登录成功",
          //         type: "success"
          //       });
          //       window.sessionStorage.setItem("token", res.data.msg);
          //       this.getCode();
          //       this.$router.push("/index");
          //       // console.log(res.data);
          //       this.$refs.ruleFormRef.resetFields();
          //     }
          //   });
          this.$nextTick(() => {
            this.$store
              .dispatch("user/userLogin", this.ruleForm)
              .then((res) => {
                window.console.log(res);
                if (res.status === "success") {
                  this.$message({
                    message: "登录成功",
                    type: "success",
                  });
                  // debugger;
                  console.log(res.msg);
                  window.sessionStorage.setItem("token", res.msg);
                  this.getCode();
                  // this.$router.push("/");
                  this.$router.push({
                    replace: true,
                    path: "/",
                  });
                  // console.log(res.data);
                  this.$refs.ruleFormRef.resetFields();
                } else if (res.status === "fail") {
                  // debugger;
                  this.getCode();
                  console.log(res);
                  this.$notify.error({
                    title: "登录失败",
                    message: res.msg,
                    type: "error",
                    duration: 5000,
                  });
                  return false;
                }
              })
              .catch(() => {
                this.$message.error("登录异常，请稍后重试");
                this.getCode();
                this.isLoading = false;
              });
          });
        }
      });
    },
    getCode() {
      this.axios({
        method: "get",
        url: "/business/admin/user/user/verification-code",
        responseType: "arraybuffer",
      }).then((res) => {
        const data =
          "data:image/png;base64," +
          btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
        this.imgSrc = data;
      });
    },
    resetForm() {
      this.$refs.ruleFormRef.resetFields();
    },
  },
  mounted() {
    this.getCode();
  },
};
</script>

<style scoped>
.left {
  background-color: #22253b;
  width: 18.75rem;
  height: 30rem;
  display: inline-block;
  vertical-align: middle;
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
  /* margin-left: 28.15rem;
  margin-top: -31.35rem; */
  float: left;
}
.name {
  text-align: center;
  font-size: 1.375rem;
  color: #fff;
  margin-top: 40px;
}

.logo {
  background-image: url("https://salesman.jysmall.net/static/img/logo.ae5cbba.png");
  width: 162px;
  height: 108.5px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-left: 4.375rem;
  margin-top: 5rem;
}

.right {
  width: 27.875rem;
  height: 30rem;
  /* margin-left: 46.9375rem;
  margin-top: 10rem; */
  box-sizing: border-box;
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  background: white;
  display: inline-block;
}

.login {
  background-image: url("../../../public/image/bg.jpg");
  background-size: 100% 100%;
  /* background-repeat:space; */
  /* position: relative; */
  height: 100vh;
  width: 100vw;
  /* position: fixed; */
  text-align: center;
}

.login_form {
  display: inline-block;
  z-index: 999;
  margin-top: 19vh;
}

.userInput {
  width: 19.5rem;
  margin-right: 13.75rem;
  margin-top: 20px;
}

.passwordInput {
  width: 19.5rem;
  margin-right: 13.75rem;
}

.codeInput {
  width: 15.5rem;
}

.code-login {
  cursor: pointer;
  margin-left: 11.5rem;
  /* margin-top: -870px; */
  transform: translate(0rem, -2.1875rem);
}

.login-right-title {
  padding-top: 4.875rem;
  text-align: center;
  font-weight: 800;
  font-size: 22px;
}

.login_button {
  display: flex;
  justify-content: center;
}

.code-class {
  width: 10rem;
}
/* .el-form-item__error{
  transform: translateY(-45px);
} */

/* // .el-form-item__content /deep/ .el-form-item__error{
  // transform: translateY(-1.875rem);
//   top:50%
// } */
.codeInput >>> .el-form-item__error {
  top: 50%;
}
</style>
