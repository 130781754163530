/*
 * @Author: chengengfeng
 * @Date: 2019-08-29 15:40:03
 * @Last Modified by: chengengfeng
 * @Last Modified time: 2019-08-29 18:13:22
*/
import { asyncComponent } from '../common/utils';

export default [
  {
    path: '/',
    name: 'empty',
    component: asyncComponent('layout/Home')
  }
];
