/*
 * @Author: chengengfeng
 * @Date: 2019-07-23 17:40:41
 * @Last Modified by: chengengfeng
 * @Last Modified time: 2020-02-11 11:12:10
 * @Description: public methods
*/
import BaseRequest from '../fetch';
import { getToken } from '../auth';

class ApiIndex extends BaseRequest {

  // 根据二级菜单ID获取用户菜单中功能权限
  getMenuModel(params) {
    // fix: no token after axios instantiation
    super._setHeaderFiled('token', getToken() || '');
    return super.fetchDelOrGet('business/admin/system/menu-model', params);
  }

  // 导出 excel
  exportExcel(url, params, querys) {
    // fix: no token after axios instantiation
    super._setHeaderFiled('token', getToken() || '');
    return super.exportToExcel(url, params, querys);
  }

  // Get 或者 Delete 时使用
  ajaxGetOrDel(url, params = {}, type = 'get') {
    window.console.log(url, params, type);
    return super.fetchDelOrGet(url, params, type);
  }

  // 自定义请求类型，Post、Put
  ajax(type, url, ...options) {
    window.console.log(type, url, ...options);
    return super.fetch(type, url, ...options);
  }

  // 删除图片或者文件
  delImgByPath(params) {
    return super.fetchDelOrGet('/cloud/admin/img', params, 'delete');
  }
  // 游戏库-删除图片
  delGameImgByPath(params) {
    return super.fetchDelOrGet('/cloud/admin/game_library/delete_img', params, 'delete');
  }
  // 图片上传-base64
  uploadBase64(params) {
    return super.fetch('put', '/cloud/admin/img', params, 'String');
  }
  // 商品图片上传-base64
  uploadCommodityBase64(params) {
    return super.fetch('put', '/cloud/admin/commodity/img', params, 'String');
  }

}

export default new ApiIndex();
