/*
 * @Author: chengengfeng
 * @Date: 2019-07-24 16:24:12
 * @Last Modified by: chengengfeng
 * @Last Modified time: 2019-08-29 18:12:43
 * @Description: system routes config
 */
import Vue from "vue";
import Router from "vue-router";
// import { asyncComponent } from '../common/utils';
// import Layout from '../views/layout'

Vue.use(Router);

const Login = (resolve) => require(["../views/login/Login.vue"], resolve);
// const Function = (resolve) => require(["../views/systemManage/function.vue"], resolve);
// const Index = (resolve) => require(["../views/riskControl/index.vue"], resolve);
const Err404 = (resolve) => require(['../views/errorPage/404.vue'], resolve);

export const constantRouterMap = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: '/404',
    name: 'err404',
    component: Err404,
  }
  // { path: '/login', name: 'login', hidden: true, component: Login },
  // 暂时不能加 * 因为二级页面是动态添加的，这就导致了 * 不是在最后面，从而导致访问二级页面从定向了404
  // { path: '*', name: '404', hidden: true, component: Err404 }
];

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: constantRouterMap,
});
