/*
 * @Author: chengengfeng
 * @Date: 2019-07-24 13:36:38
 * @Last Modified by: chengengfeng
 * @Last Modified time: 2020-03-18 15:32:35
*/
import router from './router';
import store from './store';
import { getToken } from './common/utils/auth';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import emptyMap from './router/emptyMap';

NProgress.configure({ showSpinner: false });

const whiteList = ['/login'];

function contains(arr, val) {
  if (arr.indexOf(val) !== -1) {
    return true;
  } else {
    return false;
  }
}

let parentPath = '';
let actionPath = '';
let parentId = '';

router.beforeEach((to, from, next) => {
  NProgress.start();
  // debugger;
  if (getToken()) { // 是否有token
    console.log('parentPath111', to.query.parentPath)
    parentPath = to.query.parentPath;
    actionPath = to.path;
    const idArr = actionPath.split('/');
    parentId = idArr[1];

    const path = actionPath.lastIndexOf('/');
    actionPath = actionPath.substring(path + 1, actionPath.length);

    if (to.path === '/login') {
      next({ path: '/' });
      NProgress.done();
    } else {
      // debugger;
      window.console.log('是否为登陆状态(isOK)：', store.getters['user/isOK']);
      if (!store.getters['user/isOK']) { // 如果没有生成可访问的路由
        store.dispatch('user/getUserInfo').then((res) => { // 拉取user_info
          store.dispatch('user/generateRoutes', res.data).then(() => {
            window.console.log('store.getters[user/asyncRouter]', store.getters['user/asyncRouter']);
            // 没有返回可用的路由列表
            // debugger;
            if (!store.getters['user/asyncRouter'].length) {
              router.addRoutes(emptyMap);
              next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
            } else {
              console.log(store.getters['user/asyncRouter']);
              router.addRoutes(store.getters['user/asyncRouter']);
              next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
            }
          });
        }).catch(() => {
          store.dispatch('user/fedLogOut').then(() => {
            // Message.error('当前用户身份验证失败，请重新登录');
            next({ path: '/login' });
          });
        });
      } else if ((actionPath.indexOf('_')) > 0 &&
        !contains(store.getters['user/allActionArray'], actionPath)) {
        store.dispatch('user/actionRoutes', {
          id: parentId,
          path: actionPath,
          parentPath
        }).then(() => {
          // debugger;
          window.console.log('store.gettersuser/newAction', store.getters['user/newAction']);
          router.addRoutes(store.getters['user/newAction']);
          next({ ...to, replace: true });
        }).catch(() => {
          next({ path: '/404' });
        });
      } else {
        // 如果以上验证均通过，还是匹配不到路由
        // 使用 to.matched 匹配出的路由个数来作为判断条件，匹配不到路由就跳转到404页面 next('/404')
        // replace: true 不向 history 添加新的错误记录，防止浏览器后退时卡死，需刷新页面才可正常后退的bug
        // 当有用户权限的时候，说明所有可访问路由已生成，正确匹配跳转页面 next()
        to.matched.length === 0 ? next({ path: '/404', replace: true }) : next();
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next('/login');
      NProgress.done();
    }
  }
});

// 相当于动态添加出错拦截
router.onError(() => {
  router.push({ path: '/404' });
});

router.afterEach(() => {
  NProgress.done();
});
