<!-- 系统管理 - 权限设置 -->
<template>
  <div class="system-authority">
    <cloud-title title="权限管理" />
    <div class="auth-wrapper" v-show="moduleList.length">
      <ul class="clearfix ul-wrapper">
        <li class="header">
          <el-col :span="8">菜单列表</el-col>
          <el-col :span="16">功能权限</el-col>
        </li>
        <div class="vertical-line"></div>
        <li v-for="(item, index) in moduleList" :key="index">
          <!-- 左侧 -->
          <el-col :span="8" class="h40">
            <span style="margin-left: 30px">
              <i
                @click="fold(item)"
                title="折叠"
                :class="{
                  'el-icon-caret-right' : item.folded, 'el-icon-caret-bottom': !item.folded
                }"
                class="item-icon"
              ></i>
              <!-- 一级菜单名 -->
              <el-checkbox
                :label="item.menuId"
                :key="item.menuId"
                v-model="currentMenus"
                @change="handleSelectMenu(item, item.menuId)"
              >{{item.menuName}}</el-checkbox>
            </span>
          </el-col>
          <!-- 右侧 -->
          <el-col :span="16" class="h40">
            <span style="margin-left: 30px">
              <el-checkbox
                @change="selectCurrentAllMenu(item)"
                :indeterminate="item.isMenuIndeterminate"
                v-model="item.isAllMenus"
              >所有菜单</el-checkbox>
            </span>
            <span style="margin-left: 30px" v-show="item.childMenu.length">
              <el-checkbox
                @change="selectCurrentAllModule(item)"
                :indeterminate="item.isModuleIndeterminate"
                v-model="item.isAllModules"
              >所有功能</el-checkbox>
            </span>
          </el-col>

          <div class="line1"></div>

          <ul v-show="!item.folded">
            <li class="h40" v-for="(child, index) in item.childMenu" :key="index">
              <!-- 左侧 -->
              <el-col :span="8">
                <!-- 二级菜单名 -->
                <el-checkbox
                  style="margin-left: 86px;"
                  v-model="currentMenus"
                  :key="child.menuId"
                  :label="child.menuId"
                  @change="handleSelectMenu(item, child.menuId)"
                >{{child.menuName}}</el-checkbox>
              </el-col>
              <!-- 右侧 -->
              <el-col :span="16" v-show="child.childFunction.length">
                <!-- 功能列表 -->
                <el-checkbox-group
                  v-model="currentModules"
                  @change="handleSelectModule(item, child)"
                >
                  <el-checkbox
                    v-for="m in child.childFunction"
                    :label="m.id"
                    :key="m.id"
                  >{{m.functionName}}</el-checkbox>
                </el-checkbox-group>
              </el-col>
              <el-col :span="16" v-show="!child.childFunction.length">该模块暂无功能分配</el-col>
              <div class="line1"></div>
            </li>
            <!-- <div class='line'></div> -->
          </ul>
        </li>
      </ul>

      <p class="mt20 tc">
        <button style="padding:12px 58px" @click="updateRoleMenuAndModuel" class="confirmBtn">确定</button>
        <button @click="goBack" style="margin-left: 14px;padding:12px 58px" class="cancelBtn">取消</button>
      </p>
      <!-- <el-button @click="getData">查看数据</el-button>
      <el-button @click="getDatas">查看</el-button>-->
    </div>

    <!-- 数据加载未完成，loading 提示 -->
    <div v-show="!moduleList.length" style="height: 500px; line-height: 500px; text-align: center">
      <img src="../../../public/image/ripple.svg" alt />
    </div>
  </div>
</template>

<script>
import SystemManage from "../../common/utils/api/system";
// import { Vue, Component } from 'vue-property-decorator';

// 删除数组某一个值
Array.prototype.removeByValue = function(val) {
  for (let i = 0; i < this.length; i++) {
    if (this[i] === val) {
      this.splice(i, 1);
      break;
    }
  }
};

// @Component({
//   name: 'SystemAuthority'
// })
export default {
  data() {
    return {
      menuId: this.$getQueryString("mid"),
      roleId: this.$getQueryString("rid"),
      moduleList: [],
      currentMenus: [],
      currentModules: []
    };
  },

  created() {
    this.getAllMenuAndModuel();
    this.getModuelByRoleId();
  },
  methods: {
    // 获取所有菜单下的所有功能
    getAllMenuAndModuel() {
      SystemManage.getAllMenuAndModuel().then(res => {
        if (res.status === "success") {
          this.moduleList = res.data;
          window.console.log(this.moduleList);
          this.moduleList.forEach(item => {
            this.$set(item, "allMenu", []);
            this.$set(item, "allMenus", false);
          });
        }
      });
    },

    // 根据用户组ID获取当前用户功能与菜单
    getModuelByRoleId() {
      SystemManage.getModuelByRoleId({ roleId: this.roleId }).then(res => {
        window.console.log(res);
        const modules = [];
        const menus = [];
        // 获取当前用户功能权限
        res.data.functions.forEach(item => {
          modules.push(item.functionId);
        });
        // 获取当前用户菜单权限
        res.data.menus.forEach(item => {
          menus.push(item.menuId);
        });
        this.currentMenus = menus;
        this.currentModules = modules;
      });
    },

    // getData() {
    //   window.console.log(this.moduleList);
    // },
    // getDatas() {
    //   window.console.log(this.currentMenus, this.currentModules);
    // },

    // 展开、收缩菜单
    fold(item) {
      if (typeof item.folded === "undefined") {
        this.$set(item, "folded", true);
      } else {
        item.folded = !item.folded;
      }
    },

    // 一级菜单 单选
    // eslint-disable-next-line no-unused-vars
    handleSelectMenu(item, mid) {
      window.console.log(item);
      // 获取当前模块所有的菜单
      const arr = [];
      arr.push(item.menuId);
      for (let a = 0; a < item.childMenu.length; a++) {
        arr.push(item.childMenu[a].menuId);
      }
      this.currentMenus = [...new Set(this.currentMenus)];
      // 当前已选中的所有菜单中返回当前菜单已选中的
      const mids = this.currentMenus.filter(items => {
        return arr.includes(items);
      });
      window.console.log(mids);
      const checkedCount = item.childMenu.length + 1;
      window.console.log(checkedCount);
      this.$set(item, "isMenuIndeterminate", true);
      if (mids.length === checkedCount) {
        item.isMenuIndeterminate = false;
        item.isAllMenus = true;
        // 手动赋值，以免全选菜单的时候 if 长度的判断不成立，需要点击第二次才有长度，才执行反选操作的bug
        item.allMenu = mids;
      } else {
        item.isAllMenus = false;
      }
      // 没有选中任何一级菜单的时候
      // if (!item.allMenu.length) {
      //   this.$set(item, 'isMenuIndeterminate', false);
      //   item.isAllMenus = false;
      // }
    },

    // 单个菜单模块-全选
    selectCurrentAllMenu(item) {
      window.console.log(item);
      item.isAllMenus = true;
      item.isMenuIndeterminate = false;
      if (!item.allMenu.length) {
        const arr = [];
        arr.push(item.menuId);
        this.currentMenus.push(item.menuId);
        for (let a = 0; a < item.childMenu.length; a++) {
          arr.push(item.childMenu[a].menuId);
          this.currentMenus.push(item.childMenu[a].menuId);
        }
        item.allMenu = arr;
        window.console.log(item.allMenu);
        item.isAllMenus = true;
      } else {
        this.currentMenus = [...new Set(this.currentMenus)];
        this.currentMenus.removeByValue(item.menuId);
        for (let a = 0; a < item.childMenu.length; a++) {
          this.currentMenus.removeByValue(item.childMenu[a].menuId);
        }
        item.allMenu = [];
        item.isAllMenus = false;
        item.isMenuIndeterminate = false;
      }
    },

    // 单个菜单模块-功能--全选
    selectCurrentAllModule(item) {
      window.console.log(item);
      if (typeof item.isAllModules === "undefined") {
        this.$set(item, "isAllModules", true);
      }
      for (let a = 0; a < item.childMenu.length; a++) {
        this.checkItemAll(
          item.isAllModules,
          item.childMenu[a],
          item.childMenu[a].menuId
        );
      }
      item.isModuleIndeterminate = false;
    },

    checkItemAll(flag, item, i) {
      window.console.log("*************************");
      window.console.log(flag, item, i);
      if (flag) {
        const arr = [];
        for (let a = 0; a < item.childFunction.length; a++) {
          arr.push(item.childFunction[a].id);
          this.currentModules.push(item.childFunction[a].id);
        }
        // 每一个菜单的功能项单独保存当前选中的功能ID
        // if (typeof item.checkedModules === 'undefined') {
        //   this.$set(item, 'checkedModules', arr);
        // }
        // item.checkedModules = arr;
        // item.checkAll = true;
        item.isAllModules = true;
      } else {
        this.currentModules = [...new Set(this.currentModules)];
        for (let a = 0; a < item.childFunction.length; a++) {
          this.currentModules.removeByValue(item.childFunction[a].id);
        }
        item.isAllModules = false;
        // item.isModuleIndeterminate = true;
        // item.checkedModules = [];
        // item.checkAll = false;
      }
    },

    // 功能模块-单选
    handleSelectModule(item, child) {
      window.console.log(item, child);
      this.currentMenus.push(child.menuId);
      // 当前菜单所有功能ID
      const marr = [];
      let count = 0;
      // 获取当前点击的一级菜单下面的二级菜单，在从二级菜单获取菜单下的所有功能的个数,和所有功能ID
      for (let a = 0; a < item.childMenu.length; a++) {
        count += item.childMenu[a].childFunction.length;
        for (let b = 0; b < item.childMenu[a].childFunction.length; b++) {
          marr.push(item.childMenu[a].childFunction[b].id);
        }
      }
      window.console.log(marr, count);
      this.currentModules = [...new Set(this.currentModules)];
      // 得出当前菜单下已有的功能的个数
      const mods = this.currentModules.filter(items => {
        return marr.includes(items);
      });
      window.console.log(mods);
      if (mods.length === count) {
        item.isModuleIndeterminate = false;
        item.isAllModules = true;
      } else {
        item.isModuleIndeterminate = true;
        item.isAllModules = false;
      }
    },

    // 确定修改用户组权限
    updateRoleMenuAndModuel() {
      SystemManage.updateRoleModuel({
        menuIds: this.currentMenus,
        functionIds: this.currentModules,
        roleId: this.roleId
      }).then(res => {
        if (res.status === "success") {
          this.$notify({
            title: "操作提示",
            offset: 100,
            type: res.status,
            message: "权限修改成功！"
          });
          this.goBack();
        } else {
          this.$notify({
            title: "操作提示",
            offset: 100,
            type: "error",
            message: res.message
          });
        }
      });
    },

    // 返回用户组列表
    goBack() {
      this.$router.push({
        path: "userGroup",
        replace: true,
        query: {
          menuId: this.menuId
        }
      });
    }
  }
};
</script>

<style lang='scss' scoped>
// @import "~common/style/mixin";
@import "../../common/style/mixin";
@import "../../common/style/variables";

.system-authority {
  .auth-wrapper {
    padding: 20px 40px;
    .check-tip {
      color: $menuBg;
      font-size: $font-size-medium;
    }

    .ul-wrapper {
      border: 1px solid $mainColor;
      position: relative;
    }
    .header {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid $mainColor;
      background-color: #4383af;
      text-align: center;
      color: #fff;
    }
    .vertical-line {
      width: 1px;
      height: 100%;
      background-color: $mainColor;
      position: absolute;
      left: 30%;
      top: 0;
    }
    .item-icon {
      margin-left: -5px;
      padding: 5px;
      color: $mainColor;
      cursor: pointer;
    }
    .line {
      clear: both;
      width: 100%;
      height: 1px;
      background-color: $mainColor;
    }
    .line1 {
      clear: both;
      width: 100%;
      height: 1px;
      background-color: $mainColor;
    }
    .h40 {
      height: 39px;
      line-height: 39px;
    }

    .cbs {
      margin: 15px 10px;
    }
    .el-checkbox__input.is-disabled .el-checkbox__inner {
      background-color: #edf2fc !important;
      border-color: #d8dce5 !important;
    }
    .el-checkbox + .el-checkbox {
      margin-left: 0;
    }
    .el-checkbox {
      margin-right: 30px;
    }
  }
}

.border {
  @include borders(bottom, 1px, $mainColor);
}
</style>
