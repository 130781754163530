<template>
  <div class="exchange123">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '' }">跟单系统配置</el-breadcrumb-item>
      <el-breadcrumb-item>
        <router-link :to="{ path: '' }">跟单组合配置</router-link>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 下划线 -->
    <el-divider></el-divider>
    <!-- 交易所组合下拉菜单 -->
    <div class="exchange-header">
      <el-input placeholder="请输入跟单组合名称" style="width: 200px;" v-model="input" clearable></el-input>
      状态：<el-select v-model="valid" placeholder="请选择状态" @change="changeValid" clearable>
        <el-option label="启用" value="1"></el-option>
        <el-option label="禁用" value="0"></el-option>
      </el-select>
      <el-button type="primary" icon="el-icon-search" @click="exchangeSearch">搜索</el-button>
      <el-button type="warning" icon="el-icon-zoom-in" @click="addEXchange">新增</el-button>
    </div>

    <!-- 表格 -->
    <div class="table-container">
      <el-table :data="tableData" border style="overflow-y: auto">
        <el-table-column prop="groupUid" label="跟单组合ID" align="center"></el-table-column>
        <el-table-column prop="exchangeName" label="交易所" align="center"></el-table-column>
        <el-table-column prop="groupName" label="跟单组合名称" width="80px" align="center"></el-table-column>
        <el-table-column prop="bossName" label="服务商账户" width="100px" align="center"></el-table-column>
        <el-table-column prop="followName" label="跟单账户" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        <!-- <el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column> -->
        <el-table-column label="状态" align="center">
          <template v-slot="scope">
            <span v-if="scope.row.valid == 1">启用</span>
            <span v-if="scope.row.valid == 0">禁用</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="scope">
            <span class="updataBtn" @click="updateGroup(scope.row)">[修改]</span>
            <span class="vaildBtn" @click="updateVaild(scope.row.id)" v-if="scope.row.valid == 0">[启用]</span>
            <span class="vaildBtn" @click="updateVaild(scope.row.id)" v-if="scope.row.valid == 1">[禁用]</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- footer标签 -->

    <div class="pagi">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageList.page"
        :page-size="pageList.pageSize" layout="total, prev, pager, next, jumper" :total="tableData.total">
      </el-pagination>
    </div>

    <!-- 新增弹窗页面 -->
    <div class="addToastClass">
      <el-dialog title="交易所组合配置" :visible.sync="dialogFormVisibleHeader" class="headerDialog"
        :modal-append-to-body="false" center>
        <el-form ref="outerEditor" :model="addOuterEditor" :rules="formAddRules" label-width="130px"
          style="width:550px">
          <el-form-item prop="groupName" label="跟单组名称">
            <el-input v-model="addOuterEditor.groupName" style="width:200px;"></el-input>
          </el-form-item>
          <el-form-item label="交易所：" prop="exchangeId">
            <el-select clearable v-model="addOuterEditor.exchangeId" placeholder="请选择交易所">
              <el-option v-for="item in exchangeInfosList" :key="item.value" :label="item.exchangeName"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="bossId" label="服务商账号">
            <el-select v-model="addOuterEditor.bossId" placeholder="全部" clearable>
              <el-option v-for="(item, index) in bossNameList" :key="index" :label="item.accountName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="followId" label="跟单账号">
            <el-select v-model="addOuterEditor.followId" placeholder="全部" clearable>
              <el-option v-for="(item, index) in followNameList" :key="index" :label="item.accountName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisibleHeader = false">取 消</el-button>
          <el-button type="primary" @click="headerConfirm">确 定</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 修改历史弹窗 -->
    <el-dialog title="查看修改历史" center :visible.sync="dialogFormVisibleHistory" :modal-append-to-body="false"
      @close="closePop">
      <el-form ref="formHistory" :model="formHistory">
        <el-form-item>
          <el-date-picker v-model="formHistory.createTime" type="datetime" placeholder="开始时间"
            format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          <el-date-picker v-model="formHistory.endTime" type="datetime" placeholder="结束时间" style="margin-left: 25px"
            format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          <el-button type="primary" icon="el-icon-search" style="margin-left: 25px" @click="ChangeHistory">查询
          </el-button>
        </el-form-item>
        <!-- <el-form-item prop="ruleType" label="类型">
          <el-radio-group v-model="formHistory.ruleType">
            <el-radio label="全部"></el-radio>
            <el-radio label="净值回撤"></el-radio>
            <el-radio label="交易币种限制"></el-radio>
            <el-radio label="交易频率限制"></el-radio>
          </el-radio-group>
        </el-form-item>-->

        <div class="history-table">
          <el-table :data="tableDataHistory.data" border>
            <el-table-column label="序号" width="100px" align="center">
              <template v-slot="scope">
                <p>{{ scope.$index + 1 }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="scriptName" label="跟单组合名称" align="center"></el-table-column>
            <!-- <el-table-column prop="ruleType" label="修改人" width="80px" align="center"></el-table-column> -->
            <el-table-column prop="createTime" label="修改时间" width="100px" align="center"></el-table-column>
            <el-table-column prop="followUserName" label="跟单账号名称" align="center"></el-table-column>
            <el-table-column prop="bossUserName" label="服务商账号名称" align="center"></el-table-column>
            <el-table-column prop="remark" label="备注" align="center"></el-table-column>
          </el-table>
        </div>

        <el-pagination style="text-align: center;margin-top: 10px" @size-change="handleSizeChangeHistory"
          @current-change="handleCurrentChangeHistory" :current-page="pageListHistory.page" :page-size="1"
          layout="total, prev, pager, next, jumper" :total="tableDataHistory.total">
        </el-pagination>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisibleHistory = false">取 消</el-button>
          <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>

    <!-- 编辑/修改页面 -->
    <el-dialog title="交易所组合配置" center :visible.sync="outerVisibleEditor" :modal-append-to-body="false" append-to-body>
      <el-dialog width="30%" title="内层 Dialog" :visible.sync="innerVisibleEditor" append-to-body></el-dialog>
      <el-form ref="outerEditor" :model="updateOuterEditor" :rules="formAddRules" label-width="130px"
        style="width:550px">
        <el-form-item prop="scriptName" label="跟单组名称">
          <el-input disabled v-model="updateOuterEditor.groupName" style="width:200px;"></el-input>
        </el-form-item>
        <el-form-item label="交易所：" prop="exchangeId">
          <el-select clearable v-model="updateOuterEditor.exchangeId" placeholder="请选择交易所">
            <el-option v-for="item in exchangeInfosList" :key="item.value" :label="item.exchangeName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="bossId" label="服务商账号">
          <el-select v-model="updateOuterEditor.bossId" placeholder="全部" clearable>
            <el-option v-for="(item, index) in bossNameList" :key="index" :label="item.accountName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="followId" label="跟单账号">
          <el-select v-model="updateOuterEditor.followId" placeholder="全部" clearable>
            <el-option v-for="(item, index) in followNameList" :key="index" :label="item.accountName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="outerVisibleEditor = false">取 消</el-button>
        <el-button type="primary" @click="outerEditorUpdate">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SystemManage from "../../common/utils/api/system";
import lodash from "lodash";
const qs = require("qs");

export default {
  data() {
    return {
      input: '',
      valid: '',
      bossNameList: [], // 服务商名称下拉框数据
      followNameList: [], // 跟单账户名称下拉框数据
      exchangeInfosList: [], // 获取交易所列表（带label下拉数据）
      teamSelectOptions: [], //团队下拉列表
      checkListVarite: [],
      optionData: [
        {
          id: "",
          exchangeName: ""
        }
      ],
      outerEditor1: null, // 点击编辑后回传的数据-汇总
      // 新增的数据-汇总
      addOuterEditor: {
        groupName: '',
        exchangeId: "",
        bossId: "",
        followId: ""
      },
      // 修改的数据-汇总
      updateOuterEditor: {
        groupName: '',
        exchangeId: "",
        bossId: "",
        followId: ""
      },
      formAddRules: {
        groupName: [
          { required: true, message: "请选择跟单组名称", trigger: "blur" }
        ],
        exchangeId: [
          { required: true, message: "请选择交易所", trigger: "blur" }
        ],
        followId: [
          { required: true, message: "请选择跟单组账户", trigger: "blur" }
        ],
        bossId: [
          { required: true, message: "请选择服务商账户", trigger: "blur" }
        ]
      },

      outerRules: {
        // teamSelectValue: [
        //   { required: true, message: "请输入团队名称", trigger: "change" },
        // ],
        // exchangeCheckList: [
        //   { required: true, message: "请输入账户名称", trigger: "blur" },
        // ],
      },
      outerEditorOptions: [],
      outerVisibleEditor: false,
      innerVisibleEditor: false,
      formHistory: {
        createTime: null, //
        endTime: null
      },
      tableDataHistory: [], // 历史修改记录汇总
      exchangeLeftFromServer: [],
      risk1Model3: {
        checkList: [],
        riskleverValue3: "",
        timeSecond: "",
        times: "",
        riskleverValue2: "",
        TradableBusiness: [] // 可交易业务
      },
      unitArr: [
        {
          value: "USDT",
          label: "USDT"
        },
        {
          value: "BTC",
          label: "BTC"
        },
        {
          value: "ETC",
          label: "ETC"
        }
      ],
      exchangeFromServer: "", //获取交易所组合列表
      teamNameFromServer: "", //新增列表第一个
      formAdd: {
        team: "", // 团队下拉返回的 model
        risk: "", // 风控阈值返回的model
        money: "", //初始资产返回的model
        unit: "" //资产单位下拉返回的model
      }, //
      websock: null, //websocket
      pageListHistory: {
        page: 1,
        pageSize: 10
      },
      totalHistory: 2, //修改历史分页  总数
      dialogFormVisibleHistory: false, //点击历史 弹出
      optionsSelectHistory: [], //历史下拉返回的数据
      // valueHistorySelect:"",              //     历史下拉返回的 model
      // dateValue:"",                             //历史页面返回的日期Model
      businessConfigCombinationsTwo: [], //新增页面接口的第二个选项
      secreMapListFromServer: [],
      // exchangeIDList:{},            //从secretMapList 中返回的 exchangeID 列表
      // valueFromServer:"",           //从secretMapList 中返回的 下拉框ID
      // exchangeIdFormServer:"",      //从secretMapList 中返回的 checkID
      exchangeCheck: [],
      exchangeListCheck: [],
      timeSecond: "", //时间
      times: "", //次数
      HistoryId: "",
      //资产单位列表
      riskunitList: [
        {
          label: "USDT",
          value: "USDT"
        },
        {
          label: "BTC",
          value: "BTC"
        },
        {
          label: "RMB",
          value: "RMB"
        }
      ],
      //资产单位
      riskUnit: "",
      //
      //交易所列表
      exchangeInfosFromServer: [],
      //风控等级1 下拉model
      riskleverValue1: "",
      //风控等级3 下拉model
      riskleverValue3: "",

      //风控等级3 下拉列表
      riskLeveloptions3: [
        {
          value: "选项1",
          grade: 1
        },
        {
          value: "选项2",
          grade: 2
        },
        {
          value: "选项3",
          grade: 3
        },
        {
          value: "选项4",
          grade: 4
        }
      ],
      //选中数组
      checkBoxfromServerCheck: [],
      checkBoxfromServer: [], //可交易品种 列表
      riskLeveloptions2: [
        //风控等级2 下拉菜单
        {
          value: "选项1",
          label: 1
        },
        {
          value: "选项2",
          label: 2
        },
        {
          value: "选项3",
          label: 3
        },
        {
          value: "选项4",
          label: 4
        }
      ],
      //风控等级1 下拉列表
      riskLeveloptions1: [
        {
          value: "选项1",
          label: 1
        },
        {
          value: "选项2",
          label: 2
        },
        {
          value: "选项3",
          label: 3
        },
        {
          value: "选项4",
          label: 4
        }
      ],
      //风控等级2 下拉菜单 model绑定值
      riskleverValue2: "",
      checkList: [], //复选框选中的列表
      risk1Model: {
        //表头 文本输入框绑定model值
        risk: "",
        start: "",
        company: "",
        riskleverValue1: ""
        // level:""
      },
      checkedOkex: "",
      checkedBitCoke: "", //多选框绑定Bitcoke的值
      optionsAccount2: [
        {
          //下拉1的账户列表
          value: "选项1",
          label: "80....主账户"
        },
        {
          value: "选项2",
          label: "70....主账户"
        }
      ],
      optionsAccount1: [
        {
          //下拉1的账户列表
          value: "选项1",
          label: "80....主账户"
        },
        {
          value: "选项2",
          label: "70....主账户"
        }
      ],
      valueCheckAccount2: "", //选择账户的下拉2  v-model值
      valueCheckAccount1: "", //选择账户的下拉1 v-model返回值
      //checkedBitMex:"",//多选框多选BitMex按钮
      //新增页面第一行团队下拉框
      optionsSelectHeader: [
        //     {
        //     value:"选项1",
        //     label:"团队1"
        // },
        // {
        //     value:"选项2",
        //     label:"团队2"
        // },
        // {
        //     value:"选项3",
        //     label:"团队3"
        // }
      ],
      //表头form表单 v-model绑定
      dialogFormVisibleHeader: false, //表头新增弹框判定
      checkBoxArray: [],
      // 表头交易所组合下拉 model值
      valueEXchange: "",
      //交易所组合下拉 表头
      optionsExchange: [],
      tableData: [],
      pageList: {
        page: 1, //当前是第几页
        pageSize: 10 //一页多少条数据
      },
      total: 0
    };
  },
  methods: {
    // 关闭弹窗
    closePop() {
      this.updateOuterEditor.groupName = ''
      this.updateOuterEditor.exchangeId = ''
      this.updateOuterEditor.bossId = ''
      this.updateOuterEditor.followId = ''
    },
    // 修改记录查看
    updateHistory(row) {
      this.$router.push({
        path: 'exchange_detail',
        query: {
          parentPath: "DocumentarySystemConfiguration",
          groupId: row.id
        }
      })
    },
    // 编辑修改-确定按钮-提交
    outerEditorUpdate() {
      if (this.updateOuterEditor.followId === this.updateOuterEditor.bossId) {
        this.$message({
          message: '请选择不同的跟单与服务商账号',
          type: 'error'
        });
        return false
      }
      this.$refs.outerEditor.validate((valid) => {
        if (valid) {
          SystemManage.updateFollowGroup(this.updateOuterEditor).then(res => {
            if (res.status === 'success') {
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.outerVisibleEditor = false
              this.exchangeSearch()
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
            }
          })
        }
      })
    },
    // 修改跟单组合
    updateGroup(row) {
      this.outerVisibleEditor = true
      this.exchangeInfos()
      this.getAccountSelectList()
      this.getGroup(row.id)
    },
    // 修改状态
    async updateVaild(id) {
      await this.axios
        .post("/business/admin/followGroup/updateFollowGroupForStatus", this.$qs.stringify({ id }))
        .then((res) => {
          if (res.data.status === "success") {
            this.$notify({
              title: "成功",
              message: "操作成功",
              type: "success",
              duration: 5000,
            });
            this.exchangeSearch()
          } else {
            this.$notify({
              title: "失败",
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
            this.exchangeSearch()
          }
        });
    },
    // 获取组合信息
    getGroup(id) {
      SystemManage.getGroup({ id }).then(res => {
        if (res.status === 'success') {
          this.updateOuterEditor = res.data
        }
      })
    },
    // 选中状态
    changeValid(val) {
      console.log(val)
    },
    // 获取交易所信息
    getAccountSelectList() {
      SystemManage.getAccountSelectList().then(res => {
        if (res.status === 'success') {
          this.bossNameList = this.followNameList = res.data
        }
      })
    },
    // 获取交易所列表（带label下拉数据）
    exchangeInfos() {
      SystemManage.getExchange().then(res => {
        if (res.status === 'success') {
          this.exchangeInfosList = res.data
        }
      })
    },
    clearSearch() {
      this.exchangeSearch();
      this.getSearchSelect();
    },
    // 获取 跟单账户名称下拉框数据 and 服务商名称下拉框数据
    getBossAndFollow() {
      this.axios.post("/gdSystem/gainAccountName", { type: 0 }).then(res => {
        this.bossNameList = res.data.data;
      });
      this.axios.post("/gdSystem/gainAccountName", { type: 1 }).then(res => {
        this.followNameList = res.data.data;
      });
    },
    // 搜索按钮-查询列表数据
    exchangeSearch() {
      SystemManage.getFollowGroup({
        groupName: this.input,
        valid: this.valid,
        page: this.pageList.page,
        pageSize: this.pageList.pageSize
      }).then(res => {
        if (res.status === 'success') {
          let arr = res.data.list
          arr = arr.sort(function(a,b){
            return b.valid - a.valid
          })
          this.tableData = arr
          this.tableData.total = res.data.total
        }
      })
    },
    // 交易所组合下拉菜单的数据 -- 用于查询列表（交易所组合）
    getSearchSelect() {
      this.axios.post("/gdSystem/selectGdScriptAll", {}).then(res => {
        console.log("交易所组合下拉", res);
        this.optionsExchange = res.data.data;
      });
    },
    //响应  exchangeRight  列表
    // async checkExchangeRight() {
    //   //    debugger
    //   // for(var key of this.formAddOne.checkedLeft){
    //   //     var exchangeIdParamas=key
    //   // }
    //   for (var i = 0; i <= this.formAddOne.checkedLeft.length - 1; i++) {
    //     var key = this.formAddOne.checkedLeft[i];
    //     console.log(key);
    //     await this.axios
    //       .get("/business/admin/account/account-secret", {
    //         params: {
    //           exchangeId: key,
    //         },
    //       })
    //       .then((res) => {
    //         console.log(res);
    //       });
    //   }
    //   //   await  this.axios.get('/business/admin/account/account-secret',{
    //   //             params:{
    //   //                 exchangeId:exchangeIdParamas
    //   //             }
    //   //         }).then(()=>{
    //   //            this.axios.get('/business/admin/account/account-secret',{
    //   //                params:{
    //   //                    exchangeId:exchangeIdParamas
    //   //                }
    //   //            }).then((res)=>{
    //   //              console.log(res);
    //   //            })
    //   //         })

    //   // }
    // },
    //响应 exchangeLeft 列表
    // checkExchangeLeft() {
    //   console.log(this.formAddOne.checkedLeft);
    // },
    //获取交易所组合列表 -- 新增窗口内
    getExchangeLeft() {
      this.axios.get("/business/admin/exchange/comboExchange").then(res => {
        console.log(res);
        this.exchangeLeftFromServer = res.data.data;
      });
    },
    handleCurrentChangeHistory(val) {
      console.log(val);
      this.pageListHistory.page = val;
      this.exchangeChangeHistory(this.HistoryId);
    },
    handleSizeChangeHistory(val) {
      console.log(val);
      this.pageListHistory.pageSize = val;
      this.exchangeChangeHistory(this.HistoryId);
    },
    //页面一页的数据发生改变时调用
    handleSizeChange(val) {
      this.pageList.pageSize = val;
      this.exchangeSearch();
    },
    //页面第几页发生改变时调用
    handleCurrentChange(val) {
      this.pageList.page = val;
      this.exchangeSearch();
    },
    //点击修改状态按钮
    exchangeChangeStatus(idForm, stateForm) {
      this.axios
        .post(
          "/business/admin/config/state",
          qs.stringify({ id: idForm, state: stateForm })
        )
        .then(res => {
          if (res.data.status == "success") {
            this.$notify({
              title: "成功",
              message: "修改状态成功",
              type: "success",
              positon: "top-left",
              duration: 5000
            });
          } else {
            this.$notify({
              title: "失败",
              message: "修改状态失败",
              type: "error",
              duration: 5000
            });
          }
          console.log(res);
          this.exchangeSearch();
          this.getSearchSelect();
        });
    },
    // 删除-按钮
    DelExchangeChange(id) {
      this.axios
        .delete("/business/admin/config/del", {
          params: {
            configId: id
          }
        })
        .then(res => {
          if (res.data.status === "success") {
            this.$notify({
              title: "成功",
              message: "删除成功",
              type: "success",
              duration: 5000
            });
            this.exchangeSearch();
            this.getSearchSelect();
            this.innerVisible = false;
            this.addShow = false;
          } else {
            this.$notify({
              title: "失败",
              message: "删除失败",
              type: "error",
              duration: 5000
            });
          }
        });
    },
    //获取交易所列表
    // getExchangeInfo:function(){
    //     this.axios.get('/business/admin/exchange/exchange-infos').then((res)=>{
    //        console.log(res);
    //     //    debugger
    //        res.data.data.map(() => {
    //            let val = ''
    //            this.exchangeCheck.push(val)
    //            this.exchangeListCheck.push(val)
    //        })
    //        this.exchangeFromServer=res.data.data            //交易所组合
    //     });
    // },
    //获取checkbox列表 新增窗口 后台品种列表（所有能用的交易品种）（新增交易所组合配置页面 风控2：交易品种限制，可交易品种的展示）
    getCheckBoxList: function () {
      this.axios.get("/business/admin/varieties/varietie-infos").then(res => {
        console.log(res);
        this.checkBoxfromServer = res.data.data;
      });
    },
    //获取风控等级   新增窗口
    getRiskList() {
      this.axios.get("/business/admin/grade/grade-infos").then(res => {
        console.log(res);
        this.riskLeveloptions3 = res.data.data; //风控等级3 赋值
        this.riskLeveloptions2 = res.data.data; //风控等级2 赋值
        this.riskLeveloptions1 = res.data.data; //风控等级1 赋值
      });
    },
    //获取exchange 表格列表数据
    // getExchangeList() {
    //   this.axios
    //     .get("/business/admin/config/config-info", {
    //       params: this.pageList,
    //     })
    //     .then((res) => {
    //       //    debugger
    //       console.log(res);
    //       if (res.data.status == "fail") {
    //         this.$router.push("/");
    //       } else {
    //         this.tableData = res.data.data.list;
    //         this.total = res.data.data.total;
    //         this.pageList.pageSize = res.data.data.pageSize;
    //         this.pageList.page = res.data.data.pageNum;
    //       }
    //     });
    // },
    // 新增弹窗 - 确认按钮
    headerConfirm() {
      this.$refs.outerEditor.validate((valid) => {
        if (this.addOuterEditor.followId === this.addOuterEditor.bossId) {
          this.$message({
            message: '请选择不同的跟单与服务商账号',
            type: 'error'
          });
          return false
        }
        if (valid) {
          SystemManage.saveFollowGroup(this.addOuterEditor).then(res => {
            if (res.status === 'success') {
              this.$message({
                message: '新增成功',
                type: 'success'
              });
              this.dialogFormVisibleHeader = false
              this.startFlag = false
              this.exchangeSearch()
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
            }
          })
        }
      })
    },

    //获取交易所配置组合团队下拉列表
    getExchangeTeamList: function () {
      this.axios.get("/business/admin/team/team-infos").then(res => {
        console.log(res);
        this.teamNameFromServer = res.data.data;
      });
    },
    //点击新增按钮
    addEXchange() {
      this.dialogFormVisibleHeader = true;
      this.exchangeInfos()
      this.getAccountSelectList()
    },

    //修改按钮点击
    exchangeEditor(row) {
      console.log("修改按钮点击:", row);
      this.outerVisibleEditor = true; // 修改窗口开启
      // console.log("「修改」的表单数据：row===", row);
      // this.updateOuterEditor.scriptName =
      //   row.followUserName + "(" + row.bossUserName + ")"; // 设置修改窗口的表单数据
      this.updateOuterEditor.id = row.id;
      this.updateOuterEditor.bossId = row.bossId; // 服务商名称
      this.updateOuterEditor.followId = row.followId; // 跟单名称
      this.updateOuterEditor.exchangeId = row.exchangeId; // 交易所id
    },
    // 修改历史-按钮
    exchangeChangeHistory(id) {
      this.formHistory.createTime = null;
      this.formHistory.endTime = null;
      this.HistoryId = id;
      this.axios
        .post("/gdSystem/selectGdScriptHistory", {
          id,
          page: this.pageListHistory.page,
          pageSize: this.pageListHistory.pageSize
        })
        .then(res => {
          console.log("修改历史查询按钮：", res);
          this.dialogFormVisibleHistory = true;
          this.tableDataHistory = res.data;
        });
      console.log("历史id：", id);
      console.log("历史记录：", this.tableHistory);
    },
    // 修改历史-查询
    ChangeHistory() {
      console.log("修改历史表单:", this.formHistory);
      this.axios
        .post("/gdSystem/selectGdScriptHistory", {
          id: this.HistoryId,
          // ruleType,
          createTime: this.formHistory.createTime,
          endTime: this.formHistory.endTime,
          page: this.pageListHistory.page,
          pageSize: this.pageListHistory.pageSize
        })
        .then(res => {
          // console.log("历史记录：", res.data.data);
          this.tableDataHistory = res.data;
        });
      // console.log("历史ruleType：", ruleType);
      console.log("历史id：", this.HistoryId);
      console.log("历史记录：", this.tableDataHistory);
    }
  },
  async mounted() {
    this.outerEditor1 = lodash.cloneDeep(this.outerEditor);
    this.getSearchSelect(); // 组合配置下拉数据
    this.exchangeSearch(); // 获取exchange 表格列表数据
    this.getRiskList();
    this.getCheckBoxList();
    this.getExchangeTeamList();
    this.getBossAndFollow();
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name == "riskLv" ||
      to.name == "exchangeObject" ||
      to.name == "Team" ||
      to.name == "Index" ||
      to.name == "account" ||
      to.name == "Userconf" ||
      to.name == "exConfiguration"
    ) {
      this.getExchangeList();
      this.valueEXchange = "";
    }
    next();
  }
};
</script>

<style scoped>
/* .exchange{
      margin-left: 200px;
  } */

.exchange123>>>.el-checkbox__label {
  width: 100%;
  line-height: inherit;
}

.el-button--primary {
  margin-left: 0.4375rem;
}

/* .spanTeamName{
      margin-left:-500px
  } */

.teamHeaderSelect {
  /* transform: translate(10px,0px); */
  position: absolute;
  left: 10%;
}

.exchangeCompose {
  /* transform: translate(-340px,20px); */
  /* float: left;
    margin-top:120px;
     left:10%; */
  margin-top: 3.75rem;
  /* position:absolute;
     left:10%; */
}

.exchange-two .exchange_portfolio-select {
  width: 40%;
  display: inline;
  /* padding-left: 20px; */
  float: right;
  padding-right: 40%;
}

.checkboxExchange-one {
  /* margin-left: 5px; */
  margin-left: 2%;
  width: 9.375rem;
}

.teamHeaderExchange-one {
  /* transform: translate(10px,0px); */
  margin-left: 0.625rem;
}

.exchange-three {
  margin-top: 1.25rem;
}

.checkboxExchange-two {
  margin-left: -20.9375rem;
}

.teamHeaderExchange-two {
  transform: translate(0.3125rem, 0rem);
}

.checkboxExchange-three {
  transform: translate(-17.75rem, 1.5625rem);
}

.risk1 {
  margin-top: 1.875rem;
}

.risk1 .risk-one-title {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
  display: block;
}

.unitFund {
  width: 12.5rem;
}

.updataBtn{
  color: red;
  margin-right: 10px;
  cursor: pointer;
}
.vaildBtn{
  color: green;
  cursor: pointer;
}

.risk2 {
  margin-top: 1.25rem;
}

.risk2 .risk-two-title {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
  display: block;
}

.headerDialog {
  /* height: 900px; */
  /* overflow:scroll */
  margin-top: -6.25rem;
  /* width: 2000px; */
}

.checkBoxList {
  margin-top: 0.625rem;
}

.two {
  margin-top: 1.25rem;
  /* transform: translate(-235px,0px); */
}

.exchangeObj {
  float: left;
  margin-right: 15px;
  height: 22vh;
}

.risk3 {
  margin-top: 1.25rem;
}

.risk3 .riskLever-three {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
  display: block;
}

.riskLever-three2 {
  margin-top: 1.25rem;
}

.riskLever-three2 {
  transform: translate(-14.8125rem, 0rem);
}

.unit {
  transform: translate(-21.0625rem, 0rem);
}

.risklevel-foot {
  transform: translate(-0.0625rem, -0.0625rem);
}

.timeSeCond2 {
  width: 1.5625rem;
}

.times2 {
  width: 1.5625rem;
}

.times {
  width: 3.125rem;
}

.seconds {
  width: 3.125rem;
}

.addToastClass {
  position: relative;
  width: 62.5rem;
}

.spanTeamName {
  position: absolute;
  left: 5%;
}

.table {
  width: 75rem;
  height: 31.25rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.pagi {
  margin-top: 1.25rem;
  text-align: center;
}

.table-container {
  /* margin-top: 20px;
       margin-left: 20px; */
  /* position:fixed;
      left:20%;
      top:20%;  */
  /* left:20%; */
  /* width: 1000px; */
  margin-top: 1.25rem;
  margin-left: 1.25rem;
}

.exchange-header {
  margin: 1.25rem 0 0 1.25rem;
}

.outerEditor-litterSize-input {
  width: 30px;
  margin-right: 10px;
  height: 10px;
}
</style>
