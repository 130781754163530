/*
 * @Author: chengengfeng
 * @Date: 2019-07-23 16:30:08
 * @Last Modified by: chengengfeng
 * @Last Modified time: 2019-07-29 14:57:57
 * @Description: cookies
 */
import Cookies from 'js-cookie';

const Token = 'token';

export function getToken() {
    return Cookies.get(Token);
}

export function setToken(token) {
    window.console.log('auth token', token);
    return Cookies.set(Token, token, { expires: 1 });
}

export function removeToken() {
    return Cookies.remove(Token);
}