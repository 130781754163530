<script>
export default {
  // name: "CloudPagination",
  props: {
    total: {
      type: Number,
      default: 0,
    },

    pageArray: {
      type: Array,
      default: () => [10, 20, 30, 50],
    },

    listQuery: {
      type: Object,
      default: () => {
        return {
          pageSize: 1,
          page: 1,
        };
      },
    },
  },
  data() {
    return {};
  },
  render() {
    return (
      <div class="pagination-wrapper">
        <el-pagination
          background
          on-current-change={this.handleCurrentChange}
          on-size-change={this.handleSizeChange}
          total={this.total}
          pageSizes={this.pageArray}
          currentPage={this.listQuery.page}
          pageSize={this.listQuery.pageSize}
          layout={this.setLayout()}
        />
      </div>
    );
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit("currentChange", val);
    },

    handleSizeChange(val) {
      this.$emit("sizeChange", val);
    },
    setLayout() {
      return this.total > 0
        ? "total, sizes, prev, pager, next, jumper"
        : "sizes, prev, pager, next, jumper";
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-wrapper {
  margin-top: 50px;
  text-align: center;
}
</style>
