<template>
  <div class="team">
    <el-form :inline="true" :model="formInline">
      <el-form-item>
        <!-- input搜索框 -->
        <el-input
          v-model="formInline.input"
          placeholder="团队名称"
          clearable
          @clear="clearSearch()"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <!-- 下拉选择框 -->
        <el-select v-model="formInline.value" clearable placeholder="状态">
          <el-option
            v-for="item in optionsTeamheader"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="getTeamList"
          >搜索</el-button
        >
        <!-- 弹出框  -->
        <el-button type="warning" icon="el-icon-zoom-in" @click="addBodyList"
          >新增</el-button
        >
        <el-button type="success" @click="addBodyMore">批量新增</el-button>
      </el-form-item>
    </el-form>

    <!-- 新增弹出框 -->
    <el-dialog
      title="团队配置维护"
      :visible.sync="dialogFormVisibleAdd"
      :modal-append-to-body="false"
    >
      <el-form
        ref="formbodyToast"
        :model="formbodyToast"
        label-width="180px"
        :rules="rulesAddRules"
      >
        <el-form-item label="团队名称" prop="teamName">
          <el-input v-model="formbodyToast.teamName"></el-input>
        </el-form-item>

        <el-form-item label="团队说明" prop="remark">
          <el-input type="textarea" v-model="formbodyToast.remark"></el-input>
        </el-form-item>

        <el-form-item label="排序号" prop="orderNum">
          <el-input
            v-model="formbodyToast.orderNum"
            type="number"
            min="0"
          ></el-input>
        </el-form-item>

        <el-form-item prop="state" label="状态">
          <el-radio-group v-model="formbodyToast.state">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">停用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleAdd = false">取 消</el-button>
        <el-button type="primary" @click="headerConfirm">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 批量新增 -->
    <el-dialog
      title="批量新增"
      :visible.sync="dialogFormAddMore"
      :modal-append-to-body="false"
      :before-close="cacleDialog"
    >
      <el-form
        ref="formbodyToast"
        :model="formbodyToast"
        label-width="180px"
        :rules="rulesAddRules"
      >
        <el-form-item label="模板下载：">
          <el-button type="danger" @click="downMould">下载模板</el-button>
        </el-form-item>
        <el-form-item label="批量新增：">
          <input type="file" accept=".xlsx" ref="filebox" id="fileinput" style="display: none;" @change="checkFileSure">
          <el-button type="primary" @click="checkFile">批量新增数据</el-button>
          <div>{{fileName}}</div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogFormAddMore = false">取 消</el-button> -->
        <el-button @click="cacleDialog">取 消</el-button>
        <el-button type="primary" @click="submitImport">提 交</el-button>
      </div>
    </el-dialog>

    <el-table :data="bodyTableData" border>
      <el-table-column prop="teamName" label="团队名称"> </el-table-column>
      <el-table-column prop="remark" label="团队说明"> </el-table-column>
      <el-table-column prop="orderNum" label="排序号"> </el-table-column>
      <el-table-column prop="state" label="状态">
        <template slot-scope="scope">
          <div>{{ scope.row.state == 1 ? "启用" : "停用" }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="opration" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="handleClick(scope.row)"
            >【编辑】</el-button
          >
          <el-button
            type="text"
            v-if="scope.row.state == 1"
            @click="changeState(scope.row.id, 0)"
            >【停用】</el-button
          >
          <el-button
            type="text"
            v-if="scope.row.state == 0"
            @click="changeState(scope.row.id, 1)"
            >【启用】</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 修改编辑 -->
    <el-dialog
      title="团队配置维护"
      :visible.sync="dialogFormVisibleTable"
      :modal-append-to-body="false"
    >
      <el-form
        ref="formbodyToastBody"
        :model="formbodyToastBody"
        label-width="180px"
        :rules="rulesAddRules"
      >
        <el-form-item label="团队名称" prop="teamName">
          <el-input v-model="formbodyToastBody.teamName"></el-input>
        </el-form-item>

        <el-form-item label="团队说明" prop="remark">
          <el-input
            type="textarea"
            v-model="formbodyToastBody.remark"
          ></el-input>
        </el-form-item>

        <el-form-item label="排序号" prop="orderNum">
          <el-input
            v-model="formbodyToastBody.orderNum"
            type="number"
            min="0"
          ></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="state">
          <el-radio-group v-model="formbodyToastBody.state">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">停用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleTable = false">取 消</el-button>
        <el-button type="primary" @click="confirm('formbodyToastBody')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 页尾 -->
    <div class="team-page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
// import Header from '../team/header'
// import Body from '../team/body'
export default {
  // components:{
  //    Body
  // },
  data() {
    return {
      dialogFormVisibleAdd: false,
      dialogFormAddMore: false,
      total: 2,
      fileName: '',
      uploadForm: new FormData(),
      //团队配置Body下的列表
      formInline: {
        input: "",
        value: "",
      },
      // 页面参数
      pages: {
        page: 1, //第几页
        pageSize: 10, //一页几条数据
      },
      // 当前页面号码
      // currentPage4:3,
      //页面列表的展示
      bodyTableData: [],

      //表头弹框响应回来的数据
      // headerToast:{

      // },

      // 新增弹框中的input邦定值
      formbodyToast: {
        teamName: "",
        remark: "",
        orderNum: "",
        state: "",
      },
      rulesAddRules: {
        teamName: [
          { required: true, message: "请输入团队名称", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "请输入团队说明", trigger: "blur" },
        ],
        orderNum: [
          { required: true, message: "请输入排序号", trigger: "blur" },
        ],
        state: [{ required: true, message: "请选择状态", trigger: "blur" }],
      },
      dialogFormVisibleTable: false,
      optionsTeamheader: [
        {
          value: 0,
          label: "停用",
        },
        {
          value: 1,
          label: "启用",
        },
      ],
      value: "",
      //编辑列表的 v-model邦定值
      formbodyToastBody: {
        teamName: "",
        remark: "",
        orderNum: "",
        state: "",
        // id:1,
      },
      //表头增加弹窗控制
      //  dialogFormVisible:false,
      //  value:1,
      input: "",
      //  row:"",
      //中间的列表

      //  bodyList:[],

      //    {
      // teamName:"团队1",
      // teamDec:"",
      // sort:10,
      // status:"启用"
      //  },
    };
  },
  methods: {
    clearSearch() {
      //清空时候触发
      this.getTeamList();
    },
    handleSizeChange(val) {
      console.log(val);
      this.pages.pageSize = val;
      this.getTeamList();
    },
    handleCurrentChange(val) {
      console.log(val);
      this.pages.page = val;
      this.getTeamList();
    },
    handleClick(row) {
      this.formbodyToastBody.teamName = row.teamName;
      this.formbodyToastBody.remark = row.remark;
      this.formbodyToastBody.orderNum = row.orderNum;
      this.formbodyToastBody.id = row.id;
      this.formbodyToastBody.state = row.state;
      this.dialogFormVisibleTable = true;
    },
    confirm(val) {
      this.$refs[val].validate((valid) => {
        if (valid) {
          this.axios
            .put("/business/admin/team/save", this.formbodyToastBody)
            .then((res) => {
              if (res.data.status == "success") {
                this.$notify({
                  title: "成功",
                  message: "修改成功",
                  type: "success",
                  // position: "top-left",
                  duration: 5000,
                });
                this.getTeamList();
                this.dialogFormVisibleTable = false;
              } else {
                this.$notify({
                  title: "失败",
                  message: "修改失败",
                  type: "error",
                  // position: "top-left",
                  duration: 5000,
                });
              }
            });
        } else {
          return false;
        }
      });
    },
    headerConfirm() {
      this.axios
        .put("/business/admin/team/save", this.formbodyToast)
        .then((res) => {
          console.log(res);
          if (res.data.status == "fail") {
            this.$message({
              showClose: true,
              message: `新增错误: \xa0 \xa0 ${res.data.msg}`,
              type: "error",
            });
          }
          this.getTeamList();
          this.dialogFormVisibleAdd = false;
          this.$refs.formbodyToast.resetFields();
        });
    },
    addBodyList() {
      this.dialogFormVisibleAdd = true;
    },
    addBodyMore() {
      this.dialogFormAddMore = true;
    },

    // 模板下载
    downMould() {
      this.axios
        .get("/business/admin/team/team/path")
        .then((res) => {
          if (res.data.status == "success") {
            console.log(res.data.data)
            window.location.href = res.data.data
          }
        });
    },
    //取消
    cacleDialog() {
      this.uploadForm = new FormData()
      this.fileName = ''
      this.dialogFormAddMore = false
    },
    //批量新增数据
    checkFile() {
      document.querySelector('#fileinput').click()
    },
    // 选择的文件
    checkFileSure() {
      this.fileName = document.querySelector('#fileinput').files[0].name;
      this.uploadForm.append('file', document.querySelector('#fileinput').files[0]);
    },

    //导入提交
    submitImport() {
      console.log(this.uploadForm)
      this.axios
        .post("/business/admin/team/team-import", (this.uploadForm))
        .then(res => {
          if (res.data.status == "success") {
            this.$notify({
              title: "导入成功",
              type: "success",
              duration: 5000,
            });
            this.dialogFormAddMore = false
          } else {
            this.$notify({
              title: "导入失败",
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
          }
        });
    },

    changeState(idForm, stateForm) {
      console.log(idForm, stateForm);
      this.axios
        .get("/business/admin/team/state", {
          params: {
            id: idForm,
            state: stateForm,
          },
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.$notify({
              title: "成功",
              message: "修改状态成功",
              type: "success",
              positon: "top-left",
              duration: 5000,
            });
          } else {
            this.$notify({
              title: "失败",
              message: "修改状态失败",
              type: "error",
              duration: 5000,
            });
          }
          console.log(res);
          //  if(res.data.status !=="success")  return
          this.getTeamList();
        });
    },
    getTeamList() {
      this.axios
        .get("/business/admin/team/team-info", {
          params: {
            teamName: this.formInline.input,
            state: this.formInline.value,
            page: this.pages.page,
            pageSize: this.pages.pageSize,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == "fail") {
            this.$router.push("/");
          } else {
            this.bodyTableData = res.data.data.list;
            this.total = res.data.data.total;
          }
        });
    },
  },
  mounted() {
    this.getTeamList();
  },
  // beforeRouteEnter(to,from,next){
  //   if(to.path=='/index')
  //    this.getTeamList();
  //    next();
  // }
  beforeRouteLeave(to, from, next) {
    if (
      to.name == "Index" ||
      to.name == "Exchange" ||
      to.name == "Userconf" ||
      to.name == "exConfiguration" ||
      to.name == "account" ||
      to.name == "riskLv" ||
      to.name == "exchangeObject"
    ) {
      this.getTeamList();
      this.input = "";
      this.value = "";
    }
    next();
  },
};
</script>

<style>
.team-page {
  /* position: fixed;
       bottom:2%;
       left:40%; */
  /* margin-right: 38.75rem; */
  margin-top: 1.25rem;
  text-align: center;
}


/* .el-button--info{
      margin-left:0.375rem
   }
   .el-button--warning{
       margin-left:0.375rem;
       width: 6.25rem;
   } */
.team-header {
  /* position:fixed;
      left:20%; */
  /* margin-top:-5px; */
  /* overflow-y: hidden; */
  width: 1000px;
  /* display: flex;
  margin-right: 50.625rem;
  margin-top: 1.25rem; */
}

</style>
