<!-- 系统管理 - 功能管理 -->
<template>
  <div class="function">
    <cloud-title title="功能管理" />
    <!-- input -->
    <div class="moduel-wrapper">
      <div class="form-wrapper">
        <!-- from -->
        <el-form :inline="true" class="form-inline" onsubmit="return false;">
          <el-select
            v-model="module.menuId"
            @change="menuChange"
            clearable
            placeholder="选择二级菜单："
          >
            <el-option
              v-for="(item, index) in secondMenu.list"
              :key="index"
              :label="item.menuName"
              :value="item.id"
            />
          </el-select>
          <el-select
            style="width: 300px;"
            v-model="module.functionName"
            :loading="isLoading"
            clearable
            placeholder="先选择旁边的二级菜单再选择功能"
          >
            <el-option
              v-for="(item, index) in secondMenuModule"
              :key="index"
              :label="item.name"
              :value="item.name"
            />
          </el-select>
          <!-- button -->
          <el-button
            @click="getFunctionList"
            class="blue-btn"
            icon="mr5 icon-cloud-search"
            >查询</el-button
          >
          <el-button
            @click="addBtn"
            class="black-btn"
            type="warning"
            icon="el-icon-zoom-in"
            >新增</el-button
          >
        </el-form>
      </div>

      <!-- table -->
      <el-table
        :data="dataList"
        stripe
        v-loading="listLoading"
        border
        style="width: 100%; margin-top: 20px;"
        height="380px"
      >
        <el-table-column
          align="center"
          prop="menuName"
          label="菜单名称"
          width="180"
        />
        <el-table-column align="center" prop="functionName" label="功能" />
        <el-table-column align="center" prop="requestType" label="请求类型" />
        <el-table-column align="center" prop="createTime" label="录入时间" />
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <a
              @click="handleUpdate(scope.$index, scope.row)"
              href="javascript:;"
              class="blueColor"
              >修改</a
            >
            <a
              @click="handleDelete(scope.row.id)"
              href="javascript:;"
              class="blueColor"
              >删除</a
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- pagination -->
      <cloud-pagination
        v-show="pageTotal"
        :total="pageTotal"
        :listQuery="listQuery"
        @currentChange="currentChange"
        @sizeChange="sizeChange"
      />

      <!-- add dialog -->
      <cloud-dialog
        width="38%"
        title="添加二级菜单功能模块"
        :visible.sync="showAddModuleForm"
      >
        <el-form
          :model="addModule"
          :label-width="formLabelWidth"
          :rules="addModuleFormRule"
          ref="addModuleForm"
        >
          <el-form-item
            label="选择二级菜单："
            :label-width="formLabelWidth"
            prop="menuId"
          >
            <el-select
              style="width: 100%;"
              v-model="addModule.menuId"
              placeholder="请选择二级菜单："
            >
              <el-option
                v-for="(item, index) in secondMenu.list"
                :key="index"
                :label="item.menuName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="功能名称：" prop="functionName">
            <el-input
              v-model="addModule.functionName"
              placeholder="请输入功能名称"
              auto-complete="off"
            />
          </el-form-item>
          <el-form-item label="功能代码：" prop="functionCode">
            <el-input
              v-model="addModule.functionCode"
              placeholder="请输入功能代码"
              auto-complete="off"
            />
          </el-form-item>
          <el-form-item label="路径：" prop="url">
            <el-input
              v-model="addModule.url"
              placeholder="请输入功能请求路径"
              auto-complete="off"
            />
          </el-form-item>
          <el-form-item label="选择请求类型：" prop="requestType">
            <el-select
              style="width: 100%;"
              placeholder="选择请求类型"
              v-model="addModule.requestType"
            >
              <el-option
                v-for="(item, index) in requestType"
                :key="index"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="描述：" prop="remark">
            <el-input
              v-model="addModule.remark"
              placeholder="请输入功能描述"
              auto-complete="off"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <button class="cancelBtn" @click="showAddModuleForm = false">
            取 消
          </button>
          <button class="confirmBtn" @click="saveAdd">确 定</button>
        </div>
      </cloud-dialog>

      <!-- update dialog -->
      <cloud-dialog
        width="38%"
        title="修改二级菜单功能模块"
        :visible.sync="showUpdateModuleForm"
      >
        <el-form
          :model="updateModule"
          :rules="addModuleFormRule"
          :label-width="formLabelWidth"
          ref="updateModuleForm"
        >
          <el-form-item label="选择二级菜单：" prop="menuId">
            <el-select
              style="width: 100%;"
              v-model="updateModule.menuId"
              placeholder="请选择二级菜单："
            >
              <el-option
                v-for="(item, index) in secondMenu.list"
                :key="index"
                :label="item.menuName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="功能名称：" prop="functionName">
            <el-input
              v-model="updateModule.functionName"
              placeholder="请输入功能名称"
              auto-complete="off"
            />
          </el-form-item>
          <el-form-item label="功能代码：" prop="functionCode">
            <el-input
              v-model="updateModule.functionCode"
              placeholder="请输入功能代码"
              auto-complete="off"
            />
          </el-form-item>
          <el-form-item label="路径：" prop="url">
            <el-input
              v-model="updateModule.url"
              placeholder="请输入功能请求路径"
              auto-complete="off"
            />
          </el-form-item>
          <el-form-item label="选择请求类型：" prop="requestType">
            <el-select
              style="width: 100%;"
              placeholder="请选择请求类型"
              v-model="updateModule.requestType"
            >
              <el-option
                v-for="(item, index) in requestType"
                :key="index"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="描述：" prop="remark">
            <el-input
              v-model="updateModule.remark"
              placeholder="请输入描述"
              auto-complete="off"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <button class="cancelBtn" @click="showUpdateModuleForm = false">
            取消修改
          </button>
          <button class="confirmBtn" @click="saveUpdate">确定修改</button>
        </div>
      </cloud-dialog>

      <cloud-dialog
        title="温馨提示"
        :visible.sync="showConfirm"
        v-on:onclose="showConfirm = false"
      >
        <div class="tc" style="padding: 30px 0;">
          <p>确定删除所选择的内容吗?</p>
        </div>
        <div slot="footer" class="dialog-footer">
          <button class="cancelBtn" @click="showConfirm = false">取 消</button>
          <button class="confirmBtn" @click="sureDelFunction">确定</button>
        </div>
      </cloud-dialog>
    </div>
  </div>
</template>

<script>
// import Vue from "vue";
import SystemManage from "../../common/utils/api/system";
import PublicMixin from "../mixins/index.js";
// import { Component } from 'vue-property-decorator';

// @Component({
//   name: 'FunctionManage'
// })
export default {
  // ...PublicMixin,
  mixins: [PublicMixin],
  data() {
    return {
      module: {
        // 页面搜索，部门下拉框
        menuId: "",
        functionName: "",
      },
      requestType: ["GET", "POST", "PUT", "DELETE"],
      addModule: {
        // 添加功能模块
        menuId: "",
        functionName: "",
        remark: "",
        requestType: "",
        functionCode: "",
        url: "",
      },
      updateModule: {
        menuName: "",
        menuId: "",
        functionName: "",
        requestType: "",
        remark: "",
        functionCode: "",
        url: "",
      },
      isLoading: false,
      showAddModuleForm: false, // add dialog
      showUpdateModuleForm: false, // update dialog
      addModuleFormRule: {
        // add rule
        menuId: [
          { required: true, message: "请选择二级菜单", trigger: "blur" },
        ],
        functionName: [
          { required: true, message: "请输入功能名称", trigger: "blur" },
        ],
        requestType: [
          { required: true, message: "请选择功能类型", trigger: "blur" },
        ],
        functionCode: [
          { required: true, message: "请输入功能代码", trigger: "blur" },
        ],
        url: [{ required: true, message: "请输入路径称", trigger: "blur" }],
      },
      formLabelWidth: "140px",
      secondMenu: [], // 二级菜单列表
      secondMenuModule: [], // 二级菜单对应的功能模块
      // $refs: {
      //   addModuleForm: HTMLFormElement,
      //   updateModuleForm: HTMLFormElement
      // },
      showConfirm: false,
      functionId: "",

      btns: {
        insert: 0,
        update: 0,
        delete: 0,
        select: 0,
      },
    };
  },
  methods: {
    // 打开新增弹窗-按钮
    addBtn() {
      this.showAddModuleForm = true;
      this.$refs.addModuleForm.resetFields();
    },

    // 保存新增
    saveAdd() {
      this.$refs.addModuleForm.validate((valid) => {
        if (valid) {
          SystemManage.addFunction(this.addModule, "Object").then((res) => {
            this.operTips(res, () => {
              this.$refs.addModuleForm.resetFields();
              this.showAddModuleForm = false;
              this.getFunctionList();
            });
          });
        } else {
          return false;
        }
      });
    },
    // 搜索条件菜单下拉列表
    menuChange() {
      this.isLoading = true;
      this.module.functionName = "";
      this.getFunctionByMenuId();
    },

    // 根据菜单id获取功能列表
    getFunctionByMenuId() {
      SystemManage.getFunctionByMenuId({ menuId: this.module.menuId }).then(
        (res) => {
          this.secondMenuModule = res.data;
          this.isLoading = false;
        }
      );
    },

    // 获取功能列表
    getFunctionList(flag = true) {
      if (flag) {
        this.listQuery.page = 1;
        this.listQuery.pageSize = 10;
      }
      const params = Object.assign({}, this.module, this.listQuery);
      this.listLoading = true;
      SystemManage.getFunctionList(params).then((res) => {
        this.listLoading = false;
        if (res.status === "success") {
          this.pageTotal = res.data.total; // 设置总条数
          this.dataList = res.data.list;
        } else {
          this.getListError();
        }
      });
    },
    currentChange(page) {
      this.listQuery.page = page;
      this.getFunctionList(false);
    },

    sizeChange(pageSize) {
      this.listQuery.pageSize = pageSize;
      this.getFunctionList(false);
    },

    // 功能修改
    handleUpdate(index) {
      this.showUpdateModuleForm = true;
      // this.updateModule = row;
      const item = this.dataList[index];
      this.updateModule = {
        id: item.id,
        menuName: item.menuName,
        menuId: item.menuId,
        functionName: item.functionName,
        requestType: item.requestType,
        remark: item.remark,
        functionCode: item.functionCode,
        url: item.url,
      };
      window.console.log(this.updateModule);
    },

    handleDelete(functionId) {
      this.functionId = functionId;
      this.showConfirm = true;
    },

    sureDelFunction() {
      SystemManage.delFunctionById({ id: this.functionId }).then((res) => {
        this.operTips(res, () => {
          this.getFunctionList();
        });
      });
    },

    // 保存修改
    saveUpdate() {
      this.$refs.updateModuleForm.validate((valid) => {
        if (valid) {
          // 对象解构
          // let {id: menuId, modelCode, functionName, remark, url} = this.updateModule;
          // let menuId = this.updateModule.id;
          // let {id, modelCode, functionName, remark, url} = this.updateModule;
          window.console.log("before update...");
          SystemManage.updateFunctionById(this.updateModule, "Object").then(
            (res) => {
              this.operTips(res, () => {
                this.showUpdateModuleForm = false;
                this.getFunctionList();
              });
            }
          );
        } else {
          return false;
        }
      });
    },
  },

  created() {
    // window.console.log(this.module);
    // 根据菜单等级获取所有菜单，等级不传获取所有菜单，默认二级菜单
    SystemManage.getMenuByLevel(2).then((res) => {
      window.console.log(res.data);
      this.secondMenu = res.data;
    });
    this.getFunctionList();
    // window.console.log(this.module);
  },
};
</script>
