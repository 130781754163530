/*
 * @Author: chengengfeng
 * @Date: 2019-07-24 16:14:15
 * @Last Modified by: chengengfeng
 * @Last Modified time: 2019-07-26 15:37:57
 * @Description: vuex mutation types
*/
// 用户token
export const SET_TOKEN = 'SET_TOKEN';
// 用户名
export const SET_USER_NAME = 'SET_USER_NAME';
// 路由渲染标识
export const SET_ISOK = 'SET_ISOK';
// 设置可访问的路由
export const SET_ROUTERS = 'SET_ROUTERS';
// 设置存储异步返回的路由
export const SET_ASYNCROUTER = 'SET_ASYNCROUTER';
// 导出loading标识
export const SET_EXPORT_LOADING = 'SET_EXPORT_LOADING';

// 设置当前激活路由
export const SET_CUR_ROUTE_ACTIVE = 'SET_CUR_ROUTE_ACTIVE';
export const SET_ACTION_ARRAY = 'SET_ACTION_ARRAY';
