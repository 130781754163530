/*
 * @Author: chengengfeng
 * @Date: 2019-07-29 17:07:02
 * @Last Modified by: chengengfeng
 * @Last Modified time: 2020-03-18 11:04:04
 * @Description: 系统管理
*/
import BaseRequest from '../fetch';
const Api = new BaseRequest()
export default {

  /* ******************* 量化主页-跟单 start ****************** */
  // 获取数据
  getDataList(params) {
    return Api.fetchDelOrGet('/business/admin/followGroup/getFollowGroupHomeList', params);
  },
  // 修改杠杆比例
  upDataFollow(...options) {
    return Api.fetch('post', '/business/admin/followGroup/updateFollowLeverageRatio', ...options);
  },
  // 停止跟单
  runStop(...options) {
    return Api.fetch('post', '/business/admin/followGroup/updateFollowGroupByIsRunStop', ...options);
  },
  // 开始跟单
  runStart(...options) {
    return Api.fetch('post', '/business/admin/followGroup/updateFollowGroupByIsRunStart', ...options);
  },
  // 正反跟单修改
  upDataDirection(...options) {
    return Api.fetch('post', '/business/admin/followGroup/updateFollowIsDirection', ...options);
  },
  // 小单测试
  accountOrder(...options) {
    return Api.fetch('post', '/business/admin/followGroup/followAccountOrderTest', ...options);
  },
  // 启动脚本
  startScript(...options) {
    return Api.fetch('post', '/business/admin/followGroup/startFollowScript', ...options);
  },
  // 停止脚本
  stopScript(...options) {
    return Api.fetch('post', '/business/admin/followGroup/stopFollowScript', ...options);
  },
  // 数据统计开关
  dataStatist(...options) {
    return Api.fetch('post', '/business/admin/followGroup/switchDataStatistics', ...options);
  },
  // 获取跟单比例列表
  getScaleList(options) {
    return Api.fetchDelOrGet('/business/admin/followGroup/getScaleDisposeList', options);
  },
  // 新增动态跟单比例
  addSwitchScale(...options) {
    return Api.fetch('post', '/business/admin/followGroup/batchAddScaleDispose', ...options);
  },
  // 设置动态跟单比例
  switchScale(...options) {
    return Api.fetch('post', '/business/admin/followGroup/startSwitchScale', ...options);
  },
  // 获取密码
 getPassWord(...options) {
    return Api.fetch('post', '/business/admin/dictionary/getBusinessDictionaryByCode', ...options);
  },
  // 修改密码
  setPassWord(...options) {
    return Api.fetch('post', '/business/admin/dictionary/updateBusinessDictionaryById', ...options);
  },
  // 校验服务商机器人密码
  checkRobotPwd(...options) {
    return Api.fetch('post', '/business/admin/followGroup/groupRobotPlatePwd', ...options);
  },
  // 服务商机器人启动开关
  switchRobot(...options) {
    return Api.fetch('post', '/business/admin/followGroup/startSwitchRobot', ...options);
  },
  // 服务商机器人下单
  robotOrder(...options) {
    return Api.fetch('post', '/business/admin/followGroup/placeBoosRobotOrder', ...options);
  },
  // 校验一键结算密码
  checkCurryPwd(...options) {
    return Api.fetch('post', '/business/admin/followGroup/groupSettlementPwd', ...options);
  },
  // 一键结算功能
  currySubmit(...options) {
    return Api.fetch('post', '/business/admin/followGroup/groupSettlementOneTouch', ...options);
  },
  // 一键平仓功能
  touchSubmit(...options) {
    return Api.fetch('post', '/business/admin/followGroup/groupOneTouchBossPosition', ...options);
  },
  // 获取所有异常订单
  getAllOrder(options) {
    return Api.fetchDelOrGet('/business/admin/followGroup/getFollowGroupExceptionList', options);
  },
  // 处理异常
  updateException(...options) {
    return Api.fetch('post', '/business/admin/followGroup/updateExceptionById', ...options);
  },
  // 获取操作日志
  getOperationLog(params) {
    return Api.fetchDelOrGet('/business/admin/followGroup/getFollowGroupLogList', params);
  },
  // 新增跟单组合
  saveFollowGroup(...options) {
    return Api.fetch('post', '/business/admin/followGroup/saveFollowGroup', ...options);
  },
  // 跟单组合配置列表
  getFollowGroup(options) {
    return Api.fetchDelOrGet('/business/admin/followGroup/getFollowGroupByPageInfo', options);
  },
  // 获取交易所列表
  getExchange(options) {
    return Api.fetchDelOrGet('/business/admin/exchange/exchange-infos', options);
  },
  // 获取交易所列表
  updateProportion(...options) {
    return Api.fetch('post', '/business/admin/followGroup/updateProportion', ...options);
  },
  // 获取跟单组合
  getGroup(options) {
    return Api.fetchDelOrGet('/business/admin/followGroup/getFollowGroupById', options);
  },
  // 停止跟单开关
  saveFollowMenu(...options) {
    return Api.fetch('post', '/business/admin/followGroup/saveCeaseFundSwitch', ...options);
  },
  // 查询划账跟单
  getAccountGroup(options) {
    return Api.fetchDelOrGet('/business/admin/followGroup/getGroupTransferControl', options);
  },
  // 保存划账跟单
  saveAccountMenu(...options) {
    return Api.fetch('post', '/business/admin/followGroup/saveGroupTransferControl', ...options);
  },
  // 修改跟单组合
  updateFollowGroup(...options) {
    return Api.fetch('post', '/business/admin/followGroup/updateFollowGroup', ...options);
  },
  // 获取当前仓位
  getRetain(options) {
    return Api.fetchDelOrGet('/business/admin/position/retain', options);
  },
  // 获取历史仓位
  getHistory(options) {
    return Api.fetchDelOrGet('/business/admin/position/history', options);
  },
  // 交易日志
  getTradeLog(options) {
    return Api.fetchDelOrGet('/business/admin/position/tradeLog', options);
  },
  // 检查账户是否重复
  getCheckAccount(options) {
    return Api.fetchDelOrGet('/business/admin/followGroup/checkAccount', options);
  },
  /* ******************* 用户组/角色 start ****************** */
  roleUrl: '/business/admin/system/role',
  // 获取用户组/角色列表
  getRoleList(params) {
    return Api.fetchDelOrGet(`${this.roleUrl}s`, params);
  },
  // 角色管理-删除角色
  delRoleById(params) {
    return Api.fetchDelOrGet(this.roleUrl, params, 'delete');
  },
  // 角色管理-新增角色
  addRole(...options) {
    return Api.fetch('post', this.roleUrl, ...options);
  },
  // 角色管理-修改角色
  updateRoleById(...options) {
    return Api.fetch('put', this.roleUrl, ...options);
  },
  // 角色管理-获取所有角色列表
  getAllRoles() {
    return Api.fetchDelOrGet('/business/admin/system/authority-role-roles');
  },
  // 获取账户列表
  getAccountSelectList() {
    return Api.fetchDelOrGet('/business/admin/followGroup/getAccountSelectList');
  },

  /* ******************* 用户组/角色 end ****************** */

  /* ******************* 菜单管理 start ****************** */
  menuUrl: '/business/admin/system/menu',
  // 菜单管理-查询菜单
  getMenuList(params) {
    return Api.fetchDelOrGet(`${this.menuUrl}s`, params);
  },
  // 菜单管理-删除菜单
  delMenuById(params) {
    return Api.fetchDelOrGet(this.menuUrl, params, 'delete');
  },
  // 菜单管理-新增菜单
  addMenu(...options) {
    return Api.fetch('post', this.menuUrl, ...options);
  },
  // 菜单管理-修改菜单
  updateMenuById(...options) {
    return Api.fetch('put', this.menuUrl, ...options);
  },
  // 根据菜单等级获取所有菜单，等级不传获取所有菜单
  getMenuByLevel(levelNum = 1) { // 获取所有一级菜单
    return Api.fetchDelOrGet(`${this.menuUrl}-level`, { levelNum });
  },
  /* ******************* 菜单管理 end ****************** */

  /* ******************* 功能管理 start ****************** */
  functionUrl: '/business/admin/system/function',
  // 功能管理-查询功能
  getFunctionList(params) {
    return Api.fetchDelOrGet(`${this.functionUrl}s`, params);
  },
  // 功能管理-删除功能
  delFunctionById(params) {
    return Api.fetchDelOrGet(this.functionUrl, params, 'delete');
  },
  // 功能管理-新增功能
  addFunction(...options) {
    return Api.fetch('post', this.functionUrl, ...options);
  },
  // 功能管理-修改功能
  updateFunctionById(...options) {
    return Api.fetch('put', this.functionUrl, ...options);
  },
  // 功能管理-根据菜单ID查询功能
  getFunctionByLevel(levelNum = 1) {
    return Api.fetchDelOrGet(`${this.functionUrl}-bymenuid`, { levelNum });
  },
  // 菜单管理-根据二级菜单ID获取菜单的功能权限
  getFunctionByMenuId(params) {
    return Api.fetchDelOrGet(`/business/admin/system/menu-model`, params);
  },
  /* ******************* 功能管理 end ****************** */

  /* ******************* 用户组管理 - 权限分配 end ****************** */
  // 权限管理-功能分配-获取菜单和功能列表
  getAllMenuAndModuel() {
    return Api.fetchDelOrGet(`/business/admin/system/authority-function-functions`);
  },
  // 权限管理-功能分配-根据角色ID获取菜单和功能ID列表
  getModuelByRoleId(params) {
    return Api.fetchDelOrGet(`/business/admin/system/authority-function-roleFunction`, params);
  },
  // 权限管理-功能分配-修改角色菜单和功能
  updateRoleModuel(...options) {
    return Api.fetch('put', '/business/admin/system/authority-function-updateRoleFunction', ...options);
  },
  /* ******************* 用户组管理 - 权限分配 end ****************** */

  /* ******************* 系统管理 - 版本管理 start ****************** */
  // 系统管理-版本列表
  getVersionList(params) {
    return Api.fetchDelOrGet(`/business/admin/system/versions`, params);
  },
  // 系统管理-版本详情
  getVersionById(params) {
    return Api.fetchDelOrGet(`/business/admin/system/version`, params);
  },
  // 系统管理-新增版本
  addVersion(...options) {
    return Api.fetch('post', '/business/admin/system/version', ...options);
  },
  // 系统管理-修改版本
  updateVersion(...options) {
    return Api.fetch('put', '/business/admin/system/version', ...options);
  },
  // 系统管理-删除版本（逻辑删除）
  delVersionById(versionId) {
    return Api.fetch('put', '/business/admin/system/version/update_valid', { versionId }, 'String');
  },
  // 系统管理-获取自动划账配置信息
  getNetMul(params) {
    return Api.fetchDelOrGet(`/business/admin/index/getNetMul`, params);
  },
  // 系统管理-修改自动划账配置信息
  updateNetMul(...options) {
    return Api.fetch('put', '/business/admin/index/updateNetMul', ...options);
  },
  /* ******************* 系统管理 - 版本管理 end ****************** */

  /* ******************* 系统管理 - 体验时长设置 start ****************** */
  // 系统管理 - 查询体验时长
  getExperience() {
    return Api.fetchDelOrGet(`/business/admin/system/setting/experience`);
  },
  // 系统管理 - 修改体验时长
  updateExperience(duration) {
    return Api.fetch('put', '/business/admin/system/setting/experience', { duration }, 'String');
  },
  /* ******************* 系统管理 - 体验时长设置 end ****************** */

  /* ******************* 系统管理 - 操作日志 start ****************** */
  // 后台操作日志列表
  getAdminOperList(params) {
    return Api.fetchDelOrGet(`/business/admin/operation/log/admins`, params);
  },
  // 用户操作日志列表
  getUserOperList(params) {
    return Api.fetchDelOrGet(`/business/admin/operation/log/users`, params);
  },
  /* ******************* 系统管理 - 操作日志 end ****************** */

  /* ******************* 系统管理 - 登录日志 start ****************** */
  // 后台登录日志列表
  getAdminLoginList(params) {
    return Api.fetchDelOrGet(`/business/admin/login/log/admins`, params);
  },
  // 用户登录日志列表
  getUserLoginList(params) {
    return Api.fetchDelOrGet(`/business/admin/login/log/users`, params);
  },
  /* ******************* 系统管理 - 登录日志 end ****************** */

  /* ******************* 系统管理 - 用户反馈 start ****************** */
  // 用户反馈-删除
  delFeedbackById(params) {
    return Api.fetchDelOrGet('/business/admin/feedback/delete', params, 'delete');
  },
  // 用户反馈详情
  getFeedbackById(feedbackId) {
    return Api.fetchDelOrGet('/business/admin/feedback/detail', { id: feedbackId });
  },
  // 用户反馈列表
  getFeedbackList(params) {
    return Api.fetchDelOrGet('/business/admin/feedback/list', params);
  },
  // 用户反馈-回复
  replyFeedbackById(params) {
    return Api.fetch('put', '/business/admin/feedback/reply', params, 'String');
  }

  /* ******************* 系统管理 - 用户反馈 end ****************** */
}

// export default new SystemManage();


// export default new SystemManage();
// import BaseRequest from '../fetch';

// class SystemManage {

//   role() {
//     return new RoleManage().getUserInfos();
//   }

// };

// class RoleManage extends BaseRequest {
//   getUserInfos() {
//     // // fix: no token after axios instantiation
//     // BaseRequest.fetchDelOrGet(`${this.prefixURL}/user/info`);
//     window.console.log('six six six');
//     return BaseRequest.fetchDelOrGet(`/business/admin/system/user/info`);
//     // 调用
//     // SystemManage.role().then(res => {
//     //   window.console.log(res);
//     // })
//   }
// }

