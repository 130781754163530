/*
 * @Author: chengengfeng
 * @Date: 2019-07-23 16:52:34
 * @Last Modified by: chengengfeng
 * @Last Modified time: 2020-03-18 15:08:05
 */
import axios from "axios";
import store from "../../store";
import DH from "./download-helper";
import { qs } from "./query-string";
import {
  // reLoad,
  isEmpty,
  setQueryConfig,
} from "./index";
import { getToken } from "./auth";
import { MessageBox, Notification } from "element-ui";
// import {
//   AxiosResponse,
//   AxiosRequestConfig,
// } from 'axios';

const isDev = process.env.NODE_ENV !== "production";

class BaseRequest {

  constructor() {
    this.instance = null;
    this._init();
  }

  _init() {
    isDev ? window.console.log("axios is default init...") : null;
    this.instance = axios.create();

    // 允许存储 cookies
    this.instance.defaults.withCredentials = true;
    console.log(process.env);
    // this.instance.defaults.baseURL = process.env.VUE_APP_BASE_API;

    // 请求超时时长
    this.instance.defaults.timeout = 0;

    this._setHeader(
      "Content-Type",
      "application/x-www-form-urlencoded;charset=utf-8",
      "post"
    );

    this._setHeaderFiled("token", getToken() || "");

    this._injectRequestHook((config) => {
      if (isDev) {
        this._showUrlAndParams(config);
      }
      return config;
    });

    this._injectResponseHook(
      (response) => {
        return response;
      },
      (error) => {
        window.console.log("err", error); // for debug
        Notification({
          title: "",
          message: "请求出错，稍后重试",
          type: "error",
          offset: 100,
          duration: 2 * 1000,
        });
        return Promise.reject(error);
      }
    );
  }

  /**
   * 附加设置request headers
   * @param {String} key header的key
   * @param {String} value header的value
   * @param {String} requestType 请求类型
   * axios.defaults.headers['token'] = '';
   */
  _setHeader(key, value, requestType) {
    if (key && value) {
      this.instance.defaults.headers[requestType || "common"][key] = value;
    }
  }

  /**
   * 添加请求头token
   * @param {*} value token
   */
  _setHeaderFiled(filed, value) {
    if (!isEmpty(value)) {
      /* tslint:disable:no-string-literal */
      this.instance.defaults.headers[filed] = value;
      /* tslint:enable:no-string-literal */
    }
  }

  /**
   * 注入请求钩子函数
   * @param {*} hookFunction 回调
   */
  _injectRequestHook(hookFunction) {
    this.instance.interceptors.request.use((config) => {
      if (typeof hookFunction === "function") {
        return hookFunction(config);
      }
    });
  }

  /**
   * 注入请求响应钩子函数
   * @param {*} resolveHook resolveHook 成功钩子函数
   * @param {*} rejectHook rejectHook 失败钩子函数
   */
  _injectResponseHook(resolveHook, rejectHook) {
    this.instance.interceptors.response.use(
      (response) => {
        if (typeof resolveHook === "function") {
          if (
            response.headers &&
            response.headers["content-type"] ===
            "application/x-msdownload;charset=utf-8"
          ) {
            const config = response.config;
            if (config.method === "get") {
              DH.downloadCSV(response);
            }
            return;
          }

          const returnValue = resolveHook(response);
          if (typeof returnValue !== "undefined") {
            isDev ? window.console.log("我是响应数据：", returnValue) : "";
            if (returnValue && returnValue.data.code) {
              switch (returnValue.data.code) {
                case -1: // 链接无token
                  MessageBox.confirm("请重新登录！", "操作提示", {
                    confirmButtonText: "重新登录",
                    showClose: false,
                    closeOnClickModal: false,
                    showCancelButton: false,
                    type: "warning",
                  }).then(() => {
                    // reLoad();
                    store.dispatch("user/fedLogOut").then(() => {
                      window.location.reload();
                    });
                  });
                  break;
                case -2: // 当前账户已失效，请重新登录
                  MessageBox.confirm(returnValue.data.msg, "提示", {
                    confirmButtonText: "重新登录",
                    showClose: false,
                    closeOnClickModal: false,
                    showCancelButton: false,
                    type: "warning",
                  }).then(() => {
                    store.dispatch("user/fedLogOut").then(() => {
                      window.location.reload();
                    });
                  });
                  break;
              }
            }
            return returnValue;
          }
        }
      },
      (error) => {
        if (typeof rejectHook === "function") {
          const returnValue = rejectHook(error);
          if (typeof returnValue !== "undefined") {
            return Promise.reject(returnValue);
          }
        }
      }
    );
  }

  /**
   * 打印请求参数
   */
  _showUrlAndParams(config) {
    window.console.log("=========================");
    window.console.log("request url: ", config.url);
    window.console.log("request params: ");
    window.console.log("params: ", config.params);
    window.console.log("data: ", config.data);
    window.console.log("=========================");
  }

  /**
   * 发送get或者delete请求
   * @param {*} url 请求地址
   * @param {*} params 请求参数
   * @param {*} type 请求请求类型，默认 get
   * @param {*} options[0] 是否请求验证码图片
   * @param {*} options[1] 直接设置request属性
   * @returns {Promise} 返回Ajax请求Promise对象
   * isCode = false, config = {}
   */
  fetchDelOrGet(url, params, type = "get", ...options) {
    if (!options[0]) {
      return new Promise((resolve, reject) => {
        this.instance[type](url, { params })
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        this.instance[type](url, { ...options[1] })
          .then((res) => {
            return (
              "data:image/png;base64," +
              btoa(
                new Uint8Array(res.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              )
            );
          })
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  /**
   * 自定义请求类型
   * @param {*} type 请求类型
   * @param {*} url 请求URL
   * @param {*} options 0：接口所需参数
   * @param {*} options 1：发送的数据的格式类型：String-->json字符串形式，Object-->对象形式
   * @param {*} options 2：是否需要在URL后面拼接参数，默认不需要(不传)
   * @returns {Promise} 返回Ajax请求Promise对象
   * eg: {page: 1, pageSize: 10} --> url?page=1&pageSize=10
   */
  fetch(type, url, ...options) {
    url = !isEmpty(options[2]) ? url + setQueryConfig(options[2]) : url;
    const params =
      options[1] === "String" ? qs.stringify(options[0]) : options[0];
    return new Promise((resolve, reject) => {
      this.instance[type](url, params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * 导出请求
   * @param {*} url 请求URL
   * @param {*} params 请求参数
   * @param {*} querys 是否需要在URL后面拼接参数
   */
  exportToExcel(url, params, querys) {
    // debugger;
    if (!isEmpty(querys)) {
      url = url + setQueryConfig(querys);
    }
    isDev ? window.console.log("export..", url, params, querys) : null;
    return new Promise((resolve, reject) => {
      this.instance
        .get(
          url,
          { params },
          {
            responseType: "arraybuffer",
          }
        )
        .then((res) => {
          // debugger;
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default BaseRequest;
