// 交易所组合配置
<template>
  <div class="exchange123">
    <!-- 交易所组合下拉菜单 -->
    <div class="exchange-header">
      <el-select
        v-model="valueEXchange"
        clearable
        placeholder="交易所组合"
        @clear="clearSearch"
      >
        <el-option
          v-for="item in optionsExchange"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>

      <el-button type="primary" icon="el-icon-search" @click="exchangeSearch"
        >搜索</el-button
      >

      <el-button
        type="warning"
        icon="el-icon-zoom-in"
        @click="addEXchange"
        >新增</el-button
      >
    </div>

    <!-- 表格 -->
    <div class="table-container">
      <el-table :data="tableData" border style="overflow-y: auto">
        <el-table-column prop="team_name" label="团队">
          <template slot-scope="scope">
            <div v-html="scope.row.team_name"></div>
          </template>
        </el-table-column>
        <el-table-column prop="combinationName" label="交易所组合">
        </el-table-column>
        <el-table-column prop="ruleType1" label="风控1：净值回撤参数">
          <template slot-scope="scope">
            <div v-html="scope.row.ruleType1"></div>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="ruleType2" label="风控2：交易品种限制参数">
          <template slot-scope="scope">
            <div v-html="scope.row.ruleType2"></div>
          </template>
        </el-table-column>

        <el-table-column prop="ruleType3" label="风控3：交易频率限制参数">
          <template slot-scope="scope">
            <div v-html="scope.row.ruleType3"></div>
          </template>
        </el-table-column> -->

        <el-table-column prop="ruleType4" label="风控4：自动划账参数">
          <template slot-scope="scope">
            <div v-html="scope.row.ruleType4"></div>
          </template>
        </el-table-column>

        <el-table-column prop="state" label="状态" width="100">
          <template slot-scope="scope">
            <div>
              {{ scope.row.state == 1 ? "启用" : "停用" }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="opration" label="操作" min-width="100">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="exchangeEditor(scope.row, scope.$index)"
              >【修改】</el-button
            >
            <el-button
              type="text"
              @click="exchangeChangeStatus(scope.row.id, 1)"
              v-if="scope.row.state == 0"
              >【启用】</el-button
            >
            <el-button
              type="text"
              @click="exchangeChangeStatus(scope.row.id, 0)"
              v-else
              >【停用】</el-button
            ><br>
            <el-button type="text" @click="exchangeChangeHistory(scope.row.id)"
              >【修改历史】</el-button
            >
            <el-popconfirm
              title="是否确定删除？"
              style="margin-left: 10px"
              @confirm="DelExchangeChange(scope.row.id)"
            >
              <el-button slot="reference" type="text">【删除】</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- footer标签 -->

    <div class="pagi">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageList.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageList.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 新增弹窗页面 -->
    <div class="addToastClass">
      <el-dialog
        title="交易所组合配置"
        :visible.sync="dialogFormVisibleHeader"
        class="headerDialog"
        :modal-append-to-body="false"
        center
      >
        <el-form ref="outerEditor" :model="outerEditor" :rules="formAddRules">
          <el-form-item prop="teamId" label="团队">
            <el-select
              v-model="outerEditor.teamId"
              clearable
              placeholder="请选择"
              class="teamHeaderSelect"
            >
              <el-option
                v-for="item in teamNameFromServer"
                :key="item.id"
                :label="item.teamName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="组合类型" prop="businessType">
            <el-select
              v-model="outerEditor.businessType"
              placeholder="全部"
              clearable
            >
              <el-option label="服务商" :value="1"></el-option>
              <el-option label="跟单" :value="2"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            class="exchange-two"
            label="交易所组合"
            prop="businessConfigCombinations"
          >
            <el-checkbox-group
              v-model="outerEditor.AddbusinessConfigCombinations"
              style="display: flex; flex-direction: column"
            >
              <el-checkbox
                v-for="(item, index) in exchangeInfosList"
                :key="index"
                :label="item.id"
                style="margin-bottom: 10px"
                >{{ item.exchangeName }}
                <!-- style="margin-bottom: 10px" -->
                <div class="exchange_portfolio-select">
                  <el-select
                    v-model="item.test"
                    clearable
                    placeholder="请选择"
                    style="width: 400px"
                    multiple
                    collapse-tags
                  >
                    <el-option
                      v-for="item in item.secretMapList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- 风控1 -->
          <div class="risk1">
            <span class="risk-one-title">风控1：净值回撤参数</span>
            <el-form-item label="风控阈值" prop="threshold">
              <el-input
                style="width: 210px"
                placeholder="请输入风控阈值"
                v-model="outerEditor.businessConfigRules[0].threshold"
              ></el-input>
            </el-form-item>

            <el-form-item label="初始资产" prop="initialAssets">
              <el-input
                disabled
                style="width: 210px"
                placeholder="请输入初始资产"
                v-model="outerEditor.businessConfigRules[0].initialAssets"
              ></el-input>
            </el-form-item>
            <el-form-item prop="assetsUnit" label="资产单位">
              <el-select
                v-model="outerEditor.businessConfigRules[0].assetsUnit"
                clearable
                placeholder="请选择资产单位"
              >
                <el-option
                  v-for="item in riskunitList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <div class="risklevel-foot">
              <!-- <span class="riskLever-one">风控等级</span> -->
              <el-form-item prop="grade1" label="风控等级">
                <el-select
                  v-model="outerEditor.businessConfigRules[0].grade"
                  clearable
                  placeholder="请选择风控等级"
                >
                  <el-option
                    v-for="item in riskLeveloptions1"
                    :key="item.id"
                    :label="item.grade"
                    :value="item.grade"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-form-item prop="alarmThreshold" label="预警阈值">
              <el-input
                clearable
                style="width: 210px"
                placeholder="请输入预警阈值"
                v-model="outerEditor.businessConfigRules[0].alarmThreshold"
              ></el-input>
            </el-form-item>
          </div>
          <!-- <div class="risk2">
            <span class="risk-two-title">风控2：交易品种限制参数</span>
            <el-form-item label="可交易品种" prop="varietieList">
              <br />
              <el-checkbox-group
                v-model="outerEditor.businessConfigRules[1].varietieList"
                class="checkBoxList"
              >
                <el-checkbox
                  v-for="item in checkBoxfromServer"
                  :key="item.id"
                  :label="item.varietiesName"
                  style="width: 120px; margin: 0 32px"
                ></el-checkbox>
              </el-checkbox-group>
            </el-form-item>

            <div class="two">
              <el-form-item label="风控等级" prop="grade2">
                <el-select
                  v-model="outerEditor.businessConfigRules[1].grade"
                  clearable
                  placeholder="请选择风控等级"
                >
                  <el-option
                    v-for="item in riskLeveloptions2"
                    :key="item.id"
                    :label="item.grade"
                    :value="item.grade"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-form-item label="可交易业务" prop="businessLineList">
              <el-checkbox-group
                v-model="outerEditor.businessConfigRules[1].businessLineList"
              >
                <el-checkbox label="spot"></el-checkbox>
                <el-checkbox label="margin"></el-checkbox>
                <el-checkbox label="swap"></el-checkbox>
                <el-checkbox label="futures"></el-checkbox>
                <el-checkbox label="option"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </div> -->

          <!-- <div class="risk3">
            <span class="riskLever-three">风控3：交易频率限制参数</span>
            <el-form-item label="限制" prop="secondsTotal">
              允许<el-input
                v-model="outerEditor.businessConfigRules[2].seconds"
                style="width: 100px; margin: 0 10px"
              ></el-input
              >秒内交易
              <el-input
                v-model="outerEditor.businessConfigRules[2].total"
                style="width: 100px; margin: 0 10px"
              ></el-input
              >单
            </el-form-item>
            <el-form-item label="风控等级" prop="grade3">
              <el-select
                v-model="outerEditor.businessConfigRules[2].grade"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in riskLeveloptions3"
                  :key="item.id"
                  :label="item.grade"
                  :value="item.grade"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div> -->
          <!-- 风控4 -->
          <div class="risk3">
            <span class="riskLever-three">风控4：自动划账参数</span>
            <el-form-item label="本金初始值" prop="initialAssets">
              <el-input
                style="width: 210px;margin-left:5px"
                placeholder="请输入本金初始值"
                v-model="outerEditor.businessConfigRules[3].initialAssets"
              ></el-input>
            </el-form-item>
            <el-form-item label="自动划账阈值" prop="paddleThreshold">
              <el-input
                style="width: 210px"
                placeholder="请输入自动划账阈值"
                v-model="outerEditor.businessConfigRules[3].paddleThreshold"
              ></el-input>
            </el-form-item>
            <el-form-item label="划账资金" prop="paddleAssets">
              <el-input
                style="width: 210px;margin-left:28px"
                placeholder="请输入划账资金"
                v-model="outerEditor.businessConfigRules[3].paddleAssets"
              ></el-input>
            </el-form-item>
            <el-form-item label="自动划账次数" prop="paddleCount">
              <el-input
                style="width: 210px"
                placeholder="请输入自动划账次数"
                v-model="outerEditor.businessConfigRules[3].paddleCount"
              ></el-input>
            </el-form-item>
          </div>
          <div class="risk3">
            <span class="riskLever-three">风控5：盈利平仓</span>
            <el-form-item label="本金初始值" prop="initialAssets">
              <el-input
                style="width: 210px;margin-left:5px"
                placeholder="请输入本金初始值"
                v-model="outerEditor.businessConfigRules[4].initialAssets"
              ></el-input>
            </el-form-item>
            <el-form-item label="盈利平仓阈值" prop="profitThreshold">
              <el-input
                style="width: 210px"
                placeholder="请输入盈利平仓阈值"
                v-model="outerEditor.businessConfigRules[4].profitThreshold"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisibleHeader = false">取 消</el-button>
          <el-button type="primary" @click="headerConfirm()">确 定</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 修改历史弹窗 -->
    <el-dialog
      title="查看修改历史"
      center
      :visible.sync="dialogFormVisibleHistory"
      :modal-append-to-body="false"
    >
      <el-form ref="formHistory" :model="formHistory" label-width="82px">
        <el-form-item label="时间">
          <el-date-picker
            v-model="formHistory.startTime"
            type="date"
            placeholder="开始时间"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
          <el-date-picker
            v-model="formHistory.endTime"
            type="date"
            placeholder="结束时间"
            style="margin-left: 25px"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
          <el-button
            type="primary"
            icon="el-icon-search"
            style="margin-left: 25px"
            @click="ChangeHistory"
          ></el-button>
        </el-form-item>
        <el-form-item prop="ruleType" label="类型">
          <el-radio-group v-model="formHistory.ruleType">
            <el-radio label="全部"></el-radio>
            <el-radio label="净值回撤"></el-radio>
            <el-radio label="交易币种限制"></el-radio>
            <el-radio label="交易频率限制"></el-radio>
          </el-radio-group>
        </el-form-item>

        <div class="history-table">
          <el-table :data="tableDataHistory" border>
            <el-table-column prop="createTime" label="修改时间" width="100px">
            </el-table-column>
            <el-table-column prop="createBy" label="修改人" width="80px">
            </el-table-column>
            <el-table-column prop="ruleType" label="风控类" width="80px">
            </el-table-column>
            <el-table-column prop="ruleName" label="参数" width="100px">
            </el-table-column>
            <el-table-column prop="oldValue" label="修改前"> </el-table-column>
            <el-table-column prop="newValue" label="修改后"> </el-table-column>
            <el-table-column prop="remark" label="备注"> </el-table-column>
          </el-table>
        </div>

        <!-- <div class="history-pagiHistory">
          <el-pagination
            @size-change="handleSizeChangeHistory"
            @current-change="handleCurrentChangeHistory"
            :current-page="pageListHistory.page"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="pageListHistory.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalHistory"
          >
          </el-pagination>
        </div> -->
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisibleHistory = false">取 消</el-button>
          <el-button type="primary" @click="dialogFormVisible = false"
            >确 定</el-button
          >
        </div>
      </el-form>
    </el-dialog>

    <!-- 编辑/修改页面 -->
    <el-dialog
      title="交易所组合配置"
      center
      :visible.sync="outerVisibleEditor"
      :modal-append-to-body="false"
      append-to-body
    >
      <el-dialog
        width="30%"
        title="内层 Dialog"
        :visible.sync="innerVisibleEditor"
        append-to-body
      >
      </el-dialog>
      <el-form :model="outerEditor" :rules="outerRules" ref="outerRules">
        <el-form-item label="团队" prop="teamName">
          <el-input
            v-model="outerEditor.teamName"
            disabled
            style="width: 150px"
          ></el-input>
        </el-form-item>
        <el-form-item label="组合类型" prop="businessType">
          <el-select
            v-model="outerEditor.businessType"
            placeholder="全部"
            clearable
          >
            <el-option label="服务商" :value="1"></el-option>
            <el-option label="跟单" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="交易所组合" prop="exchangeCheckList">
          <el-checkbox disabled v-model="outerEditor.checked">{{
            outerEditor.businessConfigCombinations[0].exchangeName
          }}</el-checkbox>
          <el-input
            v-model="outerEditor.businessConfigCombinations[0].accountName"
            disabled
            style="width: 350px"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item>
          <el-select v-model="outerEditor.value" clearable placeholder="请选择">
            <el-option
              v-for="item in outerEditorOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->

        <el-link type="primary">风控1：净值回撤参数</el-link>

        <el-form-item label="风控阈值">
          <el-input
            v-model="outerEditor.businessConfigRules[0].threshold"
            prop=""
            style="width: 150px"
          ></el-input>
        </el-form-item>

        <el-form-item label="初始资产">
          <el-input
            disabled
            v-model="outerEditor.businessConfigRules[0].initialAssets"
            style="width: 150px"
          ></el-input>
        </el-form-item>

        <el-form-item label="资产单位">
          <el-input
            v-model="outerEditor.businessConfigRules[0].assetsUnit"
            style="width: 150px"
            disabled
          ></el-input>
        </el-form-item>

        <el-form-item label="风控等级">
          <el-select
            v-model="outerEditor.businessConfigRules[0].grade"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in riskLeveloptions1"
              :key="item.id"
              :label="item.grade"
              :value="item.grade"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="alarmThreshold" label="预警阈值">
          <el-input
            clearable
            style="width: 210px"
            placeholder="请输入预警阈值"
            v-model="outerEditor.businessConfigRules[0].alarmThreshold"
          ></el-input>
        </el-form-item>

        <!-- <el-link type="primary">风控2：交易品种限制参数</el-link>
        <el-form-item label="可交易品种">
          <br />
          <el-checkbox-group
            v-model="outerEditor.businessConfigRules[1].varietieList"
          >
            <el-checkbox
              v-for="item in checkBoxfromServer"
              :key="item.id"
              :label="item.varietiesName"
              style="width: 120px; margin: 0 32px"
            ></el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="风控等级">
          <el-select
            v-model="outerEditor.businessConfigRules[1].grade"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in riskLeveloptions2"
              :key="item.id"
              :label="item.grade"
              :value="item.grade"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="可交易业务">
          <el-checkbox-group
            v-model="outerEditor.businessConfigRules[1].businessLineList"
          >
            <el-checkbox label="spot"></el-checkbox>
            <el-checkbox label="margin"></el-checkbox>
            <el-checkbox label="swap"></el-checkbox>
            <el-checkbox label="futures"></el-checkbox>
            <el-checkbox label="option"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-link type="primary">风控3：交易频率限制参数</el-link>

        <el-form-item label="限制">
          允许<el-input
            v-model="outerEditor.businessConfigRules[2].seconds"
            style="width: 100px; margin: 0 10px"
          ></el-input
          >秒内交易
          <el-input
            v-model="outerEditor.businessConfigRules[2].total"
            style="width: 100px; margin: 0 10px"
          ></el-input
          >单
        </el-form-item>

        <el-form-item label="风控等级">
          <el-select
            v-model="outerEditor.businessConfigRules[2].grade"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in riskLeveloptions3"
              :key="item.id"
              :label="item.grade"
              :value="item.grade"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        
        <el-link type="primary">风控4：自动划账参数</el-link>

        <el-form-item label="本金初始值">
          <el-input
            style="width: 210px;margin-left:5px"
            placeholder="请输入本金初始值"
            v-model="outerEditor.businessConfigRules[3].initialAssets"
          ></el-input>
        </el-form-item>
        <el-form-item label="自动划账阈值" prop="paddleThreshold">
          <el-input
            style="width: 210px"
            placeholder="请输入自动划账阈值"
            v-model="outerEditor.businessConfigRules[3].paddleThreshold"
          ></el-input>
        </el-form-item>
        <el-form-item label="划账资金" prop="paddleAssets">
          <el-input
            style="width: 210px;margin-left:28px"
            placeholder="请输入划账资金"
            v-model="outerEditor.businessConfigRules[3].paddleAssets"
          ></el-input>
        </el-form-item>
        <el-form-item label="自动划账次数" prop="paddleCount">
          <el-input
            style="width: 210px"
            placeholder="请输入自动划账次数"
            v-model="outerEditor.businessConfigRules[3].paddleCount"
          ></el-input>
        </el-form-item>

        <el-link type="primary">风控5：盈利平仓</el-link>

        <el-form-item label="本金初始值">
          <el-input
            style="width: 210px;margin-left:5px"
            placeholder="请输入本金初始值"
            v-model="outerEditor.businessConfigRules[4].initialAssets"
          ></el-input>
        </el-form-item>
        <el-form-item label="自动划账阈值" prop="profitThreshold">
          <el-input
            style="width: 210px"
            placeholder="请输入自动划账阈值"
            v-model="outerEditor.businessConfigRules[4].profitThreshold"
          ></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="outerVisibleEditor = false">取 消</el-button>
        <el-button type="primary" @click="outerEditorUpdate">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import lodash from "lodash";
const qs = require("qs");

export default {
  data() {
    return {
      exchangeInfosList: [], // 获取交易所列表（带label下拉数据）
      teamSelectOptions: [], //团队下拉列表
      checkListVarite: [],
      optionData: [
        {
          id: "",
          exchangeName: "",
        },
      ],
      outerEditor1: null,
      // 点击编辑后回传的数据-汇总
      outerEditor: {
        AddbusinessConfigCombinations: [],
        businessConfigCombinations: [
          {
            exchangeName: "", // 组合名称-不传
            accountName: "", // 组合下拉-不传
            accountId: "", // 风控api id*
            exchangeId: "", // 交易所id*
            // id: "",
          },
        ],
        businessConfigRules: [
          {
            threshold: "", // 风控阈值*
            initialAssets: 0, // 初始资产*
            assetsUnit: "", // 资产单位*
            grade: "", // 风控一 等级*
            alarmThreshold: "", // 预警阈值
            ruleType: 1,
          },
          {
            grade: "", // 风控二 等级*
            businessLine: "",
            varieties: "",
            businessLineList: [], // 可交易业务
            varietieList: [], // 可交易品种
            ruleType: 2,
          },
          {
            seconds: "", // 交易秒数*
            total: "", // 交易单数*
            grade: "", // 风控三 等级*
            ruleType: 3,
          },
          {
            initialAssets: 0, // 初始资产*
            paddleThreshold: "", // 划账阈值*
            paddleAssets: "",  // 划账资金
            paddleCount: "", // 划账次数*
            ruleType: 4,
            grade: 1, // 风控四 等级*
          },
          {
            initialAssets: 0, // 初始资产*
            profitThreshold: "", // 划账阈值*
            assetsUnit: "USDT",
            ruleType: 5,
            grade: 5, // 风控五 等级*
          }
        ],
        teamId: "", // 团队id*
        id: "", // id
        checked: true,
        teamName: "", // 团队名称
        businessType: "", // 组合类型
      },
      // 新增的数据-汇总
      formAddRules: {
        teamId: [
          { required: true, message: "请选择团队名称", trigger: "blur" },
        ],
        businessConfigCombinations: [
          { required: true, message: "请选择组合", trigger: "blur" },
        ],
        threshold: [
          { required: true, message: "请输入风控阈值", trigger: "input" },
        ],
        initialAssets: [
          { required: true, message: "请输入初始资产", trigger: "input" },
        ],
        assetsUnit: [
          { required: true, message: "请选择资产单位", trigger: "blur" },
        ],
        grade1: [
          { required: true, message: "请选择风控等级", trigger: "blur" },
        ],
        alarmThreshold: [
          { required: true, message: "请输入预警阈值", trigger: "blur" },
        ],
        // varietieList: [
        //   { required: true, message: "请选择交易品种", trigger: "blur" },
        // ],
        // grade2: [
        //   { required: true, message: "请选择风控等级", trigger: "blur" },
        // ],
        // businessLineList: [
        //   { required: true, message: "请选择可交易业务", trigger: "blur" },
        // ],
        // secondsTotal: [
        //   { required: true, message: "请输入限制参数", trigger: "input" },
        // ],
        // grade3: [
        //   { required: true, message: "请选择风控等级", trigger: "blur" },
        // ],
        businessType: [
          { required: true, message: "请选择组合类型", trigger: "blur" },
        ],
      },

      outerRules: {
        // teamSelectValue: [
        //   { required: true, message: "请输入团队名称", trigger: "change" },
        // ],
        // exchangeCheckList: [
        //   { required: true, message: "请输入账户名称", trigger: "blur" },
        // ],
      },
      outerEditorOptions: [],
      outerVisibleEditor: false,
      innerVisibleEditor: false,
      formHistory: {
        startTime: null, //
        endTime: null,
        ruleType: null, //ruleType
      },
      tableDataHistory: [], // 历史修改记录汇总
      exchangeLeftFromServer: [],
      risk1Model3: {
        checkList: [],
        riskleverValue3: "",
        timeSecond: "",
        times: "",
        riskleverValue2: "",
        TradableBusiness: [], // 可交易业务
      },
      unitArr: [
        {
          value: "USDT",
          label: "USDT",
        },
        {
          value: "BTC",
          label: "BTC",
        },
        {
          value: "ETC",
          label: "ETC",
        },
      ],
      exchangeFromServer: "", //获取交易所组合列表
      teamNameFromServer: "", //新增列表第一个
      formAdd: {
        team: "", // 团队下拉返回的 model
        risk: "", // 风控阈值返回的model
        money: "", //初始资产返回的model
        unit: "", //资产单位下拉返回的model
      }, //
      websock: null, //websocket
      pageListHistory: {
        page: 0,
        pageSize: 3,
      },
      totalHistory: 2, //修改历史分页  总数
      dialogFormVisibleHistory: false, //点击历史 弹出
      optionsSelectHistory: [], //历史下拉返回的数据
      // valueHistorySelect:"",              //     历史下拉返回的 model
      // dateValue:"",                             //历史页面返回的日期Model
      businessConfigCombinationsTwo: [], //新增页面接口的第二个选项
      secreMapListFromServer: [],
      // exchangeIDList:{},            //从secretMapList 中返回的 exchangeID 列表
      // valueFromServer:"",           //从secretMapList 中返回的 下拉框ID
      // exchangeIdFormServer:"",      //从secretMapList 中返回的 checkID
      exchangeCheck: [],
      exchangeListCheck: [],
      timeSecond: "", //时间
      times: "", //次数
      HistoryId: "",
      //资产单位列表
      riskunitList: [
        {
          label: "USDT",
          value: "USDT",
        },
        {
          label: "BTC",
          value: "BTC",
        },
        {
          label: "RMB",
          value: "RMB",
        },
      ],
      //资产单位
      riskUnit: "",
      //
      //交易所列表
      exchangeInfosFromServer: [],
      //风控等级1 下拉model
      riskleverValue1: "",
      //风控等级3 下拉model
      riskleverValue3: "",

      //风控等级3 下拉列表
      riskLeveloptions3: [
        {
          value: "选项1",
          grade: 1,
        },
        {
          value: "选项2",
          grade: 2,
        },
        {
          value: "选项3",
          grade: 3,
        },
        {
          value: "选项4",
          grade: 4,
        },
      ],
      //选中数组
      checkBoxfromServerCheck: [],
      checkBoxfromServer: [], //可交易品种 列表
      riskLeveloptions2: [
        //风控等级2 下拉菜单
        {
          value: "选项1",
          label: 1,
        },
        {
          value: "选项2",
          label: 2,
        },
        {
          value: "选项3",
          label: 3,
        },
        {
          value: "选项4",
          label: 4,
        },
      ],
      //风控等级1 下拉列表
      riskLeveloptions1: [
        {
          value: "选项1",
          label: 1,
        },
        {
          value: "选项2",
          label: 2,
        },
        {
          value: "选项3",
          label: 3,
        },
        {
          value: "选项4",
          label: 4,
        },
      ],
      //风控等级2 下拉菜单 model绑定值
      riskleverValue2: "",
      checkList: [], //复选框选中的列表
      risk1Model: {
        //表头 文本输入框绑定model值
        risk: "",
        start: "",
        company: "",
        riskleverValue1: "",
        // level:""
      },
      checkedOkex: "",
      checkedBitCoke: "", //多选框绑定Bitcoke的值
      optionsAccount2: [
        {
          //下拉1的账户列表
          value: "选项1",
          label: "80....主账户",
        },
        {
          value: "选项2",
          label: "70....主账户",
        },
      ],
      optionsAccount1: [
        {
          //下拉1的账户列表
          value: "选项1",
          label: "80....主账户",
        },
        {
          value: "选项2",
          label: "70....主账户",
        },
      ],
      valueCheckAccount2: "", //选择账户的下拉2  v-model值
      valueCheckAccount1: "", //选择账户的下拉1 v-model返回值
      //checkedBitMex:"",//多选框多选BitMex按钮
      //新增页面第一行团队下拉框
      optionsSelectHeader: [
        //     {
        //     value:"选项1",
        //     label:"团队1"
        // },
        // {
        //     value:"选项2",
        //     label:"团队2"
        // },
        // {
        //     value:"选项3",
        //     label:"团队3"
        // }
      ],
      //表头form表单 v-model绑定
      dialogFormVisibleHeader: false, //表头新增弹框判定
      checkBoxArray: [],
      // 表头交易所组合下拉 model值
      valueEXchange: "",
      //交易所组合下拉 表头
      optionsExchange: [],
      tableData: [],
      pageList: {
        page: 1, //当前是第几页
        pageSize: 10, //一页多少条数据
      },
      total: 3,
    };
  },
  //
  methods: {
    // 获取交易所列表（带label下拉数据）
    exchangeInfos() {
      this.axios.get("/business/admin/exchange/exchange-infos").then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          res.data.data[i].test = "";
          this.exchangeInfosList.push(res.data.data[i]);
        }
        // this.exchangeInfosList = res.data.data;
      });
    },
    clearSearch() {
      this.exchangeSearch();
    },
    // 搜索 exchangeSearch()
    exchangeSearch() {
      this.axios
        .get("/business/admin/config/config-info", {
          params: {
            configId: this.valueEXchange,
            page: this.pageList.page,
            pageSize: this.pageList.pageSize,
          },
        })
        .then((res) => {
          this.tableData = res.data.data.list;
        });
    },
    // 交易所组合下拉菜单的数据 -- 用于查询列表（交易所组合）
    getSearchSelect() {
      this.axios.get("/business/admin/config/combinations").then((res) => {
        this.optionsExchange = res.data.data;
      });
    },
    //响应  exchangeRight  列表
    // async checkExchangeRight() {
    //   //    debugger
    //   // for(var key of this.formAddOne.checkedLeft){
    //   //     var exchangeIdParamas=key
    //   // }
    //   for (var i = 0; i <= this.formAddOne.checkedLeft.length - 1; i++) {
    //     var key = this.formAddOne.checkedLeft[i];
    //     console.log(key);
    //     await this.axios
    //       .get("/business/admin/account/account-secret", {
    //         params: {
    //           exchangeId: key,
    //         },
    //       })
    //       .then((res) => {
    //         console.log(res);
    //       });
    //   }
    //   //   await  this.axios.get('/business/admin/account/account-secret',{
    //   //             params:{
    //   //                 exchangeId:exchangeIdParamas
    //   //             }
    //   //         }).then(()=>{
    //   //            this.axios.get('/business/admin/account/account-secret',{
    //   //                params:{
    //   //                    exchangeId:exchangeIdParamas
    //   //                }
    //   //            }).then((res)=>{
    //   //              console.log(res);
    //   //            })
    //   //         })

    //   // }
    // },
    //响应 exchangeLeft 列表
    // checkExchangeLeft() {
    //   console.log(this.formAddOne.checkedLeft);
    // },
    //获取交易所组合列表 -- 新增窗口内
    getExchangeLeft() {
      this.axios.get("/business/admin/exchange/comboExchange").then((res) => {
        this.exchangeLeftFromServer = res.data.data;
      });
    },
    handleCurrentChangeHistory(val) {
      console.log(val);
    },
    handleSizeChangeHistory(val) {
      console.log(val);
    },
    //点击修改状态按钮
    exchangeChangeStatus(idForm, stateForm) {
      this.axios
        .post(
          "/business/admin/config/state",
          qs.stringify({ id: idForm, state: stateForm })
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.$notify({
              title: "成功",
              message: "修改状态成功",
              type: "success",
              positon: "top-left",
              duration: 5000,
            });
          } else {
            this.$notify({
              title: "失败",
              message: "修改状态失败",
              type: "error",
              duration: 5000,
            });
          }
          this.getExchangeList();
        });
    },
    // 删除-按钮
    DelExchangeChange(id) {
      this.axios
        .delete("/business/admin/config/del", {
          params: {
            configId: id,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            this.$notify({
              title: "成功",
              message: "删除成功",
              type: "success",
              duration: 5000,
            });
            this.getExchangeList();
            this.innerVisible = false;
            this.addShow = false;
          } else {
            this.$notify({
              title: "失败",
              message: "删除失败",
              type: "error",
              duration: 5000,
            });
          }
        });
    },
    //获取交易所列表
    // getExchangeInfo:function(){
    //     this.axios.get('/business/admin/exchange/exchange-infos').then((res)=>{
    //        console.log(res);
    //     //    debugger
    //        res.data.data.map(() => {
    //            let val = ''
    //            this.exchangeCheck.push(val)
    //            this.exchangeListCheck.push(val)
    //        })
    //        this.exchangeFromServer=res.data.data            //交易所组合
    //     });
    // },
    //获取checkbox列表 新增窗口 后台品种列表（所有能用的交易品种）（新增交易所组合配置页面 风控2：交易品种限制，可交易品种的展示）
    getCheckBoxList: function() {
      this.axios.get("/business/admin/varieties/varietie-infos").then((res) => {
        this.checkBoxfromServer = res.data.data;
      });
    },
    //获取风控等级   新增窗口
    getRiskList: function() {
      this.axios.get("/business/admin/grade/grade-infos").then((res) => {
        this.riskLeveloptions3 = res.data.data; //风控等级3 赋值
        this.riskLeveloptions2 = res.data.data; //风控等级2 赋值
        this.riskLeveloptions1 = res.data.data; //风控等级1 赋值
      });
    },
    //获取exchange 表格列表数据
    getExchangeList() {
      this.axios
        .get("/business/admin/config/config-info", {
          params: this.pageList,
        })
        .then((res) => {
          //    debugger
          if (res.data.status == "fail") {
            this.$router.push("/");
          } else {
            this.tableData = res.data.data.list;
            this.total = res.data.data.total;
            this.pageList.pageSize = res.data.data.pageSize;
            this.pageList.page = res.data.data.pageNum;
          }
        });
    },
    // 确认按钮
    headerConfirm() {
      this.outerEditor.businessConfigRules[1].businessLine = this.outerEditor.businessConfigRules[1].businessLineList.join();
      this.outerEditor.businessConfigRules[1].varieties = this.outerEditor.businessConfigRules[1].varietieList.join();
      this.outerEditor.businessConfigCombinations = [];

      for (
        let i = 0;
        i < this.outerEditor.AddbusinessConfigCombinations.length;
        i++
      ) {
        const data = this.exchangeInfosList.filter((x) => {
          return x.id === this.outerEditor.AddbusinessConfigCombinations[i];
        })[0].test;
        if (data.length) {
          for (let j = 0; j < data.length; j++) {
            let test = {};
            test.exchangeId = this.outerEditor.AddbusinessConfigCombinations[i];
            test.accountId = data[j];
            this.outerEditor.businessConfigCombinations.push(test);
          }
        } else {
          let test = {};
          test.exchangeId = this.outerEditor.AddbusinessConfigCombinations[i];
          test.accountId = data;
          this.outerEditor.businessConfigCombinations.push(test);
        }
      }
      // console.log("outerEditor:", this.outerEditor)
      // this.dialogFormVisibleHeader = false; //
      this.axios
        .put("/business/admin/config/save", this.outerEditor)
        .then((res) => {
          if (res.data.status == "success") {
            this.$notify({
              title: "成功",
              message: res.data.msg,
              type: "success",
              positon: "top-left",
              duration: 5000,
            });
            this.dialogFormVisibleHeader = false;
            this.getExchangeList();
          } else {
            this.$notify.error({
              title: "失败",
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
          }
        });
    },
    // 编辑修改-确定按钮-提交
    outerEditorUpdate() {
      // this.outerEditor
      // this.outerEditor.businessConfigRules[1].businessLine = this.outerEditor.businessConfigRules[1].businessLineList.join();
      // this.outerEditor.businessConfigRules[1].varieties = this.outerEditor.businessConfigRules[1].varietieList.join();
      console.log(this.outerEditor)
      this.axios
        .put("/business/admin/config/save", this.outerEditor)
        .then((res) => {
          if (res.data.status == "success") {
            this.$notify({
              title: "成功",
              message: res.data.msg,
              type: "success",
              positon: "top-left",
              duration: 5000,
            });
            this.outerVisibleEditor = false;
            this.getExchangeList();
          } else {
            this.$notify({
              title: "失败",
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
          }
        });
    },
    //页面一页的数据发生改变时调用
    handleSizeChange(val) {
      this.pageList.pageSize = val;
      this.getExchangeList();
    },
    //页面第几页发生改变时调用
    handleCurrentChange(val) {
      this.pageList.page = val;
      this.getExchangeList();
    },
    //获取交易所配置组合团队下拉列表
    getExchangeTeamList: function() {
      this.axios.get("/business/admin/team/team-infos").then((res) => {
        this.teamNameFromServer = res.data.data;
      });
    },
    //点击新增按钮
    addEXchange() {
      this.exchangeInfosList.map((x) => {
        x.test = "";
      });
      this.outerEditor = lodash.cloneDeep(this.outerEditor1);
      this.dialogFormVisibleHeader = true;
    },

    //修改按钮点击
    exchangeEditor(row) {
      this.axios
        .get("/business/admin/config/config-info", {
          params: {
            id: row.id,
          },
        })
        .then((res) => {
          this.outerEditor.teamId = res.data.data.teamId;
          this.outerEditor.businessType = res.data.data.businessType;
          // debugger;
          this.outerEditor.teamName = res.data.data.teamName;
          this.outerEditor.id = res.data.data.id;
          if(res.data.data.businessConfigCombinations.length != 0) {
            this.outerEditor.businessConfigCombinations[0].exchangeName =
              res.data.data.businessConfigCombinations[0].exchangeName;
            this.outerEditor.businessConfigCombinations[0].accountId =
              res.data.data.businessConfigCombinations[0].accountId;
            this.outerEditor.businessConfigCombinations[0].exchangeId =
              res.data.data.businessConfigCombinations[0].exchangeId;
            this.outerEditor.businessConfigCombinations[0].accountName =
              res.data.data.businessConfigCombinations[0].accountName;
          }
          this.outerEditor.businessConfigRules =
            res.data.data.businessConfigRules; // 风控1、2、3
          if(this.outerEditor.businessConfigRules.length == 3) {
            this.outerEditor.businessConfigRules.push({
              initialAssets: 20000,
              paddleThreshold: '0.55',
              paddleAssets: '5000',
              paddleCount: '1',
              ruleType: 4,
              grade: 1
            })
          }
          if(this.outerEditor.businessConfigRules.length == 4) {
            this.outerEditor.businessConfigRules.push({
              initialAssets: 20000,
              profitThreshold: '10000',
              assetsUnit: "USDT",
              ruleType: 5,
              grade: 5
            })
          }
          this.outerVisibleEditor = true; // 修改窗口开启
        });
    },
    // 修改历史-按钮
    exchangeChangeHistory(row) {
      this.HistoryId = row;
      this.axios
        .get("/business/admin/history/history-info", {
          params: {
            configId: row,
          },
        })
        .then((res) => {
          this.dialogFormVisibleHistory = true;
          this.tableDataHistory = res.data.data;
        });
    },
    // 修改历史-类型切换
    ChangeHistory() {
      let ruleType = "";
      switch (this.formHistory.ruleType) {
        case "全部":
          ruleType = null;
          break;
        case "净值回撤":
          ruleType = 1;
          break;
        case "交易币种限制":
          ruleType = 2;
          break;
        case "交易频率限制":
          ruleType = 3;
          break;
        default:
          ruleType = null;
          break;
      }
      this.axios
        .get("/business/admin/history/history-info", {
          params: {
            configId: this.HistoryId,
            ruleType,
            startTime: this.formHistory.startTime,
            endTime: this.formHistory.endTime,
          },
        })
        .then((res) => {
          this.tableDataHistory = res.data.data;
        });
    },
  },
  async mounted() {
    this.outerEditor1 = lodash.cloneDeep(this.outerEditor);
    this.getSearchSelect();
    this.exchangeInfos(); //

    this.getExchangeList();
    this.getRiskList();
    this.getCheckBoxList();
    this.getExchangeTeamList();
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name == "riskLv" ||
      to.name == "exchangeObject" ||
      to.name == "Team" ||
      to.name == "Index" ||
      to.name == "account" ||
      to.name == "Userconf" ||
      to.name == "exConfiguration"
    ) {
      this.getExchangeList();
      this.valueEXchange = "";
    }
    next();
  },
};
</script>

<style scoped>
/* .exchange{
      margin-left: 200px;
  } */

.exchange123 >>> .el-checkbox__label {
  width: 100%;
  line-height: inherit;
}

.el-button--primary {
  margin-left: 0.4375rem;
}


/* .spanTeamName{
      margin-left:-500px
  } */

.teamHeaderSelect {
  /* transform: translate(10px,0px); */
  position: absolute;
  left: 10%;
}

.exchangeCompose {
  /* transform: translate(-340px,20px); */
  /* float: left;
    margin-top:120px;
     left:10%; */
  margin-top: 3.75rem;
  /* position:absolute;
     left:10%; */
}

.exchange-two .exchange_portfolio-select {
  width: 40%;
  display: inline;
  /* padding-left: 20px; */
  float: right;
  padding-right: 40%;
}

.checkboxExchange-one {
  /* margin-left: 5px; */
  margin-left: 2%;
  width: 9.375rem;
}

.teamHeaderExchange-one {
  /* transform: translate(10px,0px); */
  margin-left: 0.625rem;
}

.exchange-three {
  margin-top: 1.25rem;
}

.checkboxExchange-two {
  margin-left: -20.9375rem;
}

.teamHeaderExchange-two {
  transform: translate(0.3125rem, 0rem);
}

.checkboxExchange-three {
  transform: translate(-17.75rem, 1.5625rem);
}

.risk1 {
  margin-top: 1.875rem;
}

.risk1 .risk-one-title {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
  display: block;
}

.unitFund {
  width: 12.5rem;
}

.risk2 {
  margin-top: 1.25rem;
}
.risk2 .risk-two-title {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
  display: block;
}

.headerDialog {
  /* height: 900px; */
  /* overflow:scroll */
  margin-top: -6.25rem;
  /* width: 2000px; */
}

.checkBoxList {
  margin-top: 0.625rem;
}

.two {
  margin-top: 1.25rem;
  /* transform: translate(-235px,0px); */
}

.exchangeObj {
  float: left;
  margin-right: 15px;
  height: 22vh;
}

.risk3 {
  margin-top: 1.25rem;
}
.risk3 .riskLever-three {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
  display: block;
}

.riskLever-three2 {
  margin-top: 1.25rem;
}

.riskLever-three2 {
  transform: translate(-14.8125rem, 0rem);
}
.unit {
  transform: translate(-21.0625rem, 0rem);
}

.risklevel-foot {
  transform: translate(-0.0625rem, -0.0625rem);
}

.timeSeCond2 {
  width: 1.5625rem;
}

.times2 {
  width: 1.5625rem;
}

.times {
  width: 3.125rem;
}

.seconds {
  width: 3.125rem;
}

.addToastClass {
  position: relative;
  width: 62.5rem;
}

.spanTeamName {
  position: absolute;
  left: 5%;
}

.table {
  width: 75rem;
  height: 31.25rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.pagi {
  margin-top: 1.25rem;
  text-align: center;
}

.table-container {
  /* margin-top: 20px;
       margin-left: 20px; */
  /* position:fixed;
      left:20%;
      top:20%;  */
  /* left:20%; */
  /* width: 1000px; */
  margin-top: 1.25rem;
  margin-left: 1.25rem;
}

.exchange-header {
  margin: 1.25rem 0 0 1.25rem;
}

.outerEditor-litterSize-input {
  width: 30px;
  margin-right: 10px;
  height: 10px;
}
</style>
