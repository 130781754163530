<template>
  <div class="wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '' }">数据看板</el-breadcrumb-item>
      <el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '' }">盈亏统计</el-breadcrumb-item>
        <!-- <a href>盈亏统计</a> -->
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!-- title：数据总览 -->
    <div class="statistics">
      <h1>数据总览</h1>
      <span>（每小时更新数据）</span>
    </div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="筛选">
        <el-select
          v-model="formInline.businessType"
          placeholder="全部"
          clearable
        >
          <el-option label="服务商" :value="1"></el-option>
          <el-option label="跟单" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="在跑停跑数据">
        <el-select v-model="formInline.vaild" placeholder="全部" clearable>
          <el-option label="停跑" :value="0"></el-option>
          <el-option label="在跑" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="getOverview()"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div class="card-item" v-loading="TableLoading">
      <el-card
        class="box-card"
        :body-style="{
          backgroundColor:
            totalData.totalData.yield > 0 ? '#2196f3' : '#e9223f',
        }"
      >
        <div class="card-top">
          <div class="top-left">
            总资产
            <p>对比本金收益</p>
          </div>
          <div class="top-right">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">
                总资产 =
                当前总净值（包含结算数据，减去了服务商分佣；结算佣金或增减服务商资产会有变化）<br />
                总收益 =
                当前总净值-初始总本金（（对比本金收益）距离上次结算或者增减服务商后数据）<br />
                收益率 =
                总收益/初始总本金（（当前总收益）本金：结算佣金或者增减服务商会重置）
              </div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="card-middle">{{ totalData.totalData.yield }}%</div>
        <div class="card-bottom">
          <div>当前总收益：{{ totalData.totalData.profit }} USDT</div>
          <br />
          <div>当前总资产：{{ totalData.totalData.totalBalance }} USDT</div>
        </div>
      </el-card>
      <!-- <el-card
        class="box-card"
        :body-style="{
          backgroundColor:
            totalData.total7Data.yield > 0 ? '#2196f3' : '#e9223f',
        }"
      >
        <div class="card-top">
          <div class="top-left">
            过去7天收益率
            <p>{{ totalData.total7Data.startTime }} 至</p>
            <p>{{ totalData.total7Data.endTime }}</p>
          </div>
          <div class="top-right">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">
                近7天收益率：近7天净值上涨<br />
                之和 / 7天前净值 * 100%（不足<br />
                7按实际天数为准）<br />
                净资产变动额：近7天净资产收<br />
                益或损失的金额
              </div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="card-middle">{{ totalData.total7Data.yield }}%</div>
        <div class="card-bottom">
          <div>
            {{ totalData.total7Data.yield > 0 ? "盈利" : "亏损" }}
            {{ totalData.total7Data.profit }} USDT
          </div>
          <br />
        </div>
      </el-card> -->
      <el-card
        class="box-card"
        :body-style="{
          backgroundColor: totalData.total30Data > 0 ? '#2196f3' : '#e9223f',
        }"
      >
        <div class="card-top">
          <div class="top-left">
            预估月息
            <p>
              <!-- {{ totalData.total30Data.startDay }} -
              {{ totalData.total30Data.endDay }} -->
              &nbsp;
            </p>
          </div>
          <div class="top-right">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">
                月化 = 年化/12<br />
                xirr(values, dates, [guess])：计算<br />
                不定期的现金流，在投资日期和金<br />
                额不固定时，计算出收益率。
              </div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="card-middle">{{ totalData.total30Data }}%</div>
        <div class="card-middle">{{ totalData.total30XirrData }}%(xirr)</div>
        <div class="card-bottom"></div>
      </el-card>
      <!-- 年化收益率 -->
      <el-card
        class="box-card"
        :body-style="{
          backgroundColor: totalData.totalYearData > 0 ? '#2196f3' : '#e9223f',
        }"
      >
        <div class="card-top">
          <div class="top-left">
            预估年化收益率
            <p>
              <!-- {{ totalData.total30Data.startDay }} -
              {{ totalData.total30Data.endDay }} -->
              &nbsp;
            </p>
          </div>
          <div class="top-right">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">
                年化收益率=项目总收益/本金/<br />
                运行平均天数*365<br />
                xirr(values, dates, [guess])：计算<br />
                不定期的现金流，在投资日期和金<br />
                额不固定时，计算出收益率。
              </div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
        </div>
        <div class="card-middle">{{ totalData.totalYearData }}%</div>
        <div class="card-middle">{{ totalData.totalXirrYearData }}%(xirr)</div>
        <div class="card-bottom"></div>
      </el-card>
    </div>
    <!-- title：总资产收益率-折线图 -->
    <el-divider></el-divider>
    <div class="statistics">
      <h1>实时资产走势</h1>
      <span>（每天更新数据）</span>
    </div>
    <el-form :inline="true">
      <el-form-item>
        <el-select
          v-model="formInline2.businessType"
          placeholder="全部"
          clearable
        >
          <el-option label="服务商" :value="1"></el-option>
          <el-option label="跟单" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="在跑停跑数据">
        <el-select v-model="formInline2.vaild" placeholder="全部" clearable>
          <el-option label="停跑" :value="0"></el-option>
          <el-option label="在跑" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <!-- 日期选择器 -->
        <el-date-picker
          v-model="assetsDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          icon="el-icon-search"
          type="primary"
          style="margin-left: 15px"
          @click="getYield()"
          >查询</el-button
        >
      </el-form-item>
      <!-- <div class="block"></div> -->
    </el-form>
    <!-- 折线图 -->
    <div
      id="main"
      :style="{ width: '100%', height: '500px' }"
      v-loading="echartsLoading"
    ></div>
    <el-divider></el-divider>
    <!-- title：账户资产明细-折线图 -->
    <div class="statistics">
      <h1>账户资产明细</h1>
      <span>（每小时更新数据）</span>
    </div>
    <el-form :inline="true">
      <el-form-item label="筛选">
        <el-select
          v-model="formInline1.businessType"
          placeholder="全部"
          clearable
        >
          <el-option label="服务商" :value="1"></el-option>
          <el-option label="跟单" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="在跑停跑数据">
        <el-select v-model="formInline1.vaild" placeholder="全部" clearable>
          <el-option label="停跑" :value="0"></el-option>
          <el-option label="在跑" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <!-- 日期选择器 -->
        <el-date-picker
          v-model="assetsDate1"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          icon="el-icon-search"
          type="primary"
          style="margin-left: 15px"
          @click="yieldDetail()"
          >查询</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button
          icon="el-icon-document"
          type="primary"
          style="margin-left: 15px"
          @click="exportYieldDetail()"
          >导出</el-button
        >
      </el-form-item>
      <!-- <div class="block"></div> -->
    </el-form>
    <el-table
      :data="yieldDetailData"
      border
      row-key="id"
      style="width: 100%"
      v-loading="yieldDetailLoading"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      :row-class-name="tableRowClassName"
      :default-sort="{ prop: 'profit', order: 'descending' }"
    >
      <!-- <el-table-column label="序号" min-width="40" align="center">
        <template v-slot="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="团队" align="center">
        <template slot-scope="scope">
          <span v-if="!scope.row.children">{{ scope.row.teamName }}</span>
          <span v-if="scope.row.children" style="color:#409eff">
            {{ scope.row.teamName }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="combinationName"
        label="投资组合"
        align="center"
      ></el-table-column>

      <el-table-column
        prop="createTime"
        label="创建日期"
        min-width="95"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="initialAssets"
        label="本金(USDT)"
        min-width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="balance"
        label="余额(USDT)"
        min-width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="profit"
        label="近期收益"
        min-width="80"
        align="center"
        sortable
      ></el-table-column>
      <el-table-column
        prop="totalBalance"
        label="累计收益(USDT)"
        min-width="90"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="totalYield"
        label="累计收益率(%)"
        min-width="90"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="totalYearYield"
        label="预期年化收益率(%)"
        min-width="100"
        align="center"
        sortable
      ></el-table-column>
      <el-table-column
        prop="runningDay"
        label="累计运行天数"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" min-width="90" align="center">
        <template slot-scope="scope">
          <div v-if="!scope.row.children">
            <router-link
              style="color: #409eff; text-decoration: none"
              :to="{
                path: 'PortfolioDetails',
                query: {
                  configId: scope.row.configId,
                },
              }"
              >【详情】</router-link
            >
            <br />
            <router-link
              style="color: #409eff; text-decoration: none"
              :to="{
                path: 'MoneyManagement',
                query: {
                  configId: scope.row.configId,
                },
              }"
              >【资金管理】</router-link
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      style="text-align: center"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageList.page"
      :page-size="pageList.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="yieldDetailData.total"
    ></el-pagination> -->
  </div>
</template>

<script>
// import PublicMixin from '@/views/mixins';
import Mixins from "../../views/mixins";
export default {
  mixins: [Mixins],
  components: {},
  props: {},
  data() {
    return {
      TableLoading: false, // 卡片加载进度
      echartsLoading: false, // 折线图加载进度
      yieldDetailLoading: false, // 资产明细加载进度
      assetsDate: ["", ""], // 自选日期
      assetsDate1: ["", ""], // 自选日期
      // 数据总览
      totalData: {
        totalData: {
          profit: 0, // 资金
          yield: 0, // 总资产收益率
        },
        total30Data: {
          startDay: "",
          endDay: "",
          profit: 0, // 资金
          yield: 0, // 总资产收益率
        },
        total7Data: {
          startDay: "",
          endDay: "",
          profit: 0, // 资金
          yield: 0, // 总资产收益率
        },
      },
      yieldDetailData: [],
      formInline: {
        businessType: 1,
        vaild: 1,
      },
      formInline1: {
        businessType: 1,
        vaild: 1,
      },
      formInline2: {
        businessType: 1,
        vaild: 1,
      },
      // pageList: {
      //   page: 1, //当前是第几页
      //   pageSize: 10, //一页多少条数据
      // },
    };
  },
  watch: {
    assetsDate(newVal) {
      if (newVal == null) {
        this.assetsDate = ["", ""];
      }
    },
  },
  computed: {},
  methods: {
    tableRowClassName({ row, rowIndex }) {
      console.log(row, rowIndex);
      // debugger
      if (row.children) {
        return "success-row";
      }
      return "";
    },
    //
    isEmptyObj(data) {
      for (var item in data) {
        return false;
      }
      return true;
    },
    // 获取盈亏统计-数据总览
    getOverview() {
      this.TableLoading = true;
      this.axios
        .get("/business/admin/snapshot/overview", {
          params: {
            startTime: this.assetsDate[0],
            endTime: this.assetsDate[1],
            businessType: this.formInline.businessType,
            vaild: this.formInline.vaild,
          },
        })
        .then((res) => {
          console.log("获取盈亏统计-数据总览", res.data.data);
          if (!this.isEmptyObj(res.data.data)) {
            this.totalData = res.data.data;
          }
          this.TableLoading = false;
          // this.getYield();
        });
    },
    // 获取盈亏统计-总资产收益率-折线图数据
    async getYield() {
      this.echartsLoading = true;
      await this.axios
        .get("/business/admin/snapshot/yield", {
          params: {
            startTime: this.assetsDate[0],
            endTime: this.assetsDate[1],
            businessType: this.formInline2.businessType,
            vaild: this.formInline2.vaild,
          },
        })
        .then((res) => {
          console.log("获取折线图数据数据", res.data.data);
          if (res.data.status === "success") {
            let arr1 = [];
            let arr2 = [];
            let arr3 = [];
            for (let i = 0; i < res.data.data.length; i++) {
              arr1.push(res.data.data[i].totalBalance);
              arr2.push(res.data.data[i].createTime);
              arr3.push(res.data.data[i].totalInitialAsset);
            }
            this.random_Data(arr1, arr2, arr3);
            // this.yieldDetail(); // 获取账户资产明细
            this.echartsLoading = false;
          }
        });
    },
    // 获取账户资产明细
    yieldDetail() {
      const that = this;
      this.yieldDetailLoading = true;
      this.axios
        .get("/business/admin/snapshot/yield-detail", {
          params: {
            startTime: this.assetsDate1[0],
            endTime: this.assetsDate1[1],
            businessType: this.formInline1.businessType,
            vaild: this.formInline1.vaild,
            // page: this.pageList.page,
            // pageSize: this.pageList.pageSize,
          },
        })
        .then((res) => {
          // debugger
          // console.log("yieldDetail:", res.data.data);
          that.yieldDetailData = res.data.data;
          // for (let i = 0; i < res.data.data.length; i++) {
          //   that.yieldDetailData[i].recentGains =
          //     (res.data.data[i].balance - res.data.data[i].initialAssets).toFixed(2);
          // }
          that.yieldDetailLoading = false;
        });
    },
    // 加载折线图
    random_Data(totalBalance, createTime, totalInitialAsset) {
      const dom = document.getElementById("main");
      const chart = this.$echarts.init(dom);
      const money = totalBalance;
      const option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: createTime,
        },
        yAxis: {
          type: "value",
        },
        legend: {
          data: ["总本金", "总净值"],
        },
        tooltip: {
          trigger: "axis",
          formatter: function(params) {
            // console.log("parm", params);
            return (
              "日期：" +
              (params[0].name ? params[0].name : "") +
              "<br/>" +
              "总本金： " +
              (params[0].value ? params[0].value : 0) +
              "<br/>" +
              "总净值： " +
              money[params[0].dataIndex]
            );
          },
        },
        series: [
          {
            name: "总本金",
            data: totalInitialAsset,
            type: "line",
            // stack: "Total",
          },
          {
            name: "总净值",
            data: totalBalance,
            type: "line",
            // stack: "Total",
          },
        ],
      };
      chart.setOption(option);
    },
    // handleSizeChange(val) {
    //   console.log(`每页 ${val} 条`);
    //   this.pageList.pageSize = val;
    //   this.yieldDetail();
    // },
    // handleCurrentChange(val) {
    //   console.log(`当前页: ${val}`);
    //   this.pageList.page = val;
    //   this.yieldDetail();
    // },
    // 代理商列表导出
    exportYieldDetail() {
      // this.export("/business/admin/snapshot/yield-detail-export", {
      //   startTime: this.assetsDate1[0],
      //   endTime: this.assetsDate1[1],
      //   businessType: this.formInline1.businessType,
      //   vaild: this.formInline1.vaild,
      // });
      // const params = {
      //   startTime: this.assetsDate1[0],
      //   endTime: this.assetsDate1[1],
      //   businessType: this.formInline1.businessType,
      //   vaild: this.formInline1.vaild,
      // }
      location.href =
        location.origin +
        "/business/admin/snapshot/yield-detail-export?" +
        "startTime=" +
        this.assetsDate1[0] +
        "&" +
        "endTime=" +
        this.assetsDate1[1] +
        "&" +
        "businessType=" +
        this.formInline1.businessType +
        "&" +
        "vaild=" +
        this.formInline1.vaild;
    },
  },
  created() {},
  mounted() {
    this.getOverview(); //  获取盈亏统计-数据总览
    this.getYield(); // 生成折线图
    this.yieldDetail(); // 获取账户资产明细
  },
};
</script>
<style lang="scss" scope>
.wrapper {
  // .el-table .warning-row {
  //   background: rgb(251, 163, 0);
  // }

  .el-table .success-row,
  .el-table__row--level-1 {
    background: #f0f9eb;
  }
  // background-color: aquamarine;
  margin: 0 1%;
  width: 98%;
  padding-bottom: 30px;
  h1 {
    margin-bottom: 10px;
  }
  .el-divider--horizontal {
    margin: 12px 0;
  }
  .statistics {
    h1 {
      font-size: 18px;
      display: inline-block;
    }
    span {
      // margin-left: 15px;
      font-size: 14px;
      color: #bdaaaa;
      line-height: 18px;
      margin: 10px 0;
      // display: inline-block;
    }
  }
  .card-item {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 10px auto;
    .el-card {
      display: inline-block;
      width: 26%;
      height: 100%;
      .el-card__body {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }
    }
    .card-top {
      font-size: 16px;
      .top-left {
        float: left;
        color: #ffffff;
        p {
          font-size: 12px;
        }
      }
      .top-right {
        float: right;
      }
    }
    .card-middle {
      text-align: center;
      font-size: 24px;
      color: #ffffff;
      margin: 3px auto;
    }
    .card-bottom {
      div {
        float: left;
        color: #ffffff;
        font-size: 12px;
      }
    }
  }
  // .block {
  //   margin: 15px 0;
  // }
}
</style>
