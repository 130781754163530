<template>
  <div class="wrapper" v-loading="TableLoading">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '' }">数据看板</el-breadcrumb-item>
      <el-breadcrumb-item>
        <router-link :to="{ path: '' }">跟单组合数据统计</router-link>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="组合名称">
        <el-input v-model="formInline.groupName" placeholder="请输入组合名称" style="width: 180px;"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="formInline.status" placeholder="全部" clearable>
          <el-option label="统计中" :value="1"></el-option>
          <el-option label="已结束批次记录" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="开始时间">
        <el-date-picker
          v-model="formInline.beginTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="开始时间"
          style="width: 200px"
        />
      </el-form-item>
      <el-form-item label="结束时间">
        <el-date-picker
          v-model="formInline.endTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="结束时间"
          style="width: 200px"
        />
      </el-form-item> -->
      <el-form-item label="账号类型">
        <el-select v-model="formInline.accountType" placeholder="全部" clearable>
          <el-option label="服务商" :value="0"></el-option>
          <el-option label="跟单" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开始净值">
        <el-input v-model="formInline.startWorth" placeholder="请输入开始净值" style="width: 180px;"></el-input>
      </el-form-item>
      <el-form-item label="结束净值">
        <el-input v-model="formInline.endWorth" placeholder="请输入结束净值" style="width: 180px;"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="getCheckData()">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="warning" @click="getMianBan()">分析面板</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData.list"
      border
      style="width: 100%"
      v-loading="TableLoading"
    >
      <el-table-column label="序号" min-width="50" align="center">
        <template v-slot="scope">
          <p>{{ scope.$index + 1 }}</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="groupName"
        label="组合名称"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="bossWorth"
        label="服务商初始资金"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="followWorth"
        label="跟单初始资金"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="beginTime"
        label="开始时间"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="endTime"
        label="结束时间"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column label="状态" min-width="120" align="center">
        <template v-slot="scope">
          <p>
            {{
              scope.row.status === 1
                ? "统计中"
                : "已结束批次记录"
            }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        prop="nowVersion"
        label="当前版本的次数"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" width="150" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            style="color: #409eff; text-decoration: none"
            @click="getDocInfo(scope.row.id)"
            >【查看详情】</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="formInline.page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="formInline.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.total"
    ></el-pagination>
    
    <!-- 分析面板弹框 -->
    <el-dialog
      title="分析面板"
      v-loading="TableLoading"
      :visible.sync="panelDialog"
      :close-on-click-modal="false"
      width="40%"
      center>
      <div class="dataList">
        <div class="cont" v-for="(item, index) in panelData" :key="index">
          {{index+1}}. <span>{{item.groupName}}</span>：<span>{{item.count}}</span>次
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      panelDialog: false,
      formInline: {
        groupName: "", // 组合名称
        status: "", // 状态
        accountType: 0,  // 账号类型
        startWorth: "",   // 开始净值
        endWorth: "",      // 结束净值
        page: 1,       //当前是第几页
        pageSize: 10, //一页多少条数据
      },
      tableData: [],
      panelData: [],
      TableLoading: false // 加载进度
    };
  },
  watch: {},
  computed: {},
  methods: {
    // 数据统计列表
    getManageInfos() {
      // debugger;
      this.TableLoading = true;
      this.axios
        .get("/business/admin/statistical/getGroupStatisticalList", {
          params: {
            groupName: this.formInline.groupName, 
            status: this.formInline.status,    
            accountType: this.formInline.accountType,  
            startWorth: this.formInline.startWorth,   
            endWorth: this.formInline.endWorth,     
            page: this.formInline.page,
            pageSize: this.formInline.pageSize,
          },
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.TableLoading = false;
          console.log(this.tableData)
        });
    },
    getCheckData() {
      this.getManageInfos()
    },
    // 获取跟单组合数据统计数据看板
    getPanelList() {
      this.TableLoading = true;
      this.axios
        .get("/business/admin/statistical/getGroupStatisticalDataScreening", {
          params: {
            groupName: this.formInline.groupName, 
            status: this.formInline.status, 
            accountType: this.formInline.accountType,  
            startWorth: this.formInline.startWorth,   
            endWorth: this.formInline.endWorth     
          },
        })
        .then((res) => {
          this.panelData = res.data.data.statisticalCountList;
          this.TableLoading = false;
        });
    },
    // 分析面板
    getMianBan() {
      this.panelDialog = true
      this.getPanelList()
    },
    // 查看详情
    getDocInfo(id) {
      console.log(id)
      this.$router.push({
        path: 'view_details',
        query: {
          parentPath: "DataKanban",
          id: id
        }
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.formInline.pageSize = val;
      this.getManageInfos();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.formInline.page = val;
      this.getManageInfos();
    }
  },
  mounted() {
    this.getManageInfos(); 
  },
};
</script>
<style lang="scss" scope>
.wrapper {
  // background-color: aquamarine;
  margin: 0 2%;
  width: 96%;
  .el-divider--horizontal {
    margin: 12px 0;
  }
  .statistics {
    h1 {
      font-size: 18px;
      display: inline-block;
    }
    span {
      // margin-left: 15px;
      font-size: 14px;
      color: #bdaaaa;
      line-height: 18px;
      margin: 10px 0;
      // display: inline-block;
    }
  }
  .card-item {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 10px auto;
    .el-card {
      display: inline-block;
      width: 30%;
      height: 100%;
      .el-card__body {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }
    }
    .card-top {
      font-size: 16px;
      .top-left {
        float: left;
        color: #ffffff;
        p {
          font-size: 12px;
        }
      }
      .top-right {
        float: right;
      }
    }
    .card-middle {
      text-align: center;
      font-size: 24px;
      color: #ffffff;
      margin: 3px auto;
    }
    .card-bottom {
      float: right;
      color: #ffffff;
    }
  }
  .dataList {
    width: 100%;
    height: auto;
    margin-top: 15px;
    .cont {
      display: inline-block;
      width: 30%;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
    }
  }
}
</style>
