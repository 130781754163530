<!--  -->
<template>
  <div class="wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: 'index' }">系统配置</el-breadcrumb-item>
      <el-breadcrumb-item>
        <router-link :to="{ path: 'account' }">账户配置</router-link>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 下划线 -->
    <el-divider></el-divider>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item>
        <el-select clearable v-model="formInline.exchangeId" placeholder="请选择交易所">
          <el-option v-for="item in comboExchange" :key="item.value" :label="item.exchangeName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select clearable v-model="formInline.state" placeholder="请选择状态">
          <el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-button icon="el-icon-search" type="primary" style="margin-left: 15px" @click="searchAccount">查询</el-button>
        <el-button type="warning" @click="addShow = true">新增账户</el-button>
        <el-button type="primary" @click="addMore = true">批量新增</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table :data="tableData.list" border style="width: 100%" v-loading="TableLoading">
      <el-table-column label="序号" min-width="50" align="center">
        <template v-slot="scope">
          <p>{{ scope.$index + 1 }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="exchangeName" label="交易所名称" min-width="120" align="center"></el-table-column>
      <el-table-column label="账户类别" min-width="120" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.accountType == 1 ? "主账户" : "子账户" }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="accountName" label="账户名称" min-width="120" align="center"></el-table-column>
      <el-table-column prop="orderNum" label="排序号" min-width="120" align="center"></el-table-column>
      <el-table-column prop="state" label="状态" min-width="120" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.state == 1 ? "启用" : "停用" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="API使用天数" min-width="120" align="center">
        <template slot-scope="scope">
          <div :style="
            scope.row.usedDay >= 60
              ? 'color:red;display: inline;'
              : 'display: inline;'
          ">
            {{ scope.row.usedDay }}
          </div>
          <!-- <el-button
            type="text"
            @click="resetAPItime(scope.row.id)"
            v-if="scope.row.usedDay >= 60"
            >重置</el-button
          >-->
          <el-popconfirm title="是否确定重置？" @confirm="resetAPItime(scope.row.id)" v-if="scope.row.usedDay >= 60">
            <el-button slot="reference" type="text">【重置】</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column prop="opration" label="操作" min-width="150">
        <template slot-scope="scope">
          <el-button type="text" @click="editAccountInfo(scope.row.id)">【编辑】</el-button>
          <el-button type="text" @click="changeState(scope.row)">
            {{ scope.row.state == 0 ? "【启用】" : "【停用】" }}
          </el-button>
          <el-popconfirm title="是否确定删除？" style="margin-left: 10px" @confirm="DelAccountInfo(scope.row.id)">
            <el-button slot="reference" type="text">【删除】</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination style="text-align: center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="pageList.page" :page-sizes="[10, 20, 30, 50]" :page-size="pageList.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="tableData.total"></el-pagination>
    <!-- 新增弹窗 -->
    <el-dialog title="第一步：选择交易所" :visible.sync="addShow" :modal-append-to-body="false" width="30%" center>
      <el-form ref="addform" :model="addform" label-width="80px">
        <el-form-item label="交易所：">
          <el-select clearable v-model="addform.exchangeId" placeholder="请选择交易所">
            <el-option v-for="item in comboExchange" :key="item.value" :label="item.exchangeName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-dialog width="60%" title="第二步：填写信息" :visible.sync="innerVisible" :append-to-body="true" center @close="closePop">
        <el-form ref="formInfoAdd" :model="formInfoAdd" label-width="180px">
          <div v-for="(item, index) in formInfo" :key="index">
            <div class="form-title" v-if="
              index === 0 ||
              formInfo[index].formName !== formInfo[index - 1].formName
            ">
              {{ item.formName }}
            </div>
            <el-form-item :label="item.label" :prop="item.name" :rules="{
              required: item.isRequire,
              message: '请选择/输入' + item.formName + '_' + item.label,
              trigger: 'blur',
            }">
              <el-input v-if="item.type === 'text'" :placeholder="'请输入' + item.label" v-model="formInfoAdd[item.name]">
              </el-input>
              <el-input v-if="item.type === 'number'" :placeholder="'请输入' + item.label"
                v-model="formInfoAdd[item.name]"></el-input>
              <el-input v-if="item.type === 'password'" :placeholder="'请输入' + item.label"
                v-model="formInfoAdd[item.name]"></el-input>
              <el-input v-if="item.type === 'textarea'" type="textarea" :rows="2" :placeholder="'请输入' + item.label"
                v-model="formInfoAdd[item.name]"></el-input>
              <el-radio-group v-if="item.type === 'radio'" v-model="formInfoAdd[item.name]">
                <el-radio :label="1">启用</el-radio>
                <el-radio :label="0">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">取 消</el-button>
          <el-button type="primary" @click="formInfoAddSave('formInfoAdd', true)">提交</el-button>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addShow = false">取 消</el-button>
        <el-button type="primary" @click="getformInfo(null)">下一步</el-button>
      </div>
    </el-dialog>
    <!-- 编辑页面 -->
    <el-dialog width="60%" title="编辑信息" :visible.sync="editAccountShow" :append-to-body="true" center>
      <el-form ref="formInfoEdit" :model="formInfoEdit" label-width="180px">
        <div v-for="(item, index) in formInfo" :key="index">
          <div class="form-title" v-if="
            index === 0 ||
            formInfo[index].formName !== formInfo[index - 1].formName
          ">
            {{ item.formName }}
          </div>
          <el-form-item :label="item.label" :prop="item.name" :rules="{
            required: item.isRequire,
            message: '请选择/输入' + item.formName + '_' + item.label,
            trigger: 'blur',
          }">
            <el-input v-if="item.type === 'text'" :placeholder="'请输入' + item.label" :disabled="item.isDisable"
              v-model="formInfoEdit[item.name]"></el-input>
            <el-input v-if="item.type === 'number'" :placeholder="'请输入' + item.label" :disabled="item.isDisable"
              v-model="formInfoEdit[item.name]"></el-input>
            <el-input v-if="item.type === 'password'" :placeholder="'请输入' + item.label" :disabled="item.isDisable"
              v-model="formInfoEdit[item.name]"></el-input>
            <el-input v-if="item.type === 'textarea'" type="textarea" :rows="2" :placeholder="'请输入' + item.label"
              :disabled="item.isDisable" v-model="formInfoEdit[item.name]"></el-input>
            <el-radio-group v-if="item.type === 'radio'" v-model="formInfoEdit[item.name]">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="0">停用</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editAccountShow = false">取 消</el-button>
        <el-button type="primary" @click="formInfoAddSave('formInfoEdit', false)">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog title="API超时提醒" :visible.sync="expireAccountShow" width="30%" :append-to-body="true" center>
      <div v-for="(item, index) in expireAccount" :key="index">
        <span>
          {{ item.accountName }}，API使用天数{{ item.usedDay }}天，已超时{{
    item.expireDay
          }}天
        </span>
      </div>
      <br />
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="expireAccountShow = false">知道了</el-button>
      </span>-->
    </el-dialog>
    <!-- 批量新增 -->
    <el-dialog title="批量新增" :visible.sync="addMore" :modal-append-to-body="false" :before-close="cacleDialog" width="40%" center>
      <el-form ref="moreform" :model="moreform" label-width="40%">
        <el-form-item label="交易所：">
          <el-select clearable v-model="moreform.exChangeId" placeholder="请选择交易所">
            <el-option v-for="item in comboExchange" :key="item.value" :label="item.exchangeName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模板下载：">
          <el-button type="danger" @click="downMoulds">下载模板</el-button>
        </el-form-item>
        <el-form-item label="批量新增：">
          <input type="file" accept=".xlsx" ref="filebox" id="fileinput" style="display: none;" @change="checkFileSure">
          <el-button type="primary" @click="checkFile">批量新增数据</el-button>
          <div>{{fileName}}</div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cacleDialog">取 消</el-button>
        <el-button type="primary" @click="submitImport">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import qs from "qs";
export default {
  //import引入的组件需要注入到对象中才能使用
  // components: [],
  data() {
    //这里存放数据
    return {
      comboExchange: [], // 交易所-下拉数据
      formInline: {
        exchangeId: "", // 表头交易所组合下拉 model值
        state: "", // 变更类型（1、启用，2、停用）
      },
      stateList: [
        {
          value: 1,
          label: "启用",
        },
        {
          value: 0,
          label: "停用",
        },
      ],
      tableData: [], // 表格数据
      pageList: {
        page: 1, //当前是第几页
        pageSize: 10, //一页多少条数据
      },
      TableLoading: false, // 加载
      addShow: false, // 第一步弹窗
      addMore: false, // 批量新增
      innerVisible: false, // 第二步弹窗
      addform: {
        exchangeId: "", // 第一步：选择的交易所id
      },
      fileName: '',
      moreform: {
        exChangeId: "", // 交易所id
      },
      uploadForm: new FormData(),
      formInfo: [], // 动态表单的回调
      formInfoAdd: {
        exchangeId: "",
      }, // 第二步：新增账户的表单
      editAccountShow: false, // 编辑弹窗
      formInfoEdit: {}, // 修改账户的表单
      expireAccount: {}, // API60天超时数据
      expireAccountShow: false, // API60天超时弹窗开关
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 重置表单
    closePop() {
      this.$refs.formInfoAdd.resetFields();
    },
    // 获取交易所-下拉数据
    getComboExchange() {
      this.axios.get("/business/admin/exchange/comboExchange").then((res) => {
        console.log("获取交易所-下拉数据:", res.data.data);
        this.comboExchange = res.data.data;
      });
    },
    // 查询、初始化账户配置数据表格
    async searchAccount() {
      this.TableLoading = true;
      await this.axios
        .get("/business/admin/account/account-info", {
          params: {
            exchangeId: this.formInline.exchangeId,
            state: this.formInline.state,
            page: this.pageList.page,
            pageSize: this.pageList.pageSize,
          },
        })
        .then((res) => {
          console.log("查询、初始化账户配置数据表格", res.data.data);
          this.tableData = res.data.data;
          this.TableLoading = false;
        });
    },
    // 获取交易所表单
    getformInfo(id) {
      // debugger;
      this.formInfoAdd.exchangeId = this.addform.exchangeId;
      this.axios
        .get("/business/admin/account/form-info", {
          params: {
            exChangeId: id || this.addform.exchangeId,
          },
        })
        .then((res) => {
          console.log("获取交易所表单:", res.data.data);
          if (id != null) {
            this.editAccountShow = true;
          } else {
            this.innerVisible = true;
          }
          this.formInfo = res.data.data;
        });
    },
    // 修改账号状态 0 停用 、 1 启用
    changeState(idForm) {
      this.TableLoading = true;
      const stateForm = idForm.state === 0 ? 1 : 0;
      this.axios
        .get("/business/admin/account/updateState", {
          params: {
            id: idForm.id,
            state: stateForm,
          },
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.TableLoading = false;
            this.$notify({
              title: "成功",
              message: res.data.msg,
              type: "success",
              positon: "top-left",
              duration: 5000,
            });
          } else {
            this.TableLoading = false;
            this.$notify.error({
              title: "失败",
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
          }
          // console.log(res);
          this.searchAccount();
        });
    },
    // 重置API天数
    resetAPItime(id) {
      // debugger;
      this.TableLoading = true;
      this.axios
        .put("/business/admin/account/time-reset", qs.stringify({ id }))
        .then((res) => {
          if (res.data.status == "success") {
            this.TableLoading = false;
            this.$notify({
              title: "成功",
              message: res.data.msg,
              type: "success",
              positon: "top-left",
              duration: 5000,
            });
          } else {
            this.TableLoading = false;
            this.$notify.error({
              title: "失败",
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
          }
          this.searchAccount();
        });
    },
    // 删除-按钮
    DelAccountInfo(id) {
      this.axios
        .delete("/business/admin/account/del", {
          params: {
            id,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            this.$notify({
              title: "成功",
              message: "删除成功",
              type: "success",
              duration: 5000,
            });
            this.searchAccount();
            this.innerVisible = false;
            this.addShow = false;
          } else {
            this.$notify({
              title: "失败",
              message: "删除失败",
              type: "error",
              duration: 5000,
            });
          }
        });
    },
    // 打开单条编辑弹窗
    editAccountInfo(id) {
      this.axios
        .get("/business/admin/account/account-info", {
          params: {
            id,
          },
        })
        .then((res) => {
          console.log("单条编辑弹窗:", res.data.data);
          this.getformInfo(res.data.data.exchangeId);
          this.formInfoEdit = res.data.data;
        });
    },
    // 新增、编辑表单保存按钮
    formInfoAddSave(val, type) {
      this.$refs[val].validate((valid) => {
        if (valid) {
          this.axios
            .put(
              "/business/admin/account/save",
              type ? this.formInfoAdd : this.formInfoEdit
            )
            .then((res) => {
              console.log(res);
              if (res.data.status === "success") {
                this.$notify({
                  title: "成功",
                  message: type ? "新增成功" : "修改成功",
                  type: "success",
                  duration: 5000,
                });
                this.searchAccount();
                this.innerVisible = false;
                this.addShow = false;
                this.editAccountShow = false;
              } else {
                this.$notify({
                  title: "失败",
                  message: res.data.msg,
                  type: "error",
                  duration: 5000,
                });
              }
            });
        } else {
          return false;
        }
      });
    },
    // 查询过期账户列表
    async apiTime() {
      // debugger;
      await this.axios
        .get("/business/admin/account/expire-account")
        .then((res) => {
          this.expireAccount = res.data.data;
          // console.log("查询过期账户列表:", res);
          if (res.data.data.length > 0) {
            this.expireAccountShow = true;
          }
        });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageList.pageSize = val;
      this.searchAccount();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageList.page = val;
      this.searchAccount();
    },
    // 模板下载
    downMoulds() {
      this.axios
        .get("/business/admin/team/account/path")
        .then((res) => {
          if (res.data.status == "success") {
            console.log(res.data.data)
            window.location.href = res.data.data
          } 
        });
    },
    //取消
    cacleDialog() {
      this.fileName = ''
      this.addMore = false
    },
    //批量新增数据
    checkFile() {
      document.querySelector('#fileinput').click()
    },
    // 选择的文件
    checkFileSure() {
      this.fileName = document.querySelector('#fileinput').files[0].name;
      this.uploadForm.append('file', document.querySelector('#fileinput').files[0]);
      this.uploadForm.append('exChangeId', this.moreform.exChangeId);
    },
    //导入提交
    submitImport() {
      console.log(this.uploadForm)
      this.axios({
          method: 'post',
          url: '/business/admin/account/import',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data:this.uploadForm
      }).then(res => {
        if (res.data.status == "success") {
          this.$notify({
            title: "导入成功",
            type: "success",
            duration: 5000,
          });
          this.addMore = false
        } else {
          this.$notify({
            title: "导入失败",
            message: res.data.msg,
            type: "error",
            duration: 5000,
          });
        }        
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getComboExchange(); // 获取交易所-下拉数据
    this.searchAccount(); // 查询、初始化账户配置数据表格
    this.apiTime(); // API天数大于60的弹窗
  },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  //生命周期 - 更新之后
  updated() { },
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
.wrapper {
  // background-color: aquamarine;
  margin: 0 2%;
  width: 96%;

  .el-dialog {
    overflow-y: auto !important;
    height: 78% !important;
  }

  .el-divider--horizontal {
    margin: 12px 0;
  }

  .statistics {
    h1 {
      font-size: 18px;
      display: inline-block;
    }

    span {
      margin-left: 15px;
      font-size: 14px;
      color: #bdaaaa;
      line-height: 18px;
    }
  }

  .card-item {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 10px auto;

    .el-card {
      display: inline-block;
      width: 30%;
      height: 100%;

      .el-card__body {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }
    }

    .card-top {
      font-size: 16px;

      .top-left {
        float: left;
        color: #ffffff;

        p {
          font-size: 12px;
        }
      }

      .top-right {
        float: right;
      }
    }

    .card-middle {
      text-align: center;
      font-size: 24px;
      color: #ffffff;
      margin: 3px auto;
    }

    .card-bottom {
      float: right;
      color: #ffffff;
    }
  }

  // .block {
  //   margin: 15px 0;
  // }
}
.form-title {
  text-align: left;
  color: #fff;
  line-height: 40px;
  margin: 10px 0;
  font-size: 14px;
  font-weight: 800;
  padding-left: 15px;
  background: #6eb1db;
}
</style>
