<!-- dialog component -->
<script>
// import Vue, { CreateElement } from 'vue';
// import { Component, Prop, Watch, Emit } from "vue-property-decorator";

// @Component({
//   name: 'CloudDialog'
// })
export default {
  props: {
    title: {
      type: String,
      default: "Dialog Title",
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
    visible: {
      // 弹窗组件是否显示
      type: Boolean,
      default: false,
    },
    showClose: {
      // 是否有关闭按钮
      type: Boolean,
      default: true,
    },
    beforeClose: {
      // 自定义关闭前的回调
      type: Function,
    },
    maskHasClose: {
      // 点击阴影层是否可以关闭弹层
      type: Boolean,
      default: true,
    },
    /*下面这些属性会绑定到div上面 详情参照上面的html结构 */
    width: {
      // 内容宽度 如：style="{top:topDistance+'%',width:width+'%'}"
      type: String,
      default: "38%",
    },
    topDistance: {
      // top上边距
      type: String,
      default: "15vh",
    },
    padding: {
      type: String,
      default: "20px",
    },
    pdt: {
      type: Number,
      default: 22,
    },
    pdb: {
      type: Number,
      default: 47,
    },
  },
  data() {
    return {
      closed: false, // close flag
      ElEvent: "",
    };
  },
  render() {
    const TitleContent = this.$createElement(
      "span",
      {
        class: "header-title",
      },
      this.title
    );

    return (
      <transition name="fade">
        <div class="dialog-wrapper" v-show={this.visible}>
          <transition name="slideUp">
            <div
              class="dialog-content"
              ref="myDialog"
              v-show={this.visible}
              style={"marginTop:" + this.topDistance + ";width:" + this.width}
            >
              {this.hasHeader ? (
                <div class="dialog-header">
                  {(this.$slots.title = TitleContent)}
                  {this.showClose ? (
                    // <div class="dialog-btn" on-click={this.modelClose}>
                    //   &times;
                    // </div>
                    ''
                  ) : null}
                </div>
              ) : null}

              <div class="dialog-body" style={"padding:" + this.padding}>
                {this.$slots.default}
              </div>
              {this.$slots.footer ? (
                <div class="dialog-footer tc">{this.$slots.footer}</div>
              ) : null}
            </div>
          </transition>
          <div ref="dialogMask" class="dialog-mask" v-show="visible"></div>
        </div>
      </transition>
    );
  },
  mounted() {
    this.$nextTick(() => {
      if (this.maskHasClose) {
        const el = this.$refs.dialogMask;
        this.ElEvent = el;
        this.ElEvent.addEventListener("click", (event) => {
          event.stopPropagation();
          this.closeMyself();
        });
        // window.console.log(el);
      } else {
        window.console.log("无法单击");
      }
    });
  },
  methods: {
    closeMyself() {
      this.hide();
    },
    modelClose() {
      // 弹层关闭按钮
      if (typeof this.beforeClose === "function") {
        this.beforeClose(this.hide());
      } else {
        this.hide();
      }
    },

    hide() {
      // this.$emit('update:visible', false);
      this.handleUpdate();
      // this.$emit('close');
      this.handleClose();
      this.closed = true;
      this.$emit("onclose");
    },
    beforeDestroy() {
      // window.console.log(this.ElEvent);
      window.removeEventListener("click", this.ElEvent);
    },

    destroyed() {
      if (this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
    },
    // event
    // @Emit('close')
    handleClose() {
      window.console.log("closing");
    },
    // @Emit('open')
    handleOpen() {
      window.console.log("opening");
    },
    // @Emit('update:visible')
    handleUpdate() {
      window.console.log("updating");
      return false;
    },
  },
  watch: {
    deep: true,
    visible(val) {
      if (val) {
        this.closed = false;
        // this.$emit('open');
        this.handleOpen();
        this.$nextTick(() => {
          this.$refs.myDialog.scrollTop = 0;
        });
      } else {
        if (!this.closed) {
          // this.$emit('close');
          this.handleClose();
        }
      }
    },
  },
  // @Watch('visible')
  // isVisible(val: boolean) {
  //   if (val) {
  //     this.closed = false;
  //     // this.$emit('open');
  //     this.handleOpen();
  //     this.$nextTick(() => {
  //       this.$refs.myDialog.scrollTop = 0;
  //     });
  //   } else {
  //     if (!this.closed) {
  //       // this.$emit('close');
  //       this.handleClose();
  //     }
  //   }
  // }
};
</script>

<style lang="scss" scoped>
$radius: 5px;
$headerBgc: #303d4a;

// 最外层 设置position定位
.dialog-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  z-index: 1000;
}
// 内容层
.dialog-content {
  position: relative;
  color: #2e2c2d;
  font-size: 16px;
  z-index: 1995;
  border-radius: $radius;
  background-color: #fff;
  margin: 0 auto 50px;
  // border: 1px solid #f00;
  .dialog-header {
    position: relative;
    height: 54px;
    line-height: 54px;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    background-color: $headerBgc;
    .header-title {
      margin-left: 20px;
      font-size: 18px;
      font-weight: 700;
      color: #fff;
    }
    .dialog-btn {
      position: absolute;
      right: 18px;
      top: 13px;
      border: 2px solid #fff;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      line-height: 22px;
      font-size: 20px;
      color: #fff;
      text-align: center;
      cursor: pointer;
    }
  }
  // .dialog-body {
  // }
  .dialog-footer {
    margin-top: 10px;
    padding-bottom: 30px;
  }
}

// 遮罩 设置背景层，z-index值要足够大确保能覆盖，高度 宽度设置满 做到全屏遮罩
.dialog-mask {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
