<template>
  <div class="wrapper" v-loading="TableLoading">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '' }">数据看板</el-breadcrumb-item>
      <el-breadcrumb-item>
        <router-link :to="{ path: 'DataStatistics?menuId=139' }">跟单组合数据统计</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <router-link :to="{ path: '' }">数据详情</router-link>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="dataDetail">
      <div class="cont">
        组合名称：<span>{{tableData.groupName}}</span>
      </div>
      <div class="cont">
        服务商初始资金：<span>{{tableData.bossWorth}}</span>
      </div>
      <div class="cont">
        跟单初始资金：<span>{{tableData.followWorth}}</span>
      </div>
      <div class="cont">
        当前版本次数：<span>{{tableData.nowVersion}}</span>
      </div>
      <div class="cont">
        状态：<span v-if="tableData.status == 1">统计中</span>
             <span v-if="tableData.status == 2">已结束批次记录</span>
      </div>
      <div class="cont">
        开始时间：<span>{{tableData.beginTime}}</span>
      </div>
      <div class="cont">
        结束时间：<span>{{tableData.endTime}}</span>
      </div>
    </div>
    <div class="echartList" style="margin-top:15px">
      <p class="tits">服务商轨迹图</p>
      <div id="main" :style="{ width: '100%', height: '500px' }" v-loading="TableLoading"></div>
    </div>
    <div class="echartList">
      <p class="tits">跟单轨迹图</p>
      <div id="main1" :style="{ width: '100%', height: '500px' }" v-loading="TableLoading"></div>
    </div>
 
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      id: null,
      tableData: {},
      TableLoading: false // 加载进度
    };
  },
  methods: {
    // 数据统计詳情
    getManageInfos(id) {
      // debugger;
      this.TableLoading = true;
      this.axios
        .get("/business/admin/statistical/getGroupStatisticalDetails", {
          params: {
            id: id  
          },
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.TableLoading = false;
          let bossArr = res.data.data.bossStatisticsAccountList
          let followArr = res.data.data.followStatisticsAccountList
          let arr1 = []
          let arr2 = []
          let arr3 = []
          let arr4 = []
          for (let i = 0; i < bossArr.length; i++) {
            arr1.push(bossArr[i].accountWorth);
            arr2.push(bossArr[i].createTime);
          }
          this.BoosRandomData(arr1, arr2);
          for (let i = 0; i < followArr.length; i++) {
            arr3.push(followArr[i].accountWorth);
            arr4.push(followArr[i].createTime);
          }
          this.FollowRandomData(arr3, arr4);
        });
    },
    // 加载服务商折线图
    BoosRandomData(accountWorth,createTime) {
      const dom = document.getElementById("main");
      const chart = this.$echarts.init(dom);
      const option = {
        tooltip : {
          trigger: 'axis'
        },
        calculable : true,
        xAxis : [
          {
            type : 'category',
            boundaryGap : false,
            data : createTime,
            axisLabel: {
              interval: 0,
              rotate: -25
            }
          }
        ],
        yAxis : [
          {
            type : 'value',
            axisLabel : {
              formatter: '{value} 元'
            }
          }
        ],
        series : [
          {
            name:'服务商资金',
            type:'line',
            data: accountWorth
          }
        ]
      };
      chart.setOption(option);
    },
    // 加载跟单折线图
    FollowRandomData(accountWorth,createTime) {
      const dom = document.getElementById("main1");
      const chart = this.$echarts.init(dom);
      const option = {
        tooltip : {
          trigger: 'axis'
        },
        calculable : true,
        xAxis : [
          {
            type : 'category',
            boundaryGap : false,
            data : createTime,
            axisLabel: {
              interval: 0,
              rotate: -25
            }
          }
        ],
        yAxis : [
          {
            type : 'value',
            axisLabel : {
              formatter: '{value} 元'
            }
          }
        ],
        series : [
          {
            name:'服务商资金',
            type:'line',
            data: accountWorth
          }
        ]
      };
      chart.setOption(option);
    },
   
  },
  mounted() {
    this.id = this.$route.query.id
    this.getManageInfos(this.id); 
    this.BoosRandomData(); // 初始化折线图
    this.FollowRandomData();
  },
};
</script>
<style lang="scss" scope>
.wrapper {
  // background-color: aquamarine;
  margin: 0 2%;
  width: 96%;
  .el-divider--horizontal {
    margin: 12px 0;
  }
  .dataDetail {
    width: 100%;
    height: auto;
    margin-top: 15px;
    .cont {
      display: inline-block;
      width: 25%;
      height: 45px;
      line-height: 45px;
      font-size: 14px;
      color: #606266;
      span {
        color: #333333;
      }
    }
  }
  .echartList {
    width: 100%;
    height: auto;
    margin-top: 25px;
    padding-bottom: 20px;
    .tits {
      font-size: 16px;
      color: #1b96d1;
      margin-bottom: 10px;
    }
  }
}
</style>
