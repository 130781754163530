/*
 * @Author: chengengfeng
 * @Date: 2019-07-24 09:14:24
 * @Last Modified by: chengengfeng
 * @Last Modified time: 2019-07-25 16:41:34
 * @Description: public components
*/
// import { VueConstructor } from 'vue';
// import * as chart from './cloud-chart';
import CloudTitle from './cloud-title/cloud-title.vue';
import CloudHeader from './cloud-header/cloud-header.vue';
import CloudDialog from './cloud-dialog/cloud-dialog.vue';
// import CloudUpload from './cloud-upload/cloud-upload.vue';
// import CloudTinymce from './cloud-tinymce/cloud-tinymce.vue';
// import CloudViewImg from './cloud-view-img/cloud-view-img.vue';
import CloudScrollBar from './cloud-scroll-bar/cloud-scroll-bar.vue';
import CloudPagination from './cloud-pagination/cloud-pagination.vue';

const components = {
  // ...chart,
  CloudTitle,
  CloudHeader,
  CloudDialog,
  // CloudTinymce,
  // CloudUpload,
  // CloudViewImg,
  CloudScrollBar,
  CloudPagination,
};

const install = (Vue) => {
  Object.keys(components).forEach((comp) => Vue.component(comp, components[comp]));
};

export default install;
