/*
 * @Author: chengengfeng
 * @Date: 2019-07-24 16:21:20
 * @Last Modified by: chengengfeng
 * @Last Modified time: 2020-04-13 11:39:11
 * @Description: 视图层公用mixin
 */
// import Vue from 'vue';
import ApiIndex from "../../common/utils/api/index";
// import Component from 'vue-class-component';

// @Component
export default {
  data() {
    return {
      dataList: [],
      dataList1: [],
      dataList2: [],
      channelAnalysisData: [],

      listLoading: false,

      pageTotal: 0,
      pageTotal2: 0,

      menuId: this.$getQueryString("menuId"),

      formLabelWidth: "160px",

      listQuery: {
        page: 1,
        pageSize: 10,
      },

      listQuery2: {
        page: 1,
        pageSize: 10,
      },

      btns: {},
      // urls = {};
    };
  },
  methods: {
    getMenuModule() {
      // ApiIndex.getMenuModel<testData>({ menuId: this.menuId }).then((res) => { // 按钮权限
      ApiIndex.getMenuModel({ menuId: this.menuId }).then((res) => {
        // 按钮权限
        if (res.status === "success") {
          window.console.log(res);
          const premission = res.data;
          premission.forEach((item) => {
            this.btns[item.functionCode] = 1;
            // this.urls[`${item.functionCode}Url`] = item.url;
          });
          window.console.log(this.btns);
        }
      });
    },

    operTips(res, callback) {
      if (res && res.status === "success") {
        if (callback && typeof callback === "function") {
          callback();
        }
        this.$notify({
          title: "操作提示",
          offset: 100,
          type: "success",
          duration: 2000,
          message: res.message ? res.message : "操作成功！",
        });
      } else {
        this.$notify({
          title: "操作提示",
          offset: 100,
          type: "error",
          duration: 2000,
          message: res.message,
        });
      }
    },

    getListError(message = "查询列表失败") {
      this.listLoading = false;
      this.$notify.error({
        title: "操作提示",
        message,
        duration: 1000,
        offset: 100,
      });
    },

    export(url, params = {}, query = {}) {
      // this.setExportLoading(true);
      ApiIndex.exportExcel(url, params, query).catch((err) => {
        this.$notify.error({
          title: "出错了",
          message: err,
          duration: 2000,
          offset: 100,
        });
      });
    },

    /**
     * 目标变量是否为空
     * @param val 目标值
     */
    isEmpty(val) {
      if (typeof val === "boolean") {
        return false;
      }
      if (Object.prototype.toString.call(val) === "[object Array]") {
        if (val.length === 0) {
          return true;
        }
      } else if (val instanceof Object) {
        if (JSON.stringify(val) === "{}") {
          return true;
        }
      } else {
        if (
          val === "null" ||
          val === null ||
          val === "undefined" ||
          val === undefined ||
          val === ""
        ) {
          return true;
        }
        return false;
      }
      return false;
    },

    jumpRouter(path, query) {
      this.$router.push({
        path,
        query,
        replace: true,
      });
    },

    // currentChange(page, ...rest) {
    //   throw new Error('components must implement currentChange method');
    // },

    // sizeChange(pageSize, ...rest) {
    //   throw new Error('components must implement sizeChange method');
    // }
  },

  created() {
    this.getMenuModule();
  },
};
