<template>
  <header class="head-nav">
    <el-row>
      <!-- <el-col :span="5" class='logo-container'/>  -->
      <el-col :lg="10" :md="10" :sm="14" class="user-wrapper">
        <i class="icon-cloud-username"></i>
        <span class="user-name">欢迎您：{{ LoginUserName }}</span>
      </el-col>
      <el-col :lg="10" :md="10" :sm="8" class="oper">
        <!-- <span style="margin-right:20px" @click="showDoc(value.link)" v-for="(value,index) in specificationLists" :key="index">
          {{value.name}}
        </span> -->
        <span class="uPass" @click="showDialog = true">
          <i class="icon-cloud-lock1 mr5"></i>修改密码
        </span>
        <span @click="logout">
          <i class="icon-cloud-exit mr5"></i>退出登录
        </span>
      </el-col>
    </el-row>

    <cloud-dialog
      :visible.sync="showDialog"
      title="修改密码"
      :has-header="hasHeader"
      @close="closeDialog"
    >
      <el-form
        :model="updateData"
        :rules="updatePassRule"
        ref="updateData"
        style="margin-top: 16px;"
      >
        <el-form-item
          label="输入旧密码："
          :label-width="formLabelWidth"
          prop="oldPassword"
        >
          <el-input
            v-model="updateData.oldPassword"
            auto-complete="off"
            placeholder="请输入旧密码"
          />
        </el-form-item>
        <el-form-item
          label="输入新密码："
          :label-width="formLabelWidth"
          prop="newPassword"
        >
          <el-input
            v-model="updateData.newPassword"
            auto-complete="off"
            placeholder="请输入8-16位密码"
          />
        </el-form-item>
        <el-form-item
          label="新密码确认："
          :label-width="formLabelWidth"
          prop="newPassWordDec"
        >
          <el-input
            v-model="updateData.newPassWordDec"
            auto-complete="off"
            placeholder="请输入8-16位密码"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button class="confirmBtn" @click="goUpdatePass">确定</button>
        <button class="cancelBtn" @click="showDialog = false">取消</button>
      </span>
    </cloud-dialog>
  </header>
</template>

<script>
// import Vue from "vue";
import userManage from "../../common/utils/api/user";
// import { getMD5Code } from 'utils';
// import { Getter, namespace } from "vuex-class";
// import { Component } from "vue-property-decorator";

// const userSpace = namespace("user");

// @Component({
//   name: "CloudHeader",
// })
export default {
  // private specificationLists: Array<object> = [
  //   // {
  //   //   name:'运营人员说明书',
  //   //   link:'http://www.xxx.com'
  //   // }
  // ];
  data() {
    return {
      LoginUserName: "",
      showUpdateDialog: false,
      showDialog: false,
      hasHeader: true,
      formLabelWidth: "120px",
      updateData: {
        oldPassword: "",
        newPassword: "",
        newPassWordDec: "",
      },

      updatePassRule: {
        oldPassword: [
          {
            validator: (rule, value, callback) => {
              if (!value.length) {
                callback(new Error("请输入旧密码"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        newPassword: [
          {
            validator: (rule, value, callback) => {
              const pattent = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/;
              if (!pattent.test(value)) {
                callback(new Error("请输入数字、字母的6-18位密码"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        newPassWordDec: [
          {
            validator: (rule, value, callback) => {
              if (value !== this.updateData.newPassword || value === "") {
                callback(new Error("两次密码输入不一致"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  // @userSpace.Getter("username") LoginUserName!;
  mounted() {
    this.LoginUserName = this.$store.getters["user/username"];
    window.console.log("username:", this.LoginUserName);
  },
  // public $refs = {
  //   updateData: HTMLElement,
  // };
  methods: {
    goUpdatePass() {
      this.$refs.updateData.validate((valid) => {
        if (valid) {
          const opw = this.updateData.oldPassword;
          const npw = this.updateData.newPassword;
          const newData = {
            oldPassword: opw,
            newPassword: npw,
            newPassWordDec: npw,
          };
          userManage.modifyPassword(newData, "String").then((res) => {
            if (res.code === 0 && res.status === "fail") {
              this._showTip(res.message, "error");
            } else {
              this.showUpdateDialog = false;
              this._showTip(res.message, "success");
              setTimeout(() => {
                this.$store.dispatch("user/userLogout").then(() => {
                  location.reload();
                });
              }, 1e3);
            }
          });
        } else {
          return false;
        }
      });
    },

    logout() {
      this._showTip("请稍后,正在退出系统...", "warning", 0);
      this.$store.dispatch("user/userLogout").then(() => {
        location.reload();
      });
    },

    _showTip(message, type, duration = 3000, title = "提示") {
      this.$notify({
        title,
        message,
        type,
        customClass: "",
        duration,
        offset: 100,
      });
    },

    closeDialog() {
      this.$refs.updateData.resetFields();
    },

    // showDoc (value) {
    //   window.open(value);
    // },
    // async getSpecification () {
    //   let res = await specification();
    //   // let status;
    //   res.data.forEach((v, i) => {
    //     // if (v.indexOf('财务') !== -1) {
    //     //   status = '财务说明书';
    //     // } else if (v.indexOf('风控') !== -1) {
    //     //   status = '风控说明书';
    //     // } else if (v.indexOf('业务') !== -1) {
    //     //   status = '业务说明书';
    //     // } else if (v.indexOf('运营') !== -1) {
    //     //   status = '运营说明书';
    //     // } else if (v.indexOf('金融') !== -1) {
    //     //   status = '系统说明书';
    //     // }
    //     this.specificationLists.push({
    //       // name: status,
    //       name: '系统说明书',
    //       link: v
    //     });
    //   });
    //   // this.specificationLists = data.data;
    // },
  },
};
</script>

<style scoped lang="scss">
// @import "../../common/style/mixin";
// @import "../../common/style/variables";
$font-size: 1.4rem;

.head-nav {
  width: calc(100% - 220px);
  margin-left: 220px;
  height: 60px;
  background: #eee;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  color: #454545;
  border-bottom: 1px solid #eee;
  .el-row {
    line-height: 60px;
  }
  .logo-container {
    float: left;
    // width: 280px;
    height: 60px;
    // text-align: center;
    font-size: $font-size;
    text-decoration: none;
  }
  .user-wrapper {
    i {
      vertical-align: middle;
      font-size: 37px;
      padding: 0px 0 0 44px;
    }
    .user-name {
      margin-left: 20px;
    }
  }
  .oper {
    float: right;
    // width: 260px;
    text-align: right;
    margin-right: 30px;
    span {
      cursor: pointer;
      &:hover {
        color: #6b6969;
      }
    }
    .uPass {
      margin-right: 20px;
    }
  }
}
</style>
