<!-- mainView -->
<script>
// import Vue, { CreateElement } from 'vue';
// import { Component } from 'vue-property-decorator';

// @Component({
//   name: 'LayoutMainView'
// })
export default {
  render() {
    return (
      <section class="app-main" style="min-height: 100%">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </section>
    );
  },
};
</script>
