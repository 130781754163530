<template>
  <div class="wrapper" v-loading="Loading">
    <div v-for="(item, index) in riskList" :key="index" style="width: 100%; float: left;background-color: #f2f2f2;">
      <div class="exchange_portfolio">
        <div class="title">{{ item.followUserName + "（" + item.bossUserName + "）" }}</div>
        <div class="exchange_left">
          <div class="portfolio_top">组合控制台</div>
          <div class="portfolio_middle">
            <!-- 净值回撤 -->
            <!-- <div v-for="(rulesItem, index) in item.rules" :key="index"> -->
            <div class="exchange_configuration">
              <!-- 文字 -->
              <p>
                {{
                    item.execute ? "正在跟单中" : "停止跟单中"
                }}
              </p>
              <!-- 关闭报警按钮 -->
              <div class="exchange_configuration_middle">
                <el-button v-show="item.execute == true" @click="stopFollowDialog(item)" type="danger">停止跟单</el-button>
                <el-button v-show="item.execute == false" type="primary"
                  @click="startRouter(item.bossUserName, item.followUserName)">开始跟单</el-button>
                <!-- <el-button
                    type="warning"
                    plain
                    size="small"
                    v-show="rulesItem.isAlarm == true"
                    @click="closeAlarm(rulesItem.id)"
                  >关闭预警</el-button>
                  <el-popover
                    v-show="rulesItem.state == 3"
                    placement="top"
                    width="400"
                    trigger="click"
                  >
                    <div>
                      <div>关闭人：{{ userName }}</div>
                      <br />
                      <div>备注:</div>
                      <el-input type="textarea" :rows="3" placeholder="请输入备注" v-model="remark"></el-input>
                      <el-button
                        size="mini"
                        style="float: right; margin-top: 10px"
                        @click="alarmRemark(rulesItem.id)"
                      >确定</el-button>
                    </div>
                    <el-button slot="reference" type="text">关闭报警</el-button>
                  </el-popover>
                  <el-button
                    v-show="rulesItem.state == 3"
                    type="text"
                    style="margin-left: 10px"
                    @click="getDetail(rulesItem.id, true)"
                  >详情</el-button>
                  <span
                    :style="{
                      visibility: rulesItem.state !== 3 ? 'hidden' : '',
                    }"
                >{{ rulesItem.alarmTime }}</span>-->
              </div>
              <!-- 开关 -->
              <!-- <el-switch
                  v-model="rulesItem.isOn"
                  active-color="#13ce66"
                  :active-value="1"
                  :inactive-value="0"
                  inactive-color="#ff4949"
                  @change="changeState(rulesItem.id)"
              ></el-switch>-->
              <!-- 风控等级说明 -->
              <!-- <el-popover placement="top-start" title="风控等级说明：" trigger="hover">
                  <p v-if="rulesItem.grade == 1">1、推送企业微信；</p>
                  <p v-else-if="rulesItem.grade == 2">
                    1、推送企业微信
                    <br />2、binance，bitcoke,bitmex，ftx：挂单全撤；okex，huobi：api降权；
                  </p>
                  <p v-else-if="rulesItem.grade == 3">
                    1、推送企业微信
                    <br />2、binance，bitcoke,bitmex，ftx：挂单全撤；okex，huobi：api降权；
                    <br />3、binance，bitcoke，bitmex，ftx：仓位全平；huobi，okex：挂单全撤；
                  </p>
                  <p v-else-if="rulesItem.grade == 4">
                    1、推送企业微信
                    <br />2、binance，bitcoke,bitmex，ftx：挂单全撤；okex，huobi：api降权；
                    <br />3、binance，bitcoke，bitmex，ftx：仓位全平；huobi，okex：挂单全撤；
                    <br />4、binance，ftx：资产转换；bitcoke,bitmex：轮询挂撤；huobi，okex：仓位全平；
                  </p>
                  <p v-else-if="rulesItem.grade == 5">
                    1、推送企业微信
                    <br />2、binance，bitcoke,bitmex，ftx：挂单全撤；okex，huobi：api降权；
                    <br />3、binance，bitcoke，bitmex，ftx：仓位全平；huobi，okex：挂单全撤；
                    <br />4、binance，ftx：资产转换；bitcoke,bitmex：轮询挂撤；huobi，okex：仓位全平；
                    <br />5、okex，binance，ftx：资金划转；huobi：子账户冻结；
                  </p>
                  <el-button
                    slot="reference"
                    :type="
                      rulesItem.state == 1
                        ? 'info'
                        : rulesItem.state == 2
                        ? 'success'
                        : 'danger'
                    "
                    round
                  >{{ rulesItem.grade }}</el-button>
              </el-popover>-->
              <!-- 心跳 -->
              <!-- <div
                  :style="{
                    backgroundColor:
                      rulesItem.heartBeatSecond > 60 ? 'red' : '',
                    width: 22 + 'px',
                    color: rulesItem.heartBeatSecond > 60 ? '#ffffff' : '',
                    visibility:
                      rulesItem.isOn !== 1 && rulesItem.state !== 2
                        ? 'hidden'
                        : '',
                  }"
                >
                  <el-tooltip class="item" effect="dark" placement="right">
                    <p slot="content">
                      说明：
                      <br />1、当有1个风控开关是打开的，心跳自动计数每秒递增，从1-90，到90停止计数。
                      <br />2、当接收到后台服务发送心跳消息，重置从1开始计数。
                      <br />3、当计数到60，数字变成红色。待重置从1开始计数时，再恢复灰色。
                      <br />4、当关闭风控开关时，不显示心跳；
                      <br />当开启风控开关时，并且无报警，显示心跳；
                      <br />当开启风控开关时，并且有报警，如果风控等级为4，显示心跳；如果风控等级为1/2/3，不显示心跳。
                      <br />5、关闭报警后，不显示心跳。
                    </p>
                    <p style="width: 22px">{{ rulesItem.heartBeatSecond }}</p>
                  </el-tooltip>
              </div>-->
            </div>
            <div class="exchange_configuration">
              <!-- 文字 -->
              <p :style="{ color: item.remind ? 'red' : 'black' }">跟单异常告警</p>
              <!-- 关闭报警按钮 -->
              <div class="exchange_configuration_middle">
                <el-button v-show="item.remind == true" type="danger"
                  @click="exceptionLogByNewTime(item.bossUserName, item.followUserName)">查看</el-button>
                <el-button v-show="item.remind == false" type="primary" disabled>查看</el-button>
                <!-- <el-button
                    type="warning"
                    plain
                    size="small"
                    v-show="rulesItem.isAlarm == true"
                    @click="closeAlarm(rulesItem.id)"
                  >关闭预警</el-button>
                  <el-popover
                    v-show="rulesItem.state == 3"
                    placement="top"
                    width="400"
                    trigger="click"
                  >
                    <div>
                      <div>关闭人：{{ userName }}</div>
                      <br />
                      <div>备注:</div>
                      <el-input type="textarea" :rows="3" placeholder="请输入备注" v-model="remark"></el-input>
                      <el-button
                        size="mini"
                        style="float: right; margin-top: 10px"
                        @click="alarmRemark(rulesItem.id)"
                      >确定</el-button>
                    </div>
                    <el-button slot="reference" type="text">关闭报警</el-button>
                  </el-popover>
                  <el-button
                    v-show="rulesItem.state == 3"
                    type="text"
                    style="margin-left: 10px"
                    @click="getDetail(rulesItem.id, true)"
                  >详情</el-button>
                  <span
                    :style="{
                      visibility: rulesItem.state !== 3 ? 'hidden' : '',
                    }"
                >{{ rulesItem.alarmTime }}</span>-->
              </div>
              <!-- 开关 -->
              <!-- <el-switch
                  v-model="rulesItem.isOn"
                  active-color="#13ce66"
                  :active-value="1"
                  :inactive-value="0"
                  inactive-color="#ff4949"
                  @change="changeState(rulesItem.id)"
              ></el-switch>-->
              <!-- 风控等级说明 -->
              <!-- <el-popover placement="top-start" title="风控等级说明：" trigger="hover">
                  <p v-if="rulesItem.grade == 1">1、推送企业微信；</p>
                  <p v-else-if="rulesItem.grade == 2">
                    1、推送企业微信
                    <br />2、binance，bitcoke,bitmex，ftx：挂单全撤；okex，huobi：api降权；
                  </p>
                  <p v-else-if="rulesItem.grade == 3">
                    1、推送企业微信
                    <br />2、binance，bitcoke,bitmex，ftx：挂单全撤；okex，huobi：api降权；
                    <br />3、binance，bitcoke，bitmex，ftx：仓位全平；huobi，okex：挂单全撤；
                  </p>
                  <p v-else-if="rulesItem.grade == 4">
                    1、推送企业微信
                    <br />2、binance，bitcoke,bitmex，ftx：挂单全撤；okex，huobi：api降权；
                    <br />3、binance，bitcoke，bitmex，ftx：仓位全平；huobi，okex：挂单全撤；
                    <br />4、binance，ftx：资产转换；bitcoke,bitmex：轮询挂撤；huobi，okex：仓位全平；
                  </p>
                  <p v-else-if="rulesItem.grade == 5">
                    1、推送企业微信
                    <br />2、binance，bitcoke,bitmex，ftx：挂单全撤；okex，huobi：api降权；
                    <br />3、binance，bitcoke，bitmex，ftx：仓位全平；huobi，okex：挂单全撤；
                    <br />4、binance，ftx：资产转换；bitcoke,bitmex：轮询挂撤；huobi，okex：仓位全平；
                    <br />5、okex，binance，ftx：资金划转；huobi：子账户冻结；
                  </p>
                  <el-button
                    slot="reference"
                    :type="
                      rulesItem.state == 1
                        ? 'info'
                        : rulesItem.state == 2
                        ? 'success'
                        : 'danger'
                    "
                    round
                  >{{ rulesItem.grade }}</el-button>
              </el-popover>-->
              <!-- 心跳 -->
              <!-- <div
                  :style="{
                    backgroundColor:
                      rulesItem.heartBeatSecond > 60 ? 'red' : '',
                    width: 22 + 'px',
                    color: rulesItem.heartBeatSecond > 60 ? '#ffffff' : '',
                    visibility:
                      rulesItem.isOn !== 1 && rulesItem.state !== 2
                        ? 'hidden'
                        : '',
                  }"
                >
                  <el-tooltip class="item" effect="dark" placement="right">
                    <p slot="content">
                      说明：
                      <br />1、当有1个风控开关是打开的，心跳自动计数每秒递增，从1-90，到90停止计数。
                      <br />2、当接收到后台服务发送心跳消息，重置从1开始计数。
                      <br />3、当计数到60，数字变成红色。待重置从1开始计数时，再恢复灰色。
                      <br />4、当关闭风控开关时，不显示心跳；
                      <br />当开启风控开关时，并且无报警，显示心跳；
                      <br />当开启风控开关时，并且有报警，如果风控等级为4，显示心跳；如果风控等级为1/2/3，不显示心跳。
                      <br />5、关闭报警后，不显示心跳。
                    </p>
                    <p style="width: 22px">{{ rulesItem.heartBeatSecond }}</p>
                  </el-tooltip>
              </div>-->
            </div>
            <!-- </div> -->
          </div>
          <div class="portfolio_bottom">
            <el-button @click="getCurrentTransaction(item.configId)" disabled>跟单账户交易数据</el-button>
            <el-button @click="getRiskHistoryLog(item.bossUserName, item.followUserName, false)">跟单异常告警详情</el-button>
          </div>
        </div>
        <div class="exchange_right">
          <div :id="'main' + index" :style="{ width: '100%', height: '391px' }"></div>
        </div>
      </div>
    </div>
    <!-- 告警弹窗 -->
    <cloud-dialog width="38%" title="跟单异常告警" :visible.sync="showExceptionLogDialog" :modal-append-to-body="false"
      @close="closeDialog">
      <el-form :model="exceptionLogList">
        <el-from-item>
          <el-input type="textarea" :rows="2" v-model="exceptionLogList.errorMessage" disabled></el-input>
        </el-from-item>
        <b>备注：（关闭需输入备注）</b>
        <el-form-item>
          <el-input type="textarea" :rows="2" placeholder="请输入备注" v-model="exceptionLogList.remark"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
            @click="getRiskHistoryLog(exceptionLogList.bossUserName, exceptionLogList.followUserName, false)">查看详情
          </el-button>
          <el-button type="primary" @click="updateExceptionLog(exceptionLogList.id)">关闭</el-button>
        </el-form-item>
      </el-form>
    </cloud-dialog>
    <!-- 停单弹窗 -->
    <cloud-dialog width="38%" title="停止跟单" :visible.sync="showstopDialog" :modal-append-to-body="false">
      <p>请选择停止跟单的方式</p>
      <br />
      <el-radio v-model="radio" label="1">
        <span style="color:red;">断开链接，停止跟单</span>
      </el-radio>
      <br />
      <br />
      <el-radio v-model="radio" label="2" disabled>
        <span style="color:red;">仓位全平，停止跟单</span>
      </el-radio>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="showstopDialog = false">取消</el-button>
        <el-button type="primary" @click="showstopRouterDialog = true">确认</el-button>
      </div>
    </cloud-dialog>

    <!-- 停单确认弹窗 -->
    <cloud-dialog width="38%" title="确认停止跟单" :visible.sync="showstopRouterDialog" :modal-append-to-body="false">
      <p>你是否确认停止跟单</p>
      <!-- <br />
      <el-radio v-model="radio" label="1">
        <span style="color:red;">断开链接，停止跟单</span>
      </el-radio>
      <br />
      <br />
      <el-radio v-model="radio" label="2" disabled>
        <span style="color:red;">仓位全平，停止跟单</span>
      </el-radio>-->
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="showstopRouterDialog = false">取消</el-button>
        <el-button type="primary" @click="updateRouter">确认</el-button>
      </div>
    </cloud-dialog>

    <!-- 详情弹窗 -->
    <el-dialog title="详情" :visible.sync="DetailShow" :modal-append-to-body="false" width="80%" center>
      <div class="detail_title">风控报警</div>
      <el-collapse v-model="activeNames">
        <el-collapse-item :title="Detail.title" name="1">
          <div>
            <p>初始资产：{{ Detail.errorData.initialAssets }}</p>
            <p>历史最大净值:{{ Detail.errorData.historicalMax }}</p>
            <p>当前净值:{{ Detail.errorData.nowWorth }}</p>
            <p>当前回撤率:{{ Detail.errorData.nowBack }}</p>
            <p>触发阈值:{{ Detail.errorData.threshold }}</p>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div class="detail_title">当前交易数据</div>
      <el-collapse v-model="activeNames">
        <div v-for="(item, index) in Detail.mapData" :key="index">
          <el-collapse-item :title="item.name" :name="index">
            <div>初始资产:{{ item.nowWorth }}</div>
            <el-table :data="item.list" border style="width: 100%">
              <el-table-column prop="symbol" label="合约" width="180"></el-table-column>
              <el-table-column prop="side" label="方向" width="180"></el-table-column>
              <el-table-column prop v-if="item.price" label="委托价格" width="180"></el-table-column>
              <el-table-column v-if="item.price" prop="price" label="开仓均价"></el-table-column>
              <el-table-column prop="qty" label="数量USD"></el-table-column>
              <el-table-column v-if="item.type" prop="type" label="类型"></el-table-column>
              <el-table-column v-if="item.ordStatus" prop="ordStatus" label="状态"></el-table-column>
              <el-table-column v-if="item.created" prop="created" label="时间"></el-table-column>
              <el-table-column v-if="item.liquidationPrice" prop="liquidationPrice" label="强平价格"></el-table-column>
              <el-table-column v-if="item.pnl" prop="pnl" label="浮动盈亏"></el-table-column>
              <el-table-column v-if="item.urPnL" prop="urPnL" label="已实现盈亏"></el-table-column>
            </el-table>
          </el-collapse-item>
        </div>
      </el-collapse>
    </el-dialog>
    <!-- 当前交易数据弹窗 -->
    <el-dialog title="当前交易数据" :visible.sync="currentTransactionShow" :modal-append-to-body="false" width="80%" center>
      <div class="detail_title">当前交易数据</div>
      <el-collapse v-model="activeNames">
        <div v-for="(item, index) in Detail.mapData" :key="index">
          <el-collapse-item :title="item.name" :name="index">
            <div>初始资产:{{ item.nowWorth }}</div>
            <el-table :data="item.list" border style="width: 100%">
              <el-table-column prop="symbol" label="合约" width="180"></el-table-column>
              <el-table-column prop="side" label="方向" width="180"></el-table-column>
              <el-table-column prop v-if="item.price" label="委托价格" width="180"></el-table-column>
              <el-table-column v-if="item.price" prop="price" label="开仓均价"></el-table-column>
              <el-table-column prop="qty" label="数量USD"></el-table-column>
              <el-table-column v-if="item.type" prop="type" label="类型"></el-table-column>
              <el-table-column v-if="item.ordStatus" prop="ordStatus" label="状态"></el-table-column>
              <el-table-column v-if="item.created" prop="created" label="时间"></el-table-column>
              <el-table-column v-if="item.liquidationPrice" prop="liquidationPrice" label="强平价格"></el-table-column>
              <el-table-column v-if="item.pnl" prop="pnl" label="浮动盈亏"></el-table-column>
              <el-table-column v-if="item.urPnL" prop="urPnL" label="已实现盈亏"></el-table-column>
            </el-table>
          </el-collapse-item>
        </div>
      </el-collapse>
    </el-dialog>
    <!-- 跟单异常告警详情 -->
    <el-dialog title="跟单异常告警详情" :visible.sync="riskHistoryLogShow" :modal-append-to-body="false" width="90%" center
      @close="closeDialog">
      <el-form :inline="true" :model="riskHistoryForm" class="demo-form-inline" ref="riskHistoryForm">
        <el-form-item label="跟单账号">
          <el-select v-model="riskHistoryForm.followUserName" placeholder="跟单账号" clearable disabled>
            <el-option v-for="(item, index) in followNameList" :key="index" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务商账户">
          <el-select v-model="riskHistoryForm.bossUserName" placeholder clearable disabled>
            <el-option v-for="(item, index) in bossNameList" :key="index" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="assetsDate" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" format="yyyy - MM - dd" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary"
            @click="getRiskHistoryLog(riskHistoryForm.bossUserName, riskHistoryForm.followUserName, true)">查询
          </el-button>
        </el-form-item>
      </el-form>
      <el-table :data="FromHistoryTableData.data" border>
        <el-table-column label="序号" align="center">
          <template v-slot="scope">
            <p>{{ scope.$index + 1 }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="followUserName" label="跟单账户名称" align="center"></el-table-column>
        <el-table-column prop="bossUserName" label="服务商账户名称" align="center"></el-table-column>
        <!-- <el-table-column prop="logType" label="跟单比例登记"></el-table-column> -->
        <el-table-column prop="errType" label="错误类型" align="center"></el-table-column>
        <el-table-column prop="errorMessage" label="错误信息" align="center"></el-table-column>
        <el-table-column prop="location" label="报错位置" align="center"></el-table-column>
        <el-table-column prop="createTime" label="触发时间" align="center"></el-table-column>
        <el-table-column rop="logLevel" label="日志级别" align="center"></el-table-column>
        <el-table-column prop="exchange" label="涉及交易所" align="center"></el-table-column>
        <el-table-column prop="remark" label="备注" align="center"></el-table-column>
        <!-- <el-table-column prop="logType" label="日志类别">
          <template slot-scope="scope">
            <span>
              {{
              scope.row.logType == 1
              ? "净值回撤"
              : scope.row.logType == 2
              ? "交易品种限制"
              : scope.row.logType == 3
              ? "交易频率限制"
              : "风控开关"
              }}
            </span>
          </template>
        </el-table-column>-->
      </el-table>
      <el-pagination style="text-align: center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="FromHistoryTableData.page" :page-size="FromHistoryTableData.pageSize"
        layout="total, prev, pager, next, jumper" :total="FromHistoryTableData.total"></el-pagination>
      <el-dialog width="70%" title="警报内容详情" :visible.sync="innerVisible" :append-to-body="true" center>
        <p>违规订单成交明细：</p>
        <el-table :data="DetailHistoryTableData.list" border style="width: 100%">
          <el-table-column prop="errorTime" label="时间" min-width="150"></el-table-column>
          <el-table-column prop="exchange" label="交易所" width="150"></el-table-column>
          <el-table-column prop="businessLine" label="业务线"></el-table-column>
          <el-table-column prop="symbol" label="交易品种"></el-table-column>
          <el-table-column prop="side" label="方向"></el-table-column>
          <el-table-column prop="qty" label="仓位"></el-table-column>
          <el-table-column prop="pnl" label="盈亏" width="100"></el-table-column>
        </el-table>
        <el-pagination style="text-align: center" @size-change="handleDetailHistoryTableSizeChange"
          @current-change="handleDetailHistoryTablePageChange" :current-page="HistoryDetailForm.page"
          :page-size="HistoryDetailForm.pageSize" layout="total, prev, pager, next, jumper"
          :total="HistoryDetailForm.total"></el-pagination>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
// var data = [];
// var now = +new Date(1997, 9, 3);
// var oneDay = 24 * 3600 * 1000;
// var value = Math.random() * 1000;
export default {
  components: {},
  props: {},
  data() {
    return {
      radio: "1",
      bossNameList: [], // 服务商名称下拉框数据
      followNameList: [], // 跟单账户名称下拉框数据
      switchValue: false,
      riskList: [], // 总列表数据
      userName: "", // 用户名
      remark: "", // 关闭报警备注
      interval: null, // 定时器
      Detail: {
        errorData: {},
        mapData: {}
      }, // 获取的详情数据
      DetailShow: false, // 详情弹窗开关
      currentTransactionShow: false, // 当前交易数据弹窗开关
      riskHistoryLogShow: false, // 历史风控记录弹窗开关
      showExceptionLogDialog: false, // 异常告警弹窗开关
      showstopRouterDialog: false, // 停止跟单确认-弹窗开关
      showstopDialog: false, // 停止跟单方式的弹窗开关
      activeNames: ["1"], // 详情折叠面板
      activeNamesData: ["1"], // 当前交易数据折叠面板
      assetsDate: ["", ""], // 自选日期
      riskHistoryForm: {
        // configId: "",
        // logType: [],
        bossUserName: "",
        followUserName: "",
        startTime: "",
        endTime: "",
        page: 1,
        pageSize: 10
      }, // 历史风控记录表单
      pageListHistory: {
        page: 1,
        pageSize: 10
      },
      FromHistoryTableData: [], // 历史风控记录表格数据
      logTypeItem: [
        {
          name: "净值回撤",
          value: "1"
        },
        {
          name: "交易币种限制",
          value: "2"
        },
        {
          name: "交易频率限制",
          value: "3"
        },
        {
          name: "风控开关",
          value: "4"
        }
      ],
      websocketId: [],
      innerVisible: false, // 历史风控记录 的 详情页面弹窗开关
      DetailHistoryTableData: [], // 历史风控记录 的 详情 表格数据
      HistoryDetailForm: {
        page: 1,
        pageSize: 10
      },
      DetailHistoryTableId: "", //
      Loading: false, // 加载
      configIdFromServer: [], // 心跳接收数据
      exceptionLogList: {}, // 最新异常日志列表
      routerList: {} // 跟单表单
    };
  },
  watch: {
    assetsDate(newVal) {
      if (newVal == null) {
        this.assetsDate = ["", ""];
      }
    },
  },
  computed: {},
  methods: {
    // 获取主页团队列表
    getList() {
      // debugger;
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.Loading = true;
      this.axios.post("/gdSystem/getInfo").then(res => {
        console.log(res.data.data);
        // debugger;
        this.riskList = res.data.data;
        // this.riskList.map(x => {
        //   this.websocketId.push(x.rules[0].id);
        //   if (x.businessNowBalances.length !== 0) {
        //     x.data = [];
        //     x.businessNowBalances.map(y => {
        //       const params = {};
        //       params.name = new Date(parseInt(y.recordTime)).toString();
        //       params.value = [
        //         y.recordTimeStr,
        //         y.balanceVolatility,
        //         y.balanceNow
        //       ];
        //       x.data.push(params);
        //     });
        //   }
        // });
        // this.initWebSocket(); // 启动心跳WebSocket
        this.Loading = false;
        // this.$nextTick(() => {
        //   this.random_Data();
        //   this.interval = setInterval(() => {
        //     this.riskList.map(a => {
        //       a.rules.map(b => {
        //         if (b.isOn !== 0 && b.heartBeatSecond <= 91) {
        //           b.heartBeatSecond++;
        //         }
        //       });
        //     });
        //   }, 1000);
        // });
      });
    },
    getUser() {
      this.axios.get("/business/admin/user/user/info").then(res => {
        // console.log(res);
        this.userName = res.data.data.businessAdminUser.realName;
      });
    },

    // 根据时间查询最新的告警信息
    exceptionLogByNewTime(bossUserName, followUserName) {
      this.axios
        .post("/gdSystem/exceptionLogByNewTime", {
          bossUserName: bossUserName,
          followUserName: followUserName
        })
        .then(res => {
          // console.log(res);
          this.exceptionLogList = res.data.data;
          this.showExceptionLogDialog = true;
        });
    },
    //
    updateExceptionLog(id) {
      this.axios
        .post("/gdSystem/updateExceptionLog", {
          id,
          remark: this.exceptionLogList.remark,
          updateBy: this.userName
        })
        .then(res => {
          if (res.data.state == 1) {
            this.$notify({
              title: "成功",
              message: "关闭成功",
              type: "success",
              duration: 5000
            });
            this.getList();
            this.showExceptionLogDialog = false;
          }
        });
    },
    closeDialog() {
      this.showExceptionLogDialog = false;
      this.exceptionLogList = {};
      this.assetsDate = ["", ""];
      this.riskHistoryForm.page = 1;
      this.riskHistoryForm.pageSize = 10;
    },
    // 停止跟单确认弹窗打开 并获取停止跟单方式
    stopFollowDialog(row) {
      this.routerList = row;
      this.showstopDialog = true;
    },
    // 停止跟单
    updateRouter() {
      // debugger;
      this.axios
        .post("/gdSystem/router", {
          bossUserName: this.routerList.bossUserName,
          followUserName: this.routerList.followUserName,
          methodUrlName: "/compareAction",
          params: {
            compareAction: 0
          }
        })
        .then(res => {
          if (res.data.state == 1) {
            this.$notify({
              title: "成功",
              message: "停止成功",
              type: "success",
              duration: 5000
            });
            this.showstopRouterDialog = false;
            this.showstopDialog = false;
            this.getList();
          } else {
            this.$notify({
              title: "失败",
              message: res.data.messAge,
              type: "error",
              duration: 5000
            });
          }
        });
    },
    // 开启跟单
    startRouter(bossUserName, followUserName) {
      // debugger;
      this.axios
        .post("/gdSystem/router", {
          bossUserName,
          followUserName,
          methodUrlName: "/compareAction",
          params: {
            compareAction: 1
          }
        })
        .then(res => {
          if (res.data.state == 1) {
            this.$notify({
              title: "成功",
              message: "开始成功",
              type: "success",
              duration: 5000
            });
            this.showstopRouterDialog = false;
            this.showstopDialog = false;
            this.getList();
          } else {
            this.$notify({
              title: "失败",
              message: res.data.messAge,
              type: "error",
              duration: 5000
            });
          }
        });
    },

    // 关闭风控预警-按钮
    closeAlarm(id) {
      this.axios
        .post(
          "/business/admin/index/closeAlarmVoice",
          this.$qs.stringify({ ruleId: id })
        )
        .then(res => {
          if (res.data.status === "success") {
            this.$notify({
              title: "成功",
              message: "关闭成功",
              type: "success",
              duration: 5000
            });
            this.getList();
          }
        });
    },
    // 关闭报警备注-确定按钮
    alarmRemark(id) {
      this.axios
        .put("/business/admin/index/alarm", {
          closer: this.userName,
          remark: this.remark,
          ruleId: id
        })
        .then(res => {
          if (res.data.status === "success") {
            this.$notify({
              title: "成功",
              message: "关闭成功",
              type: "success",
              duration: 5000
            });
            this.getList();
          } else {
            this.$notify({
              title: "失败",
              message: res.data.msg,
              type: "error",
              duration: 5000
            });
          }
        });
    },
    // 获取详情-按钮
    async getDetail(id) {
      await this.axios
        .get("/business/admin/index/detail", {
          params: {
            ruleId: id
          }
        })
        .then(res => {
          console.log("获取详情", res.data);
          this.Detail = res.data.data;
          this.Detail.errorData =
            res.data.data.errorData !== null ? res.data.data.errorData : {};
          this.Detail.mapData = res.data.data.mapData;
          this.DetailShow = true;
        });
    },
    // 当前交易数据按钮
    async getCurrentTransaction(id) {
      await this.axios
        .get("/business/admin/index/currentTransactionData", {
          params: {
            configId: id
          }
        })
        .then(res => {
          console.log("当前交易数据:", res.data.data);

          this.Detail.mapData = res.data.data;
          this.currentTransactionShow = true;
        });
    },

    // 获取 跟单账户名称下拉框数据 and 服务商名称下拉框数据
    getBossAndFollow() {
      this.axios.post("/gdSystem/gainAccountName", { type: 0 }).then(res => {
        this.bossNameList = res.data.data;
      });
      this.axios.post("/gdSystem/gainAccountName", { type: 1 }).then(res => {
        this.followNameList = res.data.data;
      });
    },

    // 历史风控记录-按钮
    async getRiskHistoryLog(bossUserName, followUserName, type) {
      this.riskHistoryForm.bossUserName = bossUserName;
      this.riskHistoryForm.followUserName = followUserName;
      await this.axios
        .post("/gdSystem/exceptionLog", {
          // configId: id,
          bossUserName,
          followUserName,
          startTime: this.assetsDate[0],
          endTime: this.assetsDate[1],
          // logType: type ? this.riskHistoryForm.logType.join() : "",
          page: type ? 1 : this.riskHistoryForm.page,
          pageSize: type ? 10 : this.riskHistoryForm.pageSize
        })
        .then(res => {
          // console.log("跟单告警详情：", res.data);
          this.FromHistoryTableData = res.data;
          this.riskHistoryLogShow = true;
        });
    },
    // 历史风控记录 的 详情按钮
    async getHistoryDetail(id) {
      this.DetailHistoryTableId = id;
      await this.axios
        .get("/business/admin/index/historyDetail", {
          params: {
            id,
            page: this.HistoryDetailForm.page,
            pageSize: this.HistoryDetailForm.pageSize
          }
        })
        .then(res => {
          console.log("历史风控记录 的 详情：", res.data.data);
          this.DetailHistoryTableData = res.data.data;
          this.innerVisible = true;
        });
    },
    // 开关按钮
    changeState(idFromView) {
      // debugger;
      this.axios
        .get("/business/admin/index/switch", {
          params: {
            ruleId: idFromView
          }
        })
        .then(res => {
          // console.log(res);
          if (res.data.status === "success") {
            this.$notify({
              title: "成功",
              message: "关闭成功",
              type: "success",
              duration: 5000
            });
            this.getList();
          } else {
            this.$notify({
              title: "失败",
              message: res.data.msg,
              type: "error",
              duration: 5000
            });
          }
          this.getList(); // 获取主页团队列表
        });
    },
    // randomDatas() {
    //     now = new Date(+now + oneDay);
    //     value = value + Math.random() * 21 - 10;
    //     return {
    //       name: now.toString(),
    //       value: [
    //         [now.getFullYear(), now.getMonth() + 1, now.getDate()].join("/"),
    //         Math.round(value),
    //       ],
    //     };
    //   },

    // 折线图
    random_Data() {
      // debugger;
      //把代码封装到一个方法里
      // for (var i = 0; i < 1000; i++) {
      //   data.push(this.randomDatas());
      // }
      // document.getElementById("main");
      // debugger;
      const test = [];
      let arr = [];
      for (let i = 0; i < this.riskList.length; i++) {
        const myChart = this.$echarts.init(document.getElementById("main" + i));
        test.push(myChart);
        const option = {
          //加个let
          title: {
            left: "center",
            text: "资产波动",
            textStyle: {
              color: "rgba(228, 228, 228, 1)",
              fontSize: 18 // 标题颜色
            },
            padding: [13, 0, 0, 0]
          },
          backgroundColor: "rgba(41,52,65,1)", // 背景色
          dataZoom: [
            {
              type: "slider",
              xAxisIndex: [0], // 拖动时1px的数据
              filterMode: "filter",
              start: 90, // 默认开始范围 - 90%
              end: 100, // 默认结束范围 - 100%
              minSpan: 10, // 用于限制窗口大小的最小值（百分比值）
              textStyle: {
                color: "#ffffff" // dataZoom 文字的颜色。
              }
            }
          ],
          // toolbox: {
          //   iconStyle: {
          //     borderColor: "#999999"
          //   }
          // },
          tooltip: {
            trigger: "axis",
            formatter: function (params) {
              // console.log("parm", params);
              return (
                (params[0].value[0] ? params[0].value[0] : "") +
                "<br/>" +
                "净值波动率： " +
                (params[0].value[1] ? params[0].value[1] : "") +
                "%" +
                "<br/>" +
                "当前净值： " +
                (params[0].value[2] ? params[0].value[2] : "")
              );
            },
            axisPointer: {
              animation: false
            }
          },
          xAxis: {
            type: "category",
            splitLine: {
              show: false,
              lineStyle: {
                type: "dotted"
              }
            }
          },
          yAxis: {
            type: "value",
            // boundaryGap: [0, "100%"],
            axisLabel: {
              formatter: "{value} %"
            }
            // splitLine: {
            //   show: false
            // }
          },
          series: [
            {
              name: "净值波动率",
              type: "line",
              showSymbol: false,
              hoverAnimation: false,
              data: this.riskList[i].data,
              connectNulls: true
            }
          ]
        };
        // debugger;
        arr.push(option);
      }
      // let myChart = this.$echarts.init(document.getElementById("main")); //自己加的代码
      for (let j = 0; j < test.length; j++) {
        if (arr[j].series[0].data) {
          test[j].setOption(arr[j]);
        }
      }
    },
    // 历史风控记录翻页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.riskHistoryForm.pageSize = val;
      this.getRiskHistoryLog(
        this.riskHistoryForm.bossUserName,
        this.riskHistoryForm.followUserName,
        false
      );
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.riskHistoryForm.page = val;
      this.getRiskHistoryLog(
        this.riskHistoryForm.bossUserName,
        this.riskHistoryForm.followUserName,
        false
      );
    },
    // 历史风控记录-详情 翻页
    handleDetailHistoryTableSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.HistoryDetailForm.pageSize = val;
      this.getHistoryDetail(this.DetailHistoryTableId);
    },
    handleDetailHistoryTablePageChange(val) {
      console.log(`当前页: ${val}`);
      this.HistoryDetailForm.page = val;
      this.getHistoryDetail(this.DetailHistoryTableId);
    },
    initWebSocket() {
      // debugger;
      if (window.sessionStorage.getItem("token")) {
        var Token = window.sessionStorage.getItem("token");
      }
      let url = `wss://service.jysmall.net/websocket/heartbeat/${Token}`;
      this.websock = new WebSocket(url);
      this.websock.onopen = this.websocketOnopen;
      this.websock.onmessage = this.websocketOnmessage;
      this.websock.onerror = this.websocketOnerror;
      this.websock.onclose = this.websocketOnclose;
      console.log(Token);
    },
    websocketOnopen() {
      console.log("连接成功");
      // this.websock.send("111");
    },
    websocketOnerror() {
      console.log("心跳连接错误");
    },
    websocketOnmessage(e) {
      // console.log("心跳数据：", e);
      //  debugger
      // eslint-disable-next-line no-unused-vars
      let i = -1;
      // eslint-disable-next-line no-unused-vars
      let j = -1;
      const data = JSON.parse(e.data);
      console.log(data);
      this.riskList.map((a, index1) => {
        a.rules.map((b, index2) => {
          if (b.id == data.ruleId) {
            i = index1;
            j = index2;
          }
        });
      });
      if (i !== -1 && j !== -1) {
        this.riskList[i].rules[j].heartBeatSecond = 1;
      }
      if (data.balanceNow) {
        const params = {};
        params.name = new Date(parseInt(data.recordTime)).toString();
        params.value = [
          data.recordTimeStr,
          data.balanceVolatility,
          data.balanceNow
        ];
        console.log(
          this.websocketId.findIndex(x => {
            return x == data.ruleId;
          })
        );
        this.riskList[
          this.websocketId.findIndex(x => {
            return x == data.ruleId;
          })
        ].data.push(params);
        this.random_Data();
        // x.data.push(params);
      }
    },
    websocketOnclose(e) {
      console.log(e);
    }
  },
  mounted() {
    // debugger
    this.getList(); // 获取主页团队列表
    this.getUser(); // 获取用户信息
    // this.random_Data(); // 加载折线图
    this.getBossAndFollow(); // 获取 跟单账户名称下拉框数据 and 服务商名称下拉框数据
  }
};
</script>
<style lang="scss" scoped>
.el-button.is-round {
  padding: 12px 15px;
}

.wrapper {
  // background-color: aquamarine;
  margin: 0 2%;
  width: 96%;
  // height: 100vh;
  display: inline-block;

  .el-divider--horizontal {
    margin: 12px 0;
  }

  .el-collapse-item__header {
    font-size: 15px;
    font-weight: 800;
  }

  .detail_title {
    text-align: center;
    font-size: 16px;
    margin: 10px auto;
    background-color: #f2f2f2;
    height: 35px;
    line-height: 35px;
    font-weight: bolder;
  }

  .exchange_portfolio {
    width: 100%;
    margin-bottom: 15px;

    // background-color: rosybrown;
    .title {
      color: white;
      background-color: #303d4a;
      font-size: 20px;
      text-align: center;
      // margin: 0 0 10px 0;
      height: 50px;
      line-height: 50px;
    }

    .exchange_left {
      width: 30%;
      text-align: center;
      float: left;
      border: 1px solid #000000;
      background-color: #ffffff;
      /* margin: 0 10px 20px 20px; */
      margin: 20px;

      .portfolio_top {
        font-size: 29px;
        font-weight: 800;
        padding-top: 25px;
      }

      .portfolio_middle {
        width: 90%;
        margin: 35px auto;

        .exchange_configuration {
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin: 35px auto;
          background-color: #f2f2f2;
          height: 75px;

          p {
            width: 100px;
          }

          span {
            font-size: 11px;
            display: inline-block;
          }

          .exchange_configuration_middle {
            display: inline-block;
            width: 120px;
          }
        }
      }

      .portfolio_bottom {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 10px;
      }
    }

    .exchange_right {
      width: 65%;
      float: right;
      margin: 0 20px 10px 0;
    }
  }
}
</style>
