<!-- layout -->
<script>
// import { Getter } from "vuex-class";
// import Vue, { CreateElement } from "vue";
// import { Component } from "vue-property-decorator";
import { Sidebar, MainView } from "./components";

// @Component({
//   name: "Layout",
//   components: {
//     Sidebar,
//     MainView,
//   },
// })
export default {
  components: {
    Sidebar,
    MainView,
  },
  // 计算属性
  computed: {
    collapseStatus() {
      return false;
    },
    currentPath() {
      // 高亮当前左侧菜单
      const currentPath = this.$route.path;
      const index = currentPath.lastIndexOf("_");
      if (index > -1) {
        return currentPath.split("_")[0];
      } else {
        return this.$route.path;
      }
    },
  },
  data() {
    return {
      menuList: [],
    };
  },
  // @Getter("user/currentRouter") menuList!;

  mounted() {
    this.menuList = this.$store.getters["user/currentRouter"];
    window.console.log("menuList:", this.menuList);
  },

  // this.$route.path
  render() {
    return (
      <div>
        <cloud-header />
        <div class="app-wrapper">
          <div class="logo-wrapper">
            <div class="logo"></div>
          </div>
          <cloud-scroll-bar class="sidebar-container">
            <el-menu
              mode="vertical"
              show-timeout={200}
              unique-opened={true}
              default-active={this.currentPath}
              collapse={this.collapseStatus}
              background-color="#304156"
              text-color="#bfcbd9"
              active-text-color="#1b96d1"
            >
              <sidebar menuList={this.menuList} />
            </el-menu>
          </cloud-scroll-bar>
          <div class="main-container">
            <main-view />
          </div>
        </div>
      </div>
    );
  },
};
</script>

<style lang="scss">
@import "../../common/style/mixin";
@import "../../common/style/variables";
$icon: "../../../public/image/menu-logo1.png";
.app-wrapper {
  margin-top: 60px;
  .logo-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 184px;
    width: 220px;
    // z-index: 1000;
    background-color: #303d4a;
    .logo {
      margin: 50px 0;
      height: 82px;
      background-image: url($icon);
      background-color: #1b96d1;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
  .main-container {
    height: 100vh;
    padding: 26px;
  }
}
</style>
