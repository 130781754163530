<template>
  <div>
    <!-- 跟单账单操作记录 -->
    <div>
      <h2>跟单系统配置/跟单组合配置</h2>
      <el-divider></el-divider>
      <el-date-picker v-model="searchDate" value-format="yyyy-MM-dd" type="daterange" range-separator="至"
        start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <el-button type="primary" icon="el-icon-search" style="margin-left: 1rem" @click="searchData">
        搜索
      </el-button>
      <el-table border ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="bossName" label="跟单组合ID" width="120">
        </el-table-column>
        <el-table-column prop="followName" label="交易所">
        </el-table-column>
        <el-table-column prop="operationType" label="跟单组合名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="describe" label="喊单账户" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="isResult" label="跟单账户" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="createName" label="操作类型" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="createTime" label="修改内容" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="createTime" label="操作人" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="createTime" label="操作时间" show-overflow-tooltip>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <el-pagination style="text-align: center;" background layout="prev, pager, next, sizes" :total="pageNum"
      :page-sizes="[10, 20]" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page">
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageSize: 10,
      page: 1,
      pageNum: null,
      searchDate: [],
      groupId: null,
      list: []
    }
  },
  mounted() {
    this.getOperationLog();
  },
  methods: {
    // 搜索
    searchData() {
      this.page = 1
      this.getOperationLog()
    },
    // 分页
    handleCurrentChange(val) {
      this.page = val
      this.getOperationLog()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getOperationLog()
    }
  },
}
</script>

<style lang="scss" scoped>

</style>