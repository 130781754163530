<template>
  <div class="wrapper">
    <cloud-title title="资金划转记录" />
    <!-- title：数据总览 -->
    
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="账号：">
        <el-select v-model="formInline.toAccount" placeholder="请选择">
          <el-option
            v-for="item in optionsExchange"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <!-- 日期选择器 -->
        <div class="block">
          <el-date-picker
            v-model="formInline.startTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="开始时间"
            style="width: 200px"
          />
          <el-date-picker
            v-model="formInline.endTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="结束时间"
            style="width: 200px;margin-left:10px"
          />
          <el-button
            icon="el-icon-search"
            type="primary"
            style="margin-left: 15px"
            @click="clickInquire"
          >查询</el-button>
          <!-- <el-button
            type="warning"
            style="margin-left: 15px"
            @click="exportData"
          >导出</el-button> -->
        </div>
      </el-form-item>
    </el-form>
    <el-table :data="tableData.list" border style="width: 100%">
      <el-table-column label="类型" width="120">
        <template>
          <div>转入</div>
        </template>
      </el-table-column>
      <el-table-column prop="toAccount" label="账号"></el-table-column>
      <!-- <el-table-column prop="totalYield" label="账号类型"></el-table-column> -->
      <el-table-column prop="amount" label="划账金额">
        <template slot-scope="scope">
          <div>{{ parseFloat(scope.row.amount).toFixed(2) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="amountBefore" label="划转前账户净值金额（U）" width="160">
        <template slot-scope="scope">
          <div>{{ parseFloat(scope.row.amountBefore).toFixed(2) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="amountAfter" label="划转后账户净值金额(U)" width="160">
        <template slot-scope="scope">
          <div>{{ parseFloat(scope.row.amountAfter).toFixed(2) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="划转时间"></el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableData.pageNum"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="tableData.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      optionsExchange: [], //交易所组合下拉 表头
      formInline: {
        toAccount: '',
        startTime: '',
        endTime: '',
        page: 1, //当前是第几页
        pageSize: 10 //一页多少条数据
      },
      tableData: {},
      TableLoading: false
    };
  },
  methods: {
    // 交易所组合下拉菜单的数据 -- 用于查询列表（交易所组合）
    getSearchSelect() {
      this.axios.get("/business/admin/config/combinations-all").then(res => {
        this.optionsExchange = res.data.data;
        if (this.$route.query.configId) {
          this.formInline.valueEXchange = parseInt(this.$route.query.configId);
        } else {
          this.formInline.valueEXchange = res.data.data[0].id;
        }
      });
    },
    // 获取资金划转记录列表
    getTransferRecord() {
      this.TableLoading = true;
      this.axios
        .get("/business/admin/index/queryTransferRecord", {
          params: {
            toAccount: this.formInline.toAccount,
            startTime: this.formInline.startTime,
            endTime: this.formInline.endTime,
            page: this.formInline.page,
            pageSize: this.formInline.pageSize,
          }
        })
        .then(res => {
          this.tableData = res.data.data.data;
          console.log(this.tableData)
          this.TableLoading = false;
        });
    },
    // 查询按钮，发两个接口
    clickInquire() {
      this.TableLoading = true;
      this.getTransferRecord();
    },

    // 导出
    exportData() {

    },
    handleSizeChange(val) {
      this.pageList.pageSize = val;
      this.clickInquire();
    },
    handleCurrentChange(val) {
      this.pageList.page = val;
      this.clickInquire();
    },
  },
  created() {
    this.getSearchSelect(); // 下拉菜单的数据
    this.getTransferRecord(); // 资金划转记录
  },
};
</script>
<style lang="scss" scope>
.app-wrapper{
  .main-container {
    height: 100%;
    .wrapper {
      margin: 0 2%;
      width: 96%;
      .el-table {
        .el-table__cell {
          text-align: center;
        }
      } 
      .el-pagination {
        margin-top: 15px;
      }
    }
  }
}
</style>
