<template>
  <div class="riskLv">
    <el-form :inline="true">
      <el-form-item>
        <el-button
          type="warning"
          icon="el-icon-zoom-in"
          @click="dialogFormVisibleAdd()"
          >新增</el-button
        >
      </el-form-item>
    </el-form>
    <!-- risk页面新增按钮弹出层 -->
    <el-dialog
      title="风控等级配置维护"
      :visible.sync="dialogFormVisibleHeader"
      :modal-append-to-body="false"
    >
      <el-form
        ref="formRiskAdd"
        :model="formRiskAdd"
        label-width="106px"
        :rules="rules"
      >
        <el-form-item class="form1Class" prop="grade" label="风控等级">
          <el-select
            v-model="formRiskAdd.grade"
            placeholder="请选择"
            class="riskLv-riskLvSelect"
          >
            <el-option
              v-for="item in optionsAdd"  
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="风控等级">
                            <el-input v-model="formRiskAdd.grade"></el-input>
                        </el-form-item> -->
        <!-- <div class="riskDec">
                             风控等级说明
                        </div> -->

        <el-form-item class="form1Class" prop="remark" label="风控等级说明">
          <el-input type="textarea" v-model="formRiskAdd.remark"></el-input>
        </el-form-item>

        <el-form-item label="排序号" class="orderForm" prop="orderNum">
          <el-input
            v-model="formRiskAdd.orderNum"
            type="number"
            min="0"
          ></el-input>
        </el-form-item>

        <el-form-item class="orderForm" prop="state" label="状态">
          <el-radio-group v-model="formRiskAdd.state" class="rideo-class">
            <el-radio :label="0">停用</el-radio>
            <el-radio :label="1">启用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleHeader = false">取 消</el-button>
        <el-button type="primary" @click="riskHeaderConfirm">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 表格 -->
    <el-table :data="risktableData" border>
      <el-table-column prop="grade" label="风控等级"> </el-table-column>
      <el-table-column prop="remark" label="等级说明"> </el-table-column>
      <el-table-column prop="orderNum" label="排序号"> </el-table-column>

      <el-table-column prop="state" label="状态">
        <template slot-scope="scope">
          <div>{{ scope.row.state == 1 ? "启用" : "停用" }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="opration" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="editor(scope.row)">【修改】</el-button>
          <el-button
            type="text"
            @click="changeState(scope.row.id, 0)"
            v-if="scope.row.state == 1"
            >【停用】</el-button
          >
          <el-button
            type="text"
            @click="changeState(scope.row.id, 1)"
            v-if="scope.row.state == 0"
            >【启用】</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 表格编辑弹出框-->

    <el-dialog
      title="风控等级配置维护"
      :visible.sync="dialogFormVisible"
      :modal-append-to-body="false"
    >
      <el-form
        ref="formRiskBody"
        :model="formRiskBody"
        label-width="106px"
        :rules="rulesEditor"
      >
        <el-form-item label="风控等级" prop="grade">
          <el-select
            v-model="formRiskBody.grade"
            placeholder="请选择"
            class="riskLv-riskLvSelect"
          >
            <el-option
              v-for="item in optionsAdd"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <div class="riskDec2">
                             风控等级说明
                        </div>                   -->
        <el-form-item class="riskDec2Form" label="风控等级说明" prop="remark">
          <el-input type="textarea" v-model="formRiskBody.remark"></el-input>
        </el-form-item>

        <el-form-item label="排序号" class="orderForm" prop="orderNum">
          <el-input
            v-model="formRiskBody.orderNum"
            type="number"
            min="0"
          ></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="state">
          <el-radio-group v-model="formRiskBody.state" class="rideo-class">
            <el-radio :label="0">停用</el-radio>
            <el-radio :label="1">启用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="riskConfirm">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 尾部 -->
    <div class="riskLv-page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rulesEditor: {
        grade: [
          { required: true, message: "请输入活动名称", trigger: "change" },
        ],
        remark: [
          { required: true, message: "请输入等级说明", trigger: "blur" },
        ],
        orderNum: [
          { required: true, message: "请输入排序号", trigger: "blur" },
        ],
        state: [{ required: true, message: "请选择状态", trigger: "change" }],
      },
      rules: {
        grade: [
          { required: true, message: "请输入活动名称", trigger: "change" },
        ],
        remark: [
          { required: true, message: "请输入等级说明", trigger: "blur" },
        ],
        orderNum: [
          { required: true, message: "请输入排序号", trigger: "blur" },
        ],
        state: [{ required: true, message: "请选择状态", trigger: "change" }],
      },
      optionsAdd: [
        {
          value: 1,
          label: 1,
        },
        {
          value: 2,
          label: 2,
        },
        {
          value: 3,
          label: 3,
        },
        {
          value: 4,
          label: 4,
        },
        {
          value: 5,
          label: 5,
        },
      ],

      // currentPage4:3,
      dialogFormVisibleHeader: false,
      dialogFormVisible: false,
      total: 3, //总的条数
      // 获取页面列表参数
      pages: {
        page: 1, //第几页
        pageSize: 10, //每页显示数
      },

      //编辑弹出框
      formRiskBody: {
        grade: 1,
        remark: "",
        orderNum: 2,
        state: 0,
        id: 1,
      },
      //表头弹出框列表/新增不传id
      formRiskAdd: {
        grade: "", // 风控等级
        remark: "", // 风控等级说明
        orderNum: "", // 排序号
        state: "", // 状态
      },

      //表格数据
      risktableData: [
        {
          grade: 0,
          id: 0,
          orderNum: 0,
          remark: "",
          state: 0,
        },
      ],
    };
  },
  methods: {
    changeState(idForm, stateForm) {
      this.axios
        .get("/business/admin/grade/updateState", {
          params: {
            id: idForm,
            state: stateForm,
          },
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.$notify({
              title: "成功",
              message: "修改状态成功",
              type: "success",
              positon: "top-left",
              duration: 5000,
            });
          } else {
            this.$notify({
              title: "失败",
              message: "修改状态失败",
              type: "error",
              duration: 5000,
            });
          }
          console.log(res);
          this.getRiskList();
        });
    },
    editor(row) {
      // debugger
      this.dialogFormVisible = true;
      // console.log(row);
      this.formRiskBody.grade = row.grade;
      this.formRiskBody.remark = row.remark;
      this.formRiskBody.orderNum = row.orderNum;
      this.formRiskBody.state = row.state;
      this.formRiskBody.id = row.id;
      console.log(row);
    },
    // 新增 确定按钮
    riskHeaderConfirm() {
      this.axios
        .put("/business/admin/grade/save", this.formRiskAdd)
        .then((res) => {
          console.log(res);
          this.getRiskList();
          this.dialogFormVisibleHeader = false;
          if (res.data.status == "fail") {
            this.$message({
              showClose: true,
              message: `新增错误: \xa0 \xa0 ${res.data.msg}`,
              type: "error",
            });
          }
        });
    },
    riskConfirm() {
      this.dialogFormVisible = false;
      this.axios
        .put("/business/admin/grade/save", this.formRiskBody)
        .then((res) => {
          if (res.data.status == "success") {
            this.$notify({
              title: "成功",
              message: "修改成功",
              type: "success",
              position: "top-left",
              duration: 5000,
            });
            this.dialogFormVisibleHeader = false; // 弹窗开启/关闭
          } else {
            this.$notify({
              title: "失败",
              message: "修改失败",
              type: "error",
              position: "top-left",
              duration: 5000,
            });
          }
          console.log(res);
          this.getRiskList();
        });
    },
    requestRiskList() {
      // this.axios.get('/business/admin/grade/grade-info').then((res)=>{
      //     console.log(res)
      // })
    },
    handleSizeChange(val) {
      this.pages.pageSize = val;
      this.getRiskList();
    },

    handleCurrentChange(val) {
      this.pages.page = val;
      this.getRiskList();
    },

    //表头添加按钮事件
    dialogFormVisibleAdd() {
      this.dialogFormVisibleHeader = true;
      this.$refs.formRiskAdd.resetFields();
    },

    //获取页面风控等级列表
    getRiskList() {
      this.axios
        .get("/business/admin/grade/grade-info", { params: this.pages })
        .then((res) => {
          console.log(res);
          if (res.data.status == "fail") {
            this.$router.push("/");
          } else {
            this.risktableData = res.data.data.list;
            this.total = res.data.data.total;
            this.pages.page = res.data.data.pageNum;
          }
          //  this.pages.page=res.data.data.page
        });
    },
  },

  mounted() {
    this.getRiskList();
  },

  beforeRouteLeave(to, from, next) {
    if (
      to.name == "Index" ||
      to.name == "Exchange" ||
      to.name == "Team" ||
      to.name == "exConfiguration" ||
      to.name == "account" ||
      to.name == "Userconf" ||
      to.name == "exchangeObject"
    ) {
      this.getRiskList();
    }
    next();
  },
};
</script>

<style>
.riskLv-page {
  /* margin-left:160px; */
  /* width: 1500px; */
  /* margin-left: -60px;
       margin-top: 80px; */
  /* margin-right: 51.25rem; */
  text-align: center;
  margin-top: 1rem;
}

/* .form1Class{
       width: 350px;
       margin: 20px auto ;
   } */

/* .selectClass label{
       width: 96px;
       transform: translate(-10px,-30px);
   } */

/* .orderForm{
       width: 450px;
       margin:auto
   } */


.riskLv-table {
  /* margin-left: 1060px; */
  width: 75rem;
  height: 31.25rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

</style>
