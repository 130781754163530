<!-- 系统设置 - 跟单账户自动划账配置信息 -->
<template>
  <div class="user-group">
    <cloud-title title="跟单账户自动划账配置信息" />
    <!-- input -->
    <div class="role-wrapper">
      <div class="title">自动划账配置</div>

      <div class="content-wrapper">
        <el-form ref="netMulForm" style="margin-top: 40px;" :model="formInput">
          <el-form-item class="switchBtn" prop="switchNetKey">
            <el-switch
              v-model="formInput.switchNetKey"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </el-form-item>
          <el-form-item style="width: 100%" label="初始化净值比例：" prop="expectNetKey">
            <div class="fonts">喊单账户</div>
            <el-select style="width: 100px" v-model="formInput.expectNetKey" placeholder="">
              <el-option label="1:1" :value="1" />
              <el-option label="1:2" :value="2" />
              <el-option label="1:3" :value="3" />
              <el-option label="1:4" :value="4" />
              <el-option label="1:5" :value="5" />
            </el-select>
            <div class="fonts">跟单账户</div>
          </el-form-item>

          <el-form-item style="width: 100%" label="自动转账阈值：" prop="autoNetKey">
            <div class="fonts">喊单账户净值低于</div>
            <el-select style="width: 100px" v-model="formInput.autoNetKey" placeholder="">
              <el-option label="1:1" :value="1" />
              <el-option label="1:2" :value="2" />
              <el-option label="1:3" :value="3" />
              <el-option label="1:4" :value="4" />
              <el-option label="1:5" :value="5" />
            </el-select>
            <div class="fonts">跟单账户时，自动划账到跟单账户，维持初始化净值比例</div>
          </el-form-item>

          <div class="prompt">备注：每5分钟自动校正资金，如果达到划账设置的阈值，自动划账。</div>
          <el-form-item>
            <button style="padding:12px 30px" class="confirmBtn" @click="updateNetMul">确定保存</button>
            <button style="margin-left: 14px;padding:12px 30px" class="cancelBtn">取消</button>
          </el-form-item>
        </el-form>
      </div>

    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
import SystemManage from "../../common/utils/api/system";
// import { Component } from 'vue-property-decorator';

// @Component({
//   name: 'UserGroup'
// })
export default {
  data() {
    return {
      formInput: {}
    };
  },
  methods: {
    // 获取比例
    getNetMul() {
      this.listLoading = true;
      SystemManage.getNetMul().then((res) => {
        this.listLoading = false;
        if (res.status === "success") {
          this.formInput = {
            expectNetKey: res.data.expectNetMul,
            autoNetKey: res.data.autoNetMul,
            switchNetKey: res.data.switchNetMul
          };
          this.formInput.expectNetKey = Number(this.formInput.expectNetKey);
          this.formInput.autoNetKey = Number(this.formInput.autoNetKey);
          this.formInput.switchNetKey = JSON.parse(this.formInput.switchNetKey);
          this.listLoading = false;
        } else {
          return false;
        }
      });
    },

    // 确定保存
    updateNetMul() {
      SystemManage.updateNetMul(this.formInput).then(res => {
        if (res.status === "success") {
          this.$notify({
            title: "操作提示",
            offset: 100,
            type: res.status,
            message: "修改成功！"
          });
          this.getNetMul();
        } else {
          this.$notify({
            title: "操作提示",
            offset: 100,
            type: "error",
            message: res.message
          });
        }
      });
    },
  },

  created() {
    this.getNetMul();
  },
};
</script>

<style lang="scss">
.role-wrapper{
  padding: 20px;
  position: relative;
  .title{
    font-size: 18px;
    color: #333;
    font-weight: bold;
  }
  .switchBtn{
    position: absolute;
    top: 22px;
    left: 180px;
  }
  .el-form-item {
    display: flex;
    .el-form-item__label {
      font-size: 16px;
      font-weight: bold;
    }
    .el-form-item__content {
      display: flex;
      .fonts{
        font-size:15px;
        margin:0 20px
      }
    }
  }
}
.prompt {
  font-size: 20px;
  font-weight: bold;
  color: red;
  margin: 20px 0 50px;
}
</style>