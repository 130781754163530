import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import "element-ui/lib/theme-chalk/index.css";
// import element from "./element/index";
import ElementUI from 'element-ui'
import "../src/common/style/index.scss";
import axios from "axios";
import { getQueryString } from "./common/utils/index";
import "./permission";
import * as echarts from "echarts";
import components from "./components";
import qs from "qs";
import preventClick from "./preventClick";

// const baseUrl=process.env.NODE_ENV==='development'
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// axios.defaults.baseURL = '/api'
Vue.prototype.$getQueryString = getQueryString;

function getBaseUrl() {
  // return "http://localhost:8080/";  // 本地
  // return 'https://adminfk.jysmall.com/'; // 测试
  return 'https://jysfkadmin.jysywk.com/'; // 正式
  // if (url.includes("/business")) {
  //   // return "http://localhost:8080/";  // 测试
  // } else if (url.includes("/gdSystem")) {
  //   // return "https://docadmin.jysmall.com/"; // 测试
  //   return 'https://docadmin.jysywk.com/'; // 正式
  // }
}

axios.interceptors.request.use(
  (config) => {
    let token = window.sessionStorage.getItem("token");
    console.log(
      "----------------------------------------------------------------------"
    );

    console.info("start");
    console.log("url:" + config.url);
    config.headers.token = token;

    config.baseURL = getBaseUrl(config.url);
    // console.log("baseURL:" + config.baseURL);

    config.params
      ? console.log("params:" + JSON.stringify(config.params))
      : null;
    console.info("end");
    console.log(
      "----------------------------------------------------------------------"
    );

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// axios.defaults.headers.post['Content-Type'] = 'form-data';
// axios.defaults.headers.get['Content-Type'] = 'form-data';
// axios.defaults.transformRequest = [function (data) {
//   let ret = ''
//   for (let it in data) {
//     ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
//   }
//   return ret
// }]

Vue.prototype.axios = axios;

Vue.prototype.$echarts = echarts;
Vue.prototype.$qs = qs;
Vue.prototype.$eventBus = new Vue();
Vue.use(ElementUI)
// Vue.use(element);
Vue.use(components);
Vue.use(preventClick);

// Vue.config.productionTip = false

new Vue({ 
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
