/*
 * @Author: chengengfeng
 * @Date: 2019-07-24 16:13:03
 * @Last Modified by: chengengfeng
 * @Last Modified time: 2019-09-05 17:35:03
*/
import Vue from 'vue';
import Vuex from 'vuex';
// import { Commit } from 'vuex';
import user from './modules/user';
// import types from './mutation-types';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user
  },
});
