<template>
  <div class="account">
    <!-- 表头组件 -->
    <div class="account-header">
      <el-select
        v-model="valueExchange"
        clearable
        placeholder="交易所"
        @clear="clearExchange"
        class="account-header-selectOne"
      >
        <el-option
          v-for="item in optionsExchangeList"
          :key="item.value"
          :label="item.exchangeName"
          :value="item.exchangeId"
        ></el-option>
      </el-select>

      <el-select
        v-model="valueStatus"
        clearable
        placeholder="状态"
        @clear="clearStatus"
        class="account-header-selectTwo"
      >
        <el-option
          v-for="item in optionsStatusList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="searchAccount"
        class="account-header-search"
      >搜索</el-button>

      <el-button type="warning" @click="AddAcount" class="account-header-add">新增</el-button>
    </div>

    <!-- 表格组件 -->
    <div class="account-table">
      <el-table :data="tableData" border class="account-tableElementUi" style="width:100%;">
        <el-table-column prop="exchangeName" label="交易所名称"></el-table-column>
        <el-table-column prop="accountType" label="账户类别">
          <template slot-scope="scope">
            <div>{{ scope.row.accountType == 1 ? "主账户" : "子账户" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="accountName" label="账户名称"></el-table-column>
        <el-table-column prop="orderNum" label="排序号"></el-table-column>
        <el-table-column prop="state" label="状态">
          <template slot-scope="scope">
            <div>{{ scope.row.state == 1 ? "启用" : "停用" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="usedDay" label="API使用天数"></el-table-column>
        <el-table-column prop="opration" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="accountEditor(scope.row.id, scope.$index)">编辑</el-button>
            <el-button
              type="text"
              v-if="scope.row.state == 0"
              @click="changeState(scope.row.id, 1)"
            >启用</el-button>
            <el-button type="text" v-else @click="changeState(scope.row.id, 0)">停用</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页按钮 -->

    <div class="account-pagi">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageList.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageList.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 新增弹窗 -->
    <el-dialog
      title="账户配置维护"
      :visible.sync="dialogFormVisibleAdd"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      center
    >
      <!-- <el-table :data="gridData">
                <el-table-column property="date" label="日期" width="150"></el-table-column>
                <el-table-column property="name" label="姓名" width="200"></el-table-column>
                <el-table-column property="address" label="地址"></el-table-column>
      </el-table>-->
      <el-form :model="ruleFormAdd" ref="ruleFormAdd" label-width="100px" :rules="rulesAdd">
        <el-form-item label="交易所" prop="exchangeAddValue">
          <el-select v-model="ruleFormAdd.exchangeAddValue" clearable placeholder="请选择">
            <el-option
              v-for="item in optionsExchangeSelect"
              :key="item.id"
              :label="item.exchangeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账户名称" prop="accountAddInput">
          <el-input v-model="ruleFormAdd.accountAddInput" clearable></el-input>
        </el-form-item>

        <el-form-item label="排序号" prop="sortAccountAddInput">
          <el-input v-model="ruleFormAdd.sortAccountAddInput" clearable></el-input>
        </el-form-item>

        <el-form-item label="主账户" prop="IDAddAccountInput">
          <el-input v-model="ruleFormAdd.IDAddAccountInput" clearable></el-input>
        </el-form-item>

        <el-form-item prop="keyAddAccountInput" label="key">
          <el-input v-model="ruleFormAdd.keyAddAccountInput" clearable></el-input>
        </el-form-item>

        <el-form-item prop="secretAddAccountInput" label="秘钥">
          <el-input v-model="ruleFormAdd.secretAddAccountInput" clearable></el-input>
        </el-form-item>

        <el-form-item prop="passphraseAddAccountInput" label="passphrase">
          <el-input v-model="ruleFormAdd.passphraseAddAccountInput" clearable></el-input>
        </el-form-item>

        <el-form-item prop="masterAccountBalancePass" label="主账户资金密码" label-width="110px">
          <el-input v-model="ruleFormAdd.masterAccountBalancePass" clearable></el-input>
        </el-form-item>

        <el-form-item label="子账户" prop="IDAddChildrenAccountInput">
          <el-input v-model="ruleFormAdd.IDAddChildrenAccountInput" clearable></el-input>
        </el-form-item>

        <span class="risk_title">风控API</span>

        <el-form-item prop="keyRiskAccountInput" label="key">
          <el-input v-model="ruleFormAdd.keyRiskAccountInput" clearable></el-input>
        </el-form-item>

        <el-form-item prop="secreRiskAccountInput" label="秘钥">
          <el-input v-model="ruleFormAdd.secreRiskAccountInput" clearable></el-input>
        </el-form-item>

        <el-form-item prop="passphraseRiskAccountInput" label="passphrase">
          <el-input v-model="ruleFormAdd.passphraseRiskAccountInput" clearable></el-input>
        </el-form-item>

        <el-form-item prop="remarkRiskAccountInput" label="备注">
          <el-input v-model="ruleFormAdd.remarkRiskAccountInput" clearable></el-input>
        </el-form-item>

        <span class="risk_title">交易API</span>

        <el-form-item prop="keyExchangeAccountInput" label="key">
          <el-input v-model="ruleFormAdd.keyExchangeAccountInput" clearable></el-input>
        </el-form-item>

        <el-form-item prop="secreExchangeAccountInput" label="秘钥">
          <el-input v-model="ruleFormAdd.secreExchangeAccountInput" clearable></el-input>
        </el-form-item>

        <el-form-item prop="passphraseExchangeAccountInput" label="passphrase">
          <el-input v-model="ruleFormAdd.passphraseExchangeAccountInput" clearable></el-input>
        </el-form-item>

        <el-form-item prop="remarkExchangeAccountInput" label="备注">
          <el-input v-model="ruleFormAdd.remarkExchangeAccountInput" clearable></el-input>
        </el-form-item>
        <span class="risk_title">备用子账号</span>
        <el-form-item label="ID" prop="IDSpareAccountInput">
          <el-input v-model="ruleFormAdd.IDSpareAccountInput" clearable></el-input>
        </el-form-item>

        <el-form-item prop="keySpareAccountInput" label="key">
          <el-input v-model="ruleFormAdd.keySpareAccountInput" clearable></el-input>
        </el-form-item>

        <el-form-item prop="secretSpareAccountInput" label="秘钥">
          <el-input v-model="ruleFormAdd.secretSpareAccountInput" clearable></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="statusAdd">
          <el-radio-group v-model="ruleFormAdd.statusAdd">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">停用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleAdd = false">取 消</el-button>
        <el-button type="primary" @click="AddConfirm('ruleFormAdd')">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 编辑弹框 -->
    <el-dialog title="账户配置维护" :visible.sync="dialogFormVisibleBody" :modal-append-to-body="false">
      <!-- <div class="containerEditor"> -->
      <el-form ref="formEditor" :model="formEditor" label-width="82px" :rules="editorRules">
        <el-form-item label="交易所" class="ExchangeSpan" prop="exchangeName">
          <!-- <span class="ExchangeSpan">交易所</span> -->
          <el-select
            v-model="formEditor.exchangeId"
            clearable
            placeholder="请选择"
            class="ExchangeClass"
          >
            <el-option
              v-for="item in optionsExchangeSelectEditor"
              :key="item.id"
              :label="item.exchangeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <div class="accountClass">
        <span>账户名称</span>-->
        <el-form-item label="账户名称" class="accountClass" prop="accountName">
          <el-input v-model="formEditor.accountName" clearable class="accountInput"></el-input>
        </el-form-item>
        <!-- <div class="sortClass">
        <span>排序号</span>-->
        <el-form-item label="排序号" class="sortClass" prop="orderNum">
          <el-input v-model="formEditor.orderNum" clearable class="sortInput"></el-input>
        </el-form-item>
        <!-- </div> -->
        <!-- <div class="mainAccount">
        <span>主账户</span>-->
        <el-form-item label="主账户ID" class="mainAccount">
          <el-input v-model="formEditor.masterAccountId" class="mainAccountClass" clearable></el-input>
          <!-- </div> -->
        </el-form-item>
        <!-- <div class="keyClass"> -->
        <el-form-item class="keyClass" label="key">
          <el-input v-model="formEditor.masterAccountKey" clearable></el-input>
        </el-form-item>
        <!-- </div> -->

        <!-- <div class="secret"> -->
        <el-form-item class="secret" label="秘钥">
          <el-input v-model="formEditor.masterAccountSecret" clearable></el-input>
          <!-- </div> -->
        </el-form-item>
        <!-- <div class="accountChildren">   
        <span>子账户</span>-->
        <el-form-item label="子账户ID" class="accountChildren">
          <el-input v-model="formEditor.subAccountId" clearable class="IDChildren"></el-input>
          <!-- </div>    -->
        </el-form-item>
        <span class="risk_title">风控API</span>
        <!-- <div class="riskLvKey"> -->
        <el-form-item class="riskLvKey" label="key" prop="apiKey">
          <el-input v-model="formEditor.businessAccountSecrets[0].apiKey" clearable></el-input>
        </el-form-item>
        <!-- </div> -->
        <!-- <div class="secretRisk"> -->
        <el-form-item class="secretRisk" label="秘钥" prop="apiSecret">
          <el-input v-model="formEditor.businessAccountSecrets[0].apiSecret" clearable></el-input>
        </el-form-item>
        <!-- </div> -->

        <!-- <div class="secretRisk"> -->
        <el-form-item class="secretAPI" label="apiPass">
          <el-input v-model="formEditor.businessAccountSecrets[0].apiPass" clearable></el-input>
          <!-- </div> -->
        </el-form-item>
        <!-- </div> -->

        <!-- <div class="remarkRisk">  -->
        <el-form-item class="remarkRisk" label="备注">
          <el-input type="textarea" v-model="formEditor.businessAccountSecrets[0].remark" clearable></el-input>
        </el-form-item>

        <!-- <div v-if="formEditor.businessAccountSecrets[1]"> -->
        <div>
          <!-- </div> -->
          <span class="risk_title">交易API</span>
          <!-- <div class="riskKey"> -->
          <el-form-item class="riskKey" label="key" prop="apiKey1">
            <el-input v-model="formEditor.businessAccountSecrets[1].apiKey" clearable></el-input>
          </el-form-item>
          <!-- </div> -->
          <!-- <div class="secretAPI"> -->
          <el-form-item class="secretAPI" label="秘钥" prop="apiSecret1">
            <el-input v-model="formEditor.businessAccountSecrets[1].apiSecret" clearable></el-input>
            <!-- </div> -->
          </el-form-item>
          <el-form-item class="secretAPI" label="apiPass">
            <el-input v-model="formEditor.businessAccountSecrets[1].apiPass" clearable></el-input>
            <!-- </div> -->
          </el-form-item>
          <!-- <div class="remarkAPI"> -->
          <el-form-item class="remarkAPI" label="备注">
            <el-input
              type="textarea"
              v-model="formEditor.businessAccountSecrets[1].remark"
              clearable
            ></el-input>
          </el-form-item>
        </div>
        <!-- </div> -->
        <!-- <div class="spareAccount">
        <span>备用子账户</span>-->
        <span class="risk_title">备用子账户</span>
        <el-form-item class="spareAccount" label="ID">
          <!-- <div class="spareAccount-title">
                            <span>备用子账户</span>
          </div>-->
          <el-input v-model="formEditor.spareSubAccountId" clearable></el-input>
        </el-form-item>
        <!-- <div class="spareKey"> -->
        <el-form-item class="spareKey" label="key">
          <el-input v-model="formEditor.spareSubAccountKey" clearable></el-input>
          <!-- </div> -->
        </el-form-item>
        <!-- <div class="spareSecret"> -->
        <el-form-item class="spareSecret" label="秘钥">
          <el-input v-model="formEditor.spareSubAccountSecret" clearable></el-input>
        </el-form-item>
        <!-- </div> -->
        <!-- </div> -->
        <!-- <span>状态</span> -->
        <el-form-item label="状态" prop="state">
          <el-radio-group v-model="formEditor.state" class="radioStatus">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">停用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <!-- </div>    -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleBody = false">取 消</el-button>
        <el-button type="primary" @click="editorConfirm">确 定</el-button>
      </div>
      <!-- </el-form> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accountEditorFromServer: 123,
      editorRules: {
        exchangeName: [
          { required: true, message: "请输入交易所名称", trigger: "change" }
        ],
        accountName: [
          { required: true, message: "请输入账户名称", trigger: "blur" }
        ],
        orderNum: [
          { required: true, message: "请输入排序号名称", trigger: "blur" }
        ],
        state: [
          { required: true, message: "请输入状态名称", trigger: "change" }
        ],
        apiKey: [{ required: true, message: "请输入key", trigger: "change" }],
        apiSecret: [
          { required: true, message: "请输入秘钥", trigger: "change" }
        ],
        apiPass: [
          { required: true, message: "请输入apiPass", trigger: "change" }
        ],
        apiKey1: [{ required: true, message: "请输入key", trigger: "change" }],
        apiSecret1: [
          { required: true, message: "请输入秘钥", trigger: "change" }
        ]
      },
      rulesAdd: {
        exchangeAddValue: [
          { required: true, message: "请输入交易所名称", trigger: "change" }
        ],
        accountAddInput: [
          { required: true, message: "请输入账户名称", trigger: "blur" }
        ],
        sortAccountAddInput: [
          { required: true, message: "请输入排序号名称", trigger: "blur" }
        ],
        statusAdd: [
          { required: true, message: "请输入状态名称", trigger: "blur" }
        ],
        keyRiskAccountInput: [
          { required: true, message: "请输入key", trigger: "blur" }
        ],
        secreRiskAccountInput: [
          { required: true, message: "请输入秘钥", trigger: "blur" }
        ],
        keyExchangeAccountInput: [
          { required: true, message: "请输入key", trigger: "blur" }
        ],
        secreExchangeAccountInput: [
          { required: true, message: "请输入秘钥", trigger: "blur" }
        ]
      },
      ruleFormAdd: {
        exchangeAddValue: "", //交易所下拉框
        accountAddInput: "", //账户名称 input框
        sortAccountAddInput: "", //排序号
        IDAddAccountInput: "", //主账户id号
        keyAddAccountInput: "", //主账户key值
        secretAddAccountInput: "", //主账户秘钥
        passphraseAddAccountInput: "", //主账户passphrase
        masterAccountBalancePass: "", // 主账户资金密码
        IDAddChildrenAccountInput: "", //子账户ID值
        keyRiskAccountInput: "", //风控key值
        secreRiskAccountInput: "", //风控秘钥值
        passphraseRiskAccountInput: "", //风控passphrase
        remarkRiskAccountInput: "", //风控备注
        keyExchangeAccountInput: "", //交易API key值
        secreExchangeAccountInput: "", //交易API 秘钥值
        passphraseExchangeAccountInput: "", //交易API passphase
        remarkExchangeAccountInput: "", //交易API 备注
        IDSpareAccountInput: "", //备用子账户ID
        keySpareAccountInput: "", //备用子账户key
        secretSpareAccountInput: "", //备用子账户秘钥
        statusAdd: "" //状态值
      },
      // secretAddAccountInput:"",            //主账户秘钥
      // keyAddAccountInput:"",          //新增页面key值
      // IDAddAccountInput:"",                     //新增页面ID号
      // sortAccountAddInput:"",                //新增页面排序号
      // accountAddInput:"",                    //新增页面账户名称
      exchangeAddOptions: [], //新增页面交易所下拉
      // exchangeAddValue:"",           //新增页面交易所下拉 返回的model
      optionsExchangeSelectEditor: [],
      dialogFormVisibleBody: false, //控制编辑弹窗
      // valueExchangeSelect:"",
      formEditor: {
        exchangeName: "",
        accountName: "",
        orderNum: "",
        masterAccountId: "",
        masterAccountKey: "",
        masterAccountSecret: "",
        subAccountId: "",
        businessAccountSecrets: [
          {
            id: "",
            accountId: "",
            apiKey: "",
            apiSecret: "",
            remark: "",
            apiType: 1,
            apiPass: null
          },
          {
            id: "",
            accountId: "",
            apiKey: "",
            apiSecret: "",
            remark: "",
            apiType: 2,
            apiPass: null
          }
        ]
        //          inputAddchildrenSpareAccount3:"",
        //      inputAddchildrenSpareAccount2:"",
        //     inputAddchildrenSpareAccount1:"",
        //     inputAddchildrenAccount7:"",
        //       inputAddchildrenAccount6:"",
        //       inputAddchildrenAccount5:"",
        //       inputAddchildrenAccount4:"",
        //      inputAddchildrenAccount3:"",
        //       inputAddchildrenAccount2:"",
        //       inputAddchildrenAccount1:"",
        //      radioAdd:"",                //
        //       inputAddMainAccount3:"",          //编辑页面主账户  model
        //       inputAddMainAccount2:"",         //编辑页面主账户  model
        //        inputAddMainAccount1:"",           //编辑页面主账户 model
        //        inputAddSort:"",           //编辑排序号 model
        //        inputAddAccount:"",       //编辑账户 model
        //        accountName:"",               //编辑账户  账户名称下拉 MOdel
      },
      // formAdd:{
      //     inputAddchildrenSpareAccount3:"",
      //     inputAddchildrenSpareAccount2:"",
      //     inputAddchildrenSpareAccount1:"",
      //     inputAddchildrenAccount7:"",
      //     inputAddchildrenAccount6:"",
      //     inputAddchildrenAccount5:"",
      //     inputAddchildrenAccount4:"",
      //     inputAddchildrenAccount3:"",
      //     inputAddchildrenAccount2:"",
      //     inputAddchildrenAccount1:"",
      //     radioAdd:"",                //
      //     inputAddMainAccount3:"",          //新增页面主账户  model
      //     inputAddMainAccount2:"",         //新增页面主账户  model
      //     inputAddMainAccount1:"",           //新增页面主账户 model
      //     inputAddSort:"",           //新增排序号 model
      //     inputAddAccount:"",       //新增账户 model
      //     accountName:"",           //新增弹窗交易所 下拉列表返回值
      // },
      valueExchange: "", //表头交易所下拉返回的值
      valueStatus: "", //表头状态 下拉返回的值
      optionsExchangeSelect: [], //弹窗交易所 下拉列表
      // inputAddchildrenSpareAccount3:"",
      // inputAddchildrenSpareAccount2:"",
      // inputAddchildrenSpareAccount1:"",
      // inputAddchildrenAccount7:"",
      // inputAddchildrenAccount6:"",
      // inputAddchildrenAccount5:"",
      // inputAddchildrenAccount4:"",
      // inputAddchildrenAccount3:"",
      // inputAddchildrenAccount2:"",
      // inputAddchildrenAccount1:"",
      // radioAdd:"",                //
      // inputAddMainAccount3:"",          //新增页面主账户  model
      // inputAddMainAccount2:"",         //新增页面主账户  model
      // inputAddMainAccount1:"",           //新增页面主账户 model
      // inputAddSort:"",           //新增排序号 model
      // inputAddAccount:"",       //新增账户 model
      // valueExchangeSelect:"",           //新增弹窗交易所 下拉列表返回值
      // valueExchange:"",  //表头交易所下拉返回的值
      // valueStatus:"",      //表头状态 下拉返回的值
      optionsStatusList: [
        {
          value: 1,
          label: "启用"
        },
        {
          value: 0,
          label: "停用"
        }
      ], //表头状态下拉列表
      // test1: false,
      // test2: this.test1,
      optionsExchangeList: [], //表头交易所下拉列表
      // addParams:{
      //   accountName:this.formAdd.inputAddAccount,
      //   businessAccountSecrets:[
      //       {
      //           apiKey:this.formAdd.inputAddchildrenAccount2,
      //           apiSecret:this.formAdd.inputAddchildrenAccount3,
      //           apiType:1,
      //           remark:this.formAdd.inputAddchildrenAccount4
      //       },
      //       {
      //           apiKey:this.formAdd.inputAddchildrenAccount5,
      //           apiSecret:this.formAdd.inputAddchildrenAccount6,
      //           apiType:0,
      //           remark:this.formAdd.inputAddchildrenAccount7
      //       }
      //   ],
      //    exchangeName:this.formAdd.exchangeName,
      //    orderNum:this.formAdd.inputAddSort,         //排序号
      //    masterAccountId:this.formAdd.inputAddMainAccount1,       //主账户  id
      //    masterAccountKey:this.formAdd.inputAddMainAccount2,          //主账户 key
      //    masterAccountSecret:this.formAdd.inputAddMainAccount3,        //主账户 secret
      //    subAccountId:this.formAdd.childrenAccount1,                      //子账户id
      //    spareSubAccountId:this.formAdd.inputAddchildrenSpareAccount1     ,//备用子账户id
      //    spareSubAccountKey:this.formAdd.inputAddchildrenSpareAccount2  , //备用子账户key
      //    spareSubAccountSecret:this.formAdd.inputAddchildrenSpareAccount3 , //备用子账户秘钥
      //    state:this.formAdd.radioAdd
      // },
      // valueExchangeSelect:"",
      // formAccount:[],      //
      pageList: {
        page: 1, //   第几页
        pageSize: 10 //一页多少行代码
      },
      total: 4,
      dialogFormVisibleAdd: false,
      currentPage4: 4,
      //状态select双向绑定值
      // valueStatus:'',
      //交易所双向绑定值
      // value:"",

      tableData: []
    };
  },
  methods: {
    // 编辑修改
    editorConfirm() {
      this.axios
        .put("/business/admin/account/save", {
          accountName: this.formEditor.accountName,
          businessAccountSecrets: [
            {
              accountId: this.formEditor.businessAccountSecrets[0].accountId,
              apiKey: this.formEditor.businessAccountSecrets[0].apiKey,
              apiPass: this.formEditor.businessAccountSecrets[0].apiPass,
              apiSecret: this.formEditor.businessAccountSecrets[0].apiSecret,
              apiType: this.formEditor.businessAccountSecrets[0].apiType,
              id: this.formEditor.businessAccountSecrets[0].id,
              remark: this.formEditor.businessAccountSecrets[0].remark,
              type: this.formEditor.businessAccountSecrets[0].type,
              valid: this.formEditor.businessAccountSecrets[0].valid
            },
            {
              accountId: this.formEditor.businessAccountSecrets[1].accountId,
              apiKey: this.formEditor.businessAccountSecrets[1].apiKey,
              apiPass: this.formEditor.businessAccountSecrets[1].apiPass,
              apiSecret: this.formEditor.businessAccountSecrets[1].apiSecret,
              apiType: this.formEditor.businessAccountSecrets[1].apiType,
              id: this.formEditor.businessAccountSecrets[1].id,
              remark: this.formEditor.businessAccountSecrets[1].remark,
              type: this.formEditor.businessAccountSecrets[1].type,
              valid: this.formEditor.businessAccountSecrets[1].valid
            }
          ],
          exchangeId: this.formEditor.exchangeId,
          exchangeName: this.optionsExchangeSelectEditor.filter(x => {
            return x.id == this.formEditor.exchangeId;
          })[0].exchangeName,
          id: this.formEditor.id,
          masterAccountApiPass: this.formEditor.masterAccountApiPass,
          masterAccountBalancePass: this.formEditor.masterAccountBalancePass,
          masterAccountId: this.formEditor.masterAccountId,
          masterAccountKey: this.formEditor.masterAccountKey,
          masterAccountSecret: this.formEditor.masterAccountSecret,
          orderNum: this.formEditor.orderNum,
          spareSubAccountId: this.formEditor.spareSubAccountId,
          spareSubAccountKey: this.formEditor.spareSubAccountKey,
          spareSubAccountPass: this.formEditor.spareSubAccountPass,
          spareSubAccountSecret: this.formEditor.spareSubAccountSecret,
          state: this.formEditor.state,
          subAccountId: this.formEditor.subAccountId
        })
        .then(res => {
          console.log(res);
          if (res.data.status == "fail") {
            this.$message.error(res.data.msg);
          } else {
            this.$notify({
              title: "成功",
              message: "修改成功",
              type: "success",
              position: "top-left",
              duration: 5000
            });
          }
          this.dialogFormVisibleBody = false; // 关闭编辑弹窗
          this.getTableList();
        });
    },
    clearStatus() {
      this.searchAccount();
    },
    clearExchange() {
      this.searchAccount();
    },
    // 获取下拉组合数据
    getAddExchange() {
      this.axios.get("/business/admin/exchange/comboExchange").then(res => {
        console.log(res);
        this.optionsExchangeSelect = res.data.data;
      });
    },
    //获取表格列表
    getTableList() {
      console.log(this.pageList);
      // debugger;
      this.axios
        .get("/business/admin/account/account-info", {
          params: this.pageList
        })
        .then(res => {
          console.log("获取表格列表:", res);
          if (res.data.status == "fail") {
            this.$router.push("/");
          } else {
            this.tableData = res.data.data.list;
            this.total = res.data.data.total;
            this.pageList.pageSize = res.data.data.pageSize;
            this.pageList.page = res.data.data.pageNum;
            this.optionsExchangeList = res.data.data.list;
          }
          //  this.optionsStatusList=res.data.data.list;
        });
    },
    // 编辑按钮
    async accountEditor(idFromForm, a) {
      // this.$refs.formEditor.resetFields();
      console.log("id:", idFromForm, "a:", a);
      await this.axios
        .get("/business/admin/account/account-info", {
          params: {
            id: idFromForm
          }
        })
        .then(res => {
          console.log(res);
          this.formEditor = res.data.data;
          this.dialogFormVisibleBody = true; // 弹窗开关
          // debugger;
          let data = JSON.parse(
            JSON.stringify(this.formEditor.businessAccountSecrets)
          );
          for (
            let i = this.formEditor.businessAccountSecrets.length;
            i < 2;
            i++
          ) {
            let dataForm = {
              accountId: "",
              id: "",
              apiKey: "",
              apiSecret: "",
              apiPass: "",
              remark: "",
              valid: "",
              apiType: ""
            };
            data.push(dataForm);
          }
          this.formEditor.businessAccountSecrets = data;
        });

      await this.axios
        .get("/business/admin/exchange/comboExchange")
        .then(res => {
          console.log(res);
          this.optionsExchangeSelectEditor = res.data.data;
          //   this.formEditor.valueExchangeSelect=res.data.data[0].exchangeName
        });
    },
    changeState(idForm, stateForm) {
      this.axios
        .get("/business/admin/account/updateState", {
          params: {
            id: idForm,
            state: stateForm
          }
        })
        .then(res => {
          if (res.data.status == "success") {
            this.$notify.error({
              title: "成功",
              message: res.data.msg,
              type: "success",
              positon: "top-left",
              duration: 5000
            });
          } else {
            this.$notify({
              title: "失败",
              message: res.data.msg,
              type: "error",
              duration: 5000
            });
          }
          console.log(res);
          this.getTableList();
        });
    },
    //   getteamInfoList(){
    //       this.axios.get('/business/admin/team/team-info').then(
    //           (res)=>{
    //            console.log(res);
    //           }
    //       )
    //   },
    handleSizeChange(val) {
      console.log(val);
      this.pageList.pageSize = val;
      this.getTableList();
    },
    handleCurrentChange(val) {
      console.log(val);
      this.pageList.page = val;
      this.getTableList();
    },
    //点击表头确定按钮
    AddConfirm(val) {
      this.$refs[val].validate(valid => {
        if (valid) {
          this.axios
            .put("/business/admin/account/save", {
              accountName: this.ruleFormAdd.accountAddInput,
              businessAccountSecrets: [
                {
                  apiKey: this.ruleFormAdd.keyRiskAccountInput,
                  apiPass: this.ruleFormAdd.passphraseRiskAccountInput,
                  apiSecret: this.ruleFormAdd.secreRiskAccountInput,
                  apiType: 1,
                  remark: this.ruleFormAdd.remarkRiskAccountInput
                },
                {
                  apiKey: this.ruleFormAdd.keyExchangeAccountInput,
                  apiPass: this.ruleFormAdd.passphraseExchangeAccountInput,
                  apiSecret: this.ruleFormAdd.secreExchangeAccountInput,
                  apiType: 2,
                  remark: this.ruleFormAdd.remarkExchangeAccountInput
                }
              ],
              exchangeId: this.ruleFormAdd.exchangeAddValue,
              masterAccountApiPass: this.ruleFormAdd.passphraseAddAccountInput,
              masterAccountId: this.ruleFormAdd.IDAddAccountInput,
              masterAccountKey: this.ruleFormAdd.keyAddAccountInput,
              masterAccountSecret: this.ruleFormAdd.secretAddAccountInput,
              orderNum: this.ruleFormAdd.sortAccountAddInput,
              spareSubAccountId: this.ruleFormAdd.IDSpareAccountInput,
              spareSubAccountKey: this.ruleFormAdd.keySpareAccountInput,
              // spareSubAccountPass:this.ruleFormAdd.
              spareSubAccountSecret: this.ruleFormAdd.secretSpareAccountInput,
              state: this.ruleFormAdd.statusAdd,
              subAccountId: this.ruleFormAdd.IDAddChildrenAccountInput,
              masterAccountBalancePass: this.ruleFormAdd
                .masterAccountBalancePass
            })
            .then(res => {
              console.log(res);
              if (res.data.status == "success") {
                this.dialogFormVisibleAdd = false; // 返回成功再关闭添加弹窗
                this.$refs["ruleFormAdd"].resetFields(); // 返回成功再清空表单数据
              } else if (res.data.status == "fail") {
                this.$message.error(res.data.msg);
              }
              // this.$refs.ruleFormAdd.resetFields();
            });
        }
      });
    },
    //点击搜索按钮
    searchAccount() {
      //点击搜索
      this.axios
        .get("/business/admin/account/account-info", {
          params: {
            page: this.pageList.page,
            pageSize: this.pageList.pageSize,
            exchangeId: this.valueExchange,
            state: this.valueStatus
          }
        })
        .then(res => {
          console.log(res);
          this.tableData = res.data.data.list;
          this.total = res.data.data.total;
        });
      //   this.axios.get()
    },
    //点击新增按钮
    AddAcount() {
      this.dialogFormVisibleAdd = true;
      // this.axios.get("/business/admin/exchange/comboExchange").then(res => {
      //   console.log(res);
      //   this.exchangeAddOptions = res.data.data;
      // });
      this.$refs["ruleFormAdd"].resetFields();
      //   this.axios.put('/business/admin/account/save').then(()=>{

      //   })
    }
  },
  mounted() {
    // console.log(this.test2);
    this.getTableList();
    this.getAddExchange(); // 获取下拉组合数据
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name == "riskLv" ||
      to.name == "exchangeObject" ||
      to.name == "Team" ||
      to.name == "Index" ||
      to.name == "Exchange" ||
      to.name == "Userconf" ||
      to.name == "exConfiguration"
    ) {
      this.getTableList();
      this.valueExchange = "";
      this.valueStatus = "";
    }
    next();
  }
};
</script>

<style >
/* .ExchangeClass{
      margin-left: 31px;
  } */
.risk_title {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
  display: block;
}
.account-header {
  /* margin-left:10px */
  display: flex;
  width: 62.5rem;
  margin-top: 1.25rem;
  margin-left: 1.25rem;
}

.account-header-selectOne {
  width: 200px;
}
.account-header > .el-select:nth-child(2) {
  /* margin-left:10px */
}

.el-button--primary {
  /* margin-left:10px */
}

.account > .el-table {
  /* width:40%; */
  /* margin-left:1060px; */
  /* margin-top: 30px; */
}

.account-pagi {
  /* margin-left: -60px;
      margin-top: 50px; */
  margin-top: 1rem;
  /* margin-right: 18.75rem; */
  text-align: center;
}
/* .container{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  } */
.accountClass {
  /* display: flex; */
  /* margin-top: 20px; */
}

/* .accountInput{
      margin-left: 17px;
  } */

/* .sortClass{
      display: flex;
      margin-top: 20px;
  } */

/* .sortInput{
      margin-left: 29px;
  } */

/* .mainAccount{
      display: flex;
      margin-top: 40px;
  } */

/* .mainAccountClass{
      margin-left: 27px;
  } */

.keyClass {
  /* margin-top: 20px; */
  /* margin-left: 28px; */
}

/* .secret{
      margin-top: 20px;
      margin-left: 67px;
  } */

/* .IDChildren{
      width: 250px;
      margin-left: 25px;
  } */

.riskAPI {
  /* margin-top: 10px; */
}

/* .riskKey{
      margin-top: 20px;
      margin-left: 67px;
  } */

.riskLvKey {
  /* margin-left: 67px; */
  /* margin-top: 20px; */
}

/* .secretRisk{
      margin-left: 67px;
      margin-top: 20px;
  } */

/* .remarkRisk{
      margin-left: 67px;
      margin-top: 20px;
  } */

.accountChildren {
  /* margin-top: 30px; */
}

/* .secretAPI{
      margin-top: 20px;
      margin-left: 67px;
  } */

/* .remarkAPI{
      margin-top: 20px;
      margin-left: 67px;
  } */

.spareAccount {
  /* margin-top: 20px; */
}

/* .spareKey{
      margin-top: 20px;
      margin-left: 67px;
  } */

/* .spareSecret{
      margin-top: 20px;
      margin-left: 67px;
  } */

.radioStatus {
  /* margin-left: -130px; */
}

/* .primary{
      margin-top: 10px;
  } */
.riskKey {
  /* margin-top: 20px; */
}
/* .el-form-item >.el-form-item__label{
      width: 82px;
  } */
/* .spareAccount{
      width: 483px;
  } */
.spareAccount-title {
  float: left;
  margin-top: 0.125rem;
  margin-left: -5rem;
}
.account-tableElementUi {
  /* height: 200px; */
  width: 75rem;
  height: 31.25rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.account-header-search {
  margin-left: 1.25rem;
}

.account-table {
  margin-top: 20px;
  margin-left: 20px;
}

.account-header-selectTwo {
  margin-left: 1.25rem;
}

.account-header-add {
  width: 6rem;
}
</style>