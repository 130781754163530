<!-- sidebar -->
<script>
// import { Component } from "vue-property-decorator";

// Component({
//   name: "LayoutSideBar"
// })
export default {
  props: {
    menuList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  render() {
    // 渲染父级菜单
    const renderMenuList = (routes) => {
      return routes.map((item, index) => {
        if (!item.hidden && item.children) {
          return (
            <el-submenu index={index + ""}>
              <template slot="title">
                {item.meta && item.meta.icon ? (
                  <span class={[item.meta.icon]}></span>
                ) : null}
                {item.meta && item.meta.title ? (
                  <span slot="title">{item.meta.title}</span>
                ) : null}
              </template>
              {getChildMenu(item.children, item)}
            </el-submenu>
          );
        } else {
          return null;
        }
      });
    };

    // 拥有子菜单的情况下渲染子菜单
    const getChildMenu = (childList, items) => {
      return childList.map((child) => {
        if (!child.hidden) {
          if (child.children && child.children.length) {
            return (
              <sidebar-item is-nest={true} class="nest-menu" routes={[child]} />
            );
          } else {
            return (
              <router-link
                to={{
                  path: items.path + "/" + child.path,
                  query: { menuId: child.id },
                }}
              >
                <el-menu-item
                  index={`${items.path}/${child.path}`}
                  // on-click={() => this.setActive(items.path, child.path)}
                >
                  {child.meta && child.meta.title ? (
                    <span slot="title">{child.meta.title}</span>
                  ) : null}
                </el-menu-item>
              </router-link>
            );
          }
        } else {
          return null;
        }
      });
    };

    window.console.log("菜单组件：", renderMenuList(this.menuList));
    return <div>{renderMenuList(this.menuList)}</div>;
  },

  setActive(path, childPath) {
    window.console.log(`当前访问菜单路径：${path}/${childPath}`);
  },
};
</script>